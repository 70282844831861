<template> 
  <div v-if="rules && disabled_toggle == false">
    <ValidationProvider
      :name="error_message"
      :rules="!disabled_toggle ? rules:''"
      v-slot="{ valid, errors }"
    >
      <b-input-group class="mb-4">
        <b-form-select
          id="functional_relevance"
          name="example-input-2"
          v-model="selected_option"
          :options="options"
          :state="errors[0] ? false : (valid ? true : null)"
          aria-describedby="functional_relevance_error"
          :disabled="disabled_toggle"
          @change="send_value"
        ></b-form-select>
        <b-form-invalid-feedback v-if="error_message">{{ errors[0] }}</b-form-invalid-feedback>
        <b-form-invalid-feedback v-else>This Field is required</b-form-invalid-feedback>
      </b-input-group>
    </ValidationProvider>
  </div>
  <div v-else>
    <b-input-group class="mb-4">
      <b-form-select
        ref="functional_relevance"
        id="functional_relevance"
        name="example-input-2"
        v-model="selected_option"
        :options="options"
        aria-describedby="functional_relevance_error"
        :disabled="disabled_toggle"
        @change="send_value($event)"
      ></b-form-select>
    </b-input-group>
  </div>
</template>
<script>
import { EventBus } from "../../../../main";
export default {
  props: [ "label", "options", "disabled_toggle", "error_message", "rules", "name" ],
  data() {
    return {
      selected_option: null
    };
  },
  watch: {
    options() {
      this.selected_option = null;
    }
  },
  methods: {
    send_value(event) {
      var sel = document.getElementById("functional_relevance");
      let data = {
        name: this.name,
        data_val: this.selected_option,
        text: sel.options[sel.selectedIndex].text
      };
      EventBus.$emit("send_static_dropdown_value", data);
    }
  },
  created() {
    EventBus.$on("clearData", clear => {
      this.selected_option = null;
    });
    EventBus.$on("setData", data => {
      this.selected_option = data;
    });
  }
};
</script>