<template>
  <div class="pre-test-question">
    <div class="def-func-input-group-col float-right btn-rename-delete mb-0">
      <b-button type="button" :disabled="savebtn == true" variant="light" class="mt-4" @click.prevent="savemsg">Save</b-button>
    </div>
    <div class="clearfix"></div>
    <div class="brief-rounded-sectn">
      <b-row>
        <b-col>
          <label class="distribute-group-label">Setup final test questions</label>
        </b-col>
      </b-row>
      <div class="quesn-outer">
        <div class="def-func-input-group">
          <div class="def-func-input-group-col briefing-station-code">
            <b-row align-v="start">
              <b-col sm="5" xl="7">
                <div class="justify-content-space-between">
                  <div class="width-100">
                    <div class="required-field">
                      <label>Question</label>
                      <b-input-group>
                        <b-form-input v-model="question" placeholder="Question"></b-form-input>
                      </b-input-group>
                      <div class="attachment-btn">
                        <b-input-group-append is-text>
                          <font-awesome-icon :icon="['fas', 'paperclip']" />
                        </b-input-group-append>
                        <b-form-file accept="image/jpeg, image/png, .jpg, .png, .jpeg" ref="fileInput" id="fileInput"
                        class="faq-fileInput" v-model="url" @change="onFileChange($event)"></b-form-file>
                      </div>
                      <p class="feedback-error" v-show="imgError.valMsg">{{ imgError.errorMsg }}</p>
                      <p class="feedback-error" v-show="errorIncompleteData">Complete set of previous question</p>
                      <div class="feedback-error" v-if="noQuestionError"><p v-if="!question.trim()">This field is required</p></div>
                    </div>
                  </div>
                  <div class="ml-3 pt-4 mt-3">
                    <b-icon v-b-tooltip.hover.top="'Only jpg, jpeg or png allowed'" icon="info" class="info-icon-size"></b-icon>
                  </div>
                </div>
              </b-col>
              <b-col sm="2" xl="2">
                <div class="required-field">
                  <label>Points</label>
                  <b-input-group>
                    <b-form-input v-model="points" @change="calPoints($event)" @keypress="isNumber($event)" placeholder="Points"></b-form-input>
                  </b-input-group>
                  <div class="feedback-error" v-show="noPointsError"><p v-if="!points">Enter points</p><p v-if="points == 0 && points">Points should be greater than zero</p></div>
                  <p class="feedback-error" v-show="exceedScoreError">Points exceed total score</p>
                </div>
              </b-col>
                <b-col sm="3" xl="2">
                <label>Remaining Points</label>
                <b-input-group>
                  <b-form-input v-model="totalPoints" value="" disabled></b-form-input>
                </b-input-group>
              </b-col>
              <b-col sm="2" xl="1">
                <div class="def-func-input-group-col briefing-station-code-btn">
                  <button
                    type="submit"
                    variant="light"
                    class="btn add-btn th-add-btn"
                    :disabled="incompleteData"
                    @click.prevent="addQuestions"
                  >+</button>
                </div>
              </b-col>
              <b-col v-if="imgUpload.length > 0" class="text-center">
                <div class="preview" v-for="( data, imgIndex ) in imgUpload" :key="imgIndex">
                  <img :src="data.url" />
                  <div class="clearfix"></div>
                  <div class="training-questn-col questn-action">
                    <span><img src="@/assets/images/delete.png" @click="onImgDelete(imgIndex)" title="Delete" role="button" tabindex="0"></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <b-row>
        <b-col sm="12">
          <appTrainingQuestion :questions_get="questions" :questCheck="question"></appTrainingQuestion>
        </b-col>
      </b-row>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import training_questions from "./training_questions/training_questions";
import { EventBus } from "../../../../main";
import { HTTP_formdata } from "../../../../constants/http-common";
export default {
  props: [ "selected_training" ],
  components: {
    appTrainingQuestion: training_questions
  },
  data() {
    return {
      noQuestionError: false,
      noPointsError: false,
      exceedScoreError: false,
      noPointstotalError:false,
      totalPoints: 0,
      totalPoints_save: 0,
      question: "",
      questions: [],
      points: "",
      remaining_points: "",
      questiondetails: [],
      first_save:true,
      pointsCal: 0,
      savebtn: true,
      messsage_loader: false,
      incompleteData: false,
      errorIncompleteData: false,
      imgUpload: [],
      url: null,
      imgError: {
        valMsg: false,
        errorMsg: ""
      }
    };
  },
  created() {
    EventBus.$on("send-deleted-points", remainingQuestion => {
      this.pointsCal = this.pointsCal - remainingQuestion;
      this.totalPoints = this.totalPoints + eval(remainingQuestion);
      this.totalPoints = this.totalPoints_save - this.pointsCal;
    });
    EventBus.$on("send-final-quest-total-points", totalPoints_save =>{
      this.totalPoints_save = totalPoints_save
      this.totalPoints = (this.totalPoints == 0 && this.questions.length == 0) ? totalPoints_save : (this.totalPoints_save - eval(this.pointsCal)) //this.totalPoints
    })
    EventBus.$on("quizIncomplete", incompleteData => {
      this.incompleteData = incompleteData.disable
      this.errorIncompleteData = incompleteData.error
    });
  },
  methods: {
    onFileChange(e) {
      if (this.imgUpload.length < 3 ) {
        if ( e.target.files.length > 0 && ( e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "png" || e.target.files[0].type == "jpeg" ) ) {
          this.imgError.valMsg = false;
          let file = e.target.files[0];
          this.imgUpload.push({ url: URL.createObjectURL(file), data: file })
        } else {
          this.imgError.valMsg = true;
          this.imgError.errorMsg = "Select a valid image"
          setTimeout(() => {
            this.imgError.valMsg = false;
            this.imgError.errorMsg = ""
          }, 2000);
        }
      } else {
        this.imgError.valMsg = true;
        this.imgError.errorMsg = "Maximum 3 Images allowed"
        setTimeout(() => {
          this.imgError.valMsg = false;
          this.imgError.errorMsg = ""
        }, 2000);
      }
    },
    onImgDelete(imgIndex) {
      this.imgUpload.splice(imgIndex, 1);
      this.url = null;
    },
    calPoints(event) {
      this.totalPoints = (this.totalPoints_save == 0) ? 0 : (this.totalPoints_save - this.pointsCal) - this.points;
    },
    savemsg(){
      this.points = "";
      this.question = "";
      this.noPointsError = false;
      this.noQuestionError = false;
      let response = {
        popup: true,
        msg: "Question added Successfully"
      }
      EventBus.$emit("success_resp", response)
    },
    addQuestions() {
      if(this.totalPoints == 0 && this.totalPoints_save != this.pointsCal){
        EventBus.$emit("send-root-total-points", this.totalPoints_save)
        this.totalPoints = this.totalPoints_save
        this.first_save = false
      }
      if (this.question.trim() == "") {
        this.noQuestionError = true;
      } else {
        this.noQuestionError = false;
      }
      if (this.points == "" || this.points == "0") {
        this.noPointsError = true;
      } else {
        this.noPointsError = false;
      }
      if (this.totalPoints_save == 0) {
        this.noPointstotalError = true;
        EventBus.$emit("zero-total-points", this.noPointstotalError)
      } else {
        this.noPointstotalError = false;
      }
      if (this.noQuestionError == false && this.noPointsError == false && this.noPointstotalError == false) {
        if (this.totalPoints > 0) {
          this.savebtn = false
          this.messsage_loader = true;
          let jsonData = null;
          if ( this.imgUpload.length > 0) {
            jsonData = new FormData();
            this.imgUpload.forEach(function( data, index ) {
              jsonData.set(`question_file${index + 1}`, data.data);
            });
          }
          HTTP_formdata.post("training/setup_question?question=" + this.question + "&quiz_type=final&points=" + this.points + "&training=" + this.selected_training + "&total_points=" + this.totalPoints_save, jsonData)
            .then(response => {
              this.messsage_loader = false;
              this.imgUpload = [];
              this.question=""
              response.data.question.options = [];
              response.data.question.newoptions = "";
              response.data.question.label=true;
              response.data.question.edit=false;
              response.data.question.optionError = false;
              response.data.question.maxOptionError = false;
              response.data.question.noQuestionError = false;
              response.data.question.imgError = false;
              response.data.question.errorMsg = "";
              let question = response.data.question;
              this.points = "";
              this.pointsCal = this.pointsCal + eval(response.data.question.points);
              this.totalPoints = this.totalPoints_save - this.pointsCal;

              EventBus.$emit("send-questionair", question);
            })
            .catch(error => {
            this.messsage_loader = false;});
        } else {
          this.exceedScoreError = true;
          setTimeout(() => {
            this.exceedScoreError = false;
          }, 2000);
        }
      } else {
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    get_innitial_data() {
      this.messsage_loader = true;
      this.questions = [];
      let form_data = {
        training: this.selected_training,
        quiz_type: "final",
        modules: "",
        update: true
      };
      HTTP_formdata.post("training/get_all_quiz_questions", form_data)
        .then(response => {
          this.totalPoints_save = response.data[0].total_points
          this.totalPoints = response.data[0].total_points
          this.pointsCal = 0;
          for (let i = 0; i < response.data.length; i++) {
            this.totalPoints = this.totalPoints - response.data[i].points;
            this.pointsCal = this.pointsCal + eval(response.data[i].points);
            if (response.data[i].options == null) {
              response.data[i].options = [];
            }
            response.data[i].label=true
            response.data[i].edit=false
            response.data[i].optionError = false;
            response.data[i].maxOptionError = false;
            response.data[i].noQuestionError = false;
            response.data[i].imgError = false;
            response.data[i].errorMsg = "";
          }
          this.questions = response.data;
          this.savebtn = false;
          EventBus.$emit("send-root-total-points", this.totalPoints_save)
          this.messsage_loader = false;
        })
        .catch(error => {
          this.messsage_loader = false;
        });
    }
  },
  mounted() {
    this.noQuestionError = false
    this.noPointsError = false
    this.exceedScoreError = false
    this.noPointstotalError = false
    this.get_innitial_data();
  }
};
</script>