<template>
  <div class="content-outer">
    <div class=" training-dashboard mt-3">
      <b-card no-body>
        <b-tabs card class="training-dashboard-tabs">
          <b-tab title="CANITY" active>
            <b-container>
              <b-row>
                <gaugeChartSection title="Canity" :data="canityGaugeData" />
                <canityStatsSection :data="canityStatsData" />
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title="SHOPTALK STATS">
            <b-container>
              <b-row >
                <gaugeChartSection title="ShopTalk" :data="shoptalkGaugeData" />
                <lineGraphSection :data="shoptalkStatsData" />
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
  import { EventBus } from "../../../main";
  import { HTTP, HTTP_formdata } from "../../../constants/http-common";
  import gaugeChartSection from "./gaugeChartSection";
  import lineGraphSection from "./lineGraphSection.vue";
  import canityStatsSection from './canityStatsSection.vue';
  export default {
    data() {
      return {
        canityGaugeData: {
          currentWeek: "",
          lastWeek: ""
        },
        shoptalkGaugeData: {
          currentWeek: "",
          lastWeek: "",
        },
        shoptalkStatsData: {},
        canityStatsData: {
          trainings: [],
          userCount: [],
          statistics: {
            label: [],
            value: []
          }
        }
      }
    },
    components: {
      gaugeChartSection,
      lineGraphSection,
      canityStatsSection
    },
    methods: {
      canityGauge() {
        HTTP.get( "canity/get_weekly_stats" )
          .then(response => {
            if ( response.status == 200 ) {
              this.canityGaugeData.currentWeek = response.data[0];
              this.canityGaugeData.lastWeek = ( response.data.length > 1 ) ? response.data[1] : '';
              EventBus.$emit( "canityGaugeSection", this.canityGaugeData );
            }
          })
          .catch(err => {});
      },
      shoptalkGauge() {
        HTTP.get( "training_stats/get_weekly_stats" )
          .then(response => {
            if ( response.status == 200 ) {
              this.shoptalkGaugeData.currentWeek = response.data[0];
              this.shoptalkGaugeData.lastWeek = ( response.data.length > 1 ) ? response.data[1] : '';
              EventBus.$emit( "shoptalkGaugeSection", this.shoptalkGaugeData );
            }
          })
          .catch(err => {});
      },
      shoptalkStats() {
        HTTP.get( "training_stats/get_graph_stats" )
          .then(response => {
            if ( response.status == 200 ) {
              this.shoptalkStatsData = response.data;
              this.shoptalkStatsData.statistics.forEach( ( item ) => {
                item.data.forEach( ( ele, index, arr ) => {
                  arr[index] = parseFloat( ele );
                });
              });
            }
          })
          .catch(err => {});
      },
      canityStats() {
        HTTP.get( "canity/get_graph_info" )
          .then(response => {
            if ( response.status == 200 ) {
              HTTP_formdata.post( "canity/get_graph_stats", { order: response.data.order } )
                .then(resp => {
                  if ( resp.status == 200 ) {
                    this.canityStatsData.trainings = response.data.trainings;
                    setTimeout(() => {
                      if ( response.data.categories.length > 0 ) {
                        let trainingName1 = document.getElementById('training_0').getBoundingClientRect();
                        let spaceBetweenElement = '';
                        if ( document.getElementById('training_1') ) {
                          let trainingName2 = document.getElementById('training_1').getBoundingClientRect();
                          spaceBetweenElement = trainingName2.top - trainingName1.bottom;
                        } else
                          spaceBetweenElement = trainingName1.bottom - trainingName1.top;
                        response.data.categories.forEach( ( item ) => {
                          item.height = item.training_count * trainingName1.height + item.training_count * spaceBetweenElement;
                        });
                      }
                      this.canityStatsData.categories = response.data.categories;
                      EventBus.$emit( "canityCategoryData", this.canityStatsData.categories );
                    }, 10);
                    this.canityStatsData.userCount = response.data.user_count;
                    this.canityStatsData.statistics.label = Array(resp.data.percentage.length).join(".").split(".");
                    this.canityStatsData.statistics.value = resp.data.percentage;
                    this.canityStatsData.statistics.value.forEach( ( item, index, arr ) => {
                      arr[index] = parseFloat( item );
                    });
                    EventBus.$emit( "canityStatsData", this.canityStatsData.statistics );
                  }
                })
                .catch(error => {});
            }
          })
          .catch(err => {});
      }
    },
    beforeMount() {
      this.shoptalkStats();
      this.canityStats();
      this.canityGauge();
      this.shoptalkGauge();
    }
  }
</script>
