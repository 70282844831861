<template>
    <b-modal id="modal-summary-pdf" 
    hide-footer
    size="lg"
    modal-class="flipModal manage-user-modal"
    title="Checklist Summary Preview"
    centered
    class="training-pdf-div">
        <div class="pdf-btns-inner">
            <b-button variant="light" @click="downloadPDF()" class="mt-4">Download</b-button>
        </div>
        <div id="training-pdf-outer">
            <b-container>
                <div ref="content">
                    <b-row>
                        <b-col>
                            <h3 class="mb-5">Checklist Summary</h3>
                        </b-col>
                    </b-row>
                    <b-row class="label-row">
                        <b-col><label>Filter Results by: <span>{{pdfDetails.filter}}</span></label></b-col>
                        <b-col><label>Value: <span>{{pdfDetails.valueFilter}}</span></label></b-col>
                        <!-- <b-col><label>Function: <span>{{pdfDetails.functionFilter}}</span></label></b-col> -->
                    </b-row>
                    <b-row class="label-row">
                        <b-col md="4"><label>From: <span>{{pdfDetails.from_date|moment}}</span></label></b-col>
                        <b-col md="4"><label>To: <span>{{pdfDetails.to_date|moment}}</span></label></b-col>
                    </b-row>
                    <div v-if="this.reportDetail.selected == 'responseRate'" class="def-func-input-form-group breifing-content pdf-print pt-3">
                        <div class="def-func-input-group pt-3 mb-0">
                            <div class="def-func-input-group-col width-10">
                                <label class="global-freq freq-resp-title check-title">Details - Completion Rate</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.reportDetail.selected == 'complianceRatio'" class="def-func-input-form-group breifing-content pdf-print pt-3">
                        <div class="def-func-input-group pt-3 mb-0">
                            <div class="def-func-input-group-col width-10">
                                <label class="global-freq freq-resp-title check-title">Details - Compliance Ratio</label>
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col>
                            <reportScoreTables :showScorePdf="showScorePdf" :selectedReport="reportDetail" :reportData="reportData.report_details"></reportScoreTables>
                        </b-col>
                    </b-row>
                </div>
                    <b-row>
                        <b-col class="reportTable-label">
                            <reportTables :selectedReport="reportDetail" :reportData="reportData"></reportTables>
                        </b-col>
                    </b-row>
            </b-container>
        </div>
        <div class="clearfix"></div>
    </b-modal>
</template>
<script>
import { EventBus } from "../../main";
import jsPDF from "jspdf";
import html2canvas  from 'html2canvas';
import reportTables from "../../components/admin_layouts/dashboard_layouts/reports_table";
import reportScoreTables from "../../components/admin_layouts/dashboard_layouts/report_score_table";
import moment from "moment";
import 'jspdf-autotable';

export default {
    data() {
        return{
            pdfDetails: {
                filter: "",
                valueFilter: "",
                // functionFilter: "",
                from_date: "",
                to_date: ""
            },
            reportDetail: {
                displayScope: false,
                displayRate: false,
                selected: ""
            },
            reportData: "",
            showScorePdf: ""
        }
    },
    components: {
        reportTables,
        reportScoreTables
    },
    filters: {
        moment: function(value) {
            if (value)
                return moment(value).format("DD MMM YYYY");
            else
                return "-";
        }
    },
    created: function() {
        EventBus.$on("downloadPDFClicked", pdfDetails => {
            this.pdfDetails = {
                from_date: pdfDetails.from_date,
                to_date: pdfDetails.to_date,
                filter: pdfDetails.filter,
                // functionFilter: ((pdfDetails.functionFilter == "Select") ? "-" : pdfDetails.functionFilter),
                valueFilter: ((pdfDetails.valueFilter == "Select") ? "-" : pdfDetails.valueFilter)
            };
            this.reportDetail = pdfDetails.reportDetail;
            this.reportData = pdfDetails.reportData;
            this.showScorePdf = "";
            if ( this.reportDetail.selected != "responseRate" || this.reportDetail.selected != "complianceRatio" ) {
                setTimeout(() => {
                    this.showScorePdf = this.reportDetail.selected;
                }, 500);
            }
        });
    },
    methods: {
        downloadPDF(){
            var canvasElement = document.createElement('canvas');
            let tableName = ''
            if (this.reportDetail.selected == 'responseRate')
                tableName = 'responseRate'
            else if (this.reportDetail.selected == 'complianceRatio') 
                tableName = 'complianceRatio'
            html2canvas(this.$refs.content, {scale: 1,scrollX: 0, scrollY: 0}).then(function (canvas) {
                var imgData = canvas.toDataURL('image/png');
                var imgWidth = 181; 
                var pageHeight = 285;  
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;
                var doc = new jsPDF('p', 'mm', 'a4');
                var position = 0;
                doc.addImage(imgData, 'PNG', 15, 15 , imgWidth, imgHeight);
                heightLeft -= pageHeight;
                const tableTop = imgHeight * 1.28;
                if ( tableName != '' ) {
                    const html = '';
                    if (tableName == 'responseRate') {
                        var res = doc.autoTableHtmlToJson(document.getElementById('responseRateTable'));
                    }
                    else if (tableName == 'complianceRatio') {
                        var res = doc.autoTableHtmlToJson(document.getElementById('complianceRatioTable'));
                    }
                    doc.autoTable( res.columns, res.data,{  
                        styles: { halign: 'center' },
                        startY: doc.pageCount > 1? doc.autoTableEndPosY() + 20 : tableTop,
                        headStyles: {
                            fillColor: [0, 50, 97],
                            fontSize: 10,
                            halign : 'left',
                            0: { halign: 'left' }, 1: { halign: 'left' }
                        },
                        columnStyles: { 0: { halign: 'left' }, 1: { halign: 'left' } }
                    })
                    doc.fromHTML(html, 15, 15, {
                        width: 210,
                        height: 295,
                    });
                } else {
                    while ( heightLeft >= 0 ) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }
                }
                doc.save('Checklist Summary.pdf');
            });
        }
    }
}
</script>
<style scoped>
.training-pdf-div {margin: 100px auto 20px;width: 754px;}
#training-pdf-outer {width: 722px;min-height: 1060px;color: #485882;padding: 28px 0px 0;text-align: center;margin: 0 auto;max-width: 100%;}
#modal-summary-pdf label {color: #333;font-size: 14px;}
#modal-summary-pdf .pdf-btns-inner {margin: auto;text-align: right;max-width: 100%;padding: 0 1.5rem;}
#modal-summary-pdf .label-row {text-align: left;margin-bottom: 10px;}
#modal-summary-pdf label span {text-transform: capitalize;font-size: 14px;}
#modal-summary-pdf .def-func-table {margin-top: 0;}
#modal-summary-pdf .def-func-table tbody th, #modal-summary-pdf .def-func-table tbody td {padding: 6px 10px;}
#modal-training-pdf.manage-user-modal .modal-body {padding: 0;}
#modal-training-pdf.manage-user-modal .modal-dialog {max-width: 772px;}
#modal-summary-pdf th {font-size: 12px;padding: 5px 10px;height: auto;}
#modal-summary-pdf .def-func-table thead th span, #modal-summary-pdf table td {border: none;}
#modal-summary-pdf table {border-collapse: collapse;}
#modal-summary-pdf .carousel.slide a[role="button"], #modal-summary-pdf .carousel.slide .carousel-indicators {display: none;}
#modal-summary-pdf label.scoreOptions-title, #modal-summary-pdf label.scoreOptions-title {padding: 10px;text-align: left;}
#modal-summary-pdf label.scoreOptions-title {width: 50%;}
#modal-summary-pdf label.scoreOptions-title span {display: inline-block;}
#modal-summary-pdf .score-options.report-details {flex-wrap: wrap;justify-content: flex-start;}
#modal-summary-pdf .training-options-body {padding: 10px;text-align: left;}
#modal-summary-pdf .perform-content-col {width: 100%;max-width: 100%;}
#modal-summary-pdf .perform-image-col {display: none;}
#modal-summary-pdf .perform-image-col .carousel-item {margin-bottom: 0;}
#modal-summary-pdf .perform-content {margin-top: 30px;}
#modal-summary-pdf .training-questn-col.questn-number {width: 30px;}
#modal-summary-pdf .training-questn-col.questn:not(.border-bottom) {text-align: left;padding-left: 10px;}
#modal-summary-pdf .training-questns-section.mt-3 {margin-top: 2rem !important;margin-bottom: 1.5rem !important;}
#modal-summary-pdf .training-options-section.mb-5 {margin-bottom: 5rem !important;}
#modal-summary-pdf .breifing-content .def-func-input-group-col.width-10 {padding: 0;}
#modal-summary-pdf .reportTable-label .def-func-input-form-group.breifing-content > .def-func-input-group {display: none;}
#modal-summary-pdf tbody tr:nth-child(2n+1) {background: #f5f5f5;}
#modal-summary-pdf tbody tr td {border: none;}
#modal-summary-pdf .score-options.report-details {flex-wrap: wrap;}
#modal-summary-pdf label.scoreOptions-title {width: 50%;text-align: left;}
</style>