<template>
  <div v-if="rules && (disabled_toggle == false || disabled_toggle==null)">
    <ValidationProvider
      :name="error_message"
      :rules="!disabled_toggle ? rules:''"
      v-slot="{ valid, errors }"
    >
      <label for="input-1">{{ label }}</label>
      <b-input-group>
        <b-form-input
          :placeholder = placeholder
          id="functional_relevance"
          name="example-input-2"
          v-model="inputed_values"
          @keypress="isNumber($event)"
          @keyup="send_data"
          :state="errors[0] ? false : (valid ? true : null)"
          aria-describedby="functional_relevance_error"
          :disabled="disabled_toggle"
        ></b-form-input>
        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
      </b-input-group>
    </ValidationProvider>
  </div>
  <div v-else>
    <label for="input-1">{{ label }}</label>
    <b-input-group>
      <b-form-input
      :placeholder = placeholder
        id="functional_relevance"
        name="example-input-2"
        v-model="inputed_values"
        @keypress="isNumber($event)"
        aria-describedby="functional_relevance_error"
        :disabled="disabled_toggle"
      ></b-form-input>
    </b-input-group>
  </div>
</template>
<script>
import { EventBus } from "../../../../main";
export default {
  props: [ "label", "options", "disabled_toggle", "error_message", "rules", "name", "placeholder" ],
  data() {
    return {
      inputed_values: ""
    };
  },
  methods: {
    send_data() {
      let send_data = {
        name: this.name,
        data_val: this.inputed_values
      };
      EventBus.$emit("static-required-number-field", send_data);
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>