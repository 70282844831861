<template>
  <div>
    <!-- <b-button @click="showModal" class="btn" variant="light">No internet</b-button> -->

    <b-modal ref="modal-no-internet" v-model="modalShow"
      modal-class="flipModal manage-user-modal text-center" 
      title="No Internet Connection" id="modal-no-internet" centered hide-footer>
      <div class="d-block">
        <p class="font-16">No Internet connection! Make sure that your internet is turned on, then try again.</p>
      </div>
      <b-button class="mt-3" variant="light" @click="hideModal">Okay</b-button>
    </b-modal>
  </div>
</template>

<script>
  import { EventBus } from "../../../../main";
  export default {
    data() {
      return {
        modalShow: null
      }
    },
    watch: {
      modalShow( val ) {
        if ( val == false ) {
          EventBus.$emit( "noConnectionOk", 'Okay' );
        }
      }
    },
    methods: {
      hideModal() {
        this.$refs['modal-no-internet'].hide();
        EventBus.$emit( "noConnectionOk", 'Okay' );
      }
    }
  }
</script>