<template>
  <vue-bootstrap-typeahead
    :data="typeData"
    ref="typeaheadVendor"
    v-model="typesearch"
    :serializer="item => item.name"
    placeholder="Vendor"
    :minMatchingChars="1"
    @hit="selectedTypeAhead = $event"
  ></vue-bootstrap-typeahead>
</template>
<script>
  import { HTTP } from "../../../constants/http-common";
  import { EventBus } from "../../../main";
  export default {
    props: [ 'apiFunction' ],
    data() {
      return {
        typeData: [],
        typesearch: "",
        selectedTypeAhead: null
      };
    },
    watch: {
      typesearch(newQuery) {
        if (newQuery) {
          HTTP.get( this.apiFunction + newQuery )
            .then(response => {
              if ( response.status == 200 ) {
                this.typeData = response.data.vendors;
              }
            })
            .catch(error => {
              if (this.$refs.typeaheadVendor)
                this.$refs.typeaheadVendor.inputValue = ''
              this.typeData = [];
            });
        } else {
          if (this.$refs.typeaheadVendor)
            this.$refs.typeaheadVendor.inputValue = ''
          this.typeData = [];
          EventBus.$emit( "typeaheadSearchVendor", this.typesearch );
        }
      },
      selectedTypeAhead(newQuery) {
        EventBus.$emit( "typeaheadSearchVendor", newQuery );
      }
    },
    created() {
      EventBus.$on("clearVendorData", clear => {
        if (this.$refs.typeaheadVendor)
          this.$refs.typeaheadVendor.inputValue = ''
        this.typesearch = "";
      });
      EventBus.$on("vendorData", data => {
        if (this.$refs.typeaheadVendor)
          this.$refs.typeaheadVendor.inputValue = data
        this.typesearch = data;
      });
    }
  };
</script>