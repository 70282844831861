<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class="clearfix"></div>
                  <div class="def-func-input-group">
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                      <b-form @submit.prevent="passes( addChecklist )">
                        <div class="def-func-input-group-col width-7">
                          <ValidationProvider
                            name="Checklist name"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Checklist Name</label>
                            </div>
                            <b-form-input
                              :state="errors[0] ? false : ( valid ? true : null )"
                              v-model="addChecklistData.title"
                              placeholder="Checklist Name"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col width-5">
                          <ValidationProvider
                            name="Function"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Functional Relevance</label>
                            </div>
                            <b-form-select
                              :state="errors[0] ? false : ( valid ? true : null )"
                              v-model="addChecklistData.function"
                              :options="functionResult"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col btn-add-outer width-1">
                          <button type="submit" variant="primary" class="btn add-btn th-add-btn">+</button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
                <checklistTable :checklistResult="checklistResult"></checklistTable>
                <b-pagination
                  v-model="paginate_params.page"
                  :total-rows="rows"
                  :per-page="paginate_params.limit"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                ></b-pagination>
                <b-row>
                  <b-col>
                    <div class="def-func-input-form-group">
                      <div class="clearfix"></div>
                      <div class="def-func-input-group">
                        <ValidationObserver ref="observerEdit" v-slot="{ passes }">
                          <b-form @submit.prevent="passes( editChecklist )">
                            <div class="def-func-input-group-col width-7">
                              <ValidationProvider
                                name="Checklist"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Rename Selected Checklist</label>
                                </div>
                                <b-form-input :disabled="checklistId == ''"
                                  :state="errors[0] ? false : ( valid ? true : null )"
                                  v-model="editChecklistData.title" placeholder="Rename Checklist"
                                />
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col width-5">
                              <ValidationProvider
                                name="Function"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Select Function</label>
                                </div>
                                <b-form-select :disabled="checklistId == ''"
                                  :state="errors[0] ? false : ( valid ? true : null )"
                                  v-model="editChecklistData.function"
                                  :options="functionResult"
                                ></b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col btn-rename-delete width-1">
                              <button :disabled="checklistId == ''" type="submit" variant="light" class="btn th-add-btn">Rename</button>
                            </div>
                          </b-form>
                        </ValidationObserver>
                        <div class="def-func-input-group-col btn-rename-delete width-1">
                          <button :disabled="checklistId == ''" v-b-modal.confirm-delete @click="delete_popup" variant="light" class="btn th-add-btn">Delete</button>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="clearfix"></div>
      </b-container>
    </div>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
import checklist_table from "../../components/admin_layouts/dashboard_layouts/checklist_table";
import confirm_modal from "../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { EventBus } from "../../main";
export default {
  data() {
    return {
      functionResult: [{
        value: null,
        text: "Select",
        disabled: true
      }],
      addChecklistData: {
        title: "",
        function: null
      },
      editChecklistData: {
        title: "",
        function: null,
        dependency: ""
      },
      checklistResult: [],
      checklistId: '',
      rows: 10,
      paginate_params: {
        page: 1,
        limit: "10"
      },
      response: {
        msg: "",
        popup: false
      }//,
      // dependencies: ""
    };
  },
  components: {
    checklistTable: checklist_table,
    confirmModal: confirm_modal
  },
  watch: {
    "paginate_params.page": {
      handler() {
        this.checklistResult = [];
        this.getChecklistList();
        deep: true;
      }
    }
  },
  methods: {
    getFuncList() {
      HTTP.get("functions/display_functions")
        .then(response => {
          for (var i = 0; i < response.data.length; i++) {
            this.functionResult.push({
              value: response.data[i].public_id,
              text: response.data[i].title
            });
          }
        })
        .catch(err => {});
    },
    addChecklist() {
      HTTP_formdata.post("audits/create_audits", this.addChecklistData)
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.addChecklistData.title = "";
            this.addChecklistData.function = null;
            this.editChecklistData.dependency = ""
            this.$nextTick(() => {
              this.$refs.observer.reset();
            });
            this.getChecklistList();
          }
        })
        .catch(error => {});
    },
    getChecklistList() {
      this.editChecklistData.title = "";
      this.editChecklistData.function = null;
      this.editChecklistData.dependency = "";
      // this.dependencies = ""
      this.$nextTick(() => {
        this.$refs.observerEdit.reset();
      });
      HTTP_formdata.post("audits/get_audits", this.paginate_params)
        .then(response => {
          this.checklistResult = response.data.data;
          this.rows = response.data.totalLength;
        })
        .catch(error => {});
    },
    editChecklist() {
      HTTP_formdata.put( "audits/edit_audits/" + this.checklistId, this.editChecklistData )
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data.message
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editChecklistData.title = "";
            this.editChecklistData.function = null;
            this.editChecklistData.dependency = "";
            // this.dependencies = ""
            this.$nextTick(() => {
              this.$refs.observerEdit.reset();
            });
            this.getChecklistList();
            this.checklistId = "";
            EventBus.$emit("UnCheckAll", this.checklistId);
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.response.msg = error.response.data.message
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
            this.editChecklistData.title = "";
            this.editChecklistData.function = null;
            this.editChecklistData.dependency = "";
            this.$nextTick(() => {
              if (this.$refs.observerEdit)
                this.$refs.observerEdit.reset();
            });
            // this.getChecklistList();
            this.checklistId = "";
            EventBus.$emit("UnCheckAll", this.checklistId);
          }
        });
    },
    deleteChecklist() {
      let deletecheck = {
        audit: this.checklistId,
        dependency: this.editChecklistData.dependency
      }
      HTTP_formdata.post( "audits/delete_checklist", deletecheck )
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data.message
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editChecklistData.title = "";
            this.editChecklistData.function = null;
            this.editChecklistData.dependency = "";
            // this.dependencies = ""
            this.$nextTick(() => {
              if (this.$refs.observerEdit)
                this.$refs.observerEdit.reset();
            });
            this.getChecklistList();
            this.checklistId = "";
            EventBus.$emit("UnCheckAll", this.checklistId);
          }
        })
        .catch(error => {
          if ( error.response.data.code == 400 ) {
            this.response.msg = error.response.data.message
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
            this.editChecklistData.title = "";
            this.editChecklistData.function = null;
            this.editChecklistData.dependency = "";
            // this.dependencies = ""
            this.$nextTick(() => {
              if (this.$refs.observerEdit)
                this.$refs.observerEdit.reset();
            });
            this.getChecklistList();
            this.checklistId = "";
            EventBus.$emit("UnCheckAll", this.checklistId);
          } else {
            this.response.msg = "Select a Checklist to be deleted"
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    delete_popup() {
      EventBus.$emit( "deleteSelected", this.editChecklistData.title );
    }
  },
  beforeMount() {
    this.getFuncList();
    this.getChecklistList();
  },
  created() {
    EventBus.$on("selectChecklist", checklistSelectedData => {
      this.checklistId = checklistSelectedData.public_id;
      this.editChecklistData.title = checklistSelectedData.title;
      this.editChecklistData.function = checklistSelectedData.function_id;
      // this.dependencies = checklistSelectedData.dependencies
      this.editChecklistData.dependency = checklistSelectedData.dependencies;
    });
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteChecklist();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
    EventBus.$off("selectChecklist")
  }
};
</script>
<style>
  @import "./def_function.css";
</style>