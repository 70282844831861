<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="dashboard-items-row">
                <span v-for="( result, index ) in dashboardResponse" :key="index">
                  <div class="dashboard-items" v-if="result.accessRights == ''" @click="redirectURL( result.title )">
                    <p class="dashboard-item-name">{{ result.title }}</p>
                    <img class="dashboard-item-icon" :src="require(`@/assets/images/${result.imageIcon}`)">
                    <span class="badge">{{ result.count | count }}</span>
                  </div>
                  <div class="dashboard-items" v-else-if="result.accessRights != '' && loggedin_user_details.access_rights.includes( result.accessRights )" @click="redirectURL( result.title )">
                    <p class="dashboard-item-name">{{ result.title }}</p>
                    <img class="dashboard-item-icon" :src="require(`@/assets/images/${result.imageIcon}`)">
                    <span class="badge">{{ result.count | count }}</span>
                  </div>
                </span>
              </div>
              <div class="dashboard-text text-center">
              Click the <b-img :src='logoImg'></b-img> icon on the top left to access other menu items
              <!-- Click the <b-img :src='require(`@/assets/images/logo.png`)'></b-img> icon on the top left to access other menu items -->
              </div>
              <div v-show="report_issue" class="dashboard-text text-center">To report an issue, <span @click="$router.push({ path: '/report-issue' })" class="color-text font-22">Click here</span></div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
  import { HTTP } from "../../constants/http-common";
  import { isloggedin } from "../../constants/app-constants";
  export default {
    data() {
      return {
        dashboardResponse: "",
        loggedin_user_details: isloggedin,
        report_issue: false,
        logoImg: process.env.VUE_APP_LOGO
      };
    },
    methods: {
      getNotificationList() {
        setTimeout(() => {
          HTTP.get( "user_notifications/notification_count" )
            .then(response => {
              if ( response.data.status == 200 ) {
                this.dashboardResponse = response.data.data;
                this.report_issue = response.data.report_issue;
              }
            })
            .catch(err => {});
        }, 1000);
      },
      redirectURL( active ) {
        if ( active == 'Videogramme' ) {
          this.$router.push({ path: "/list_of_videogramme" });
        } else if ( active == 'Checklists' ) {
          this.$router.push({ path: "/pending_checks" });
        } else if ( active == 'Trainings' ) {
          this.$router.push({ path: "/my_trainings" });
        } else if ( active == 'My Incidents' ) {
          this.$router.push({ path: "/my-incidents" });
        } else {
          this.$router.push({ path: "/list_of_briefings" });
        }
      }
    },
    beforeMount() {
      if ( this.loggedin_user_details.passwordCheck == 2 )
        this.$router.push({ path: "/first-login" });
      else if ( this.loggedin_user_details.passwordCheck == 1 )
        this.$router.push({ path: "/change-password" });
      else
        this.getNotificationList();
      // localStorage.removeItem('_check_local');
    },
    filters: {
      count: function( value ) {
        return ( value == 0 ) ? '' : value
      }
    }
   };
</script>
<style scoped>
  @import "./def_function.css";
  @import "./dashboard.css";
  .dashboard-text {
    color: #333;
    font-size: 22px;
    margin: 5% 0;
  }
  .dashboard-text img {
    max-width: 35px;
    margin-top: -5px;
  }
  .font-22 {
    font-size: 22px;
    cursor: pointer;
  }
</style>