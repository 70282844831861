<template>
  <div>
    <b-modal hide-footer id="log-modal" size="lg" title="Training Logs" 
    modal-class="flipModal manage-user-modal" centered
    @show="historyLogs(1)"
    >
      <div>
        <div class="def-func-table mt-0 text-center">
          <label><h3>{{ trainingData.title}}</h3></label>
          <table striped responsive>
            <thead>
              <tr>
                <th class="text-left width-30per"> <span>Date</span></th>
                <th class="text-center"><span>Status</span></th>
              </tr>
            </thead>
            <tbody>
              <div class="text-center table-spinner-div">
                <b-spinner v-show="history_loader" variant="primary" label="Spinning"></b-spinner>
              </div>
              <div v-if="result.length == 0 && history_loader == false" class="text-center table-spinner-div">
                No Log Found
              </div>
              <tr v-for="( result, index ) in result" :key="index">
                <td class="text-left">{{ result.date.date | moment }}</td>
                <td class="text-center">{{ result.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination
          v-model="paginate_params.page"
          :total-rows="paginate_params.rows"
          :per-page="paginate_params.limit"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import moment from "moment";
  import { EventBus } from "../../../main";
  import { HTTP } from "../../../constants/http-common";
  export default {
    data() {
      return {
        result: [],
        paginate_params: {
          page: 1,
          rows: 10,
          limit: 10
        },
        trainingData: "",
        history_loader: false
      }
    },
    watch: {
      "paginate_params.page": {
        handler() {
          this.historyLogs();
          deep: true;
        }
      }
    },
    created() {
      EventBus.$on( "historyLog", data => {
        if ( data ) {
          this.trainingData = data;
        }
      });
    },
    methods: {
      historyLogs(check) {
        this.paginate_params.page = ( check ) ? check : this.paginate_params.page;
        this.history_loader = true;
        this.result = [];
        let recurrence = ( this.trainingData.recurrence == null ) ? '' :'&recurrence=' + this.trainingData.recurrence;
        HTTP.get( "training/get_test_history/" + this.trainingData.training_public_id + "?user_public_id=" + this.trainingData.user_public_id + "&page="+ this.paginate_params.page +"&limit=" + this.paginate_params.limit + recurrence )
          .then(response => {
            this.history_loader = false;
            this.result = response.data.data;
            this.paginate_params.rows = response.data.total_length;
          })
          .catch(err => {});
      }
    },
    filters: {
      moment: function(value) {
        if ( value == null )
          return value = "";
        else
          return moment(value).format("DD MMM YYYY");
      }
    }
  }
</script>