<template>
  <div>
    <div class="training-questns quiz-questions-sectn">
      <b-row>
        <b-col v-if="!isquestionloaded">
        </b-col>
        <b-col v-else>
          <div v-if="this.moduledata" class="test-questn-sectn perform-check quiz-questions-outer">
            <div class="briefing-admin-panel justify-content-space-between">
              <div class="quiz-questions-col">
                <label>
                  <span>Pretest - Module {{ moduledata.moduleNum + 1}}</span>
                </label>
              </div>
              <div class="quiz-questions-col">
                <label class="start-training-score">
                  <span>{{moduledata.title}}</span>
                </label>
              </div>
              <div class="quiz-questions-col">
                <label></label>
              </div>
            </div>
          </div>
          <div class="training-questns-section quiz-remain-outer">
            <p class="quiz-remaining-questn"> Question {{currentquestion.index + 1}}/{{questions.length}}</p>
            <div class="training-questn-col questn-number">
              <b-badge class="questn-badge">{{currentquestion.index + 1}}</b-badge>
            </div>
            <div class="training-questn-col questn">
              <label>{{currentquestion.question}}</label>
            </div>
          </div>
          <div class="text-center">
            <div class="preview" v-for="(imgData,imgIndex) in currentquestion.question_file" :key="imgIndex">
              <img :src="imgData.path" role="button" tabindex="0">
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="training-options-section">
            <div class="training-options-header">
              <div class="def-func-input-form-group">
                <div class="def-func-input-group mb-0">
                  <b-form>
                    <label class="mb-0">Click the correct answer</label>
                  </b-form>
                </div>
              </div>
            </div>
            <div class="training-options-body">
              <b-form-group>
                <div class="training-options-row">
                  <div class="training-questn-col questn-number">
                    <b-form-group class="quiz-options">
                      <b-form-radio-group
                        class="quiz-options-inner"
                        id
                        buttons
                        stacked
                        name="answer"
                        button-variant="light"
                      >
                        <b-form-radio
                          v-for="(options,index) in currentquestion.options"
                          name="quiz-option"
                          :key="'option_'+index"
                          v-model="selected_option"
                          :value="options.option"
                          :disabled="disableOptions"
                          :class="[{'correct-answer': selectCorrectOptions(index, correctanswerindex, currentquestion.index)  }]"
                        >
                          <b-badge class="questn-badge">{{ index | alphabatalize}}</b-badge>
                          {{options.option}}
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                </div>
              </b-form-group>
              <div class="training-options-row quiz-answer-result">
                <div
                  class="training-questn-col quiz-wrong-ans"
                  v-if="currentquestion.answer != selected_option && selected_option != ''"
                >
                  <img src="@/assets/images/wrong.png" />
                  Unfortunately, that is not correct. The correct answer is "{{correctanswerindex | alphabatalize}}"
                </div>
                <div
                  class="training-questn-col quiz-correct-ans"
                  v-if="currentquestion.answer == selected_option"
                >
                  <img src="@/assets/images/correct.png" />Congratulations! That is correct
                </div>
                <div style="color:red"
                  class="training-questn-col quiz-wrong-ans"
                  v-if="noOptionSelected"
                >Select an Option</div>
              </div>
            </div>
          </div>
          <div class="training-options-footer">
            <div class="def-func-input-group-col btn-rename-delete width-1 float-right">
              <button
                type="submit"
                variant="light"
                class="btn th-add-btn"
                @click.prevent="next_question(currentquestion.index)"
              >{{nexttext}}</button>
            </div>
            <div class="def-func-input-group-col btn-rename-delete width-1 float-right">
              <button @click="saveQuiz" type="submit" variant="light" class="btn th-add-btn">Save</button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main"
export default {
  props: ["trainingid", "moduledata"],
  data() {
    return {
      isquestionloaded:false,
      nexttext: "Next",
      disableOptions:false,
      questions: [],
      currentquestion: {},
      selected_option: "",
      useranswer: [],
      correctanswer: "",
      correctanswerindex: 0,
      noOptionSelected: false,
      preiouslySelectedQuestion: [],
      preiouslySelectedQuestionans: "",
      isoptionselectedtosaveoptions:true //to prevent optionisselected() call multiple times , set as false in optionisselected() when option is saved
    };
  },
  filters: {
    alphabatalize: function(value) {
      let aplha = ["a", "b", "c", "d", "e", "f"];
      return aplha[value];
    }
  },
  watch:{
    selected_option(newval){
      if(newval){
        this.disableOptions = true;
      }
    }
  },
  methods: {
    selectCorrectOptions( index, correctanswerindex, questionindex ) {
      if (index == correctanswerindex && this.selected_option != "") {
        if(this.isoptionselectedtosaveoptions){
          this.optionisselected(index, questionindex)
        }
        return true;
      } else {
        return false;
      }
    },
    optionisselected(index, questionindex) {
      this.isoptionselectedtosaveoptions = false
      if(this.useranswer.length > 0){
        let index_pos= this.useranswer.findIndex(
          v => v.question == this.questions[questionindex].public_id
        )
        if(index_pos >=0){
            this.useranswer.splice(index_pos,1)
        };
      }
      this.useranswer.push({
        question: this.questions[questionindex].public_id,
        answer: this.selected_option
      });
      let formdata = {
        training_id: this.trainingid,
        is_saved: (questionindex + 1 < this.questions.length) ? true : false,
        result: this.useranswer
      };
      if(this.moduledata){
        formdata.module_id =  this.moduledata.public_id;
        formdata.type = "practice";
      } else {
          formdata.module_id = '';
        formdata.type = "final";
      }
      HTTP_formdata.post("training/save_quiz_score", formdata)
        .then(response => {})
        .catch(error => {});
    },
    findCorrectAns() {
      for (let i = 0; i < this.currentquestion.options.length; i++) {
        if (
          this.currentquestion.options[i].option == this.currentquestion.answer
        ) {
          this.correctanswerindex = i;
        }
      }
    },
    get_innitial_data() {
      this.questions = [];
      let form_data = {
        training: this.trainingid,
        update: false
      };
      // check if practice/final api to fetch question from
      if(this.moduledata){
        form_data.modules =  this.moduledata.public_id;
        form_data.quiz_type = "practice";
      } else {
        form_data.modules = ""
        form_data.quiz_type = "final";
      }     
      HTTP_formdata.post("training/get_all_quiz_questions", form_data)
        .then(response_question => {
          for (let i = 0; i < response_question.data.length; i++) {
            if (response_question.data[i].options == null) {
              response_question.data[i].options = [];
            }
          }
          //code to find the previously saved correct answer 
          let formdataQuiz = {
            training_id: this.trainingid,
          };
          if(this.moduledata){
            formdataQuiz. module_id = this.moduledata.public_id;
            formdataQuiz.type = "practice";
          } else {
            formdataQuiz. module_id = '';
            formdataQuiz.type = "final";
          }
          HTTP_formdata.post("audits/get_saved_quiz", formdataQuiz)
            .then(response => {
              this.preiouslySelectedQuestion = [];
              this.useranswer = [];
              //check if user has previous anserers saved
              this.isquestionloaded = true
              if (response.data.length>0) {
                //hide quiz section  until questions are loaded revels question
                // set question 
                //condition allows for finding question that the user had stopped answering previously
                this.questions = response_question.data;
                this.currentquestion = this.questions[response.data[0].result.length];
                this.currentquestion.index = response.data[0].result.length;
                this.findCorrectAns();
                //code to fetch the previously selected anser and make it as marked
                //functionality not used since user will resume from where he has stoped answering
                this.preiouslySelectedQuestion = response.data[0].result;
                this.useranswer = response.data[0].result;
                this.preiouslySelectedQuestion.findIndex(v => {
                if (v.question == this.questions[0].public_id) {
                  this.preiouslySelectedQuestionans = v.answer;
                }
                });
              } else {
                //if user has no answered show him the first questions
                this.questions = response_question.data;
                this.currentquestion = this.questions[0];
                this.currentquestion.index = 0;
                this.findCorrectAns();
                this.preiouslySelectedQuestion = [];
              }
              if ( ( ( this.currentquestion.index ) + 1 ) == this.questions.length ) {
                this.nexttext = "Finish"
              }
            })
            .catch(error => {});
        })
        .catch(error => {});
    },
    next_question(index) {
      this.isoptionselectedtosaveoptions = true
      this.disableOptions = false;
      //allow next onlf if options are allowed
      if (this.selected_option) {
        if ( (index) + 1 == (this.questions.length - 1)) {
          this.nexttext = "Finish"
        }
        //get the index position of the previously selected option in oreder to prevent dublicate insertion of the same question id in this.useranswer
        //save the selected ans as well as the question id

        if (index + 1 < this.questions.length) {
          this.currentquestion = this.questions[index + 1];
          this.currentquestion.index = index + 1;
          this.findCorrectAns();
          this.preiouslySelectedQuestion.findIndex(v => {
            if (v.question == this.currentquestion.public_id) {
              this.preiouslySelectedQuestionans = v.answer;
            }
          });
        } else {
          let response = {
            msg: "Quiz Completed",
            popup: true
          }
          if ( this.moduledata ) {
            EventBus.$emit("success_resp", response);
          } else {
            EventBus.$emit("test_resp", response);
          }
          let complete = true
          EventBus.$emit("quiz-completed",complete)
        }
      } else {
        this.noOptionSelected = true;
        setTimeout( () => {
          this.noOptionSelected = false;
        }, 3000 );
      }
      this.selected_option= ""
    },
    saveQuiz() {
      let emitdata = {
        moduleData: "",
        start_quiz: false
      };
      EventBus.$emit("innitialize-quiz", emitdata);
      let response = {
        msg: "Quiz Saved Successfully",
        popup: true
      }
      EventBus.$emit("success_resp", response);
    }
  },
  mounted() {
    this.get_innitial_data();
  }
};
</script>
<style scoped>
.quiz-remain-outer {
    margin-top: 30px;
}
.quiz-remaining-questn {
    position: absolute;
    color: #999999;
    font-size: 16px;
    text-decoration: underline;
    margin-top: -35px;
}
.preview {margin: 10px 10px 15px;padding: 15px;border: 1px solid #e8ecf1;border-radius: 5px;display: inline-block;max-width: 40%;text-align: center;position: relative;}
.preview img {max-width: 100%;max-height: 400px;}
.preview .training-questn-col.questn-action {
    float: right;
    margin-top: 15px;
    right: 0;
    width: 50px;
    padding: 10px 10px 10px 0;
    border: 1px solid #f5f5f5;
}
</style>