<template>
  <b-col xl="3" lg="12" class="training-update-outer">
    <b-row align-v="stretch">
      <b-col xl="12" lg="6" md="6" class="training-update-col">
        <gaugeChart :title="title" :target="data.currentWeek.target" label="Current" :refId="title + '-current-week'" />
      </b-col>
      <b-col xl="12" lg="6" md="6" class="training-update-col">
        <gaugeChart :title="title" :target="data.lastWeek.target" label="Previous" :refId="title + '-last-week'" />
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
  import { EventBus } from "../../../main";
  import gaugeChart from "./gaugeChart";
  export default {
    props: [ "title", "data" ],
    data() {
      return {
      }
    },
    components: {
      gaugeChart
    },
    created() {
      EventBus.$on( "canityGaugeSection", response => {
        EventBus.$emit( "canityGaugeChartCurrent", response.currentWeek );
        if ( response.lastWeek ) EventBus.$emit( "canityGaugeChartLast", response.lastWeek );
      });
      EventBus.$on( "shoptalkGaugeSection", response => {
        EventBus.$emit( "shoptalkGaugeChartCurrent", response.currentWeek );
        if ( response.lastWeek ) EventBus.$emit( "shoptalkGaugeChartLast", response.lastWeek );
      });
    },
    beforeCreate() {
      EventBus.$off("canityGaugeSection")
      EventBus.$off("shoptalkGaugeSection")
    }
  }
</script>