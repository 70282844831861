<template>
  <div>
    <div v-bind:class="{ accessShow: popup}" class="access-updated test-completed no-internet animation">
      <div v-bind:class="{ popupShow: popup, popupActive: popupActive }" class="fade-out flip">
        <div class="no-internet-body reminder-sent-body">
          <h3 class="no-internet-title">{{ title }}...</h3>
          <div class="reminder-sent-content">
            <p v-for="( data, index ) in message" :key="index">
              <span v-if="data.type == 'success'"><b-icon icon="check" class="icon-check"></b-icon>{{ data.count + ' ' + data.msg }}</span>
              <span v-else><b-icon icon="x" class="icon-close"></b-icon>{{ data.count + ' ' + data.msg }}</span>
            </p>
            <p v-if="loader" class="text-center">
              <br>
              <b-spinner variant="primary" label="Spinning"></b-spinner>
              <br>
              Please wait. Distribution being finalised.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { EventBus } from "../../../../main";
  export default {
    data() {
      return {
        popup: false,
        popupActive: false,
        message: "",
        title: "",
        loader: false
      };
    },
    watch: {
    },
    methods: {
      popupsuccess() {
        setTimeout(() => (this.popupActive = false), 4500);
        setTimeout(() => (this.popup = false), 5000);
        setTimeout(() => (this.message = ""), 5000);
        setTimeout(() => (this.title = ""), 5000);
        setTimeout(() => (this.loader = false), 5000);
      }
    },
    created() {
      EventBus.$on("confirm_count_resp", response => {
        this.popup = response.popup
        this.popupActive = response.popup
        this.message = response.data
        this.title = response.title
        if ( response.loader ) setTimeout(() => ( this.loader = response.loader ), 2000);
      });
      EventBus.$on("confirmPopUPClose", resp => {
        if ( resp )
          this.popupsuccess();
      });
    },
    beforeCreate() {
      EventBus.$off("confirm_count_resp");
      EventBus.$off("confirmPopUPClose");
    }
  };
</script>