var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-outer"},[_c('b-container',[_c('div',{staticClass:"def-func-form"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes( _vm.submitIssue )}}},[_c('div',{staticClass:"def-func-input-form-group mb-0"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"def-func-input-group mt-4"},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"required-field"},[_c('label',[_vm._v("Title")])]),_c('b-form-input',{attrs:{"placeholder":"Title","state":errors[0] ? false : ( valid ? true : null )},model:{value:(_vm.issueData.summary),callback:function ($$v) {_vm.$set(_vm.issueData, "summary", $$v)},expression:"issueData.summary"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"attachment-issue",attrs:{"md":"4"}},[_c('div',{staticClass:"justify-content-space-between"},[_c('div',{staticClass:"width-100"},[_c('label',[_vm._v("Attachment")]),_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.issueData.attachment),callback:function ($$v) {_vm.$set(_vm.issueData, "attachment", $$v)},expression:"issueData.attachment"}})],1),_c('div',{staticClass:"ml-3 pt-4 mt-3"},[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Only jpg, jpeg, png, mp4, mov, wmv, ppt, pps, pdf allowed'),expression:"'Only jpg, jpeg, png, mp4, mov, wmv, ppt, pps, pdf allowed'",modifiers:{"hover":true,"top":true}}],staticClass:"info-icon-size",attrs:{"icon":"info"}})],1)])])],1)],1),_c('div',{staticClass:"def-func-input-group mt-4"},[_c('b-row',[_c('b-col',{staticClass:"issue-editor",attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"required-field"},[_c('label',[_vm._v("Description")])]),_c('b-form-textarea',{staticClass:"textarea-no-resize",attrs:{"placeholder":"Description","rows":"7","max-rows":"10","no-resize":"","state":errors[0] ? false : ( valid ? true : null )},model:{value:(_vm.issueData.description),callback:function ($$v) {_vm.$set(_vm.issueData, "description", $$v)},expression:"issueData.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"def-func-input-form-group"},[_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right btn-rename-delete",attrs:{"type":"submit","variant":"light"}},[_vm._v("Submit")])],1)])])],1)],1)])]}}])})],1)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messsage_loader),expression:"messsage_loader"}],staticClass:"text-center spinner-div overlay"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }