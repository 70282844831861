<template>
  <div>
    <div class="content-outer">
      <b-container>
        <div class="def-func-form">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes( submitIssue )">
              <div class="def-func-input-form-group mb-0">
                <b-row>
                  <b-col>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="8">
                          <ValidationProvider name="Title" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Title</label>
                            </div>
                            <b-form-input placeholder="Title"
                              :state="errors[0] ? false : ( valid ? true : null )"
                              v-model="issueData.summary" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                        <b-col md="4" class="attachment-issue">
                          <div class="justify-content-space-between">
                            <div class="width-100">
                              <label>Attachment</label>
                              <b-form-file
                                v-model="issueData.attachment"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                            </div>
                            <div class="ml-3 pt-4 mt-3">
                              <b-icon v-b-tooltip.hover.top="'Only jpg, jpeg, png, mp4, mov, wmv, ppt, pps, pdf allowed'" icon="info" class="info-icon-size"></b-icon>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12" class="issue-editor">
                          <ValidationProvider name="Description" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Description</label>
                            </div>
                            <b-form-textarea class="textarea-no-resize"
                              placeholder="Description" rows="7" max-rows="10" v-model="issueData.description" no-resize
                              :state="errors[0] ? false : ( valid ? true : null )"
                            ></b-form-textarea>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-form-group">
                      <div class="clearfix"></div>
                      <div class="w-100">
                        <b-button type="submit" variant="light" class="float-right btn-rename-delete">Submit</b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
  import { HTTP_formdata } from "../../constants/http-common";
  import { EventBus } from "../../main";
  export default {
    data() {
      return {
        issueData: {
          project: process.env.VUE_APP_JIRA_PROJECT,
          type: "Problem",
          summary: "",
          description: "",
          attachment: null
        },
        messsage_loader: false
      }
    },
    methods: {
      submitIssue() {
        this.messsage_loader = true;
        let formData = new FormData();
        formData.set("attachment", this.issueData.attachment);
        HTTP_formdata.post( "issues/create_issue?project=" + this.issueData.project + "&type=" + this.issueData.type + "&summary=" + this.issueData.summary + "&description=" + this.issueData.description , formData )
          .then(response => {
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              let respPopUp = {
                msg: response.data.message,
                popup: true
              }
              EventBus.$emit( "success_resp", respPopUp );
              this.issueData.summary = "";
              this.issueData.attachment = null;
              this.issueData.description = "";
              this.$nextTick(() => {
                this.$refs.observer.reset();
              });
            }
          })
          .catch(error => {
            if ( error.response.status == 400 ) {
              this.messsage_loader = false;
              let errorResp = {
                msg: error.response.data.message,
                popup: true
              };
              EventBus.$emit( "error_resp", errorResp );
            }
          });
      }
    }
  }
</script>
<style>
  @import "./def_function.css";
  @import "./dashboard.css";
  .dashboard-text {
    color: #333;
    font-size: 22px;
    margin: 5% 0;
}
.dashboard-text img {
    max-width: 35px;
    margin-top: -5px;
}
.info-span {
    color: #999;
    padding: 5px;
}
.issue-editor .ck.ck-content.ck-editor__editable {
    min-height: 200px;
}
.def-func-input-form-group .attachment-issue .custom-file label {
    min-height: 40px;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
    padding: 10px;
}
.def-func-input-form-group .attachment-issue .custom-file label::after {
    height: 38px;
    padding: 8px 20px;
    font-size: 16px;
    background: #003261;
    color: white;
    font-weight: bold;
    letter-spacing: 0.03rem;
}
.textarea-no-resize {overflow-y: auto !important;}
</style>