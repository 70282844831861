<template>
  <table striped responsive>
    <thead>
      <tr>
        <th class="text-left">
          <span>Training</span>
        </th>
        <th class="text-left">
          <span>Format</span>
        </th>
        <th class="text-left table-date">
          <span>Due Date</span>
        </th>
        <!-- <th class="text-center table-date">
          <span>Total Time Required</span>
        </th> -->
        <th class="text-left table-date">
          <span>Status</span>
        </th>
        <th class="text-center training-action">
          <span>Actions</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row,index) in data" :key="'completed_'+index">
        <td class="text-left">{{ row.title }}</td>
        <td class="text-left">{{ row.training_format }}</td>
        <td class="text-left" v-if="row.due_date == null">{{ row.due_date }}</td>
        <td class="text-left" v-else>{{ row.due_date.date | moment }}</td>
        <!-- <td class="text-center">{{ row.time_to_complete | dayWord }}</td> -->
        <td class="text-left">{{ row.status }}</td>
        <td class="text-center">
          <div class="manage-user-action training-action">
            <button v-if="row.training_format == 'internal'">
              <router-link :to="'/start_training/'+row.public_id">
                <img src="@/assets/images/btn_startcheck.png" @click="trainingDetails( row )" title="Start Training" />
              </router-link>
            </button>
            <b-button v-else v-b-modal.modal-my-training @click="startTraining( row )">
              <img src="@/assets/images/btn_startcheck.png" title="Start Training" />
            </b-button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import moment from "moment";
import { EventBus } from "../../../main"
export default {
    props:["data"],
    filters: {
        moment: function(value) {
            return moment(value).format("DD MMM YYYY");
        },
        dayWord: function(value) {
            return ( value == "" || value == null ) ? '' : ( value == 1 ) ? value+' day' : value+' days'
        }
    },
    methods: {
        trainingDetails( result ) {
            this.$cookies.set("__train", result);
        },
        startTraining( data ) {
          data.header = ( data.training_format == 'external' ) ? "User Information - External" : "Enter Training Details - Classroom";
          EventBus.$emit( "startTrainingData", data );
        }
    },
    beforeCreate() {
        if ( this.$cookies.isKey("__train") == true )
            this.$cookies.remove( "__train" );
    }
}
</script>