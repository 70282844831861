<template>
<div>
  <b-modal
      hide-footer
      id="confirm-delete"
      size="md"
      modal-class="flipModal manage-user-modal def-modal"
      :title="( page == 'createBriefings') ? 'Confirm Media Change' : (type) ? 'Confirm Continuous Distribution' : 'Confirm Delete'"
      centered v-model="modalShow"
      content-class="shadow"
    >
      <p class="mb-5 text-center" v-if="type">
        {{ type }}
      </p>
      <p class="mb-5 text-center" v-else-if="multiTitle == ''">
        Are you sure you want to delete '{{ title }}'?
      </p>
      <p class="mb-5 text-left" v-else>
        Are you sure you want to delete... <br /><br /> <span v-html="multiTitle">{{ multiTitle }}</span> ?
      </p>
      <div class="w-100">
        <b-button @click="deleteConfirmation()" variant="light" class="float-left btn-rename-delete">Yes, Continue</b-button>
        <b-button @click="close" variant="light" class="float-right btn-rename-delete">Close</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import { EventBus } from "../../../../main";
  export default {
    data() {
      return {
        modalShow: null,
        popup: false,
        popupActive: false,
        message: "",
        title: "",
        multiTitle: "",
        type: "",
        page: "",
        confirm: false
      };
    },
    watch: {
      popup(val) {
        if ( val == true ) {
          this.popupsuccess();
        }
      },
      modalShow( val ) {
        if ( val == false && this.confirm == false ) {
          // console.log("--------- watch")
          this.page = "";
          EventBus.$emit( "closeConfirmDelete" );
        }
      }
    },
    methods: {
      popupsuccess() {
        setTimeout(() => (this.popupActive = false), 2000);
        setTimeout(() => (this.popup = false), 2500);
        setTimeout(() => (this.message = ""), 2500);        
      },
      deleteConfirmation() {
        this.confirm = true;
        this.$bvModal.hide("confirm-delete");
        this.page = "";
        let response = ( this.type ) ? "Continuous Distribution" : "delete";
        EventBus.$emit( "deleteConfirmation", response );
      },
      close() {
        // console.log("this.modalShow", this.modalShow)
        if ( this.modalShow ) {
          // console.log("in -----------")
          this.$bvModal.hide('confirm-delete');
          // this.page = "";
          // EventBus.$emit( "closeConfirmDelete" );
        }
      }
    },
    created() {
      EventBus.$on("deleteSelected", display => {
        this.confirm = false;
        if (display.confirmToState) {
          this.type = "Please confirm you really want to " + display.confirmToState + " '" + display.title + "' " + this.$route.meta.route_ref + ".";
        } else if ( display.briefingMediaChange) {
          this.page = "createBriefings";
          this.type = "Please confirm you really want to change the media option from '" + display.previousOption + "' to '" + display.briefingMediaChange+ "'.";
        } else {
          this.type = "";
          if ( display.line == "multi") {
            this.multiTitle = display.title;
          } else {
            this.title = display
          }
        }
      });
    }
  };
</script>