<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <b-row>
                <b-col>
                  <div class="def-func-input-group-col float-right">
                    <b-button @click.prevent="reportExport()" class="float-right mb-1" variant="light">Export to Excel</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mt-4 mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">Summary</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mb-4">
                  <div class="def-func-table manage-users-table mt-4">
                    <incidentTable area="Summary" :colHead="incident.summaryLabel" :colData="incident.summaryData"></incidentTable>
                  </div>
                </b-col>
              </b-row>
              <div class="clearfix"></div>
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">Open ticket Breakdown - Severity</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="def-func-table manage-users-table mt-4">
                    <incidentTable area="Severity" :colHead="incident.severityLabel" :colData="incident.severityData"></incidentTable>
                  </div>
                </b-col>
              </b-row>
              <div class="clearfix"></div>
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">Open ticket Breakdown - Category</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="def-func-table manage-users-table mt-4">
                    <incidentTable area="Category" :colHead="incident.categoryLabel" :colData="incident.categoryData"></incidentTable>
                  </div>
                </b-col>
              </b-row>
              <div class="clearfix"></div>
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">Monthly Breakdown</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="def-func-table manage-users-table mt-4">
                    <incidentTable area="Monthly" :colHead="incident.monthlyLabel" :colData="incident.monthlyData"></incidentTable>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
  import { HTTP, HTTP_BASE } from "../../../constants/http-common";
  import incidentTable from "../../../components/admin_layouts/dashboard_layouts/incident_report_table"
  export default {
    components: {
      incidentTable
    },
    data() {
      return {
        incident: {
          summaryLabel: [],
          summaryData: [],
          severityLabel: [],
          severityData: [],
          categoryLabel: [],
          categoryData: [],
          monthlyLabel: [],
          monthlyData: []
        }
      };
    },
    mounted() {
      this.incident.summaryLabel = [ { align: "center", label: "Total Incident" }, { align:"center", label: "Closed Incident" }, { align:"center", label: "Open Incident" } ]
      this.incident.severityLabel = [ { align: "center", label: "High" }, { align:"center", label: "Medium" }, { align:"center", label: "Low" } ]
      // this.incident.categoryLabel = [ { align: "center", label: "Safety Related" }, { align:"center", label: "Process Issues" }, { align:"center", label: "Compliance" }, { align:"center", label: "Screening" } ]
      this.incident.monthlyLabel = [ { align: "center", label: "Month" }, { align:"center", label: "Total Incident" }, { align:"center", label: "Closed Incident" }, { align:"center", label: "Open Incident" } ]
      this.incidentData();
      // this.incidentCategory();
    },
    methods: {
      incidentData() {
        HTTP.get( "incident_reports/incident_report_stats" )
        .then( response => {
          if ( response.status == 200 ) {
            this.incident.summaryData = [ [ response.data.summary[0].total_incident, response.data.summary[0].close_incident, response.data.summary[0].open_incident ] ]
            this.incident.severityData = [ [ response.data.summary[0].high_incident, response.data.summary[0].medium_incident, response.data.summary[0].low_incident ] ]
            for ( var i = 0; i < response.data.categories.length; i++ ) {
              this.incident.categoryLabel.push({ align: "center", label: response.data.categories[i].category });
              this.incident.categoryData.push( response.data.categories[i].category_count );
            }
            this.incident.categoryData = [ this.incident.categoryData ]
            // for ( var i = 0; i < response.data.monthly.length; i++ ) {
            //   this.incident.monthlyData[i].push( response.data.monthly[i].incident_date, response.data.monthly[i].incident_total, response.data.monthly[i].incident_close, response.data.monthly[i].incident_open );
            // }
            this.incident.monthlyData = response.data.monthly
          }
        })
        .catch( error => {} )
      },
      incidentCategory() {
        HTTP_BASE.get( "incident_report_categories" )
          .then( response => {
            this.incident.categoryLabel = [];
            let category = response.data['hydra:member'];
            for (var i = 0; i < category.length; i++)
              this.incident.categoryLabel.push({ align: "center", label: category[i].category });
          })
          .catch(err => {
            this.incident.categoryLabel = [];
          });
      },
      reportExport() {
        this.messsage_loader = true;
        HTTP.get("incident_reports/incident_report_export", { responseType: 'blob' } )
          .then(response => {
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'IncidentsReport.xlsx');
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 404 || error.response.status == 500 ) {
              this.messsage_loader = false;
              let resp = {
                msg: ( error.response.status == 500 ) ? "Status Code: 500" : error.response.data.message,
                popup: true
              }
              EventBus.$emit("error_resp", resp);
            }
          });
      }
    }
  };
</script>
<style>
  @import "../def_function.css";
</style>