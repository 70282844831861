<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <ValidationObserver ref="observerBriefingReport" v-slot="{ passes }">
                <b-form class="input-padding breifing-content" @submit.prevent="passes( searchFilter )">
                  <div class="def-func-form">
                    <div class="def-func-input-form-group">
                      <div class="def-func-input-group check-results-sectn">
                        <b-row class="align-bottom">
                          <b-col class="def-func-input-group-col" sm="7" lg="7" xl="3">
                            <b-form-group>
                              <ValidationProvider name="Filter results by" rules="required" v-slot="{ valid, errors }">
                                <div class="required-field">
                                  <label for>Filter results by</label>
                                  <b-form-select v-model="filter"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    :options="filterOptions" @change="filterChange">
                                  </b-form-select>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col">
                            <b-form-group>
                              <ValidationProvider name="Value" v-slot="{ errors }" :rules="`${filtervalid ? 'required' : ''}`">
                                <div class="required-field">
                                  <label for>Value</label>
                                  <vue-bootstrap-typeahead v-if="filtervalid"
                                    :data="valueOptions"
                                    ref="valtypeahead"
                                    v-model="valueFilter"
                                    :serializer="item => item.text"
                                    :minMatchingChars="1"
                                    @hit="selectedVal = $event"
                                  ></vue-bootstrap-typeahead>
                                  <b-form-input v-else type="text" v-model="valueFilter" disabled></b-form-input>
                                  <b-form-invalid-feedback style="display:block;">{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="3" lg="3" xl="2">
                            <b-form-group id="from-Date" label="From" label-for="From_Date">
                              <b-input-group class="date-field">
                                <b-input-group-append is-text>
                                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                </b-input-group-append>
                                <Datepicker ref="fromDatepicker" v-model="from_date" clear-button :disabled-dates="stateFrom.disabledDates"></Datepicker>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="3" lg="3" xl="2">
                            <b-form-group id="to-Date" label="To" label-for="To_Date">
                              <b-input-group class="date-field">
                                <b-input-group-append is-text>
                                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                </b-input-group-append>
                                <Datepicker ref="toDatepicker" v-model="to_date" clear-button :disabled-dates="stateTo.disabledDates"></Datepicker>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col align-el" sm="2" lg="1" xl="1">
                            <div class="def-func-input-group-col btn-add-outer">
                              <button type="submit" variant="light" class="btn add-btn">
                                <font-awesome-icon :icon="['fas', 'search']" />
                              </button>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
              <div class="clearfix"></div>
              <span v-show="showResults">
                <b-row>
                  <b-col>
                    <div class="def-func-input-group-col mb-4 float-right">
                      <b-button :disabled="resultData.length == 0" @click.prevent="searchFilter(1)" class="float-right mb-1" variant="light">Export to Excel</b-button>
                    </div>
                  </b-col>
                </b-row>
                <resultTable :result="resultData"></resultTable>
                <b-pagination
                  v-model="paginate_params.page"
                  :total-rows="paginate_params.rows"
                  :per-page="paginate_params.limit"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                ></b-pagination>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  import Datepicker from "vuejs-datepicker";
  import { HTTP } from "../../constants/http-common";
  import { EventBus } from "../../main";
  import resultTable from "../../components/admin_layouts/dashboard_layouts/briefing_reports_table";
  import { isloggedin } from "../../constants/app-constants";
  export default {
    data() {
      return {
        from_date: "",
        to_date: "",
        stateTo: {
          disabledDates: {
            to: "",
            from: ""
          }
        },
        stateFrom: {
          disabledDates: {
            to: "",
            from: ""
          }
        },
        messsage_loader: false,
        showResults: false,
        paginate_params: {
          rows: 10,
          page: 1,
          limit: 10
        },
        resultData: [],
        filter: null,
        filterOptions: [
          { value: null, text: "Select", disabled: true },
          { value: "specific_briefing", text: "Specific Briefing" },
          { value: "specific_user", text: "Specific User" },
          { value: "station", text: "Station" }
        ],
        filtervalid: true,
        valueOptions: [],
        selectedVal: null,
        valueFilter: null,
        valueData: null,
        json: ""
      };
    },
    components: {
      Datepicker,
      resultTable
    },
    watch: {
      from_date(val) {
        this.stateTo.disabledDates.to = this.from_date;
      },
      to_date(val) {
        this.stateFrom.disabledDates.from = this.to_date;
      },
      "paginate_params.page": {
        handler() {
          this.searchFilter(2);
          deep: true;
        }
      },
      valueFilter( newQuery ) {
        if ( newQuery )
          this.valChange(newQuery);
        else {
          this.valueOptions = [];
          this.valueFilter = null;
          this.valueData = null
        }
      },
      selectedVal(data) {
        this.valueData = data;
      }
    },
    methods: {
      searchFilter( excel ) {
        this.messsage_loader = true;
        if ( !excel ) {
          this.json = {
            filter: this.filter,
            value: this.valueData,
            fromDate: this.from_date,
            toDate: this.to_date
          }
        }
        excel = ( excel == 1 ) ? 1 : 0;
        let value = ( this.json.value ) ? "&value=" + this.json.value.value : ""
        let fromDate = ( this.json.fromDate ) ? "&from_date=" + moment( this.json.fromDate ).utc().format() : ""
        let toDate = ( this.json.toDate ) ? "&to_date=" + moment( this.json.toDate ).utc().format() : ""
        HTTP.get("briefings/get_briefing_report?filter=" + this.json.filter + value + fromDate + toDate + "&limit=" + this.paginate_params.limit + "&excel=" + excel + "&page=" + this.paginate_params.page, ( excel == 1 ) ? { responseType: 'blob' } : '' )
          .then(response => {
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              if ( excel == 1 ) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'BriefingReports.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
              } else {
                this.paginate_params.rows = response.data.total_count
                this.resultData = response.data.data;
                this.showResults = true;
              }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 404 || error.response.status == 500 ) {
              this.messsage_loader = false;
              this.showResults = false;
              this.resultData = []
              let resp = {
                msg: ( error.response.status == 500 ) ? "Status Code: 500" : error.response.data.message,
                popup: true
              }
              EventBus.$emit("error_resp", resp);
            }
          });
      },
      filterChange() {
        this.valueOptions = [];
        this.valueFilter = null;
        this.valueData = null;
        if ( this.$refs.valtypeahead )
          this.$refs.valtypeahead.inputValue = ""
        if ( this.filter == 'all_briefings') {
          this.filtervalid = false;
        } else if ( this.filter == 'org_code' || this.filter == 'function' ) {
          if ( isloggedin.roles[0] == 'ROLE_SUPERADMIN' || isloggedin.briefing_reports == "global" ) {
            this.filtervalid = true;
          } else {
            this.filtervalid = false;
            this.valChange('');
          }
        } else {
          this.filtervalid = true;
        }
      },
      valChange( valQuery ) {
        this.valueOptions = [];
        this.valueData = null;
        HTTP.get( "briefings/filters?filter=" + this.filter + "&value=" + valQuery )
          .then(response => {
            if ( response.status == 200 ) {
              this.valueOptions = [];
              this.valueOptions = response.data.values;
              if ( this.filtervalid == false && this.filter != 'all_briefings' ) {
                this.valueData = response.data.values[0];
                this.valueFilter = response.data.values[0].text;
              }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 404 ) {
              this.valueOptions = [];
              if (this.$refs.valtypeahead)
                this.$refs.valtypeahead.inputValue = ''
              this.valueData = null;
              this.valueFilter = null;
            }
          });
      }
    },
    beforeMount() {
      if ( isloggedin.briefing_reports == "global" ) {
        this.filterOptions.push(
          { value: "org_code", text: "ORG Code" },
          { value: "function", text: "Function" },
          { value: "all_briefings", text: "All Briefings" }
        );
      } else if ( isloggedin.briefing_reports == "function" ) {
        this.filterOptions.push(
          { value: "org_code", text: "ORG Code" },
          { value: "function", text: "Function" }
        );
      } else if ( isloggedin.briefing_reports == "org_code" ) {
        this.filterOptions.push(
          { value: "org_code", text: "ORG Code" }
        );
      }
    }
  };
</script>
<style scoped>
  @import "./def_function.css";
  button.btn a {
    color: #003261;
    display: flex;
    align-items: center;
  }
  button.btn a img {
    padding-right: 10px;
  }
</style>