<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class="clearfix"></div>
                  <ValidationObserver ref="observer" v-slot="{ passes }">
                    <b-form @submit.prevent="passes( addFunction )">
                      <b-row>
                        <b-col sm="8">
                          <ValidationProvider
                            name="Function Name"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                          <div class="required-field">
                            <b-form-group label="Function Name" class="th-label ">
                              <b-form-input
                                placeholder="Function Name"
                                class="th-input"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="addFunctionData.title"
                              ></b-form-input>
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="4">
                          <div class="def-func-input-group-col btn-add-outer">
                            <button type="submit" variant="light" class="btn add-btn th-add-btn">+</button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </div>
                <defFuncTable :functionResponse="functionResponse"></defFuncTable>
                <div class="def-func-input-form-group">
                  <div class="clearfix"></div>
                  <div class="def-func-input-group">
                    <ValidationObserver ref="observerEdit" v-slot="{ passes }">
                      <b-form @submit.prevent="passes( editFunction )">
                        <div class="def-func-input-group-col">
                          <ValidationProvider
                            name="Function Name"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Rename Selected Function</label>
                              <b-form-input :disabled="functionId == ''"
                                placeholder="Rename Function"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="editFunctionData.title"
                              />
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col btn-rename-delete">
                          <button :disabled="functionId == ''" type="submit" variant="light" class="btn th-add-btn">Rename</button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                    <div class="def-func-input-group-col btn-rename-delete">
                      <button :disabled="functionId == ''" v-b-modal.confirm-delete @click="delete_popup" variant="light" class="btn th-add-btn">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
import def_table from "../../components/admin_layouts/dashboard_layouts/def_table";
import confirm_modal from "../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import { HTTP_formdata, HTTP, HTTP_BASE } from "../../constants/http-common";
import { EventBus } from "../../main";
export default {
  components: {
    defFuncTable: def_table,
    confirmModal: confirm_modal
  },
  data() {
    return {
      addFunctionData: {
        title: ""
      },
      functionResponse: "",
      editFunctionData: {
        title: ""
      },
      functionId: "",
      response: {
        msg: "",
        popup: false
      }
    };
  },
  methods: {
    addFunction() {
      HTTP_formdata.post("functions/add_functions", this.addFunctionData)
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.addFunctionData.title = "";
            this.$nextTick(() => {
              this.$refs.observer.reset();
            });
            this.getFuncList();
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            this.response.msg = err.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    getFuncList() {
      HTTP.get("functions/display_functions")
        .then(response => {
          this.functionResponse = response.data;
        })
        .catch(err => {});
    },
    editFunction() {
      HTTP_formdata.put(
        "functions/rename_functions/" + this.functionId,
        this.editFunctionData
      )
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editFunctionData.title = "";
            this.$nextTick(() => {
              this.$refs.observerEdit.reset();
            });
            this.getFuncList();
            this.functionId = "";
            EventBus.$emit("UnCheckAll", this.functionId);
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            this.response.msg = err.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    deleteFunction() {
      HTTP.delete("functions/delete_functions/" + this.functionId)
        .then(response => {
          if ( response.data.code == 200 ) {
            this.response.msg = response.data.response
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editFunctionData.title = "";
            this.$nextTick(() => {
              if (this.$refs.observerEdit) {
                this.$refs.observerEdit.reset();
              }
            });
            this.getFuncList();
            this.functionId = "";
            EventBus.$emit("UnCheckAll", this.functionId);
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            this.response.msg = err.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
            this.editFunctionData.title = "";
            this.$nextTick(() => {
              if (this.$refs.observerEdit) {
                this.$refs.observerEdit.reset();
              }
            });
            this.getFuncList();
            this.functionId = "";
            EventBus.$emit("UnCheckAll", this.functionId);
          }
        });
    },
    delete_popup() {
      EventBus.$emit( "deleteSelected", this.editFunctionData.title );
    }
  },
  created() {
    EventBus.$on("selectFunction", functionPassed => {
      this.functionId = functionPassed.id;
      HTTP_BASE.get("functions/" + this.functionId)
        .then(response => {
          this.editFunctionData.title = response.data.title;
        })
        .catch(err => {});
    });
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteFunction();
      }
    });
  },
  beforeMount() {
    this.getFuncList();
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
    EventBus.$off("selectFunction")
  }
};
</script>
<style>
@import "./def_function.css";
</style>
