<template>
  <table striped responsive>
    <thead>
      <tr>
        <th :class="'text-' + row.align" v-for="( row, index ) in colHead" :key="index"><span>{{ row.label }}</span></th>
      </tr>
    </thead>
    <tbody v-if="area == 'Monthly'" >
      <tr v-for="( row, index ) in colData" :key="index">
        <td class="text-center">{{ row.incident_date | moment }}</td>
        <td class="text-center">{{ row.incident_total }}</td>
        <td class="text-center">{{ row.incident_close }}</td>
        <td class="text-center">{{ row.incident_open }}</td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="( row, index ) in colData" :key="index">
        <td class="text-center" v-for="( val, key ) in row" :key="key">{{ val }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import moment from "moment";
  export default {
    data() {
      return {
      };
    },
    props:[ "colHead", "colData", "area" ],
    filters: {
      moment( value ) {
        return moment(value).format("MMM YYYY");
      }
    }
  };
</script>