<template>
  <div>
    <b-modal
    hide-footer
    id="modal-briefing-history"
    size="lg"
    modal-class="flipModal manage-user-modal"
    title="History"
    centered @show="initialModal" @hidden="resetModal"
    >
      <div id="briefing-history-content">
        <p data-v-12b9dbf2="" class="manage-title">{{ historyTitle }}</p>
        <div class="faq-outer" id="faq-modalView">
          <div class="text-center" v-if="history_loader">
            <b-spinner v-show="history_loader" variant="primary" label="Spinning"></b-spinner>
          </div>
          <div v-else>
            <div class="history-statistics">
              <b-row>
                <b-col lg="auto">
                  <h6><span class="history-legend">T</span> Distributed: {{ statistic.distributed }}</h6>
                </b-col>
                <b-col lg="auto" class="text-center">
                  <h6><span class="history-legend">{{ statisticLabel[statisticRef].cInital }}</span> {{ statisticLabel[statisticRef].cLabel }} : {{ statistic.completed }}</h6>
                </b-col>
                <b-col lg="auto" class="text-right">
                  <h6><span class="history-legend">{{ statisticLabel[statisticRef].pInital }}</span> {{ statisticLabel[statisticRef].pLabel }} : {{ statistic.pending }}</h6>
                </b-col>
                <b-col lg="auto">
                  <h6><span class="history-legend">D</span> Deactivated Users: {{ statistic.deactive }}</h6>
                </b-col>
                <b-col sm="12" col lg="auto" class="text-right pr-0">
                  <b-button @click.prevent="sendRemindersCheck" :disabled="send_reminder_flag" class="float-right sendReminder" variant="light">Send Reminders</b-button>
                </b-col>
                <!-- statistic.pending == 0 || statistic.distributed == statistic.deactive -->
              </b-row>
            </div>
            <b-card no-body class="history-tabs">
              <b-tabs v-model="tabIndex" card @input="onShowHistory">
                <b-tab v-for="( tab, i ) in tabData" :key="'tabIndex-' + i" :title="tab.title">
                  <b-card-text>
                    <div class="text-center" v-if="criteria_loader">
                      <b-spinner v-show="criteria_loader" variant="primary" label="Spinning"></b-spinner>
                    </div>
                    <span v-else>
                      <div v-show="historyData.length" v-for="( result, historyIndex ) in historyData" :key="historyIndex">
                        <!-- <div v-if="result.criteria == 'specific_users' || result.criteria == 'undistributed_users' || ( result.criteria != 'specific_users' && result.total_users == 0 )" class="faq-tablist faq-noTab" role="tablist"> -->
                        <div v-if="result.criteria == 'specific_users' || tabData[tabIndex].value == 'undistributed_users'" class="faq-tablist faq-noTab" role="tablist">
                          <b-card no-body>
                            <b-card-header header-tag="header" role="tab">
                              <b-button href="#" variant="info" class="cursor-default">
                                <div class="justify-content-space-between">
                                  <div v-if="result.criteria == 'specific_users' || tabData[tabIndex].value == 'undistributed_users'" class="faq-header-col width-4" style="text-transform: capitalize">{{ result.users | userName }} <strong :title='(result.contract == "E") ? "Contracted" : "Own Employee"'>{{ '[' + result.contract + ']' }}</strong></div>
                                  <div v-else class="faq-header-col width-7">{{ result.name }}</div>
                                  <div class="faq-header-col width-9 history-legend-outer" v-bind:class="{'history-spUser-legend-outer': result.criteria == 'specific_users'}">
                                    <div>
                                      <b-row v-if="result.criteria == 'station_code'">
                                        <b-col lg="3" class="width-135">
                                          <h6><span class="history-legend">T</span> {{ result.total_users }}</h6>
                                        </b-col>
                                        <b-col lg="3" class="text-center width-135">
                                          <h6><span class="history-legend">{{ statisticLabel[statisticRef].cInital }}</span> {{ result.completed }}</h6>
                                        </b-col>
                                        <b-col lg="3" class="text-right width-135">
                                          <h6><span class="history-legend">{{ statisticLabel[statisticRef].pInital }}</span> {{ result.pending }}</h6>
                                        </b-col>
                                        <b-col lg="3" class="width-135">
                                          <h6><span class="history-legend">D</span> {{ result.deactive }}</h6>
                                        </b-col>
                                      </b-row>
                                      <b-row v-else-if="result.criteria == 'specific_users'" style="float:right;">
                                        <b-col lg="auto" class="width-135 pr-0">
                                          <h6 title="Distributed Date"><span class="history-legend">T</span> {{ ( result.sent_on ) ? result.sent_on.date : '' | moment }}</h6>
                                        </b-col>
                                        <b-col lg="auto" class="width-135 pr-0">
                                          <h6 title="Reminder Date"><span class="history-legend">R</span> {{ ( result.reminder_date ) ? result.reminder_date.date : '' | moment }}</h6>
                                        </b-col>
                                        <b-col lg="auto" class="width-135 pr-0">
                                          <h6 :title="statisticLabel[statisticRef].cLabel + ' Date'"><span class="history-legend">{{ statisticLabel[statisticRef].cInital }}</span> {{ ( result.completed_date ) ? result.completed_date.date : '' | moment }}</h6>
                                        </b-col>
                                        <b-col lg="auto" class="width-135 pr-0">
                                          <h6 title="Deactivated Date"><span class="history-legend">D</span> {{ ( result.deactive ) ? result.deactive.date : '' | moment }}</h6>
                                        </b-col>
                                      </b-row>
                                      <b-row v-else>
                                        <b-col lg="auto" class="width-6 pr-0">
                                          <h6 title="Distributed Date" class="text-center"><span class="history-legend">T</span> {{ ( result.distributed_date ) ? result.distributed_date : '' | moment }}</h6>
                                        </b-col>
                                        <!-- <b-col lg="auto" class="width-135 pr-0">
                                          <h6 title="Reminder Date"><span class="history-legend">R</span> {{ ( result.reminder_date ) ? result.reminder_date.date : '' | moment }}</h6>
                                        </b-col>
                                        <b-col lg="auto" class="width-135 pr-0">
                                          <h6 :title="statisticLabel[statisticRef].cLabel + ' Date'"><span class="history-legend">{{ statisticLabel[statisticRef].cInital }}</span> {{ ( result.completed_date ) ? result.completed_date.date : '' | moment }}</h6>
                                        </b-col> -->
                                        <b-col lg="auto" class="width-6 pr-0">
                                          <h6 title="Excluded Date" class="text-center"><span class="history-legend">E</span> {{ ( result.undistributed_on ) ? result.undistributed_on.date : '' | moment }}</h6>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </div>
                                  <!-- <div class="faq-header-col width-3 text-center" v-show="result.criteria != 'specific_users'">{{ result.internal_users | userType }}</div> -->
                                  <div class="faq-header-col width-1" :class="{ 'sentDate-col': result.criteria == 'specific_users' ? true : false }">
                                  <!-- <div v-show="result.criteria == 'specific_users'" class="specific-user-btn-outer">
                                      <b-button id="dates-log">
                                        Logs
                                      </b-button>
                                      <div class="popover b-popover bs-popover-bottom" x-placement="bottom">
                                        <div class="arrow"></div>
                                        <h3 class="popover-header">Dates</h3>
                                        <div class="popover-body">
                                          <label><strong>Issue: </strong> {{ ( result.sent_on ) ? result.sent_on.date : '' | moment }}</label><br />
                                          <label><strong>Reminder: </strong> {{ ( result.reminder_date ) ? result.reminder_date.date : '' | moment }}</label><br />
                                          <label><strong>{{ statisticLabel[statisticRef].cLabel }}: </strong> {{ ( result.completed_date ) ? result.completed_date.date : '' | moment }}</label>
                                        </div>
                                        </div>
                                      <div container="briefing-history-content">
                                        
                                      </div>
                                    </div> -->
                                  </div>
                                </div>
                              </b-button>
                            </b-card-header>
                          </b-card>
                        </div>
                        <div v-else class="faq-tablist" role="tablist">
                          <b-card no-body>
                            <b-card-header header-tag="header" role="tab">
                              <b-button href="#" v-b-toggle="'accordion_' + historyIndex" variant="info" @click.prevent="historyUsers( historyIndex, result, 1 )">
                                <div class="justify-content-space-between">
                                  <div class="faq-header-col width-7">{{ result.name }}</div>
                                  <div class="faq-header-col width-9 history-legend-outer">
                                    <div>
                                      <b-row>
                                        <b-col lg="3">
                                          <h6><span class="history-legend">T</span> {{ result.total_users }}</h6>
                                        </b-col>
                                        <b-col lg="3" class="text-center">
                                          <h6><span class="history-legend">{{ statisticLabel[statisticRef].cInital }}</span> {{ result.completed }}</h6>
                                        </b-col>
                                        <b-col lg="3" class="text-right">
                                          <h6><span class="history-legend">{{ statisticLabel[statisticRef].pInital }}</span> {{ result.pending }}</h6>
                                        </b-col>
                                        <b-col lg="3">
                                          <h6><span class="history-legend">D</span> {{ result.deactive }}</h6>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </div>
                                  <!-- <div class="faq-header-col width-3 text-center">{{ result.internal_users | userType }}</div> -->
                                  <div class="faq-header-col width-1">
                                    <div class="history-active-icon">&#10095;</div>
                                  </div>
                                </div>
                              </b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion_' + historyIndex" accordion="my-accordion-new" role="tabpanel">
                              <b-card-body class="">
                                <b-card-text>
                                  <!-- <div class="group-names-outer history-group-names" v-if="result.criteria == 'region' && result.group.length > 0">
                                    <label class="distribute-group-label">Group Name</label>
                                    <b-badge variant="light" v-for="( grpData, grpIndex ) in result.group" :key="grpIndex">{{ grpData }}</b-badge>
                                  </div> -->
                                  <div v-if="result.total_users == 0" class="no-user">No user</div>
                                  <div v-else-if="historyUserData.userData.length == 0" class="text-center">
                                    <b-spinner v-show="msg_loader" variant="primary" label="Spinning"></b-spinner>
                                  </div>
                                  <div v-else class="def-func-table manage-users-table m-0">
                                    <table>
                                      <thead><tr><th>Name</th><th>Distributed Date</th><th>Reminder Date</th><th>{{ statisticLabel[statisticRef].cLabel }} Date</th></tr></thead>
                                      <tbody>
                                        <tr v-for="( data, userIndex ) in historyUserData.userData" :key="userIndex">
                                          <td class="text-left" style="text-transform: capitalize">{{ data.name | userName }} <strong :title='(data.contract == "E") ? "Contracted" : "Own Employee"'>{{ '[' + data.contract + ']' }}</strong></td>
                                          <td class="text-left">{{ ( data.sent_on ) ? data.sent_on.date : '' | moment }}</td>
                                          <td class="text-left">{{ ( data.reminder_date ) ? data.reminder_date.date : '' | moment }}</td>
                                          <td class="text-left">{{ ( data.completed_date ) ? data.completed_date.date : '' | moment }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="clearfix"></div>
                                </b-card-text>
                                <div class="clearfix"></div>
                                <b-pagination
                                  v-model="historyPayload.users_page"
                                  :total-rows="historyPayload.user_rows"
                                  :per-page="historyPayload.users_limit"
                                  first-text="First"
                                  prev-text="Prev"
                                  next-text="Next"
                                  last-text="Last"
                                ></b-pagination>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                      <div v-show="!historyData.length" class="no-user">No user</div>
                    </span>
                  </b-card-text>
                  <b-pagination
                    v-model="historyPayload.page"
                    :total-rows="historyPayload.rows"
                    :per-page="historyPayload.limit"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                  ></b-pagination>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="text-center spinner-div overlay load-outer" v-show="reminder_loader">
      <label class="wait-text">Please wait...</label> 
      <br>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
      <br>
      <!-- <label v-show="count_loader" class="loading-text">Completed {{ respCount }}/{{ totalChunkCount }}</label> -->
      <label v-show="count_loader" class="loading-text">Completed {{ completedCount }}/{{ totalCount }}</label>
    </div>
    <detailResp></detailResp>
    <noInternetModal />
  </div>
</template>
<script>
  import moment from "moment";
  import { HTTP } from "../../../constants/http-common";
  import { EventBus } from "../../../main";
  import noInternetModal from "./status_message/no-internet";
  import detailResp from "./status_message/distribution_failed";
  export default {
    props: [ "distributeId", "historyTitle", "historyArea" ],
    data() {
      return {
        historyData: [],
        historyUserData: {
          userData: [],
          requestData: ""
        },
        historyPayload: {
          page: 1,
          limit: 10,
          rows: 10,
          users_page: 1,
          users_limit: 5,
          user_rows: 5
        },
        msg_loader: false,
        history_loader: false,
        criteria_loader: false,
        tabIndex: 0,
        tabData: [
          // { title: 'Region', value: 'region' },
          // { title: 'ORG Code', value: 'orga_code' },
          { title: 'Station Code', value: 'station_code' },
          { title: 'Specific Users', value: 'specific_users' },
          { title: 'Excluded Users', value: 'undistributed_users' }
        ],
        statistic: {
          distributed: 0,
          completed: 0,
          pending: 0,
          deactive: 0
        },
        statisticLabel: {
          training: { cLabel: 'Completed', cInital: 'C', pLabel: 'Pending', pInital: 'P'  },
          briefing: { cLabel: 'Acknowledged', cInital: 'A', pLabel: 'Not Acknowledged', pInital: 'N'  },
          videogramme: { cLabel: 'Viewed', cInital: 'V', pLabel: 'Not Viewed', pInital: 'N'  }
        },
        send_reminder_flag: false,
        statisticRef: 'training',
        totalChunkCount: 1,
        respCount: 0,
        count_loader: false,
        reminder_loader: false,
        totalCount: 0,
        completedCount: 0,
        reminderUserList: [],
        timer: false,
        hundredCounter: 0,
        axiosSource: "",
        request: null,
        reminderCheck: false,
        timerCheck: false,
        counterAPICheck: false,
        counterCheck: false,
        respStatus: []
      };
    },
    components: {
      noInternetModal,
      detailResp
    },
    filters: {
      userName: function(value) {
        return (value.middle) ? value.first + ' ' + value.middle + ' ' + value.last : value.first + ' ' + value.last;
      },
      moment: function(value) {
        if ( !value || value == null ) return value = "";
        else return moment(value).format("DD MMM YYYY");
      },
      userType: function(val) {
        return (val == 0) ? "All Users" : "Internal Users";
      },
      userTypeInitial: function(val) {
        return (val == 0) ? "[E]" : "[I]";
      }
    },
    watch: {
      "historyPayload.page": {
        handler() {
          this.onShowHistory();
          deep: true;
        }
      },
      "historyPayload.users_page": {
        handler() {
          this.historyUsers();
          deep: true;
        }
      },
      onLine( val ) {
        if ( val != null ) {
          // console.log("manage user", val);
          this.networkConnect( val );
        }
      }
    },
    computed: {
      onLine() {
        return this.$store.getters.networkConnection;
      }
    },
    created() {
      EventBus.$on("noConnectionOk", newQuery => {
        if ( newQuery == 'Okay' ) {
          this.networkConnect();
        }
      });
    },
    beforeMount() {
      this.networkConnect();
    },
    methods: {
      onShowHistory() {
        this.criteria_loader = true;
        this.historyData = [];
        this.historyUserData.userData = [];
        HTTP.get( "distribution_histories/get_history?id=" + this.distributeId + "&area=" + this.historyArea + "&criteria=" + this.tabData[this.tabIndex].value + "&page=" + this.historyPayload.page + "&limit=" + this.historyPayload.limit )
          .then(response => {
            this.messsage_loader = false
            this.criteria_loader = false;
            this.history_loader = false;
            if ( response.status == 200 ) {
              this.historyData = [];
              this.historyUserData.userData = [];
              this.historyData = response.data.history;
              this.historyPayload.rows = response.data.total_length;
              this.statistic.distributed = response.data.distributed;
              this.statistic.completed = response.data.completed;
              this.statistic.pending = response.data.pending;
              this.statistic.deactive = response.data.total_deactive;
              this.send_reminder_flag = response.data.send_reminder_flag;
            }
          })
          .catch(error => {
            this.historyData = [];
            this.historyUserData.userData = [];
            this.messsage_loader = false;
            this.history_loader = false;
            this.criteria_loader = false;
          });
      },
      historyUsers( index, data, pageNum ) {
        this.msg_loader = true;
        this.historyUserData.userData = [];
        if ( pageNum ) { this.historyPayload.users_page = pageNum; this.historyPayload.user_rows = 5; }
        if ( data ) { this.historyUserData.requestData = data; this.historyUserData.requestData.distribution_id = data.distribution_id.toString(); }
        if ( this.historyUserData.requestData.distribution_id && this.historyUserData.requestData.public_id ) {
          HTTP.get( "distribution_histories/get_users?id=" + this.distributeId + "&distribution_id=" + this.historyUserData.requestData.distribution_id + "&criteria_id=" + this.historyUserData.requestData.public_id + "&page=" + this.historyPayload.users_page + "&limit=" + this.historyPayload.users_limit )
            .then(response => {
              this.msg_loader = false;
              if ( response.status == 200 ) {
                this.historyUserData.userData = [];
                this.historyUserData.userData = response.data.users;
                this.historyPayload.user_rows = response.data.total_length;
              }
            })
            .catch(error => {
              this.historyUserData.userData = [];
              this.msg_loader = false
            });
        }
      },
      resetModal() {
        this.historyData = []
        this.historyUserData = { userData: [], requestData: "" }
        this.historyPayload.page = 1;
        this.historyPayload.users_page = 1;
        this.tabIndex = 0;
        this.statistic.distributed = 0;
        this.statistic.completed = 0;
        this.statistic.pending = 0;
        this.statistic.deactive = 0;
      },
      initialModal() {
        this.history_loader = true;
        this.statisticRef = ( this.$route.meta.route_ref ) ? this.$route.meta.route_ref : 'training';
        this.onShowHistory();
      },
      // sendReminder() {
      //   this.respCount = 0;
      //   this.reminder_loader = true;
      //   let chunkArray = [], chunkLength = 1, respStatus = [];
      //   HTTP.get( "distribution_histories/get_not_completed_users?id=" + this.distributeId + "&module=" + this.$route.meta.route_ref )
      //     .then(response => {
      //       if ( response.status == 200 ) {
      //         if ( response.data.count > 0 ) {
      //           let usersList = response.data.users;
      //           this.totalChunkCount = Math.ceil( response.data.count / chunkLength );
      //           this.count_loader = true;
      //           for ( let i = 0; i < response.data.count; i += chunkLength ) {
      //             chunkArray = usersList.slice( i, i + chunkLength );
      //             HTTP.get( "distribution_histories/send_reminders?id=" + this.distributeId + "&user_public_id=" + chunkArray + "&module=" + this.$route.meta.route_ref )
      //               .then(resp => {
      //                 if ( resp.status == 200 ) {
      //                   respStatus.push( "success" );
      //                   this.respCount++;
      //                   if ( this.respCount == this.totalChunkCount ) {
      //                     setTimeout(() => {
      //                       if ( respStatus.includes('success') ) {
      //                         this.reminder_loader = false;
      //                         this.count_loader = false;
      //                         let responsesuccess = {
      //                           popup: true,
      //                           msg: "Reminders Sent Successfully"
      //                         }
      //                         EventBus.$emit("success_resp", responsesuccess);
      //                       } else {
      //                         this.reminder_loader = false;
      //                         this.count_loader = false;
      //                         let resperr = {
      //                           popup: true,
      //                           msg: "Failed to Send Reminders"
      //                         };
      //                         EventBus.$emit("error_resp", resperr)
      //                       }
      //                     }, 2000);
      //                   }
      //                 }
      //               })
      //               .catch(error => {
      //                 // if ( !error.response ) {
      //                 //   console.log("hello hello");
      //                 // } else 
      //                 if ( error.response.status == 400 ) {
      //                   respStatus.push( "fail" );
      //                   this.respCount++;
      //                   if ( this.respCount == this.totalChunkCount ) {
      //                     setTimeout(() => {
      //                       if ( respStatus.includes('success') ) {
      //                         this.reminder_loader = false;
      //                         this.count_loader = false;
      //                         let responsesuccess = {
      //                           popup: true,
      //                           msg: "Reminders Sent Successfully"
      //                         }
      //                         EventBus.$emit("success_resp", responsesuccess);
      //                       } else {
      //                         this.reminder_loader = false;
      //                         this.count_loader = false;
      //                         let resperr = {
      //                           popup: true,
      //                           msg: "Failed to Send Reminders"
      //                         };
      //                         EventBus.$emit("error_resp", resperr)
      //                       }
      //                     }, 2000);
      //                   }
      //                 }
      //               });
      //           }
      //         }
      //       }
      //     })
      //     .catch(error => {
      //       this.reminder_loader = false;
      //       this.count_loader = false;
      //       if ( error.response.status == 400) {
      //         let resperr = {
      //           popup: true,
      //           msg: "No users"
      //         };
      //         EventBus.$emit("error_resp", resperr)
      //       }
      //     });
      // },
      sendRemindersCheck() {
        this.networkConnect();
        if ( this.onLine == true || this.onLine == null ) {
          this.reminder_loader = true;
          this.reminderCheck = true;
          HTTP.get( "distribution_histories/get_not_completed_users?id=" + this.distributeId + "&module=" + this.$route.meta.route_ref )
            .then(response => {
              // console.log(response.data.users);
              if ( response.status == 200 && response.data.users.length > 0 ) {
                this.totalCount = response.data.count;
                this.reminderUserList = response.data.users;
                this.completedCount = 0;
                this.setAPITimer();
              }
            })
            .catch(err => {
              this.reminder_loader = false;
              if ( err.response.status == 400 ) {
                this.reminderCheck = false;
                let resperr = {
                  popup: true,
                  msg: 'Failed to send reminder'
                };
                EventBus.$emit( "error_resp", resperr );
                this.totalCount = 0;
                this.reminderUserList = [];
                this.completedCount = 0;
                this.hundredCounter = 0;
              }
            });
        }
      },
      setAPITimer() {
        this.timerCheck = true;
        // console.log("start timer");
        this.timer = setInterval(() => {
          clearInterval( this.timer );
          this.timer = false;
          if ( this.request ) this.cancel();
          let waitTime = ( this.hundredCounter < 100 ) ? 15000 : 30000;
          this.hundredCounter = 0;
          // console.log("waitTime",  waitTime);
          setTimeout(() => {
            this.setAPITimer();
          }, waitTime );
        }, 60000);
        // console.log('this.timer',this.timer);
        this.sendReminderInitial();
      },
      async sendReminderInitial( recall ) {
        // console.log("initial reminder");
        this.count_loader = true;
        let errorCheck = 0;
        if ( this.onLine == true || this.onLine == null ) {
          for ( let i = this.completedCount; i < this.totalCount; i++ ) {
            if ( this.onLine == true || this.onLine == null ) {
              this.counterCheck = true;
              // console.log("iteration", i);
              if ( this.timer == false ) {
                // console.log('this.timer false');
                break;
              } else {
                if ( this.hundredCounter >= 100 )  {
                  // console.log('this.hundredCounter 100', this.hundredCounter);
                  break;
                } else {
                  // console.log('all good', this.hundredCounter);
                  // console.log('email');
                  // console.log('start reminder api');
                  this.axiosSource = axios.CancelToken.source();
                  this.request = { cancel: this.axiosSource.cancel };
                  await HTTP.get( "distribution_histories/send_reminders?id=" + this.distributeId + "&user_public_id=" + this.reminderUserList[i] + "&module=" + this.$route.meta.route_ref, { cancelToken: this.axiosSource.token } )
                    .then(response => {
                      if ( response.status == 200 ) {
                        // console.log(response.data);
                        // console.log('success sent');
                        this.clearOldRequest();
                        this.respStatus.push({ status: "success", msgId: response.data.flag, msg: response.data.message });
                        this.completedCount++;
                        this.hundredCounter++;
                      }
                    })
                    .catch(err => {
                      if (axios.isCancel(err)) {
                      } else {
                        // console.log('onLine-- ',this.onLine);
                        // console.log('completedCount-- ',this.completedCount);
                        // console.log('hundredCounter-- ',this.hundredCounter);
                        this.clearOldRequest();
                        if ( !err.response ) {
                          // console.log('***************if no response from reminder api*********** Error: Network Error');
                          errorCheck = 1;
                        } else if ( err.response.status == 400 ) {
                          // console.log('failed sent');
                          this.respStatus.push({ status: "fail", msgId: err.response.data.flag, msg: err.response.data.message });
                          this.completedCount++;
                          this.hundredCounter++;
                        } else {
                          // console.log('else if error is not 400');
                          errorCheck = 1;
                          // console.log('failed sent 500');
                          // this.respStatus.push({ status: "fail", msgId: 4, msg: "Internal Server Error - 500" });
                          // this.completedCount++;
                          // this.hundredCounter++;
                        }
                      }
                    });
                    if (errorCheck == 1) {
                      errorCheck = 0;
                      // console.log("errorCheck is 1");
                      break;
                    }
                }
              }
            } else {
              // console.log("no net in the middle of loop")
              break;
            }
          }
          setTimeout(() => {
            if ( this.completedCount == this.totalCount ) {
              clearInterval(this.timer);
              this.timer = false;
              let respDetail = [ { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" } ];

              for ( const obj of this.respStatus ) {
                if ( obj.msgId == '1' ) { respDetail[0].count++; respDetail[0].msg = obj.msg; respDetail[0].type = obj.status; }
                else if ( obj.msgId == '2' ) { respDetail[1].count++; respDetail[1].msg = obj.msg; respDetail[1].type = obj.status; }
                else if ( obj.msgId == '3' ) { respDetail[2].count++; respDetail[2].msg = obj.msg; respDetail[2].type = obj.status; }
                // else if ( obj.msgId == '4' ) { respDetail[3].count++; respDetail[3].msg = obj.msg; respDetail[3].type = obj.status; }
              }

              let result = respDetail.filter( function( item ) {
                if ( item.count > 0 ) return true;
                else return false;
              });

              this.reminder_loader = false;
              this.count_loader = false;

              let responseData = {
                popup: true,
                data: result,
                title: 'Reminders sent',
                loader: false
              };
              EventBus.$emit("confirm_count_resp", responseData);

              // console.log('----------Report------------------');
              // console.log('totalCount', this.totalCount);
              // console.log('completedCount', this.completedCount);
              // console.log('hundredCounter', this.hundredCounter);

              setTimeout(() => {
                EventBus.$emit( "confirmPopUPClose", true );
                this.reminderCheck = false;
                this.timerCheck = false;
                this.counterCheck = false;
                this.totalCount = 0;
                this.reminderUserList = [];
                this.completedCount = 0;
                this.hundredCounter = 0;
                this.respStatus = [];
                this.axiosSource = "";
                this.request = null;
              }, 2000);
            }
          }, 2000);
        }
      },
      cancel() {
        this.request.cancel();
        this.clearOldRequest();
      },
      clearOldRequest() {
        this.request = null;
      },
      networkConnect( val ) {
        let network = ( val ) ? val : this.onLine;
        if ( network != null ) {
          // console.log("not null")
          // console.log(network)
          if ( network == false ) {
            this.$bvModal.show("modal-no-internet");
            this.counterAPICheck = ( this.reminderCheck == true ) ? true : false;
            if (this.request) this.cancel();
          } else if ( network == true ) {
            this.$bvModal.hide("modal-no-internet");
            if ( this.counterAPICheck == true ) {
              if ( this.reminderCheck == true ) {
                if ( this.timerCheck == true ) {
                  if ( this.counterCheck == true ) this.sendReminderInitial();
                  else {
                    if ( this.timer == false ) this.setAPITimer(); else this.sendReminderInitial();
                  }
                } else
                  this.sendRemindersCheck();
              }
              this.counterAPICheck = false;
            }
          }
        } else {
          // console.log("null")
        }
      }
    }
  };
</script>
<style scoped>
  .faq-active-icon.faq-active-icon-circle {
    border-radius: 50%;
    border: 6px solid #003261;
  }
  .spinner-div.load-outer {
    flex-direction: column;
  }
  .wait-text, .loading-text {
    color: #003261;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05em;
    z-index: 1;
  }
  .wait-text {
    margin-bottom: -10px;
  }
  .loading-text {
    margin-top: -10px;
  }
  #modal-briefing-history .manage-title[data-v-c2d0060a] {
    text-align: center;
    border: none;
    margin-bottom: 2rem;
    font-size: 20px;
  }
  .history-tabs .faq-header-col.sentDate-col {
    min-width: 120px;
    text-align: right;
  }
  /* .specific-user-btn-outer {
    position: relative;
  } */
  .specific-user-btn-outer .popover {
    right: 0;
    left: auto;
    top: 35px;
    max-width: 210px;
    display: none;
  }
  .specific-user-btn-outer:hover .popover {
    display: block;
  }
  .specific-user-btn-outer .arrow {
    right: 24px;
  }
  .specific-user-btn-outer button {
    height: 24px;
    font-size: 12px;
  }
  .specific-user-btn-outer .popover label {
    display: block;
  }
  .faq-header-col.history-legend-outer.history-spUser-legend-outer {
    min-width: 375px;
  }
  .history-spUser-legend-outer h6 {
    width: unset;
  }
</style>