<template>
  <div>
    <div class="def-func-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              <span>Region</span>
            </th>
            <th class="text-left">
              <span>Function</span>
            </th>
            <th class="text-left">
              <span>Org Code</span>
            </th>
            <th class="text-center">
              <span>Date Created</span>
            </th>
            <th class="text-center">
              <span>Dependencies</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="( result, index ) in orgResult" :key="index">
            <td>
              <b-form-radio
                name="org"
                v-model="orgIdPassed"
                :value="result.public_id"
                @change="onSelectOrg($event, result)"
              ></b-form-radio>
            </td>
            <td class="text-left">{{ result.region }}</td>
            <td class="text-left">{{ result.function }}</td>
            <td class="text-left">{{ result.code }}</td>
            <td class="text-center">{{ result.created_on.date | moment }}</td>
            <td class="text-center">{{ result.dependencies }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { EventBus } from "../../../main";
export default {
  props: ["orgResult"],
  data() {
    return {
      orgIdPassed: ""
    };
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    }
  },
  methods: {
    onSelectOrg(event, result) {
      EventBus.$emit("selectOrg", result);
    }
  },
  created() {
    EventBus.$on("UnCheckAll", orgId => {
      this.orgIdPassed = orgId;
    });
  },
  beforeCreate() {
    EventBus.$off("UnCheckAll")
  }
};
</script>