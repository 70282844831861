<template>
  <div>
    <div class="content-outer setup-test-question">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="briefing-admin-panel test-questn-sectn mb-5">
              <b-row class="py-3 mb-4">
                <b-col md="12" lg="3" class="text-left">
                  <h5 class="dc-blue-label"><label class="dc-gray-label">Training:</label> {{ trainingData.trainingName }}</h5>
                </b-col>
                <b-col md="12" lg="3" class="text-left">
                  <h5 class="dc-blue-label"><label class="dc-gray-label">Module:</label> {{ trainingData.modName }}</h5>
                </b-col>
                <b-col md="12" lg="3" class="text-left">
                  <h5 class="dc-blue-label">{{ trainingData.studyTimeReq }} <label class="dc-gray-label">Minutes</label></h5>
                </b-col>
                <b-col md="12" lg="3" class="text-left">
                  <h5 class="dc-blue-label"><label class="dc-gray-label">Type:</label> {{ trainingData.trainingType }}</h5>
                </b-col>
              </b-row>
            </div>
            <b-container class="traning-file-center">
              <div class="doc-width">
                <div class="pdf-preview" v-if="trainingData.format == 'pdf'" id="pdfPreview" oncontextmenu="return false;"></div>
                <div v-else-if ="trainingData.fileURL.endsWith('.html')">
                   <iframe  :src="trainingData.fileURL" frameborder="0" width="100%"></iframe>
                </div>
                <div v-else >
                  <VueDocPreview id="dociFrame" height="100" :url="trainingData.fileURL" type="office" office="pptx" />
                </div>
              </div>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
  import { HTTP } from "../../../constants/http-common";
  import Pdfh5 from "pdfh5";
  import VueDocPreview from 'vue-doc-preview';
  import "pdfh5/css/pdfh5.css";
  export default {
    data() {
      return {
        trainingData: {
          format: "pdf",
          fileURL: "",
          trainingName: "",
          modName: "",
          trainingType: "",
          studyTimeReq: ""
        },
        pdfh5: null,
        messsage_loader: false
      };
    },
    components: {
      VueDocPreview
    },
    methods: {
      getOnlineTrainingData() {
        HTTP.get( "modules/get_module_detail/" + this.$route.params.moduleid )
          .then(response => {           
            this.messsage_loader = false;
            this.trainingData = {
              format: response.data[0].file_format,
              fileURL: response.data[0].file_link,
              trainingName: response.data[0].training_name,
              modName: response.data[0].module_name,
              trainingType: response.data[0].type,
              studyTimeReq: response.data[0].study_time_req
            }
            if ( this.trainingData.format == 'pdf' ) {
              this.pdfh5 = new Pdfh5( "#pdfPreview", {
                pdfurl: this.trainingData.fileURL,
                // lazy: true,
                goto: 1
              });
            } 
          })
          .catch(err => {
            window.close();
          });
      }
    },
    mounted() {
      this.messsage_loader = true;
      this.getOnlineTrainingData();
    }
  };
</script>

<style scoped>
iframe{
  height: calc(100vh - 300px);
}
</style>