<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <validation-observer ref="observer" v-slot="{ passes }">
                <b-form class="input-padding" @submit.prevent="passes(onSubmit)">
                  <div class="def-func-form">
                    <div class="def-func-input-form-group">
                      <div class="def-func-input-group mb-0">
                        <div class="brief-rounded-sectn" v-if="faqAnsPermission == 1">
                          <b-row>
                            <b-col>
                              <label class="distribute-group-label">Briefing Administrators Panel</label>
                            </b-col>
                          </b-row>
                          <b-row class="briefing-admin-panel">
                            <b-col>
                              <div class="distribute-group ack-rate-popup">
                                <label class="text-left">
                                  Acknowledgement Rate:
                                  <span v-if="acknowledgement == 'Not Distributed'">{{ acknowledgement }}</span>
                                  <span v-else v-b-modal.modal-ack-rate>{{ acknowledgement == '0.00%' ? acknowledgement : acknowledgement.toFixed(2)+"%" }}</span>
                                </label>
                              </div>
                            </b-col>
                            <b-col>
                              <div class="distribute-group">
                                <label class="text-center">
                                  Questions Raised:
                                  <span>{{questionRaised}}</span>
                                </label>
                              </div>
                            </b-col>
                            <b-col>
                              <div class="distribute-group">
                                <label class="text-right">
                                  Answers Pending:
                                  <span>{{answerspanding}}</span>
                                </label>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-button v-if="faqAnsPermission == 0" :disabled="acknowledgedFlag == 1" v-b-modal.modal-ack-brief variant="light" class="float-right">Acknowledge Briefing</b-button>
                  <div class="clearfix"></div>
                  <div class="brief-rounded-sectn display-briefing">
                    <div class="def-func-form">
                      <div class="def-func-input-form-group">
                        <h2 class="display-title">{{briefing_title}}</h2>
                        <b-row>
                          <b-col>
                            <div class="def-func-input-group">
                              <div class="distribute-group border-bottom border-default">
                                <b-row class="briefing-admin-panel pt-2">
                                  <b-col>
                                    <div class>
                                      <label class="text-left">
                                        Effective: <span>{{ start_date | moment }}</span>
                                      </label>
                                    </div>
                                  </b-col>
                                  <b-col v-if="briefing_type == 'Mandatory'">
                                    <div class>
                                      <label class="text-center">
                                        <span class="mandatory-field">{{briefing_type}}</span>
                                      </label>
                                    </div>
                                  </b-col>
                                  <b-col>
                                    <div class>
                                      <label class="text-right">
                                        Until:
                                        <span v-if="end_date == 'UFN'">
                                          {{ end_date }}
                                        </span>
                                        <span v-else>
                                          {{ end_date | moment }}
                                        </span>
                                      </label>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                              <div class="distribute-group">
                                <b-row class="briefing-admin-panel pt-4">
                                  <b-col sm="12">
                                    <label>
                                      <span>{{briefing_details}}</span>
                                    </label>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="distribute-group">
                          <b-row>
                            <b-col md="12" lg="6">
                              <div class="brief-look-cols slider-outer">
                                <h6 class="brief-look-title text-center">Keynotes:</h6>
                                <div class="look-popup-text ck-content" v-html="buller_point"></div>
                              </div>
                            </b-col>
                            <b-col sm="6">
                              <div class="brief-look-cols">
                                <h6 class="brief-look-title text-center">Additional Reference:</h6>
                                <div class="brief-look-cols slider-outer" v-if="briefingImages.length > 0">
                                  <div>
                                    <transition-group name="fade" tag="div" class="slider-inner">
                                      <div v-for="number in [currentNumber]" :key="number">
                                        <img :src="currentImage" />
                                      </div>
                                    </transition-group>
                                    <a class="prev" @click="prev">
                                      <img src="@/assets/images/btn_startcheck.png" title="Previous" />
                                    </a>
                                    <a class="next" @click="next">
                                      <img src="@/assets/images/btn_startcheck.png" title="Next"  />
                                    </a>
                                  </div>
                                </div>
                                <div class="display-briefing-video" v-else>
                                  <video-player v-if="video_src" class="video-player-box display-brief-video"
                                    ref="videoPlayer"
                                    :options="playerOptions"
                                    :playsinline="true"
                                    customEventName="customstatechangedeventname" 
                                    @statechanged="playerStateChanged($event)">
                                  </video-player>
                                </div>
                              </div>
                              <div class="brief-look-cols" v-if="slides.length>0">
                                <h6 class="brief-look-title text-center">Attachments</h6>
                                <vueper-slides
                                  ref="myVueperSlides"
                                  :visible-slides="3"
                                  slide-multiple
                                  :gap="3"
                                  :slide-ratio="1 / 4"
                                  :dragging-distance="200"
                                  :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
                                  class="pdf-slider no-shadow"
                                >
                                  <vueper-slide
                                    v-for="(slide, id) in slides"
                                    :key="id"
                                    :title="slide.title"
                                    :content="slide.content"
                                    class="pdf-slides"
                                  />
                                </vueper-slides>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                    <b-button v-if="faqAnsPermission == 0" :disabled="acknowledgedFlag == 1" v-b-modal.modal-ack-brief variant="light" class="my-4 float-right">Acknowledge Briefing</b-button>
                  </div>
                </b-form>
              </validation-observer>
              <b-form>
                <div class="def-func-input-form-group">
                  <div class="def-func-input-group global-checklist-freq">
                    <label
                      class="global-freq freq-resp-title"
                    >Frequently asked questions &amp; comments</label>
                  </div>
                </div>
                <div class="faq-outer">
                  <div class="faq-tablist" role="tablist">
                    <b-card
                      no-body
                      v-for="(userlist,index) in userMessageList"
                      :key="'userlist_'+index"
                    >
                      <b-card-header
                        header-tag="header"
                        role="tab"
                        @click="call_chat(userlist.public_id, index)"
                      >
                        <b-button block href="#" v-b-toggle="'accordion-'+index" variant="info">
                          <div class="justify-content-space-between">
                            <div class="faq-header-col width-1">
                              <div class="faq-active-icon"></div>
                            </div>
                            <div class="faq-header-col width-4 posted-time">
                              <span>#{{index + 1}}</span>
                              <span
                                class="posted-date-time"
                              >{{ userlist.timestamp.date | showdatetime }} {{ userlist.timestamp.timezone }}</span>
                            </div>
                            <div class="faq-header-col width-3 posted-name">
                              Posted By:
                              <span>{{userlist.postedBy.first | uppercaseFirstLetter}} {{userlist.postedBy.last | uppercaseFirstLetter}}</span>
                            </div>
                            <div class="faq-header-col width-2">{{userlist.group}}</div>
                            <div class="faq-header-col width-1">{{userlist.station}}</div>
                            <div class="faq-header-col width-1">{{userlist.orga_code}}</div>
                            <div class="width-1" :class="userlist.questions == 1 ? 'answered' : 'faq-header-col'">
                              <div class="faq-answered-icon"></div>
                            </div>
                          </div>
                        </b-button>
                      </b-card-header>

                      <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                        <b-card-body
                          :class="{'activate-chat-box': index === selectedMessageIndex ? true : false}"
                          v-for="(messages,index) in userMessages"
                          :key="'messages'+index"
                        >
                          <b-card-text @click="select_briefings(index)">
                            <div class="brief-rounded-sectn faq-user">
                              <label
                                class="distribute-group-label"
                              >{{messages.posted_by_name.first | uppercaseFirstLetter}} {{messages.posted_by_name.last | uppercaseFirstLetter}} {{messages.question_timestamp.date | showdatetime}} {{messages.question_timestamp.timezone}}</label>
                              <label class="faq-text">{{messages.question}}</label>
                              <a v-if="messages.quest_files && messages.quest_files.length > 0" :href="messages.quest_files[0].name" target="_blank" download>
                                <img class="faq-file-icon" src="@/assets/images/add-pdf.png">
                                <label class="faq-text faq-file-name">{{ messages.quest_files[0].file_name }}</label>
                              </a>
                              <div class="clearfix"></div>
                            </div>
                            <div class="clearfix"></div>
                          </b-card-text>
                          <b-card-text v-if="messages.answer !='' ">
                            <div class="brief-rounded-sectn faq-admin">
                              <label
                                class="distribute-group-label"
                              >{{messages.answered_by_name.first | uppercaseFirstLetter}} {{messages.answered_by_name.last | uppercaseFirstLetter}} {{messages.answer_timestamp.date | showdatetime}} {{messages.answer_timestamp.timezone}}</label>
                              <label class="faq-text">{{messages.answer}}</label>
                              <a v-if="messages.ans_files && messages.ans_files.length > 0" :href="messages.ans_files[0].name" target="_blank" download>
                                <img class="faq-file-icon" src="@/assets/images/add-pdf.png">
                                <label class="faq-text faq-file-name">{{ messages.ans_files[0].file_name }}</label>
                              </a>
                            </div>
                            <div class="clearfix"></div>
                          </b-card-text>
                          <div class="clearfix"></div>
                        </b-card-body>
                        <b-card-body class="brief-card-body" v-if="files.length > 0">
                          <b-card-text class="brief-upload-pdf">
                            <div class="brief-rounded-sectn faq-user faq-file">
                              <label class="faq-text faq-file-name">{{ files[0].name }}</label>
                              <span class="cancel-pdf" @click="deleteAttachment">x</span>
                            </div>
                            <div class="clearfix"></div>
                          </b-card-text>
                          <div class="clearfix"></div>
                        </b-card-body>
                        <b-card-footer>
                          <b-card-text class="faq-type">
                            <div class="faq-input">
                              <div class="def-func-input-group briefing-list-filter mb-0">
                                <div class="def-func-input-group-col btn-search">
                                  <b-input-group-append is-text :disabled="userlist.my_faq == 0">
                                    <font-awesome-icon :icon="['fas', 'paperclip']" />
                                  </b-input-group-append>
                                  <b-form-input v-model="message" placeholder="Message" :disabled="userlist.my_faq == 0"></b-form-input>
                                  <b-form-file
                                    ref="fileInput"
                                    id="fileInput"
                                    @change="upload_files($event)"
                                    class="faq-fileInput"
                                    :disabled="userlist.my_faq == 0"
                                  ></b-form-file>
                                </div>
                                <div class="pt-2 ml-2">
                                  <b-icon v-b-tooltip.hover.top="'Only PDF allowed'" icon="info" class="info-icon-size"></b-icon>
                                </div>
                                <div class="def-func-input-group-col faq-send-btn">
                                  <b-button
                                    class="btn btn-link"
                                    variant="light"
                                    :disabled="valDisable == 1 || userlist.my_faq == 0"
                                    @click="ask_questions_get_answers"
                                  >Send</b-button>
                                </div>
                              </div>
                              <p class="feedback-error" v-show="attachmentError">Only PDF allowed</p>
                            </div>
                          </b-card-text>
                        </b-card-footer>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      hide-footer
      id="modal-ack-brief"
      size="lg"
      modal-class="flipModal manage-user-modal"
      title="Acknowledge Briefing"
      centered v-model="ackModalShow"
    >
      <div>
        <div class="text-center">
          <div>
            <p class="color-text">{{ briefing_title }}</p>
            <b-row align-h="center" class="mt-4">
              <b-col md="4" class="mt-3">
                <button @click="acknowledge_briefing" class="btn btn-rename-delete">Acknowledge</button>
              </b-col>
              <b-col md="4" class="mt-3">
                <button class="btn btn-rename-delete" @click="$bvModal.hide('modal-ack-brief')">Cancel</button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      hide-footer
      id="modal-ack-rate"
      size="lg"
      modal-class="flipModal manage-user-modal"
      :title="ackTitle"
      centered
      @show="onShowAck"
    >
      <div class="faq-outer" id="faq-modalView">
        <div class="faq-tablist" role="tablist">
          <b-card no-body v-for="( result, ackindex ) in ackReview" :key="ackindex">
            <b-card-header header-tag="header" role="tab" @click="ackToggleReset">
              <b-button href="#" v-b-toggle="'accordion_' + ackindex" variant="info">
                <div class="justify-content-space-between">
                  <div class="faq-header-col width-1">
                    <div class="faq-active-icon"></div>
                  </div>
                  <div class="faq-header-col width-8">{{ result.station.code }}</div>
                  <div class="faq-header-col width-2">{{ result.station.percentage }}</div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion_'+ackindex" accordion="my-accordion-new" role="tabpanel">
              <b-card-body class="">
                <b-card-text>
                  <div class="def-func-table manage-users-table m-0">
                    <b-form-checkbox switch size="lg" v-model="ack_label" class="ack-switch-btn">
                      <span v-if="ack_label">Acknowledged</span><span v-else>Non Acknowledged</span>
                    </b-form-checkbox>
                    <span v-if="ack_label">
                      <table>
                        <thead>
                          <tr><th>Name</th><th>Date</th></tr>
                        </thead>
                        <tbody>
                          <tr v-for="( ackData, ackKey ) in result.station.ack_users" :key="ackKey">
                            <td class="text-left">{{ ackData.name }}</td>
                            <td>{{ ackData.acknowledged_on.date | moment }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                    <span v-else>
                      <table>
                        <thead>
                          <tr><th>Name</th></tr>
                        </thead>
                        <tbody>
                          <tr v-for="( nonAckData, nonAckKey ) in result.station.not_ack_users" :key="nonAckKey">
                            <td class="text-left">{{ nonAckData.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                  <div class="clearfix"></div>
                </b-card-text>
                <div class="clearfix"></div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </b-modal>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <ackModel></ackModel>
  </div>
</template>
<script>
import { HTTP, HTTP_formdata } from "../../constants/http-common";
import { EventBus } from "../../main";
import moment from "moment";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { videoPlayer } from 'vue-video-player';
import ackModel from "../../components/admin_layouts/dashboard_layouts/status_message/acknowledgement_model";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    videoPlayer,
    ackModel
  },
  data() {
    return {
      ack_label: true,
      slides: [],
      video_src: "",
      userMessageList: [],
      userMessages: [],
      selectedMessageIndex: "",
      message: "",
      briefing_title: "",
      briefing_type: "",
      start_date: "",
      end_date: "",
      briefing_details: "",
      buller_point: "",
      acknowledgement: 0,
      questionRaised: 0,
      answerspanding: 0,
      briefingImages: [],
      currentNumber: 0,
      timer: null,
      acknowledgedFlag: 0,
      files: [],
      faqAnsPermission: 0,
      faqTabUuid: "",
      valDisable: 1,
      attachmentError: false,
      ack_id: "",
      ackTitle: "",
      ackReview: [],
      playerOptions: {
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
      },
      messsage_loader: false,
      nextPagePath: "",
      ackModalShow: null
    };
  },
  computed: {
    currentImage: function() {
      return this.briefingImages[
        Math.abs(this.currentNumber) % this.briefingImages.length
      ];
    },
    player() {
      return this.$refs.videoPlayer.player
    },
    mouseLeave() {
      return this.$store.getters.mouseLeave;
    }
  },
  filters: {
    moment: function(value) {
      if (value) {
        return moment(value).format("DD MMM YYYY");
      }
    },
    showdatetime: function(value) {
      if (value) {
        return moment(value).format("DD-MMM-YYYY, h:mm");
      }
    },
    uppercaseFirstLetter: function(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  watch: {
    message(newVal) {
      ( this.message == '' ) ? this.valDisable = 1 : (( this.selectedMessageIndex == '' ) ? this.valDisable = 1 : this.valDisable = 0);
    },
    mouseLeave( val ) {
      if ( val ) {
        this.ackHandler();
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if ( !this.nextPagePath && this.faqAnsPermission == 0 && this.acknowledgedFlag == 0 ) {
      this.$bvModal.show("modal-briefing-acknowledgement");
      this.nextPagePath = to.path;
      next(false);
    } else {
      next();
    }
  },
  methods: {
    ackToggleReset() {
      this.ack_label = true;
    },
    onShowAck() {
      let ackPercentage = (typeof this.acknowledgement === 'string') ? this.acknowledgement : this.acknowledgement.toFixed(2) + '%';
      this.ackTitle = 'Acknowledgement Rate: ' + ackPercentage
    },
    select_briefings(index) {
      this.selectedMessageIndex = index;
      ( this.message == '' ) ? this.valDisable = 1 : this.valDisable = 0;
    },
    upload_files(event) {
      if ( event.target.files.length > 0 && event.target.files[0].type && event.target.files[0].type == "application/pdf" ) {
        this.files = [];
        this.files.push(event.target.files[0]);
        document.getElementById('fileInput').value=''
      } else {
        this.attachmentError = true;
        setTimeout(() => { this.attachmentError = false; }, 2000);
      }
    },
    ask_questions_get_answers() {
      let url = "";
      if ( this.faqAnsPermission == 1 ) {
        let quest_files = ( this.userMessages[this.selectedMessageIndex].quest_files.length > 0 ) ? this.userMessages[this.selectedMessageIndex].quest_files[0].file_name : "";
        url = "/briefings/ans_queries/" + this.$route.params.id + "?faq_public_id=" + this.userMessages[this.selectedMessageIndex].faq_public_id + "&question=" + this.userMessages[this.selectedMessageIndex].question + "&answer=" + this.message + "&quest_files=" + quest_files;
        let file_data = new FormData();
        let ans_file = ( this.files.length > 0 ) ? this.files[0] : null;
        file_data.set( "ans_files", ans_file );
        HTTP_formdata.post( url, file_data )
          .then(Response => {
            this.message = "";
            this.files = [];
            this.call_chat(this.userMessages[this.selectedMessageIndex].posted_by_id);
            this.selectedMessageIndex = "";
            this.valDisable = 1;
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 404 || error.response.status == 500 ) {
              let resp = {
                msg: ( error.response.status == 500 ) ? "Status Code: 500" : error.response.data.message,
                popup: true
              }
              EventBus.$emit("error_resp", resp);
            }
          });
      } else {
        url = "user_briefing_faqs/addFaq";
        let form_data = {
          briefing: this.$route.params.id,
          question: this.message
        };
        HTTP_formdata.post(url, form_data)
          .then(Response => {
            if ( this.files.length > 0 ) {
              let formdata = new FormData();
              formdata.set("file", this.files[0]);
              HTTP_formdata.post( "/user_briefing_faqs/upload_attachment/" + Response.data.faq_public_id, formdata )
                .then(Response => {
                  this.files = [];
                  this.message = "";
                  this.call_chat(this.faqTabUuid);
                  this.valDisable = 1;
                })
                .catch(error => {
                  if ( error.response.status == 400 || error.response.status == 404 || error.response.status == 500 ) {
                    let resp = {
                      msg: ( error.response.status == 500 ) ? "Status Code: 500" : error.response.data.message,
                      popup: true
                    }
                    EventBus.$emit("error_resp", resp);
                  }
                });
            } else {
              this.message = "";
              this.call_chat(this.faqTabUuid);
              this.valDisable = 1;
            }
          })
          .catch(error => {});
      }
    },
    call_chat(uuid, index) {
      this.faqTabUuid = uuid
      this.userMessages = [];
      let user_data = {
        brieifing_id: this.$route.params.id,
        user_id: uuid
      };
      HTTP_formdata.post("briefings/display_faqs_by_user", user_data)
        .then(response => {
          this.userMessages = response.data;
          if ( index >= 0 ) {
            this.userMessageList[index].questions = 0
            this.message = "";
            this.files = [];
            if ( this.faqAnsPermission == 0 ) {
              this.selectedMessageIndex = "a";
            } else {
              this.selectedMessageIndex = "";
            }
          }
        })
        .catch(response => {});
    },
    next: function() {
      this.currentNumber += 1;
    },
    prev: function() {
      this.currentNumber -= 1;
    },
    deleteAttachment() {
      this.files = [];
    },
    acknowledge_briefing() {
      let form_data = {
        briefing: this.$route.params.id,
        ack_id: this.ack_id
      };
      HTTP_formdata.post("user_briefing_acks/add_acknowledgement", form_data)
        .then(response => {
          if ( response.status == 200 ) {
            this.messsage_loader = false;
            this.acknowledgedFlag = 1
            this.$nextTick(() => {
              this.$bvModal.hide("modal-ack-brief");
            });
            let resp = {
              msg: response.data.data,
              popup: true
            }
            EventBus.$emit("success_resp", resp);
            if ( this.mouseLeave )
              this.$store.dispatch( 'mouseLeave', false )
            setTimeout(() => {
              if ( this.nextPagePath ) {
                this.$router.push({path: this.nextPagePath })
              }
            }, 3000);
          }
        })
        .catch(error => {
          this.messsage_loader = false;
        });
    },
    ackHandler(event) {
      if ( this.faqAnsPermission == 0 && this.acknowledgedFlag == 0 && !this.ackModalShow  )
        this.$bvModal.show("modal-briefing-acknowledgement");
      else if ( this.ackModalShow ) {
        if ( this.mouseLeave )
          this.$store.dispatch( 'mouseLeave', false )
      }
    }
  },
  mounted() {
    this.messsage_loader = true;
    HTTP.get("briefings/get_briefing/" + this.$route.params.id)
      .then(response => {
        this.briefing_title = response.data[0].title;
        this.acknowledgement = response.data[0].ack_rate;
        this.questionRaised = response.data[0].quest_raised;
        this.answerspanding = response.data[0].quest_pending;
        this.briefing_type = response.data[0].type;
        this.start_date = response.data[0].from_date.date;
        this.end_date = (response.data[0].to_date.date) ? response.data[0].to_date.date : response.data[0].to_date;
        this.briefing_details = response.data[0].content[0].description;
        this.buller_point = response.data[0].content[0].keynote;
        this.acknowledgedFlag = response.data[0].acknowledged;
        this.faqAnsPermission = response.data[0].ans_faq;
        this.ack_id = response.data[0].ack_id;
        if ( this.faqAnsPermission == 0 ) {
          this.selectedMessageIndex = "a";
        } else {
          this.selectedMessageIndex = "";
        }
        if ( this.acknowledgedFlag ) {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-briefing-acknowledgement')
          })
        }
        if (response.data[0].media.length > 0) {
          this.video_src = (response.data[0].media[0].video.length > 0) ? response.data[0].media[0].video[0].path : "";
          this.playerOptions.sources[0].src = (response.data[0].media[0].video.length > 0) ? response.data[0].media[0].video[0].path : "";
          if ( response.data[0].media[0].image.length > 0 ) {
            for (let i = 0; i < response.data[0].media[0].image.length; i++) {
              this.briefingImages.push(
                response.data[0].media[0].image[i].path
              );
            }
          }
          if ( response.data[0].media[0].pdf.length > 0 ) {
            for (let i = 0; i < response.data[0].media[0].pdf.length; i++) {
              this.slides.push({
                id: "slide_" + i,
                title: "",
                content:
                  '<a target="_blank" href="' + response.data[0].media[0].pdf[i].path +
                  '" download><img src="' + require('@/assets/images/add-pdf.png') + '" title="PDF" /><br><p>' +
                  response.data[0].media[0].pdf[i].display_name +
                  "</p><br></a>"
              });
            }
          }
        }
        this.ackReview = response.data[1].acknowledgement_review;
        this.messsage_loader = false;
      })
      .catch(error => {
        this.messsage_loader = false;
      });

    HTTP.get("briefings/display_all_faqs/" + this.$route.params.id)
      .then(response => {
        this.userMessageList = response.data;
      })
      .catch(error => {});
  },
  created() {
    // window.addEventListener('beforeunload', (e) => {
    // //   // Cancel the event
    //   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // //   // Chrome requires returnValue to be set
    // //   this.ackHandler(e);
    // //   console.log('leavingbb', e)
    //   e.returnValue = "Do you want to acknowledge that you have read the mandatory briefing?";
    // //   delete e['returnValue'];
    //   // return null;
    //   console.log("this.anything - event");
    //   console.log(e);
    //   this.$bvModal.show("modal-briefing-acknowledgement");
    // });

    // Handle page visibility change events
    document.addEventListener( "visibilitychange", () => {
      if ( document.hidden )
        this.ackHandler();
    });

    EventBus.$on("ackConfirm", data => {
      if ( data == true ) {
        this.messsage_loader = true;
        this.acknowledge_briefing();
      } else {
        if ( this.nextPagePath )
          this.$router.push({path: this.nextPagePath })
        if ( this.mouseLeave )
          this.$store.dispatch( 'mouseLeave', false )
      }
    });
  },
  beforeCreate() {
    EventBus.$off("ackConfirm")
  }
};
</script>
<style scoped>
  @import "./def_function.css";
  .card-body:hover,
  .card-body:hover .distribute-group-label {
    background-color: aliceblue;
  }
  .base-image-input {
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }
  .placeholder {
    background: #f0f0f0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 18px;
    font-family: Helvetica;
  }
  .placeholder:hover {
    background: #e0e0e0;
  }
  .file-input {
    display: none;
  }
  .activate-chat-box {
    background-color: aliceblue;
  }
  .faq-fileInput label.custom-file-label {
      display: none;
  }
  .ack-switch-btn {margin: 0px 4px 10px;}
  .ack-switch-btn label.custom-control-label span {
      font-size: 16px;
      color: black;
  }
  .ack-rate-popup span {
      outline: none;
      cursor: pointer;
  }
  .ack-rate-popup span:hover {
      text-decoration: underline;
  }
  .faq-tablist .card {
      margin: 20px 0 0;
  }
</style>