<template>
  <b-modal
    hide-footer
    id="modal-due-on"
    size="md"
    modal-class="flipModal manage-user-modal text-center"
    :title="(frequency == 'Daily' || frequency == 'Weekly') ? 'Select days of week' : 'Select date'"
    @show="onShow"
    centered>
    <div v-if="frequency == 'Daily' || frequency == 'Weekly'" class="justify-content-space-between">
      <b-form-checkbox-group
        v-if="frequency == 'Daily'"
        id="checkbox-group-2"
        name="flavour-2"
        class="add-media-inner select-days"
        v-model="datepickerFreq"
        buttons
        button-variant="light"
      >
        <b-form-checkbox value="Monday">Mo</b-form-checkbox>
        <b-form-checkbox value="Tuesday">Tu</b-form-checkbox>
        <b-form-checkbox value="Wednesday">We</b-form-checkbox>
        <b-form-checkbox value="Thursday">Th</b-form-checkbox>
        <b-form-checkbox value="Friday">Fr</b-form-checkbox>
        <b-form-checkbox value="Saturday">Sa</b-form-checkbox>
        <b-form-checkbox value="Sunday">Su</b-form-checkbox>
      </b-form-checkbox-group>
      <b-form-checkbox-group
        v-else
        id="checkbox-group-2"
        name="flavour-2"
        class="add-media-inner select-days"
        v-model="datepickerFreq"
        buttons
        button-variant="light"
        :disabled="datepickerFreq.length >= max"
      >
        <b-form-checkbox value="Monday">Mo</b-form-checkbox>
        <b-form-checkbox value="Tuesday">Tu</b-form-checkbox>
        <b-form-checkbox value="Wednesday">We</b-form-checkbox>
        <b-form-checkbox value="Thursday">Th</b-form-checkbox>
        <b-form-checkbox value="Friday">Fr</b-form-checkbox>
        <b-form-checkbox value="Saturday">Sa</b-form-checkbox>
        <b-form-checkbox value="Sunday">Su</b-form-checkbox>
      </b-form-checkbox-group>
    </div>
    <div v-else class="freq-monthly">
      <Datepicker v-model="datepickerFreq" :disabled-dates="state.disabledDates" :inline="true"></Datepicker>
    </div>
    <div class="justify-content-space-between sel-freq-btns">
      <b-button
        class="btn-rename-delete mt-5 freq-update-btn"
        variant="light"
        @click="onSelect( $event )"
      >Update</b-button>
      <b-button
        class="btn-rename-delete mt-5 freq-update-btn"
        variant="light"
        @click.prevent="onClear( $event )"
      >Clear</b-button>
    </div>
    <template v-slot:modal-footer></template>
  </b-modal>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { EventBus } from "../../../main";
export default {
  props: ["frequency", "data", "index"],
  data() {
    return {
      datepickerFreq: "",
      state: {
        disabledDates: {
          to: new Date()
        }
      },
      max: 1
    };
  },
  components: {
    Datepicker
  },
  watch: {
    frequency(newVal) {
      if (newVal == "Daily" || newVal == "Weekly") {
        this.datepickerFreq = [];
        if (this.data != "") this.datepickerFreq = this.data;
      } else {
        this.datepickerFreq = "";
        if (this.data != "") this.datepickerFreq = this.data;
      }
    }
  },
  methods: {
    onSelect(event) {
      let dueOn = {
        data: (Array.isArray(this.datepickerFreq) && this.datepickerFreq.length == 0) ? "" : this.datepickerFreq,
        index: this.index
      }
      EventBus.$emit("selectFrequency", dueOn);
      this.$nextTick(() => {
        this.$bvModal.hide("modal-due-on");
      });
    },
    onShow(bvModalEvt) {
      if (this.frequency == "Daily" || this.frequency == "Weekly") {
        this.datepickerFreq = [];
        if (this.data != "") this.datepickerFreq = this.data;
      } else {
        this.datepickerFreq = "";
        if (this.data != "") this.datepickerFreq = this.data;
      }
    },
    onClear( event ) {
      if (this.frequency == "Daily" || this.frequency == "Weekly") {
        this.datepickerFreq = [];
      } else {
        this.datepickerFreq = "";
      }
    }
  },
  created() {
    EventBus.$on("clearData", clear => {
      this.datepickerFreq = ""
    });
  }
};
</script>