import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons/faPaperclip";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons/faPhoneSquareAlt";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons/faUndoAlt";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OtpInput from "@bachdgvn/vue-otp-input";
import { ValidationObserver, ValidationProvider, extend, localize, required } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import VueCookies from "vue-cookies";
import { isloggedin } from "./constants/app-constants";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import CKEditor from "@ckeditor/ckeditor5-vue";
import successmsg from "./components/admin_layouts/dashboard_layouts/status_message/success_resp";
import errormsg from "./components/admin_layouts/dashboard_layouts/status_message/error_resp";
import testmsg from "./components/admin_layouts/dashboard_layouts/status_message/test_completed";
import store from "./store/store";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import routefiles from "./routes";
import VOffline from 'v-offline';

Vue.use(CKEditor);
Vue.component("successmsg", successmsg);
Vue.component("errormsg", errormsg);
Vue.component("testmsg", testmsg);
Vue.component("v-otp-input", OtpInput);
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);
Vue.component('VOffline', VOffline);

Vue.use(VueCookies);
// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);
localize({
  en: {
    messages: {
      confirmed: 'Password does not match'
    }
  }
});

export const EventBus = new Vue();
//custom validations
extend('verify_password', {
  // Message:  field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return strongRegex.test(value);
  },
  message: "1 upper case, 1 lower case, 1 number, 1 special character"
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.component("font-awesome-icon", FontAwesomeIcon);
//FontAwesome
library.add(faMapMarkerAlt);
library.add(faSearch);
library.add(faPaperclip);
library.add(faCalendarAlt);
library.add(faChevronDown);
library.add(faEnvelope);
library.add(faPhoneSquareAlt);
library.add(faPhoneAlt);
library.add(faUndoAlt);

Vue.config.productionTip = false;

Vue.use(VueRouter);

// Global typeahead registration
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);

export const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: routefiles,
  mode: "history"
});

window.axios = axios;
Vue.use(VueAxios, axios);

router.beforeEach((to, from, next) => {
  let token = $cookies.get("user") ? $cookies.get("user").data.token : "";//( isloggedin.token ) ? isloggedin.token : $cookies.get("user") ? $cookies.get("user").data.token : "";
  let accessRight = ( isloggedin.access_rights ) ? isloggedin.access_rights : $cookies.get("uar") ? $cookies.get("uar").access_rights: "";
  ( Array.isArray( accessRight ) ) ? ( ( accessRight.includes("All") ) ? null : accessRight.push("All") ) : "";
  if ( to.matched.some( record => record.meta.requiresAuth ) ) {
    store.dispatch('tryAutoLogin');
    if ( ! token || store.state.idToken == null )
      next("/login");
    else {
      // if ( accessRight.some( function ( eachItem ) { if ( ! Array.isArray( to.meta.access_rights )) return eachItem == to.meta.access_rights; else return to.meta.access_rights.includes( eachItem ) } ) )
      if ( accessRight.some( function ( eachItem ) { if ( ! Array.isArray( to.meta.access_rights )) return eachItem == to.meta.access_rights; else { if ( to.params.type == "briefings" ) return to.meta.access_rights[0].briefing.includes( eachItem ); else if ( to.params.type == "trainings" ) return to.meta.access_rights[0].training.includes( eachItem ); else return to.meta.access_rights[0].videogramme.includes( eachItem ); } } ) )
        next();
      else
        next({ name: 'notFound' });
    }
  } else {
    if ( token || store.state.idToken ) {
      if ( to.meta.allowed_both ) next(); else next("/");
    } else
      next();
  }
});

new Vue({
  el: "#app",
  store,
  render: h => h(App),
  router: router
});
