<template>
  <div>
    <b-modal ref="modalBriefingAcknowledgement" v-model="modalShow" @shown="resetModal"
      modal-class="flipModal manage-user-modal def-modal" content-class="shadow"
      title="Briefing Acknowledgement" id="modal-briefing-acknowledgement" centered hide-footer>
      <p class="mb-5 text-left">
        Dear User,<br /><br />You are moving out of the page.<br /><br />Do you want to acknowledge that you have read the mandatory briefing?
      </p>
      <div class="w-100">
        <b-button @click="ackConfirmation( true )" variant="light" class="float-left btn-rename-delete">Yes, Acknowledge</b-button>
        <b-button @click="ackConfirmation( false )" variant="light" class="float-right btn-rename-delete">No</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import { EventBus } from "../../../../main";
  export default {
    data() {
      return {
        modalShow: null,
        status: false
      }
    },
    watch: {
      modalShow( val ) {
        if ( val == false && this.status == false ) {
          this.ackConfirmation( false )
        }
      }
    },
    methods: {
      ackConfirmation( confirm ) {
        this.status = true;
        if ( this.modalShow ) 
          this.$bvModal.hide("modal-briefing-acknowledgement");
        EventBus.$emit( "ackConfirm", confirm );
      },
      resetModal() {
        this.status = false;
      }
    }
  }
</script>