<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-table manage-users-table">
                  <trainingtabel :trainings="trainings"></trainingtabel>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-container>
    </div>
    <b-modal
      hide-footer
      id="modal-edit-user"
      size="lg"
      modal-class="flipModal manage-user-modal"
      title="Create & Edit User Information"
      centered
      v-bind:content-class="{ show: modalShow, modalActive: modalActive }"
    >
      <template v-slot:modal-footer></template>
    </b-modal>
    <b-modal
      hide-footer
      id="modal-deactivate-User"
      size="md"
      modal-class="flipModal manage-user-modal"
      title="Deactivate User"
      centered
    >
      <div class="def-func-input-form-group">
        <b-row>
          <b-col>
            <div class="def-func-input-group">
              <label>Enter reason For Deactivation</label>
              <b-form-textarea id="textarea" rows="6" max-rows="6"></b-form-textarea>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="btn-rename-delete" variant="light">Cancel</b-button>
          </b-col>
          <b-col>
            <b-button class="btn-rename-delete float-right" variant="light">Deactivate</b-button>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer></template>
    </b-modal>
  </div>
</template>
<script>
import trainingtabel from "./trainingtable";
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  data() {
    return {
      selectedFilter: null,
      modalShow: false,
      modalActive: false,
      trainings: [],
      currentPage: 1,
      rows: 0,
      perPage: 10
    };
  },
  components: {
    trainingtabel
  },
  watch: {
    currentPage(newVal) {
      this.get_all_trainings(this.currentPage, 10);
    }
  },
  methods: {
    get_all_trainings(pagenumber, limit) {
      this.trainings = [];
      let formData = {
        page: pagenumber,
        limit: limit
      };
      HTTP_formdata.post("training/get_all_trainings", formData)
        .then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.trainings = response.data.data;
          }
          this.rows = response.data.totalLength;
        })
        .catch(response => {});
    }
  },
  mounted() {
    this.get_all_trainings(1, 10);
  },
  created() {
    EventBus.$on( "refreshTrainingList", response => {
      this.get_all_trainings(this.currentPage, 10);
    });
  }
};
</script>
<style>
@import "../def_function.css";
@import "../manage_users.css";
</style>
