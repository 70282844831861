<template>
  <div id="app" @mouseleave="mouseleave">
    <div v-bind:class="{ menuOpen: menuOpen }"> <!-- , tabScreen: windowWidth -->
      <appheader v-if="$route.meta.route_access =='admin'"></appheader>
      <appsidebar v-if="$route.meta.route_access =='admin'"></appsidebar>
      <router-view></router-view>
      <div class="clearfix"></div>
    </div>
    <v-offline @detected-condition="amIOnline"></v-offline>
  </div>
</template>

<script>
import sidebar from "../src/components/admin_layouts/sidebar";
import header from "../src/components/admin_layouts/header";
import { EventBus } from "./main";
import { isloggedin } from "./constants/app-constants";
// import store from "./store/store";
export default {
  components: {
    appheader: header,
    appsidebar: sidebar
  },
  name: "app",
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      menuOpen: false,
      windowWidth: true,
      onLine: null
    };
  },
  watch: {
    onLine( val ) {
      if ( val != null ) {
        this.$store.dispatch( 'networkData', val )
        // console.log("main app - ",val);
      }
    }
  },
  methods: {
    amIOnline(e) {
      this.onLine = e;
      // console.log("main app amIOnline - ", this.onLine);
    },
    mouseleave() {
      if ( this.$route.name && this.$route.name == 'displayBriefing' ) {
        this.$store.dispatch( 'mouseLeave', true )
      }
    }
  },
  // beforeCreate () {
  //   document.title = "ShopTalk";
  // },
  created: function() {
    EventBus.$on("slideMenuClicked", MenuClicked => {
      this.menuOpen = !this.menuOpen;
    });
    // let token = ( isloggedin.token ) ? isloggedin.token : $cookies.get("user") ? $cookies.get("user").data.token : "";
    // if ( token || store.state.idToken != null ) {
    //   this.$store.dispatch('tryAutoLogin');
    // } //else {

    // }

  //   window.onresize = () => {
  //     if (window.innerWidth <= 991) {
  //       this.windowWidth = true;
  //     } else {
  //       this.windowWidth = false;
  //     }
  //   };
  }
};
</script>
