<template>
  <div>
    <div class="training-questns" v-for="(quest,index) in questions" :key="'question_'+index">
      <b-row>
        <b-col>
          <div class="training-questns-section">
            <div class="training-questn-col questn-number">
              <b-badge class="questn-badge">{{index + 1}}</b-badge>
            </div>
            <div class="training-questn-col questn">
              <label v-show="quest.label">{{quest.question}}</label>
              <b-form-input
                v-show="quest.edit"
                v-model="quest.question"
                @blur="blurField"
              ></b-form-input>
              <div class="attachment-btn" v-show="quest.edit">
                <b-input-group-append is-text>
                  <font-awesome-icon :icon="['fas', 'paperclip']" />
                </b-input-group-append>
                <b-form-file accept="image/jpeg, image/png, .jpg, .png, .jpeg" ref="fileInput" id="fileInput"
                class="faq-fileInput" v-model="url" @change="onFileChange( $event, index )"></b-form-file>
              </div>
              <div style="color:red" v-if="quest.noQuestionError"><p v-if="!quest.question.trim()">This field is required</p></div>
              <p class="feedback-error" v-show="quest.imgError">{{ quest.errorMsg }}</p>
            </div>
            <div class="training-questn-col questn-point" v-if="quest.points">{{quest.points}}</div>
            <div class="training-questn-col questn-action">
              <img @click="showHideSaveEdit(index)" src="@/assets/images/edit-questn.png" title="Edit" />
              <img v-b-modal.confirm-delete
                src="@/assets/images/delete-base-img.png" title="Delete"
                @click="deletePopup(index, quest.public_id, quest.question)"
              />
            </div>
          </div>
          <span v-show="quest.label" v-if="quest.question_file.length > 0">
            <div class="text-center col">
              <div class="preview" v-for="(imgData,imgIndex) in quest.question_file" :key="imgIndex">
                <img :src="imgData.path" />
                <div class="clearfix"></div>
              </div>
            </div>
          </span>
          <span v-show="quest.edit" v-if="imgUpload.length > 0">
            <div class="text-center col">
              <div class="preview" v-for="(imgData,imgIndex) in imgUpload" :key="imgIndex">
                <img :src="imgData.path" />
                <div class="clearfix"></div>
                <div class="training-questn-col questn-action">
                  <span><img @click="onImgDelete(imgIndex)" src="@/assets/images/delete.png" title="Delete" role="button" tabindex="0"></span>
                </div>
              </div>
            </div>
          </span>
          <div class="training-options-section">
            <div class="training-options-header">
              <div class="def-func-input-form-group">
                <div class="def-func-input-group mb-0">
                  <b-form>
                    <div class="def-func-input-group-col">
                      <b-form-input
                        placeholder="Enter up to 6 answers where the user can select the correct one *"
                        v-model="quest.newoptions"
                      ></b-form-input>
                      <p class="feedback-error" v-show="quest.optionError">This field is required</p>
                      <p class="feedback-error" v-show="quest.maxOptionError">Maximum 6 options allowed</p>
                    </div>
                    <div class="def-func-input-group-col btn-add-outer">
                      <button :disabled="!quest.newoptions"
                        type="submit"
                        variant="light"
                        class="btn add-btn"
                        @click.prevent="optionsManipulation(index,quest.public_id,'add')"
                      >+</button>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
            <div class="training-options-body">
              <b-form-group>
                <div
                  class="training-options-row"
                  v-for="(options,indx) in quest.options"
                  :key="'option_'+indx+index"
                >
                  <div class="training-questn-col questn-number">
                    <b-badge class="questn-badge">{{ indx | alphabatalize }}</b-badge>
                  </div>
                  <div class="training-questn-col questn">
                    <label>{{options.option}}</label>
                  </div>
                  <div class="training-questn-col questn-action questn-delete">
                    <img @click.prevent="optionsManipulation(index,quest.public_id,'delete',indx)" src="@/assets/images/delete.png" title="Delete" />
                  </div>
                  <div class="training-questn-col questn-correct-ans">
                    <b-form-radio
                      @change="optionsManipulation(index,quest.public_id,'correctAnswer',questions[index].options[indx].option)"
                      v-model="questions[index].answer"
                      :value="questions[index].options[indx].option"
                    >Correct Answer</b-form-radio>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
import { EventBus } from "../../../../../main";
import confirm_modal from "../../../../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import { HTTP_formdata } from "../../../../../constants/http-common";
export default {
  props: ["questions_get", "questCheck"],
  data() {
    return {
      questions: [],
      editField: "",
      options: "",
      deleteData: "",
      imgUpload: [],
      url: null
    };
  },
  components: {
    confirmModal: confirm_modal
  },
  filters: {
    alphabatalize: function(value) {
      let aplha = ["a", "b", "c", "d", "e", "f"];
      return aplha[value];
    }
  },
  created() {
    EventBus.$on("send-questionair", question => {
      this.questions.push(question);
    });
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteQuestion();
      }
    });
  },
  watch: {
    questCheck(newVal) {
      if (newVal) {
        this.quizCheck();
      }
    },
    questions(newVal) {
      this.quizCheck();
    },
    questions_get(n){
      this.questions = this.questions_get
      this.quizCheck();
    }
  },
  methods: {
    onFileChange(e, index) {
      if (this.imgUpload.length < 3 ) {
        if ( e.target.files.length > 0 && ( e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "png" || e.target.files[0].type == "jpeg" ) ) {
          this.questions[index].imgError = false;
          let file = e.target.files[0];
          this.imgUpload.push({ path: URL.createObjectURL(file), data: file, name: "" })
        } else {
          this.questions[index].imgError = true;
          this.questions[index].errorMsg = "Select a valid image"
          setTimeout(() => {
            this.questions[index].imgError = false;
            this.questions[index].errorMsg = ""
          }, 2000);
        }
      } else {
        this.questions[index].imgError = true;
        this.questions[index].errorMsg = "Maximum 3 Images allowed"
        setTimeout(() => {
          this.questions[index].imgError = false;
          this.questions[index].errorMsg = ""
        }, 2000);
      }
    },
    onImgDelete(imgIndex) {
      this.imgUpload.splice(imgIndex, 1);
    },
    quizCheck() {
      if ( this.questions.length > 0 ) {
        for (let i = 0; i < this.questions.length; i++) {
          if ( this.questions[i].answer == null || this.questions[i].answer == "" || this.questions[i].options.length < 2 ) {
            let incompleteData = {
              disable: true,
              error: true
            }
            EventBus.$emit( "quizIncomplete", incompleteData );
            return;
          } else {
            let incompleteData = {
              disable: false,
              error: false
            }
            EventBus.$emit( "quizIncomplete", incompleteData );
          }
        }
      } else {
        let incompleteData = {
          disable: false,
          error: false
        }
        EventBus.$emit( "quizIncomplete", incompleteData );
      }
    },
    showHideSaveEdit(index){
      if (this.questions[index].question.trim() == "") {
        this.questions[index].noQuestionError = true;
        setTimeout(() => {
          this.questions[index].noQuestionError = false;
        }, 2000);
      } else {
        this.questions[index].noQuestionError = false;
        this.questions[index].label = !this.questions[index].label
        this.questions[index].edit = !this.questions[index].edit
        if ( !this.questions[index].edit ) {
          let form_data = "";
          let old_files = "";
          let count = 0;
          if ( this.imgUpload.length > 0 ) {
            form_data = new FormData();
            this.imgUpload.forEach(function( data, index ) {
              if ( data.name == "" ) {
                count++;
                form_data.set(`question_file${count}`, data.data);
              } else {
                old_files += (old_files) ? "," + data.name : data.name;
              }
            });
          }
          if ( count == 0 ) form_data = "";
          HTTP_formdata.post("training/edit_quiz_question?old_files=" + old_files + "&question=" + this.questions[index].public_id + "&question_text=" + this.questions[index].question, form_data)
            .then(response => {
              this.imgUpload = [];
              this.questions[index].question_file = response.data.data
            })
            .catch(error => {});
        } else {
          this.imgUpload = this.questions[index].question_file
        }
      }
    },
    deletePopup(index, questionUUID, quest) {
      this.deleteData = {
        index: index,
        questionUUID: questionUUID
      }
      EventBus.$emit( "deleteSelected", quest );
    },
    deleteQuestion() {
      HTTP_formdata.post("training/delete_question/" + this.deleteData.questionUUID)
        .then(response => {
          let responseSuccess = {
            msg: response.data,
            popup: true
          }
          EventBus.$emit( "success_resp", responseSuccess );
          let remainingQuestion = this.questions[this.deleteData.index].points;
          EventBus.$emit("send-deleted-points", remainingQuestion);
          this.questions.splice(this.deleteData.index, 1);
          this.deleteData = ""
          this.quizCheck();
        })
        .catch(error => {});
    },
    optionsManipulation(index, publicId, type, correctOptOrOptPos) {
      let correctAnswer = "";
      if (correctOptOrOptPos && type == "correctAnswer") {
        correctAnswer = correctOptOrOptPos;
      } else {
        correctAnswer = this.questions[index].answer;
      }
      let optionsTemp = [];
      if (this.questions[index].options.length < 6 || type == "correctAnswer" || type == "delete") {
        this.questions[index].maxOptionError = false;
        optionsTemp = this.questions[index].options.slice();
        if (type == "add") {
          if ( !this.questions[index].newoptions.trim() || this.questions[index].newoptions.trim() == "" ) {
            this.questions[index].optionError = true;
            setTimeout(() => {
              this.questions[index].optionError = false;
            }, 2000);
            return;
          }
          optionsTemp.push({ option: this.questions[index].newoptions });
        } else if (type == "delete") {
          if ( optionsTemp[correctOptOrOptPos].option == correctAnswer ) {
            this.questions[index].answer = ""
            correctAnswer = ""
          }
          optionsTemp.splice(correctOptOrOptPos, 1);
        } else if (type == "correctAnswer") {
        }
        let form_data = {
          option: optionsTemp,
          correct_answer: correctAnswer,
          question: publicId
        };
        HTTP_formdata.post("training/question_options", form_data)
          .then(response => {
            this.questions[index].options = response.data.question.options;
            if (type == "add") {
              this.questions[index].newoptions = ""
            } else if (type == "delete") {
              let resp = {
                msg: "Quiz option deleted successfully.",
                popup: true
              }
              EventBus.$emit("success_resp", resp);
            }
            this.quizCheck();
          })
          .catch(error => {
            if ( error.response.status == 400 ) {
              let resp = {
                msg: error.response.data.message,
                popup: true
              };
              EventBus.$emit("error_resp", resp);
            }
          });
      } else if ( this.questions[index].options.length >= 5 ) {
        this.questions[index].maxOptionError = true;
        setTimeout(() => {
          this.questions[index].maxOptionError = false;
        }, 2000);
        return;
      }
    },
    blurField() {
      this.editField = "";
    }
  }
};
</script>