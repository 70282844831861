<template>
  <div v-show="selectedReport.displayRate">
    <b-row v-if="selectedReport.selected == 'responseRate'">
      <b-col>
        <div class="def-func-input-form-group breifing-content">
          <div class="def-func-input-group pt-3 mb-0">
            <div class="def-func-input-group-col width-10">
              <label class="global-freq freq-resp-title check-title">Details - Completion Rate</label>
            </div>
          </div>
          <div class="def-func-table manage-users-table mt-2">
            <table class="scoreTable" id="responseRateTable">
              <thead>
                <tr>
                  <th class="text-left"><span>Station</span></th>
                  <th class="text-left"><span>Checklist</span></th>
                  <th class="text-center"><span>Response Rate</span></th>
                  <th class="text-center"><span>Overdue</span></th>
                  <th class="text-center"><span>Abandoned</span></th>
                  <th class="text-left"><span>Station Manager</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="( data, index ) in reportData.response_rate_details" :key="index">
                  <td class="text-left">{{ data.station }}</td>
                  <td class="text-left">{{ data.checklist }}</td>
                  <td class="text-center">{{ data.response_rate }}%</td>
                  <td class="text-center" v-if="data.overdue.count == 0">{{ data.overdue.count }}</td>
                  <td class="text-center" v-else v-b-modal.modal-overdue @click.prevent="popUpData( data.overdue.overdue_arr, 'overdue' )"><span class="cursor-pointer">{{ data.overdue.count }}</span></td>
                  <td class="text-center" v-if="data.abandoned.count == 0">{{ data.abandoned.count }}</td>
                  <td class="text-center" v-else v-b-modal.modal-abandoned @click.prevent="popUpData( data.abandoned.abandoned_arr, 'abandoned' )"><span class="cursor-pointer">{{ data.abandoned.count }}</span></td>
                  <td class="text-left">{{ data.station_manager }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="selectedReport.selected == 'complianceRatio'">
      <b-col>
        <div class="def-func-input-form-group breifing-content">
          <div class="def-func-input-group pt-3 mb-0">
            <div class="def-func-input-group-col width-10">
              <label class="global-freq freq-resp-title check-title">Details - Compliance Ratio</label>
            </div>
          </div>
          <div class="def-func-table manage-users-table mt-2">
            <table class="scoreTable" id="complianceRatioTable">
              <thead>
                <tr>
                  <th class="text-left"><span>Station</span></th>
                  <th class="text-left"><span>Checklist</span></th>
                  <th class="text-center"><span>Compliance</span></th>
                  <th class="text-center"><span>Critical</span></th>
                  <th class="text-center"><span>Failed</span></th>
                  <th class="text-center"><span>Passed</span></th>
                  <th class="text-center"><span>Abv Average</span></th>
                  <th class="text-center"><span>BDP</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="( data, index ) in reportData.compliance_details" :key="index">
                  <td class="text-left">{{ data.station_code }}</td>
                  <td class="text-left">{{ data.title }}</td>
                  <td class="text-center">{{ data.compliance_ratio }}%</td>
                  <td class="text-center">{{ data.critical_flaw }}</td>
                  <td class="text-center">{{ data.failed }}</td>
                  <td class="text-center">{{ data.passed }}</td>
                  <td class="text-center">{{ data.above_avg }}</td>
                  <td class="text-center">{{ data.bdp }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      hide-footer
      id="modal-overdue"
      size="md"
      modal-class="flipModal manage-user-modal reports-modal"
      title="Overdue check details"
      centered
      v-bind:content-class="{ show: modalShow, modalActive: modalActive }"
    >
      <div class="def-func-form">
        <div class="def-func-input-form-group">
          <b-row>
            <b-col md="12">
              <div class="def-func-input-group mb-0">
                  <div class="def-func-input-group-col width-4 pr-0">
                    <p class="manage-title">Date</p>
                  </div>
                  <div class="def-func-input-group-col width-6 pl-0">
                    <p class="manage-title">Assigned To</p>
                  </div>
              </div>
            </b-col>
            <b-col md="12" v-for="( data, index ) in overDueData" :key="index">
              <div class="def-func-input-group mb-3">
                  <div class="def-func-input-group-col width-4 pr-0">
                    <span>{{ data.on_day.date | moment }}</span>
                  </div>
                  <div class="def-func-input-group-col width-6 pl-0">
                    <span style="text-transform: capitalize;">{{ data.assigned_to }}</span>
                  </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <template v-slot:modal-footer></template>
    </b-modal>
    <b-modal
      hide-footer
      id="modal-abandoned"
      size="md"
      modal-class="flipModal manage-user-modal reports-modal"
      title="Abandoned check details"
      centered
      v-bind:content-class="{ show: modalShow, modalActive: modalActive }"
    >
      <div class="def-func-form">
        <div class="def-func-input-form-group">
          <b-row>
            <b-col md="12" v-for="( data, index ) in abandonedData" :key="index">
              <div class="def-func-input-group">
                <div class="def-func-input-group-col width-4 pr-0">
                  <label class="border-bottom">{{ data.abandoned_date.date | moment }}</label>
                  <span class="color-text">{{ data.reason }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <template v-slot:modal-footer></template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: [ "selectedReport", "reportData" ],
  data() {
    return {
      modalShow: false,
      modalActive: false,
      overDueData: [],
      abandonedData: []
    }
  },
  methods: {
    popUpData( data, type ) {
      if (data) {
        this.overDueData = [];
        this.abandonedData = [];
        if ( type == 'overdue' )
          this.overDueData = data;
        else if ( type == "abandoned" )
          this.abandonedData = data;
      }
    }
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    }
  }
}
</script>