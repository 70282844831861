<template>
  <div>
    <div class="def-func-table">
      <table striped responsive>
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              <span>Function</span>
            </th>
            <th class="text-center">
              <span>Date Created</span>
            </th>
            <th class="text-center">
              <span>Dependencies</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="( result, index ) in functionResponse" :key="index">
            <td>
              <b-form-radio name="title" v-model="functionPassed.id" :value="result.public_id" @change="onSelectFunction"></b-form-radio>
            </td>
            <td class="text-left">{{ result.title }}</td>
            <td>{{ result.created_on.date | moment }}</td>
            <td>{{ result.dependencies }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { EventBus } from "../../../main";
export default {
  props: ["functionResponse"],
  data() {
    return {
      functionPassed: {
        id: ""
      }
    };
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    }
  },
  methods: {
    onSelectFunction(event) {
      this.functionPassed.id = event;
      EventBus.$emit("selectFunction", this.functionPassed);
    }
  },
  created() {
    EventBus.$on("UnCheckAll", functionId => {
      this.functionPassed.id = functionId;
    });
  },
  beforeCreate() {
    EventBus.$off("UnCheckAll")
  }
};
</script>