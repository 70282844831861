<template>
  <table>
    <thead>
      <tr>
        <th class="text-left">
          <span>Checklist Name</span>
        </th>
        <th class="text-left">
          <span>Function</span>
        </th>
        <th class="text-left">
          <span>Station</span>
        </th>
        <th class="text-center">
          <span>Action</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="( result, index ) in checklist" :key="index">
        <td class="text-left">{{ result.title }}</td>
        <td class="text-left">{{ result.fun_title }}</td>
        <td class="text-left">{{ result.station_code }}</td>
        <td>
          <div class="manage-user-action icons-4">
            <router-link to="/edit_checklist">
              <img src="@/assets/images/editbriefing.png" @click="checkData( result )" title="Edit" />
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  export default {
    props: [ "checklist" ],
    methods: {
      checkData( result ) {
        this.$cookies.set("__sucheck", result);
      }
    },
    beforeCreate() {
      if ( this.$cookies.isKey("__sucheck") == true )
        this.$cookies.remove( "__sucheck" );
    }
  };
</script>