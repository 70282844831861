import deffunction from "./pages/admin/def_function";
import employeegroups from "./pages/admin/employee_groups";
import organisationsetup from "./pages/admin/organisation_set_up";
import setupstations from "./pages/admin/set_up_stations";
import manageusers from "./pages/admin/manage_users";
import listofbriefings from "./pages/admin/list_of_briefings/list_of_briefings";
import listofchecklists from "./pages/admin/list_of_checklists/list_of_checklists";
import createnewbriefing from "./pages/admin/create_new_briefing";
import displaybriefing from "./pages/admin/display_briefing";
import distribute_briefing from "./pages/admin/distribute_briefing";
import login from "./pages/admin/authenticate/login";
import firstLogin from "./pages/admin/authenticate/auth/first-login";

import sendVideogramme1 from "./pages/admin/videogramme/send_videogramme_1";
import globalChecklistDatabase from "./pages/admin/global_checklist_database";
import setUpMyChecklists from "./pages/admin/set_up_my_checklists";
import pendingChecks from "./pages/admin/pending_checks";
import setUpNewTraining from "./pages/admin/set_up_new_training";
import defChecklist from "./pages/admin/def_checklist";
import listOfTrainings from "./pages/admin/list_of_trainings/list_of_trainings";
import setUpTestQuestions from "./pages/admin/setup_test_questions/set_up_test_questions";
import performCheck from "./pages/admin/perform_check";
import checkResults from "./pages/admin/check_results";
import startTraining from "./pages/admin/start_training/start_training";
import onlineTraining from "./pages/admin/start_training/online_training";
import reports from "./pages/admin/reports";
import trainingDashboard from "./pages/admin/training_dashboard/dashboard";

import loginCommponent from "../src/pages/admin/authenticate/auth/login"
import forgotPassComponent from "../src/pages/admin/authenticate/auth/forgot_password";
import resetpassword from "../src/pages/admin/authenticate/auth/reset_password";
import verifyOTP from "../src/pages/admin/authenticate/auth/verify_OTP";
import trainingResults from "./pages/admin/training_results";
import briefingReports from "./pages/admin/briefing_report";
import trainingResultPDF from "./pages/admin/training_result_pdf";
import myTrainings from "./pages/admin/my_trainings/my_trainings";
import pageNotFound from "./pages/admin/404";
import dashboard from "./pages/admin/dashboard";
import reportIssue from "./pages/admin/report_issue";
import listOfVideogramme from "./pages/admin/list_of_videogram/list_of_videogramme";
import profile from "./pages/admin/profile";
import changePassword from "./pages/admin/change_password";
import reportIncident from "./pages/admin/incident_report/report_incident";
import myIncidents from "./pages/admin/incident_report/my_incidents";
import incidentAction from "./pages/admin/incident_report/incident_action";
import incidentReport from "./pages/admin/incident_report/incidents_report";
import redirect from "./pages/admin/redirect.vue";

export default [
  {
    path: "/login",
    component: login,
    // name: "login",
    meta: {
      route_access: "admin_login",
      route_header: "Login"
    },
    children: [
      { path: "", component: loginCommponent },
      { path: "forgot-password", component: forgotPassComponent },
      { path: "verify-OTP", component: verifyOTP },
      {
        path: "reset-password/:resettoken/token",
        component: resetpassword,
        props: true
      }
    ]
  },
  {
    path: "/incident-external-action/:incidentId?/:incidentToken?/token",
    component: incidentAction,
    name: "incidentExternalAction",
    meta: {
      requiresAuth: false,
      route_access: "admin_login",
      incident_page: "incident-action",
      route_header: "Incident Action",
      access_rights: "All",
      allowed_both: true
    },
  },
  {
    path: "/first-login",
    component: firstLogin,
    name: "firstLogin",
    meta: {
      requiresAuth: true,
      access_rights: "All"
    }
  },
  {
    path: "/",
    component: dashboard,
    name: "dashboard",
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Dashboard",
      access_rights: "All"
    },
  },
  {
    path: "/report-issue",
    component: reportIssue,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Report an Issue",
      access_rights: "Report Issue"
    },
  },
  {
    path: "/report-incident",
    component: reportIncident,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Report an Incident",
      access_rights: "All"
    },
  },
  {
    path: "/my-incidents",
    component: myIncidents,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "My Incidents",
      access_rights: "My Incidents"
    },
  },
  {
    path: "/incident-action/:incidentId?",
    component: incidentAction,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      incident_page: "incident-action",
      route_header: "Incident Action",
      access_rights: "My Incidents"
    },
  },
  {
    path: "/incident-report",
    component: incidentReport,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Incidents Report",
      access_rights: "Incident Report"
    },
  },
  {
    path: "/incident-view/:incidentId?",
    component: incidentAction,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      incident_page: "incident-view",
      route_header: "Incident View",
      access_rights: "My Incidents"
    },
  },
  {
    path: "/define_function",
    component: deffunction,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Define Functions",
      access_rights: "Define Functions"
    }
  },
  {
    path: "/employee_groups",
    component: employeegroups,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Employee Groups",
      access_rights: "Employee Groups"
    }
  },
  {
    path: "/organisation_set_up",
    component: organisationsetup,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Organisation Set Up",
      access_rights: "Organisation Set Up"
    }
  },
  {
    path: "/set_up_stations",
    component: setupstations,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Set Up Stations",
      access_rights: "Set Up Station"
    }
  },
  {
    path: "/manage_users",
    component: manageusers,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Manage Users",
      access_rights: "Manage Users"
    }
  },
  {
    path: "/list_of_briefings",
    component: listofbriefings,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "List of Briefings",
      access_rights: "List Briefing",
      route_ref: "briefing"
    }
  },
  {
    path: "/create_new_briefing",
    component: createnewbriefing,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Create New Briefing",
      access_rights: "Create Briefing"
    }
  },
  {
    path: "/distribute/:type/:distribute_id",
    component: distribute_briefing,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Distribute",
      access_rights: [{
        briefing: ["Create Briefing", "List Briefing"],
        training: ["Set Up New Training", "List Trainings"],
        videogramme: ["Upload Videogramme", "List Videogrammes"]
      }]
    }
  },
  {
    path: "/display_briefing/:id",
    component: displaybriefing,
    name: "displayBriefing",
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Display Briefing",
      access_rights: "List Briefing"
    }
  },
  {
    path: "/upload_videogramme",
    component: sendVideogramme1,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Upload Videogramme",
      access_rights: "Upload Videogramme"
    }
  },
  {
    path: "/global_checklist_database",
    component: globalChecklistDatabase,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Global Checklist Database",
      access_rights: "Global Checklist"
    }
  },
  {
    path: "/list_of_checklists",
    component: listofchecklists,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "List of Checklists",
      access_rights: "List Checklists"
    }
  },
  {
    path: "/set_up_my_checklists",
    component: setUpMyChecklists,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Set Up My Checklists",
      access_rights: "Set Up My Checklist"
    }
  },
  {
    path: "/pending_checks",
    component: pendingChecks,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Pending Checks",
      access_rights: "Perform Checks"
    }
  },
  {
    path: "/set_up_new_training",
    component: setUpNewTraining,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Set Up New Training",
      access_rights: "Set Up New Training"
    }
  },
  {
    path: "/define_checklists",
    component: defChecklist,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Define Checklists",
      access_rights: "Global Checklist"
    }
  },
  {
    path: "/list_of_trainings",
    component: listOfTrainings,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "List of Trainings",
      access_rights: "List Trainings",
      route_ref: "training"
    }
  },
  {
    path: "/set-up-questions/:trainingid?",
    component: setUpTestQuestions,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Set Up Test Questions",
      access_rights: "Set Up New Training"
    }
  },
  {
    path: "/perform_check/:checklistId?",
    component: performCheck,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Perform Check",
      access_rights: "Perform Checks"
    }
  },
  {
    path: "/retrieve_reports",
    component: checkResults,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Retrieve Reports",
      access_rights: "Retrieve Reports"
    }
  },
  {
    path: "/start_training/:trainingid",
    component: startTraining,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Start Training",
      access_rights: "My Trainings"
    }
  },
  {
    path: "/revise_training/:trainingid",
    component: startTraining,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Revise Training",
      access_rights: "My Trainings"
    }
  },
  {
    path: "/online_training/:moduleid",
    component: onlineTraining,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Online Training",
      access_rights: "My Trainings"
    }
  },
  {
    path: "/training_results",
    component: trainingResults,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Training Results",
      access_rights: "Training Results"
    }
  },
  {
    path: "/briefing_reports",
    component: briefingReports,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Briefing Reports",
      access_rights: "Briefing Reports"
    }
  },
  {
    name: "training_result_pdf",
    path: "/training_result_pdf",
    component: trainingResultPDF,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Training Result Preview",
      access_rights: "Training Results"
    }
  },
  {
    path: "/my_trainings",
    component: myTrainings,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "My Trainings",
      access_rights: "My Trainings"
    }
  },
  {
    path: "/list_of_videogramme",
    component: listOfVideogramme,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "List of Videogramme",
      access_rights: "List Videogrammes",
      route_ref: "videogramme"
    }
  },
  {
    path: "/profile",
    component: profile,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Profile",
      access_rights: "All"
    }
  },
  {
    path: "/change-password",
    component: changePassword,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Change Password",
      access_rights: "All"
    }
  },
  {
    path: "/summary",
    component: reports,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Summary",
      access_rights: "Checklist Summary"
    }
  },
  {
    path: "/edit_briefing/:briefingId?",
    component: createnewbriefing,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Edit Briefing",
      access_rights: "List Briefing"
    }
  },
  {
    path: "/edit_checklist",
    component: setUpMyChecklists,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Edit Checklist",
      access_rights: "List Checklists"
    }
  },
  {
    path: "/training_dashboard",
    component: trainingDashboard,
    meta: {
      requiresAuth: true,
      route_access: "admin",
      route_header: "Trainings Dashboard",
      access_rights: "Training Dashboard"
    }
  },
  {
    path: "/redirect",
    component: redirect,
    name: "redirect",
    meta: {
      requiresAuth: true,
      access_rights: "All"
    }
  },
  {
    path: "/pageNotFound",
    alias: "*",
    name: "notFound",
    component: pageNotFound,
    meta: {
      requiresAuth: true,
      access_rights: "All"
    }
  }
];