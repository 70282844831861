<template>
  <b-row>
    <b-col>
      <div class="def-func-table manage-users-table">
        <table striped responsive>
          <thead>
            <tr>
              <th class="text-center"><span>Briefing</span></th>
              <th class="text-center"><span>Employee</span></th>
              <th class="text-center"><span>Username</span></th>
              <th class="text-center"><span>Station</span></th>
              <th class="text-center"><span>User Created Date</span></th>
              <th class="text-center"><span>User Status</span></th>
              <th class="text-center"><span>Distribution Date</span></th>
              <th class="text-center"><span>Briefing Created On</span></th>
              <th class="text-center"><span>Effective Date</span></th>
              <th class="text-center"><span>End Date</span></th>
              <th class="text-center"><span>Acknowledged On</span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="( data, index ) in result" :key="index">
              <td class="text-center">{{ data.briefing }}</td>
              <td class="text-center">{{ data | empName }}</td>
              <td class="text-center">{{ data.username }}</td>
              <td class="text-center">{{ data.station }}</td>
              <td class="text-center">{{ data.user_created_date | moment }}</td>
              <td class="text-center">{{ data.status }}</td>
              <td class="text-center">{{ data.distribution_date | moment }}</td>
              <td class="text-center">{{ data.briefing_created_on | moment }}</td>
              <td class="text-center">{{ data.from_date | moment }}</td>
              <td class="text-center">{{ data.to_date | moment }}</td>
              <td class="text-center">{{ data.acknowledged_on | moment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  import moment from "moment";
  import { EventBus } from "../../../main";
  export default {
    props: [ "result" ],
    data() {
      return {
      }
    },
    watch: {
    },
    methods: {
    },
    filters: {
        moment( value ) {
          return ( value == null ) ? "" : ( value == "UFN" ) ? "UFN" : moment(value).format("DD MMM YYYY");
        },
        empName( value ) {
          return ( value.first_name && value.last_name ) ? value.first_name + " " + value.last_name : "";
        }
    }
  }
</script>