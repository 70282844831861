<template>
  <div>
    <div class="start-training-cols">
      <div class="start-training-col">
        <div class="module-sectns">
            <div v-if="this.hide_quiz ==true" class="refresh-info">
              <p>Kindly remember that to track your completion status, this training should be taken in one go, and you cannot go back to the training after leaving this page. Please ensure to exit the training via the “Exit Course” button at the conclusion of the training module. To take the final quiz, please refresh this page after you complete the training video.</p>
            </div>
          <div class="modules-content" v-for="(mod,index) in modules" :key="'module_'+index">
            <div class="module-head">
              <div class="module-name">Module {{index + 1}}</div>
              <div class="module-time">{{mod.study_time_req}} Mins</div>
            </div>
            <div class="module-body">
              <!-- <a v-if="mod.training_format == 'web'" :href="'/online_training/' + mod.public_id" target="_blank"><img src="@/assets/images/online-training-blue.png" title="Online Training" /></a>
              <a v-else :href="mod.files.name" target="_blank" download><img src="@/assets/images/download-blue.png" title="Download" /></a> -->
              <a :href="( mod.training_format == 'web' ) ? '/online_training/' + mod.public_id : mod.files.name + '?dist_id=' + dist_id" target="_blank" @click="trainingLog" :download="( mod.training_format == 'web' ) ? false : true"><img src="@/assets/images/play-content.png" :title="( mod.training_format == 'web' ) ? 'View' : 'Download'" /></a>
              <h6 class="step-title">{{mod.title}}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="start-training-col" v-if="this.hide_quiz !==true">
        <div class="test-knowledge-btn" v-for="(mod,index) in modules" :key="'moduleKey_'+index" >
          <div v-if="mod.quiz == 'in progress' || mod.quiz == 'not started'" class="def-func-input-group-col btn-rename-delete">
            <button v-if="mod.training_type == 'Test Included'"
              @click.prevent="call_quiz(mod,'practice',index)"
              variant="light"
              class="btn"
            >Start Quiz</button>
            <button v-else
              @click.prevent="infoOnly(mod.public_id, index)"
              variant="light"
              class="btn"
            >Completed</button>
          </div>
          <div v-else-if="mod.quiz == 'completed'" class="def-func-input-group-col btn-rename-delete">
            <button variant="light" @click.prevent="" class="btn cursor-pointer">Done</button>
            <img class="check-img" src="@/assets/images/check.png" />
          </div>
        </div>
      </div>
      <div class="start-training-col">
        <div class="final-test-outer">
          <div v-if="finalQuiz.final_quiz == 1 && finalQuiz.train_status != 3 && this.hide_quiz !==true " class="final-test-btn" @click="call_final_quiz" >
            <span>Start Final</span>
            <strong>TEST</strong>
          </div>
          <div v-else-if="finalQuiz.final_quiz == 1 && finalQuiz.train_status == 3" class="final-test-btn inactive">
            <span>Start Final</span>
            <strong>TEST</strong>
          </div>
          <span v-if="finalQuiz.train_status == 3">
            <span v-if="results == 'passed'">
              <h2 class="color-text my-5 congrats-text">Congratulations!</h2>
              <img class="congrats-img" src="@/assets/images/congrats.png" />
              <div class="final-test-msg">
                <p>You have passed</p>
                <p class="final-test-name upperCase">{{ finalQuiz.training_name }}</p>
                <p>On {{ finalQuiz.date_completed.date | moment }}</p>
              </div>
              <div>
                <button v-b-modal.downloadPDF variant="light" class="btn" @click.prevent="getCertDetails()">Print Certificate</button>
              </div>
            </span>
            <span v-else>
              <h2 class="color-text my-5 congrats-text">Low Score!</h2>
              <img class="congrats-img my-2" src="@/assets/images/tumbs_down.png" />
              <div class="final-test-msg text-center">
                <p>Unfortunately, you will need to retake the test</p>
                <router-link to="/my_trainings">   
                  <button type="submit" variant="light" class="btn th-add-btn">            
                      Retake Test
                  </button>
                </router-link>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>
    <b-modal
        hide-footer
        id="downloadPDF"
        size="lg"
        modal-class="flipModal manage-user-modal"
        title="Certificate"
        centered
      >
      <certificate ></certificate>
    </b-modal>
  </div>
</template>
<script>
import { HTTP, HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
import moment from "moment";
import certificate from "../my_trainings/certificate";
export default {
  data() {
    return {
      modules: [],
      finalQuiz: "",
      hide_quiz: "",
      results: "",
      dist_id: null
    };
  },
  components: {
      certificate
  },
  methods: {
    getData(){
      console.log('please work', $cookies.get("__train").distribute_id)
      this.dist_id = $cookies.get("__train").distribute_id
      },
    get_modules(training_id) {
      this.modules = [];
      if ( $cookies.get("__train") ) {
        let formData = {
          training: this.$route.params.trainingid,
          distribute_id: $cookies.get("__train").distribute_id
        };
        HTTP_formdata.post("training/get_user_modules", formData)
          .then(response => {
            this.modules = response.data;
          })
          .catch(response => {});
      }
    },
    call_quiz(moduleData, type, moduleNum) {
      moduleData.type = type;
      moduleData.moduleNum = moduleNum;
      let emitdata = {
        moduleData: moduleData,
        start_quiz: true
      };
      EventBus.$emit("innitialize-quiz", emitdata);
    },
    call_final_quiz(){
      let emitdata = {
        start_quiz: true
      };
      EventBus.$emit("innitialize-final-quiz", emitdata);
    },
    finalQuizDetail() {
      if ( $cookies.get("__train") ) {
        let formData = {
          training: this.$route.params.trainingid,
          distribute_id: $cookies.get("__train").distribute_id
        };
        HTTP_formdata.post("training/get_final_quiz_details", formData)
          .then(response => {
            this.hide_quiz = response.data.hide_quiz
            this.finalQuiz = response.data
            // this.finalQuiz.total_points = ( response.data.total_points && response.data.total_points > 0) ? eval(response.data.total_points) : 0
            this.finalQuiz.total_points = ( response.data.total_points && response.data.total_points > 0) ? parseFloat(response.data.total_points) : 0
            let percentage = ( this.finalQuiz.score && this.finalQuiz.total_points && parseInt(this.finalQuiz.total_points) > 0 ) ? ( ( parseFloat(this.finalQuiz.score)/parseFloat(this.finalQuiz.total_points) )*100 ) : '';
            this.results = ( percentage != "" && percentage >= 70 ) ? 'passed' : 'failed';
            let trainingData = {
              training_name: this.finalQuiz.training_name,
              score: ( this.finalQuiz.score ) ? this.finalQuiz.score : ""
            }
            EventBus.$emit( "training_header_data", trainingData);
          })
          .catch(error => {console.log(error, 'errorResponse')});
      }
    },
    getCertDetails() {
      HTTP.get("training/get_training_certificate/" + $cookies.get("__train").distribute_id)
        .then(response => {
        let pdfDetails = {
          user_name: response.data[0].user_name,
          training_title: response.data[0].title,
          date_completed: response.data[0].date_completed,
          modules_completed: response.data[0].modules_completed
        }
          EventBus.$emit( "downloadPDFClicked", pdfDetails );
        })
        .catch(err => {});
    },
    infoOnly( mod_id, index ) {
      let formData = {
        training: this.$route.params.trainingid,
        modules: mod_id
      }
      HTTP_formdata.post("training/save_info_training", formData)
        .then(response => {
          if ( response.status == 200 ) {
            let resp = {
              msg: response.data.response,
              popup: true
            }
            EventBus.$emit("success_resp", resp);
            this.modules[index].quiz = 'completed';
          }
        })
        .catch(response => {});
    },
    trainingLog() {
      let formData = {
        training_public_id: this.$route.params.trainingid,
        distribute_public_id: $cookies.get("__train").distribute_id
      }
      HTTP_formdata.post("user_training_logs/add_start_training_logs", formData)
        .then(response => {})
        .catch(response => {});
    }
  },  
  mounted() {
    this.get_modules();
    this.finalQuizDetail();
    this.getData();
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    }
  }
};
</script>
<style scoped>
.final-test-outer .inactive {
  opacity: 0.15;
  cursor: default;
}
.refresh-info {
  font-weight: 500;
  color: #003261;
}
</style>