<template>
  <div v-bind:class="{ accessShow: popup}" class="access-updated animation">
    <div v-bind:class="{ popupShow: popup, popupActive: popupActive }" class="fade-out flip">
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script>
  import { EventBus } from "../../../../main";
  export default {
    data() {
      return {
        popup: false,
        popupActive: false,
        message: ""
      };
    },
    watch: {
      popup(val) {
        if ( val == true ) {
          this.popupsuccess();
        }
      }
    },
    methods: {
      popupsuccess() {
        setTimeout(() => (this.popupActive = false), 2000);
        setTimeout(() => (this.popup = false), 2500);
        setTimeout(() => (this.message = ""), 2500);        
      }
    },
    created() {
      EventBus.$on("success_resp", response => {
        this.popup = response.popup
        this.popupActive = response.popup
        this.message = response.msg
      });
    },
    beforeCreate() {
      EventBus.$off("success_resp")
    }
  };
</script>