<template>
    <b-row>
        <b-col>
            <div class="def-func-table manage-users-table">
                <table striped responsive>
                    <thead>
                        <tr>
                            <th class="text-center"><span>Training</span></th>
                            <th class="text-center"><span>Employee</span></th>
                            <th class="text-center"><span>Username</span></th>
                            <th class="text-center"><span>Station</span></th>
                            <th class="text-center"><span>Due Date</span></th>
                            <th class="text-center"><span>Date Completed</span></th>
                            <th class="text-center minWidth80"><span>Score</span></th>
                            <th class="text-center"><span>User Status</span></th>
                            <th class="text-center"><span>User Contract</span></th>
                            <th class="text-center training-action"><span>Actions</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="( data, index ) in result" :key="index">
                            <td class="text-center">{{ data.title }}</td>
                            <td class="text-center">{{ data.employee_name }}</td>
                            <td class="text-center">{{ data.username }}</td>
                            <td class="text-center">{{ data.station }}</td>
                            <td class="text-center">{{ data.due_date | moment }}</td>
                            <td class="text-center">{{ data.date_completed | moment }}</td>
                            <td>{{ data.score | score }}</td>
                            <td class="text-center">{{ data.user_status }}</td>
                            <td class="text-center">{{ data.contract }}</td>
                            <td>
                                <div class="manage-user-action training-action">
                                    <!--button>
                                        <img src="@/assets/images/chat.png" title="Chat" />
                                    </button-->
                                    <button :disabled="!data.score" v-b-modal.downloadPDF @click="getCertDetails(data)">
                                        <img src="@/assets/images/print.png" title="Print" />
                                    </button>
                                    <button v-b-modal.log-modal @click="historyLogs(data)">
                                        <img src="@/assets/images/log.png" title="Log" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-col>
        <b-modal
            hide-footer
            id="downloadPDF"
            size="lg"
            modal-class="flipModal manage-user-modal"
            title="Certificate"
            centered
            >
            <certificate ></certificate>
        </b-modal>
        <!--Log modal start-->
        <trainingLogs></trainingLogs>
        <!-- <b-modal hide-footer id="log-modal" size="lg" title="Training Logs" modal-class="flipModal manage-user-modal" centered>
            <div>
                <div class="def-func-table mt-0">
                    <table striped responsive>
                        <thead>
                            <tr>
                                <th class="text-left"> <span>Date</span></th>
                                <th class="text-center"><span>Status</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">4/27/2021</td>
                                <td class="text-center">log-123456789</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-modal> -->
    </b-row>
</template>
<script>
    import moment from "moment";
    import certificate from "../../../pages/admin/my_trainings/certificate";
    import trainingLogs from "./training_log";
    import { EventBus } from "../../../main";
    import { HTTP, HTTP_formdata } from "../../../constants/http-common";
    export default {
        props: [ "selectedResult", "selected", "userStatus" ],
        components: {
            certificate,
            trainingLogs
        },
        data() {
            return {
                result: []
            }
        },
        watch: {
            selectedResult(newQuery) {
                this.calculateResult(this.selected);
            },
            selected(newQuery) {
                this.calculateResult(newQuery);
            }
        },
        methods: {
            calculateResult(filter) {
                this.result = this.selectedResult[filter]
            },
            getCertDetails(data) {
                HTTP.get( "training/get_training_certificate/" + data.distribute_id + "?user_id=" + data.user_public_id )
                    .then(response => {
                        let pdfDetails = {
                            user_name: response.data[0].user_name,
                            training_title: response.data[0].title,
                            date_completed: response.data[0].date_completed,
                            modules_completed: response.data[0].modules_completed
                        }
                        EventBus.$emit( "downloadPDFClicked", pdfDetails );
                    })
                    .catch(err => {});
            },
            historyLogs(data) {
                EventBus.$emit( "historyLog", data );
            }
        },
        filters: {
            moment: function(value) {
                if ( value == null )
                    return value = "";
                else
                    return moment(value).format("DD MMM YYYY");
            },
            score: function(value) {
                if ( value == null || value == "" )
                    return value = "";
                else
                    return value = value + " %";
            }
        }
    }
</script>