<template>
  <div class="def-func-form freq-resp-table mt-5">
    <div class="def-func-table manage-users-table">
      <table class="global-checklist-table">
        <thead>
          <tr>
            <th class="text-left">
              <span>Check</span>
            </th>
            <th class="text-left">
              <span>Scope</span>
            </th>
            <th class="table-period">
              <span>D</span>
              <span>W</span>
              <span>M</span>
              <span>A</span>
            </th>
            <th class="text-center table-actions setup-action">
              <span>Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="( result, index ) in statementResult" :key="index">
            <td class="text-left">{{ result.statement }}</td>
            <td class="text-left">{{ result.scope }}</td>
            <td class="text-center table-period">
              <b-form-checkbox-group
                id="checkbox-group-2"
                name="flavour-2"
                class="add-media-inner mt-2"
                disabled
                v-model="result.type"
                :options="frequencyOptions"
              ></b-form-checkbox-group>
            </td>
            <td>
              <div class="manage-user-action">
                <button v-b-modal.modal-create-new-check @click.prevent="modalClicked( result, 'edit' )">
                  <img src="@/assets/images/editbriefing.png" title="Edit" />
                </button>
                <button v-if="result.mandatory_flag == 1" class="btn-inactive" disabled>
                  <img src="@/assets/images/delete.png" title="Delete"/>
                </button>
                <button v-else v-b-modal.confirm-delete @click.prevent="delete_popup( result )">
                  <img src="@/assets/images/delete.png"  title="Delete"/>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
  import { HTTP_formdata } from "../../../constants/http-common";
  import { EventBus } from "../../../main";
  import confirm_modal from "./status_message/confirm_resp";
  export default {
    props: ["refresh", "checklist", "station"],
    data() {
      return {
        checklistId: "",
        statementResult: [],
        frequencyOptions: [
          { value: "Daily", text: " " },
          { value: "Weekly", text: " " },
          { value: "Monthly", text: " " },
          { value: "Annual", text: " " }
        ],
        deleteData: "",
        stationCode: ""
      };
    },
    components: {
      confirmModal: confirm_modal
    },
    watch: {
      refresh(newVal) {
        if (newVal) {
          this.getChecklist(this.checklistId);
        }
      },
      checklist(newVal) {
        this.checklistId = newVal;
        this.getChecklist(this.checklistId);
      },
      station(newVal) {
        this.stationCode = newVal;
        this.getChecklist(this.checklistId);
      }
    },
    methods: {
      getChecklist(id) {
        let request = {
          title: id,
          station_code: (this.stationCode) ? this.stationCode : this.station
        };
        HTTP_formdata.post("audits/get_checks", request)
          .then(response => {
            this.statementResult = response.data;
            EventBus.$emit("statements", this.statementResult);
          })
          .catch(error => {
            this.statementResult = [];
            EventBus.$emit("statements", this.statementResult);
          });
      },
      modalClicked(result, action) {
        if (result && action == "edit") {
          EventBus.$emit("statementEdit", result);
        } else {
          let jsonData = {
            statement: result.public_id,
            title: this.checklistId,
            station_code: ( this.stationCode ) ? this.stationCode : this.station
          }
          HTTP_formdata.post( "audits/delete_local_statements", jsonData  )
            .then(response => {
              this.deleteData = ""
              EventBus.$emit("refreshStatementList", response);
            })
            .catch(error => {});
        }
      },
      delete_popup( result ) {
        this.deleteData = result;
        EventBus.$emit( "deleteSelected", this.deleteData.statement );
      }
    },
    created() {
      EventBus.$on("clearData", clear => {
        this.checklistId = ""
        this.statementResult = []
      });
      EventBus.$on( "deleteConfirmation", response => {
        if ( response == "delete" ) {
          this.modalClicked( this.deleteData, 'delete' )
        }
      });
    },
    beforeCreate() {
      EventBus.$off("deleteConfirmation")
    }
  };
</script>
<style scoped>
tr td div .btn-inactive {
  opacity: 0.15;
  cursor: default;
}
</style>
