<template>
  <div>
    <div class="content-outer">
      <div class="login-outer first-login-outer">
        <ValidationObserver ref="firstLogin" v-slot="{ passes }">
          <b-form class="first-login-form text-left mt-4" @submit.prevent="passes( onSubmit )">
            <div class="def-func-input-form-group">
              <div class="def-func-input-group">
                <b-row>
                  <b-col class="def-func-input-group-col">
                    <ValidationProvider name="Old Password" rules="required|min:8" v-slot="{ valid, errors }">
                      <label>Old Password</label>
                      <b-form-input v-model="changePassword.oldPassword" :state="errors[0] ? false : (valid ? true : null)" type="password" placeholder="Old Password"></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="def-func-input-group-col">
                    <ValidationProvider name="New Password" vid="confirmation" rules="required|min:8|verify_password" v-slot="{ valid, errors }">
                      <label>New Password</label>
                      <b-form-input type="password" v-model="changePassword.newPassword" :state="errors[0] ? false : (valid ? true : null)" placeholder="New Password"></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="def-func-input-group-col mb-0">
                    <ValidationProvider name="Confirm Password" rules="required|min:8|confirmed:confirmation" v-slot="{ valid, errors }">
                      <label>Confirm Password</label>
                      <b-form-input type="password" v-model="changePassword.confirmNewPassword" :state="errors[0] ? false : (valid ? true : null)" placeholder="Confirm Password"></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="clearfix"></div>
            <b-row>
              <div class="text-right w-100">
                <!--b-button type="submit" class="ml-3" variant="light">Cancel</b-button-->
                <b-button type="submit" class="ml-3" variant="light">Change Password</b-button>
              </div>
            </b-row>
            <div class="clearfix"></div>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
  import { HTTP, HTTP_formdata } from "../../constants/http-common";
  import { EventBus } from "../../main";
  export default {
    data() {
      return {
        changePassword: {
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
          phone_no: "",
          country_calling_code: "",
          country_code: ""
        },
        messsage_loader: false
      };
    },
    methods: {
      onSubmit() {
        this.messsage_loader = true;
        HTTP_formdata.post("users/change_password", this.changePassword)
          .then(response => {
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              let resp = {
                msg: response.data,
                popup: true
              };
              EventBus.$emit("success_resp", resp);
              this.$cookies.remove("user");
              setTimeout(() => {
                this.$store.dispatch('logout')
                // .then(resp => {
                //   this.$router.push({ path: "/login" });
                // }).catch(e => {
                //   console.log(e);
                // });
                // this.$router.push({path:"/login"})
              }, 3000);
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            if ( err.response.status == 400 ) {
              let resp = {
                msg: err.response.data.response,
                popup: true
              };
              EventBus.$emit("error_resp", resp);
            } else if ( err.response.status == 401 ) {
              this.$cookies.remove("user");
              this.$store.dispatch('logout')
              // .then(resp => {
              //   this.$router.push({ path: "/login" });
              // }).catch(e => {
              //   console.log(e);
              // });
              // this.$router.push({path:"/login"})
            }
          });
      }
    },
    mounted() {
      if ( this.$cookies.isKey("user") == false )
        this.$router.push({path:"/login"})
    }
  };
</script>
<style scoped>
@import "./login.css";
@import "./def_function.css";
.login-outer.first-login-outer {max-width: 590px;}
.login-outer.first-login-outer .first-login-form {max-width: 340px;margin: 30px auto 0;}
h2.login-title {font-size: 17px;font-weight: normal;border-bottom: none;line-height: normal;}
.phone-icon svg {font-size: 20px;}
.def-func-input-form-group label {font-size: 14px;color: #999999;display: block;margin-bottom: 7px;text-align: left;}
.def-func-input-group {display: table;width: 100%;table-layout: fixed;}
.def-func-input-group-col {margin-bottom: 25px;}
.def-func-input-group input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}
.def-func-input-group input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}
.def-func-input-group input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}
.def-func-input-group input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}
.def-func-input-group textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}
.def-func-input-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}
.def-func-input-group textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}
.def-func-input-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}
</style>