var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"login-outer"},[_c('img',{staticClass:"login-logo",attrs:{"src":_vm.shopTalkImg}}),_c('h2',{staticClass:"login-title"},[_vm._v("Login")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"login-form text-left",on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-4"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"person-fill"}})],1),_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Username"},model:{value:(_vm.login_data.username),callback:function ($$v) {_vm.$set(_vm.login_data, "username", $$v)},expression:"login_data.username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-4"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"lock-fill"}})],1),_c('b-form-input',{attrs:{"type":"password","state":errors[0] ? false : (valid ? true : null),"placeholder":"Password"},model:{value:(_vm.login_data.password),callback:function ($$v) {_vm.$set(_vm.login_data, "password", $$v)},expression:"login_data.password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"clearfix"}),_c('b-alert',{attrs:{"variant":"success"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(_vm._s(_vm.message))]),_c('b-alert',{attrs:{"variant":"danger"},model:{value:(_vm.fail),callback:function ($$v) {_vm.fail=$$v},expression:"fail"}},[_vm._v(_vm._s(_vm.message))]),_c('b-button',{staticClass:"login-submit",attrs:{"type":"submit","variant":"light"}},[_vm._v("Continue")]),_c('div',{staticClass:"clearfix"}),_c('router-link',{staticClass:"login-forgot",attrs:{"to":"/login/forgot-password"}},[_vm._v("Forgot Password")])],1),_c('p',{staticClass:"powered-by"},[_vm._v("POWERED BY")]),_c('img',{staticClass:"poweredby-logo",attrs:{"src":_vm.poweredByImg}})]}}])}),_c('b-alert',{attrs:{"variant":"warning"}},[_vm._v("Warning Alert")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messsage_loader),expression:"messsage_loader"}],staticClass:"text-center spinner-div"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }