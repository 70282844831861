<template>
  <div>
    <div class="login-outer">
      <img class="login-logo" :src="shopTalkImg" />
      <!-- <img class="login-logo" src="@/assets/images/shopTalk.png" /> -->
      <h2 class="login-title">Login</h2>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <b-form class="login-form text-left" @submit.prevent="passes(onSubmit)">
          <ValidationProvider name="Username" rules="required" v-slot="{ valid, errors }">
            <b-input-group class="mb-4">
              <b-input-group-prepend is-text>
                <b-icon icon="person-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                :state="errors[0] ? false : (valid ? true : null)"
                v-model="login_data.username"
                placeholder="Username"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-input-group>
          </ValidationProvider>
          <ValidationProvider rules="required" name="Password" v-slot="{ valid, errors }">
            <b-input-group class="mb-4">
              <b-input-group-prepend is-text>
                <b-icon icon="lock-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                type="password"
                v-model="login_data.password"
                :state="errors[0] ? false : (valid ? true : null)"
                placeholder="Password"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-input-group>
          </ValidationProvider>
          <div class="clearfix"></div>
          <b-alert v-model="success" variant="success">{{message}}</b-alert>
          <b-alert v-model="fail" variant="danger">{{message}}</b-alert>
          <b-button type="submit" class="login-submit" variant="light">Continue</b-button>
          <div class="clearfix"></div>
          <router-link class="login-forgot" to="/login/forgot-password">Forgot Password</router-link>
        </b-form>
        <p class="powered-by">POWERED BY</p>
        <img class="poweredby-logo" :src="poweredByImg">
        <!-- <img class="poweredby-logo" src="@/assets/images/frontlineDynamics.png"> -->
      </ValidationObserver>
      <b-alert variant="warning">Warning Alert</b-alert>
    </div>
    <div class="text-center spinner-div" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BASE_URL, BASE_URL_VER } from "../../../../constants/app-constants"
export default {
  data() {
    return {
      success: false,
      fail: false,
      messsage_loader: false,
      message: "",
      login_data: {
        username: "",
        password: ""
      },
      shopTalkImg: process.env.VUE_APP_SHOPTALK,
      poweredByImg: process.env.VUE_APP_POWERED_BY
    };
  },
  methods: {
    onSubmit() {
      this.messsage_loader = true;
      let login_json = {
        username: this.login_data.username.toLocaleLowerCase().trim(),
        password: this.login_data.password
      };
      axios
        .post(BASE_URL + "users/login", login_json)
        .then(result => {
          this.messsage_loader = false;
          if (result.data.code == 200) {
            this.success = true;
            this.fail = false;
            this.message = "LogIn Success! Redirecting...";
            this.$cookies.set("user", result);
            setTimeout(() => {
              this.success = false;
              this.message = "";
            }, 3000);
            let expirationDate = new Date(result.data.token_expiry * 1000);
            localStorage.setItem('__used', expirationDate);
            this.$store.dispatch( 'login', {token: result.data.token, userId: result.data.public_id, expirationDate: result.data.token_expiry} )
            let uar = { access_rights: ["All"] }
            if ( result.data.change_password == 2 ) {
              this.$cookies.set("uar", uar);
              this.$router.push({ path: "/first-login" });
              window.location.reload();
            } else if ( result.data.change_password == 1 ) {
              this.$cookies.set("uar", uar);
              this.$router.push({ path: "/change-password" });
              window.location.reload();
            } else {
              axios.create({
                baseURL: BASE_URL_VER,
                headers: {
                  Authorization: 'Bearer ' + result.data.token
                }
              }).get("groups/get_user_access_rights")
              .then(response => {
                this.$cookies.set("uar", response.data.user_data);
                this.$cookies.set("__shuttle", response.data.shuttle_tracker);
                // setTimeout( function() { window.location.reload(true); }, 100 )
                this.$router.go("/");
              })
              .catch(err => {});
            }
          }
        })
        .catch(err => {
          this.messsage_loader = false;
          this.fail = true;
          this.success = false;
          this.message = "Invalid credentials.";
          setTimeout(() => {
            this.fail = false;
            this.message = "";
          }, 3000);
        });
    }
  },
  beforeCreate() {
    if ( this.$cookies.isKey("__uvd") == true )
      this.$cookies.remove( "__uvd" );
  },
  beforeMount() {
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    if ( isSafari && localStorage.getItem("_check_local") === null) {
      let currentData = new Date();
      localStorage.setItem('_check_local', currentData);
      window.location.reload();
    }
  }
};
</script>