var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"login-outer first-login-outer"},[_c('img',{staticClass:"login-logo",attrs:{"src":_vm.shopTalkImg}}),_c('h2',{staticClass:"login-title"},[_vm._v("Hi "+_vm._s(_vm._f("firstLetterCapital")(_vm.loggedin_user_details.name.first))+" "+_vm._s(_vm._f("firstLetterCapital")(_vm.loggedin_user_details.name.last))+", this is your first login."),_c('br'),_vm._v("For security reasons, we request you to please change your password.")]),_c('ValidationObserver',{ref:"firstLogin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"first-login-form text-left mt-4",on:{"submit":function($event){$event.preventDefault();return passes( _vm.onSubmit )}}},[_c('div',{staticClass:"def-func-input-form-group"},[_c('div',{staticClass:"def-func-input-group no-username"},[_c('b-row',[_c('b-col',{staticClass:"def-func-input-group-col"},[_c('ValidationProvider',{attrs:{"name":"New Password","vid":"confirmation","rules":"required|min:8|verify_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('label',[_vm._v("New Password")]),_c('b-form-input',{attrs:{"type":"password","state":errors[0] ? false : (valid ? true : null),"placeholder":"New Password"},model:{value:(_vm.changePassword.newPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "newPassword", $$v)},expression:"changePassword.newPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"def-func-input-group-col"},[_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required|min:8|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('label',[_vm._v("Confirm Password")]),_c('b-form-input',{attrs:{"type":"password","state":errors[0] ? false : (valid ? true : null),"placeholder":"Confirm Password"},model:{value:(_vm.changePassword.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "confirmNewPassword", $$v)},expression:"changePassword.confirmNewPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"def-func-input-group-col mb-0"},[_c('label',[_vm._v("Cell Number (Optional)")]),_c('div',{staticClass:"cell-phone-outer"},[_c('VuePhoneNumberInput',{staticClass:"country-code",attrs:{"clearable":true,"show-code-on-list":true,"error":(_vm.isValid == false && _vm.cellPhone != '') ? true : false,"valid-color":'#28a745',"error-color":'#dc3545',"default-country-code":_vm.changePassword.country_code},on:{"update":function($event){return _vm.phoneData($event)}},model:{value:(_vm.phoneVal),callback:function ($$v) {_vm.phoneVal=$$v},expression:"phoneVal"}})],1),_c('ValidationProvider',{attrs:{"name":"Cell Phone","rules":("" + ((_vm.cellPhone != '') ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"state":errors[0] ? false : ( valid ? true : null )},model:{value:(_vm.changePassword.phone_no),callback:function ($$v) {_vm.$set(_vm.changePassword, "phone_no", $$v)},expression:"changePassword.phone_no"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm._f("custom_message")(errors[0])))])]}}],null,true)})],1)],1)],1)]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"text-center"},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.messsage_loader),expression:"messsage_loader"}],attrs:{"variant":"primary","label":"Spinning"}})],1),_c('b-alert',{staticClass:"mt-4",attrs:{"variant":"success"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(_vm._s(_vm.message))]),_c('b-alert',{staticClass:"mt-4",attrs:{"variant":"danger"},model:{value:(_vm.fail),callback:function ($$v) {_vm.fail=$$v},expression:"fail"}},[_vm._v(_vm._s(_vm.message))]),_c('b-button',{staticClass:"login-submit",attrs:{"type":"submit","variant":"light"}},[_vm._v("Change Password")]),_c('div',{staticClass:"clearfix"})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }