<template>
  <div class="toBeToggled" id="toBeToggled">
    <b-navbar class="sidebar-nav">
      <b-navbar-nav>
        <b-nav-item class="menu-title">Login Details</b-nav-item>
        <b-nav-item class="menu-name">Polar Air Cargo</b-nav-item>
        <b-nav-item class="login-details">
          {{loggedin_user_details.name.first}} {{loggedin_user_details.name.last}}
          <span>{{ loggedin_user_details.group_name }}</span>
        </b-nav-item>
        <b-nav-item class="login-details" @click="overlayClicked" to="/change-password">Change Password</b-nav-item>
        <li class="nav-item">
          <div
            class="base-image-input"
            :style="imageData ? { 'background-image': `url(${imageData})` } : { 'background-image': `url(${defaultUserPhoto})` }"
            @click="chooseImage"
          >
            <span v-if="!imageData" class="placeholder"></span>
            <input class="file-input" ref="fileInput" type="file" @change="onSelectFile" />
          </div>
        </li>
        <b-nav-item class="login-details sign-out-btn" @click="sign_out">Logout</b-nav-item>
      </b-navbar-nav>
      <div class="clearfix"></div>
      <b-navbar-nav class="sidebar-menu">
        <b-nav-item class="menu-title">Menu</b-nav-item>
        <b-nav-item @click="overlayClicked" to="/" class="collapse-icon no-dropdown">Dashboard</b-nav-item>
        <b-nav-item class="collapse-icon" v-b-toggle.menu-admin v-if="loggedin_user_details.access_rights.includes('Define Functions') || loggedin_user_details.access_rights.includes('Organisation Set Up') || loggedin_user_details.access_rights.includes('Set Up Station') || loggedin_user_details.access_rights.includes('Employee Groups') || loggedin_user_details.access_rights.includes('Manage Users')">
          Admin
        </b-nav-item>
        <b-collapse id="menu-admin" class="collapse-menu" accordion="menu-accordion" v-if="loggedin_user_details.access_rights.includes('Define Functions') || loggedin_user_details.access_rights.includes('Organisation Set Up') || loggedin_user_details.access_rights.includes('Set Up Station') || loggedin_user_details.access_rights.includes('Employee Groups') || loggedin_user_details.access_rights.includes('Manage Users')">
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Define Functions')" to="/define_function">Define Functions</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Organisation Set Up')" to="/organisation_set_up">Org Set Up</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Set Up Station')" to="/set_up_stations">Set Up Stations</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Employee Groups')" to="/employee_groups">Employee Groups</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Manage Users')" to="/manage_users">Manage Users</b-nav-item>
        </b-collapse>

        <b-nav-item class="collapse-icon" v-b-toggle.menu-briefings v-if="loggedin_user_details.access_rights.includes('Create Briefing') || loggedin_user_details.access_rights.includes('List Briefing')">
          Briefings
        </b-nav-item>
        <b-collapse id="menu-briefings" class="collapse-menu" accordion="menu-accordion" v-if="loggedin_user_details.access_rights.includes('Create Briefing') || loggedin_user_details.access_rights.includes('List Briefing') || loggedin_user_details.access_rights.includes('Briefing Reports')">
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Create Briefing')" to="/create_new_briefing">Create Briefing</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('List Briefing')" to="/list_of_briefings">List Briefings</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Briefing Reports')" to="/briefing_reports">Briefing Reports</b-nav-item>
        </b-collapse>

        <b-nav-item class="collapse-icon" v-b-toggle.menu-videogramme v-if="loggedin_user_details.access_rights.includes('Upload Videogramme') || loggedin_user_details.access_rights.includes('List Videogrammes')">
          Videogrammes
        </b-nav-item>
        <b-collapse id="menu-videogramme" class="collapse-menu" accordion="menu-accordion" v-if="loggedin_user_details.access_rights.includes('Upload Videogramme') || loggedin_user_details.access_rights.includes('List Videogrammes')">
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Upload Videogramme')" to="/upload_videogramme">Upload</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('List Videogrammes')" to="/list_of_videogramme">List Videogrammes</b-nav-item>
        </b-collapse>

        <b-nav-item class="collapse-icon" v-b-toggle.menu-checklists v-if="loggedin_user_details.access_rights.includes('Global Checklist') || loggedin_user_details.access_rights.includes('Set Up My Checklist') || loggedin_user_details.access_rights.includes('Perform Checks') || loggedin_user_details.access_rights.includes('List Checklists') || loggedin_user_details.access_rights.includes('Checklist Summary') || loggedin_user_details.access_rights.includes('Retrieve Reports')">
          Checklists
        </b-nav-item>
        <b-collapse id="menu-checklists" class="collapse-menu" accordion="menu-accordion" v-if="loggedin_user_details.access_rights.includes('Global Checklist') || loggedin_user_details.access_rights.includes('Set Up My Checklist') || loggedin_user_details.access_rights.includes('Perform Checks') || loggedin_user_details.access_rights.includes('List Checklists') || loggedin_user_details.access_rights.includes('Checklist Summary') || loggedin_user_details.access_rights.includes('Retrieve Reports')">
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Global Checklist')" to="/define_checklists">Define Checklists</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Global Checklist')" to="/global_checklist_database">Global Database</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Set Up My Checklist')" to="/set_up_my_checklists">Set Up Checklists</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('List Checklists')" to="/list_of_checklists">List Checklists</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Perform Checks')" to="/pending_checks">Pending Checks</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Checklist Summary')" to="/summary">Summary</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Retrieve Reports')" to="/retrieve_reports">Retrieve Reports</b-nav-item>
        </b-collapse>

        <b-nav-item class="collapse-icon" v-b-toggle.menu-training v-if="loggedin_user_details.access_rights.includes('Set Up New Training') || loggedin_user_details.access_rights.includes('My Trainings') || loggedin_user_details.access_rights.includes('List Trainings') || loggedin_user_details.access_rights.includes('Training Results') || loggedin_user_details.access_rights.includes('Training Dashboard')">
          Trainings
        </b-nav-item>
        <b-collapse id="menu-training" class="collapse-menu" accordion="menu-accordion" v-if="loggedin_user_details.access_rights.includes('Set Up New Training') || loggedin_user_details.access_rights.includes('My Trainings') || loggedin_user_details.access_rights.includes('List Trainings') || loggedin_user_details.access_rights.includes('Training Results') || loggedin_user_details.access_rights.includes('Training Dashboard')">
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Training Dashboard')" to="/training_dashboard">Dashboard</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Set Up New Training')" to="/set_up_new_training">Set Up Training</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('List Trainings')" to="/list_of_trainings">List Trainings</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('My Trainings')" to="/my_trainings">My Trainings</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Training Results')" to="/training_results">Training Results</b-nav-item>
        </b-collapse>

        <b-nav-item class="collapse-icon" v-b-toggle.menu-incident-report>
          Incident Report
        </b-nav-item>
        <b-collapse id="menu-incident-report" class="collapse-menu" accordion="menu-accordion">
          <b-nav-item @click="overlayClicked" to="/report-incident">Report an Incident</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('My Incidents')" to="/my-incidents">My Incidents</b-nav-item>
          <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Incident Report')" to="/incident-report">Reports</b-nav-item>
        </b-collapse>

        <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.access_rights.includes('Report Issue')" to="/report-issue" class="collapse-icon no-dropdown">Report an Issue</b-nav-item>
        <b-nav-item @click="overlayClicked" v-if="loggedin_user_details.shuttle_tracker == 'TRUE' && ( loggedin_user_details.access_rights.includes('Shuttle Tracker Dashboard') || loggedin_user_details.access_rights.includes('Set Up Parameter') || loggedin_user_details.access_rights.includes('Shuttle Overview') || loggedin_user_details.access_rights.includes('Plan Load') || loggedin_user_details.access_rights.includes('Dock Manager') || loggedin_user_details.access_rights.includes('Shuttle Tracker Report') )" :href="loggedin_user_details.shuttle_tracker_link" target="_blank" class="collapse-icon no-dropdown">Shuttle Tracker</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <div class="clearfix"></div>
    <div class="menuOverlay"></div>
    <successmsg></successmsg>
    <errormsg></errormsg>
    <testmsg></testmsg>
  </div>
</template>
<script>
import { EventBus } from "../../main";
import { HTTP_imageupload } from "../../constants/http-common";
import { isloggedin } from "../../constants/app-constants";
export default {
  data() {
    return {
      loggedin_user_details: isloggedin,
      imageData: null,
      MenuClicked: false,
      defaultUserPhoto: require("@/assets/images/user.png")
    };
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    sign_out() {
      this.$store.dispatch('logout')
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (
        files &&
        files[0] &&
        (files[0].type == "image/png" ||
          files[0].type == "image/jpeg" ||
          files[0].type == "png" ||
          files[0].type == "jpeg")
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);

        let image_data = new FormData();
        image_data.set("profileImage", files[0]);

        HTTP_imageupload.post(
          "users/upload_image/" + isloggedin.public_id,
          image_data
        )
          .then(Response => {
            if(Response.data.status = 200) {
              var user_cookie = $cookies.get("user")
              user_cookie.data.image = Response.data.filepath
              this.$cookies.set("user",user_cookie)
              let response ={
                popup:true,
                popupActive:true,
                msg:"Profile Image Uploaded Successfully"
              }
              EventBus.$emit("success_resp", response)
            }
          })
          .catch(error => {
            let response = {
              popup:true,
              popupActive:true,
              msg:"Failed to Upload Profile Image "
            }
            EventBus.$emit("error_resp", response)
          });
      }
    },
    overlayClicked() {
      EventBus.$emit("slideMenuClicked", this.MenuClicked);
    }
  },
  mounted() {
    this.imageData = isloggedin.image;
  }
};
</script>
<style scoped>
.base-image-input {
  display: block;
  width: 52px;
  height: 52px;
  cursor: pointer;
  background-size: 90%;
  background-position: center center;
  float: left;
  background-repeat: no-repeat;
  background-color: white;
}
.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
}
.file-input {
  display: none;
}
</style>

