<template>
  <b-modal
    hide-footer
    id="modal-create-new-check"
    size="lg"
    modal-class="flipModal manage-user-modal"
    :title="(statement != '') ? 'Edit check' : 'Create new check'"
    centered
    @hidden="onClose"
  >
    <div class="def-func-input-group mb-0">
      <div class="def-func-input-form-group">
        <ValidationObserver ref="observerLocal" v-slot="{ passes }">
          <b-form @submit.prevent="passes( addLocalStatement )">
            <b-row class="justify-content-space-between">
              <b-col md="12">
                <ValidationProvider
                  name="Describe check"
                  rules="required"
                  v-slot="{ valid, errors }"
                >
                  <div class="required-field">
                    <label>Describe Check to be performed</label>
                  </div>
                  <input :disabled="statement != '' && statement.scope == 'Global Mandate'"
                    :state="errors[0] ? false : ( valid ? true : null )"
                    v-model="globalData.check"
                    type="text" placeholder="Describe Check"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
              <b-col md="12" class="text-center add-media-inner mt-1">
                <b-form-checkbox
                  :disabled="statement != ''"
                  id="checkbox-1"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                  v-model="globalData.add_to_global"
                >Add Check to Global Database for other Stations to adopt if needed.</b-form-checkbox>
              </b-col>
              <b-col class="def-func-input-group-col mt-5" md="12">
                <ValidationProvider
                  name="Define Frequency"
                  rules="required"
                  v-slot="{ valid, errors }"
                >
                  <div class="required-field">
                    <b-form-group
                      label="Define Frequency (for current checklist only)"
                      class="global-freq"
                    >
                      <b-form-checkbox-group
                        :state="errors[0] ? false : ( valid ? true : null )"
                        id="checkbox-group-local"
                        name="flavour-2"
                        class="add-media-inner"
                        v-model="globalData.type"
                        :options="frequenciesOptions"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-col>
              <div class="clearfix"></div>
              <b-col md="12" class="text-center">
                <b-button
                  type="submit"
                  class="btn-rename-delete mt-3 float-right"
                  variant="light"
                >Update</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
    <template v-slot:modal-footer></template>
  </b-modal>
</template>
<script>
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  props: ["checklist", "function", "statement", "station"],
  data() {
    return {
      globalData: {
        function: "",
        title: "",
        station_code: "",
        check: "",
        type: [],
        add_to_global: "false"
      },
      frequenciesOptions: [
        { value: "Daily", text: "Daily", disabled: false },
        { value: "Weekly", text: "Weekly", disabled: false },
        { value: "Monthly", text: "Monthly", disabled: false },
        { value: "Annual", text: "Annual", disabled: false }
      ]
    };
  },
  watch: {
    statement(newVal) {
      if (newVal) {
        (this.globalData.check = newVal.statement),
          (this.globalData.type = newVal.type),
          (this.globalData.add_to_global =
            newVal.scope == "Locally Defined" ? "false" : "true");
        if ( newVal.mandatory_flag == 1 ) {
          for (var i = 0; i < this.frequenciesOptions.length; i++) {
            for (var j = 0; j < newVal.global_type.length; j++) {
              if ( newVal.global_type[j] == this.frequenciesOptions[i].value ) {
                this.frequenciesOptions[i].disabled = true;
              }
            }
          }
        }
      }
    }
  },
  methods: {
    onClose(event) {
      let refresh = "close";
      EventBus.$emit("refreshStatementListLocal", refresh);
      this.globalData.add_to_global = "false";
      this.globalData.check = "";
      this.globalData.type = [];
      this.frequenciesOptions = [
        { value: "Daily", text: "Daily", disabled: false },
        { value: "Weekly", text: "Weekly", disabled: false },
        { value: "Monthly", text: "Monthly", disabled: false },
        { value: "Annual", text: "Annual", disabled: false }
      ]
    },
    addLocalStatement() {
      if (this.statement == "") {
        this.globalData.function = this.function;
        this.globalData.title = this.checklist;
        this.globalData.station_code = this.station;
        HTTP_formdata.post("audits/create_new_check", this.globalData)
          .then(response => {
            this.globalData.add_to_global = "false";
            this.globalData.check = "";
            this.globalData.type = [];
            let refresh = response;
            EventBus.$emit("refreshStatementListLocal", refresh);
            this.$nextTick(() => {
              this.$refs.observerLocal.reset();
              this.$bvModal.hide("modal-create-new-check");
            });
          })
          .catch(err => {});
      } else {
        let request = {
          title: this.checklist,
          station_code: this.station,
          statement: this.globalData.check,
          type: this.globalData.type
        };
        HTTP_formdata.put(
          "audits/edit_setup_statements/" + this.statement.public_id,
          request
        )
          .then(response => {
            this.globalData.add_to_global = "false";
            this.globalData.check = "";
            this.globalData.type = [];
            let refresh = response;
            EventBus.$emit("refreshStatementListLocal", refresh);
            this.$nextTick(() => {
              this.$refs.observerLocal.reset();
              this.$bvModal.hide("modal-create-new-check");
            });
          })
          .catch(err => {});
      }
    },
    created() {
      EventBus.$on("clearData", clear => {
        this.frequency = "";
        this.globalData.add_to_global = "false"
        this.globalData.check = ""
        this.globalData.type = []
        this.globalData.function = ""
        this.globalData.title = ""
        this.globalData.station_code = ""
        this.$nextTick(() => {
          this.$refs.observerLocal.reset();
          this.$bvModal.hide("modal-create-new-check");
        });
      });
    }
  }
};
</script>