<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <checklistForm></checklistForm>
                <div class="def-func-input-form-group global-checklist-search">
                  <b-row>
                    <b-col md="8">
                      <div class="def-func-input-group-col btn-search">
                        <b-input-group>
                          <b-form-input
                            placeholder="Search"
                            v-model="paginate_params.statement"
                          ></b-form-input>
                          <b-input-group-append is-text>
                            <font-awesome-icon :icon="['fas', 'search']" />
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="def-func-form">
                <div class="def-func-table manage-users-table">
                  <table class="global-checklist-table">
                    <thead>
                      <tr>
                        <th class="text-left">
                          <span>Check</span>
                        </th>
                        <th class="text-left">
                          <span>Function</span>
                        </th>
                        <th class="text-left">
                          <span>Checklist</span>
                        </th>
                        <th class="text-center">
                          <span>MAN</span>
                        </th>
                        <th class="table-period">
                          <span>D</span>
                          <span>W</span>
                          <span>M</span>
                          <span>A</span>
                        </th>
                        <th class="text-center table-actions">
                          <span>Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="( result, index ) in globalChecklistResult" :key="index">
                        <td class="text-left">{{ result.statement }}</td>
                        <td class="text-left">{{ result.fun_title }}</td>
                        <td class="text-left">{{ result.audit_name }}</td>
                        <td class="text-center" v-if="result.mandatory == true">Y</td>
                        <td class="text-center" v-else-if="result.mandatory == false">N</td>
                        <td class="text-center table-period">
                          <b-form-checkbox-group id="checkbox-group-2" name="flavour-2" class="add-media-inner mt-2" disabled v-model="result.type" :options="frequencyOptions" ></b-form-checkbox-group>
                        </td>
                        <td>
                          <div class="manage-user-action">
                            <button v-b-modal.modal-edit-global-checklist>
                              <img
                                @click.prevent="onSelectGlobalChecklist( result, 'edit' )"
                                src="@/assets/images/editbriefing.png"
                              />
                            </button>
                            <button v-if="result.mandatory == true" class="btn-inactive">
                              <img src="@/assets/images/delete.png" title="Delete" />
                            </button>
                            <button v-else v-b-modal.confirm-delete @click.prevent="delete_popup( result )">
                              <img src="@/assets/images/delete.png" title="Delete" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <b-pagination
                  v-model="paginate_params.page"
                  :total-rows="rows"
                  :per-page="paginate_params.limit"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                ></b-pagination>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
    hide-footer
    id="modal-edit-global-checklist"
    modal-class="flipModal manage-user-modal"
    title="Edit Global Checklist"
    centered
    @hidden="resetModal"
    >                      
      <div class="def-func-input-group mb-0">
        <div class="def-func-input-form-group">
          <ValidationObserver ref="observerEditGlobal" v-slot="{ passes }">
            <b-form @submit.prevent="passes( editGlobalChecklist )">
              <b-row class="justify-content-space-between">
                <b-col md="12">
                  <ValidationProvider name="Describe check" rules="required" v-slot="{ valid, errors }">
                    <div class="required-field">
                      <label>Describe check to be performed</label>
                    </div>
                    <b-form-input :state="errors[0] ? false : ( valid ? true : null )" v-model="editGlobalStatement.statement"></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
                <div class="clearfix"></div>
                <b-col class="def-func-input-group-col mt-5" md="12">
                <ValidationProvider
                  name="Define Frequency"
                  rules="required"
                  v-slot="{ valid, errors }"
                >
                  <div class="required-field">
                    <b-form-group
                      label="Define Frequency"
                      class="global-freq"
                    >
                      <b-form-checkbox-group
                        :state="errors[0] ? false : ( valid ? true : null )"
                        id="checkbox-group-local"
                        name=""
                        class="add-media-inner"
                        v-model="editGlobalStatement.type"
                        :options="frequenciesOptions"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </ValidationProvider>
              </b-col>
              <div class="clearfix"></div>
                <b-col md="12" class="mt-4 text-center">
                  <b-button type="submit" class="btn-rename-delete mt-3 float-right" variant="light">Update</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </div>
      </div>
      <template v-slot:modal-footer></template>
    </b-modal>
    <confirmModal></confirmModal>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
  import { HTTP_formdata } from "../../constants/http-common";
  import checklistForm from "../../components/admin_layouts/dashboard_layouts/global_checklist_form";
  import confirm_modal from "../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
  import { EventBus } from "../../main";
  export default {
    data() {
      return {
        rows: 10,
        paginate_params: {
          statement: "",
          page: 1,
          limit: "10"
        },
        globalChecklistResult: "",
        editGlobalStatement: {
          statement: "",
          type: []
        },
        frequencyOptions: [
          { value: "Daily", text: " " },
          { value: "Weekly", text: " " },
          { value: "Monthly", text: " " },
          { value: "Annual", text: " " }
        ],
        globalId: "",
        response: {
          msg: "",
          popup: false
        },
        deleteData: "",
        messsage_loader: false,
        frequenciesOptions: [
          { value: "Daily", text: "Daily" },
          { value: "Weekly", text: "Weekly" },
          { value: "Monthly", text: "Monthly" },
          { value: "Annual", text: "Annual" }
        ]
      };
    },
    watch: {
      "paginate_params.page": {
        handler() {
          this.globalChecklistResult = [];
          this.globalChecklistList();
          deep: true;        
        }
      },
      "paginate_params.statement": {
        handler() {
          this.globalChecklistResult = [];
          this.globalChecklistList();
          deep: true;      
        }
      }
    },
    components: {
      checklistForm,
      confirmModal: confirm_modal
    },
    methods: {
      globalChecklistList() {
        HTTP_formdata.post("audits/search_audit_statements", this.paginate_params)
          .then(response => {
            if (response.data.data != "Data not found") {
              this.globalChecklistResult = response.data.data;
              this.rows = response.data.totalLength;
            } else {
              this.globalChecklistResult = [];
              this.rows = 1;
            }
          })
          .catch(error => {});
      },      
      onSelectGlobalChecklist( result, action ) {
        if ( action == "edit" ) {
          this.editGlobalStatement.statement = result.statement;
          this.editGlobalStatement.type = result.type;
          this.globalId = result.public_id
        } else {
          HTTP_formdata.put( "audits/delete_audit_statements/" + result.public_id  )
            .then(response => {
              if ( response.status == 200 ) {
                this.response.msg = response.data.message
                this.response.popup = true
                EventBus.$emit("success_resp", this.response);
                this.globalChecklistResult = [];
                this.globalChecklistList();
                this.deleteData = ""
              }
            })
            .catch(error => {
              if ( error.response.status == 400 ) {
                this.response.msg = error.response.data.message
                this.response.popup = true
                EventBus.$emit("error_resp", this.response);
                this.deleteData = ""
              }
            });
        }
      },
      editGlobalChecklist() {
        HTTP_formdata.put( "audits/edit_audit_statements/" + this.globalId, this.editGlobalStatement )
          .then(response => {
            if ( response.status == 200 ) {
              this.response.msg = response.data
              this.response.popup = true
              EventBus.$emit("success_resp", this.response);
              this.editGlobalStatement.statement = "";
              this.editGlobalStatement.type = [];
              this.globalId = ""
              this.$nextTick(() => {
                this.$refs.observerEditGlobal.reset();
                this.$bvModal.hide("modal-edit-global-checklist");
              });
              this.globalChecklistResult = [];
              this.globalChecklistList();
            }
          })
          .catch(error => {});
      },
      resetModal() {
        this.editGlobalStatement.statement = "";
        this.editGlobalStatement.type = [];
        this.globalId = ""
      },
      delete_popup( result ) {
        this.deleteData = result;
        EventBus.$emit( "deleteSelected", this.deleteData.statement );
      }
    },
    beforeMount() {
      this.globalChecklistList();
    },
    created() {
      EventBus.$on( "addGlobal", response => {
        if ( response.status == 200 ) {
          this.messsage_loader = response.messsage_loader;
          this.response.msg = response.data
          this.response.popup = true
          EventBus.$emit("success_resp", this.response);
          this.globalChecklistResult = [];
          this.globalChecklistList();
        }
      });
      EventBus.$on( "deleteConfirmation", response => {
        if ( response == "delete" ) {
          this.onSelectGlobalChecklist( this.deleteData, 'delete' );
        }
      });
      EventBus.$on( "loader", messsage_loader => {
        this.messsage_loader = messsage_loader;
      });
    },
    beforeCreate() {
      EventBus.$off("deleteConfirmation")
      EventBus.$off("addGlobal")
    }
  };
</script>
<style scoped>
  @import "./def_function.css";
  tr td div .btn-inactive {
    opacity: 0.15;
    cursor: default;
  }
</style>
