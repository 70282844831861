<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class="clearfix"></div>
                  <div class="def-func-input-group">
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                      <b-form @submit.prevent="passes( addOrg )">
                        <div class="def-func-input-group-col width-4">
                          <ValidationProvider
                            name="Region"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Region</label>
                              <b-form-input
                                placeholder="Enter name of Region"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="addOrganisationData.region"
                              />
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col width-4">
                          <ValidationProvider
                            name="Function"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Select Function</label>
                              <b-form-select
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="addOrganisationData.function"
                                :options="functionResult"
                              ></b-form-select>
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col width-4">
                          <ValidationProvider
                            name="Org Code"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Org Code</label>
                              <b-form-input
                                placeholder="Org Code"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="addOrganisationData.code"
                              />
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col btn-add-outer">
                          <button type="submit" variant="light" class="btn add-btn th-add-btn">+</button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
                <orgTable :orgResult="orgResult"></orgTable>
                <div class="def-func-input-form-group">
                  <div class="clearfix"></div>
                  <div class="def-func-input-group org-mob-screen">
                    <ValidationObserver ref="observerEdit" v-slot="{ passes }">
                      <b-form @submit.prevent="passes( editOrg )">
                        <div class="def-func-input-group-col width-4">
                          <ValidationProvider
                            name="Region"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Rename Selected Region</label>
                              <b-form-input :disabled="orgId == ''"
                                placeholder="Rename Region"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="editOrganisationData.region"
                              />
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col width-4">
                          <ValidationProvider
                            name="Function"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Select Function</label>
                              <b-form-select :disabled="orgId == ''"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="editOrganisationData.function"
                                :options="functionResult"
                              ></b-form-select>
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col width-3">
                          <ValidationProvider
                            name="Org Code"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Rename Org Code</label>
                              <b-form-input :disabled="orgId == ''"
                                placeholder="Rename Org Code"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="editOrganisationData.code"
                              />
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col btn-rename-delete">
                          <button :disabled="orgId == ''" type="submit" variant="light" class="btn th-add-btn">Rename</button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                    <div class="def-func-input-group-col btn-rename-delete">
                      <button :disabled="orgId == ''" v-b-modal.confirm-delete @click="delete_popup" class="btn th-add-btn" variant="light">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
import org_table from "../../components/admin_layouts/dashboard_layouts/org_table";
import confirm_modal from "../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { EventBus } from "../../main";
export default {
  data() {
    return {
      functionResult: [
        {
          value: null,
          text: "Select",
          disabled: true
        }
      ],
      addOrganisationData: {
        region: "",
        function: null,
        code: ""
      },
      editOrganisationData: {
        region: "",
        function: null,
        code: ""
      },
      orgResult: "",
      orgId: "",
      response: {
        msg: "",
        popup: false
      },
      selectedFunction: ""
    };
  },
  components: {
    orgTable: org_table,
    confirmModal: confirm_modal
  },
  methods: {
    getFuncList() {
      HTTP.get("functions/display_functions")
        .then(response => {
          for (var i = 0; i < response.data.length; i++) {
            this.functionResult.push({
              value: response.data[i].public_id,
              text: response.data[i].title
            });
          }
        })
        .catch(err => {});
    },
    addOrg() {
      HTTP_formdata.post(
        "organisations/add_organisations",
        this.addOrganisationData
      )
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.addOrganisationData.region = "";
            this.addOrganisationData.function = null;
            this.addOrganisationData.code = "";
            this.$nextTick(() => {
              this.$refs.observer.reset();
            });
            this.getOrgList();
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.response.msg = error.response.data.message
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    getOrgList() {
      HTTP.get("organisations/display_organisations")
        .then(response => {
          this.orgResult = response.data;
        })
        .catch(error => {});
    },
    editOrg() {
      HTTP_formdata.put(
        "organisations/rename_organisations/" + this.orgId,
        this.editOrganisationData
      )
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editOrganisationData.region = "";
            this.editOrganisationData.function = null;
            this.editOrganisationData.code = "";
            this.selectedFunction = "";
            this.$nextTick(() => {
              this.$refs.observerEdit.reset();
            });
            this.getOrgList();
            this.orgId = "";
            EventBus.$emit("UnCheckAll", this.orgId);
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.response.msg = error.response.data.message
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    deleteOrg() {
      HTTP.delete("organisations/delete_organisations/" + this.orgId)
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editOrganisationData.region = "";
            this.editOrganisationData.function = null;
            this.editOrganisationData.code = "";
            this.selectedFunction = "";
            this.$nextTick(() => {
              this.$refs.observerEdit.reset();
            });
            this.getOrgList();
            this.orgId = "";
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.response.msg = error.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
            this.editOrganisationData.region = "";
            this.editOrganisationData.function = null;
            this.editOrganisationData.code = "";
            this.selectedFunction = "";
            this.$nextTick(() => {
              this.$refs.observerEdit.reset();
            });
            this.getOrgList();
            this.orgId = "";
            EventBus.$emit("UnCheckAll", this.orgId);
          }
        });
    },
    delete_popup() {
      let display = {
        line: "multi",
        title: this.editOrganisationData.region + ' <br /> ' + this.selectedFunction + ' <br /> ' + this.editOrganisationData.code
      }
      EventBus.$emit( "deleteSelected", display );
    }
  },
  beforeMount() {
    this.getFuncList();
    this.getOrgList();
  },
  created() {
    EventBus.$on("selectOrg", response => {
      this.orgId = response.public_id;
      this.selectedFunction = response.function;
      this.editOrganisationData.region = response.region;
      this.editOrganisationData.function = response.function_public_id;
      this.editOrganisationData.code = response.code;
    });
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteOrg();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
    EventBus.$off("selectOrg")
  }
};
</script>
<style>
@import "./def_function.css";
</style>