<template>
  <div v-show="selectedReport.displayScope">
    <b-row>
      <b-col>
        <div class="perform-content">
          <span>
            <div class="training-questns-section def-func-input-form-group">
              <div class="training-questn-col questn border-bottom">
                <label class="global-freq freq-resp-title check-title">Details - {{ scoreTitle }}</label>
              </div>
            </div>
            <div v-for="( report, index ) in scoreData" :key="index">
              <div class="training-questns-section mt-3">
                <div class="training-questn-col questn-number">
                  <b-badge class="questn-badge">{{ index + 1 }}</b-badge>
                </div>
                <div class="training-questn-col questn">
                  <label>{{ report.question }}</label>
                </div>
              </div>
              <div class="training-options-section mb-5">
                <div class="perform-content-col">
                  <div class="training-options-header perform-options-header">
                    <div class="perform-scoreOptions add-media-inner">
                      <div class="score-options report-details">
                        <label class="scoreOptions-title">Station: <span class="color-text">{{ report.station }}</span></label>
                        <label class="scoreOptions-title">Checklist: <span class="color-text">{{ report.audit_title }}</span></label>
                        <label class="scoreOptions-title">Date: <span class="color-text upperCase">{{ report.on_date.date | moment }}</span></label>
                        <label class="scoreOptions-title">Checked By: <span class="color-text">{{ report.checked_by }}</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="training-options-body">
                    <label class="checkers_comments" :for="'checkers_comments' + (index)">Checkers comments</label>
                    <b-form-textarea :id="'checkers_comments' + (index)" rows="5" no-resize :value="report.remarks" disabled></b-form-textarea>
                  </div>
                </div>
                <div class="perform-image-col">
                  <b-carousel v-if="report.images && report.images.length > 0"
                    :id="'carousel-1' + (index)" v-model="slide" :interval="4000" controls indicators
                    background="#ababab" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide v-for="( imgsrc, imgindex ) in report.images" :key="imgindex" :img-src="imgsrc.images">
                    </b-carousel-slide>
                  </b-carousel>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import moment from "moment";
  export default {
    props: [ "selectedReport", "reportData", "showScorePdf" ],
    data() {
      return {
        slide: 0,
        sliding: null,
        scores: { 
          critical_flaw: "Critical Flaws",
          failed: "Failed",
          passed: "Passed",
          above_avg: "Above Average",
          bdp: "BDPs"
        },
        scoreTitle: "",
        scoreData: []
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
      scoreDetail() {
        this.scoreTitle = (this.selectedReport.selected) ? this.scores[this.selectedReport.selected] : "";
        this.scoreData = (this.selectedReport.selected) ? this.reportData[this.selectedReport.selected] : [];
      }
    },
    watch: {
      "selectedReport.selected"(newQuery) {
        this.scoreDetail();
      },
      showScorePdf(newVal) {
        if (newVal) {
          this.scoreDetail();
        }
      }
    },
    filters: {
      moment: function(value) {
        return moment(value).format("DD MMM YYYY");
      }
    }
  }
</script>