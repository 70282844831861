<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <ValidationObserver ref="observer" v-slot="{ passes }">
                    <b-form @submit.prevent="passes( addStation )">
                      <b-row>
                        <b-col>
                          <div class="def-func-input-group">
                            <div class="def-func-input-group-col width-sm-2 width-2">
                                <ValidationProvider
                                  name="Station Code"
                                  rules="required"
                                  v-slot="{ valid, errors }"
                                >
                                  <div class="required-field">
                                    <label>Station Code</label>                              
                                    <vue-bootstrap-typeahead v-if="stationId == ''"
                                      :data="stationCode"
                                      ref="typeahead"
                                      v-model="codeSearch"
                                      :serializer="item => item.code"
                                      placeholder="Station Code"
                                      :minMatchingChars="1"
                                      :state="errors[0] ? false : ( valid ? true : null )"
                                      @hit="selectedCode = $event"
                                    ></vue-bootstrap-typeahead>                              
                                    <b-form-input v-else type="text" v-model="addStationData.code" disabled></b-form-input>                              
                                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                                  </div>
                                </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col width-4">
                              <ValidationProvider
                                name="Station Name"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Station Name</label>
                                  <b-form-input
                                    placeholder="Station Name"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="addStationData.name"
                                  />
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col width-sm-3 width-5">
                              <ValidationProvider
                                name="Country"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Country</label>
                                  <b-form-input
                                    placeholder="Country"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="addStationData.country"
                                  ></b-form-input>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col width-sm-3 width-5">
                              <ValidationProvider
                                name="City"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>City</label>
                                  <b-form-input
                                    placeholder="City"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="addStationData.city"
                                  ></b-form-input>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <div class="def-func-input-group">
                            <div class="def-func-input-group-col width-7">
                              <ValidationProvider
                                name="Address Line 1"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Address Line 1</label>
                                  <b-form-input
                                    placeholder="Address Line 1"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="addStationData.address_line1"
                                  />
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col width-3">
                              <label>Address Line 2</label>
                              <b-form-input
                                v-model="addStationData.address_line2"
                                placeholder="Address Line 2"
                              />
                            </div>
                            <div class="def-func-input-group-col width-2">
                              <ValidationProvider
                                name="Zip Code"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Zip Code</label>
                                  <b-form-input
                                    placeholder="Zip Code"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="addStationData.zip_code"
                                  />
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <div class="def-func-input-group">
                            <div class="def-func-input-group-col width-sm-3 width-4">
                              <ValidationProvider
                                name="Region"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Belongs to (Region)</label>
                                  <b-form-select
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="orgRegion"
                                    :options="orgResult"
                                    @change="getOrgDetails( $event )"
                                  ></b-form-select>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col width-3">
                              <ValidationProvider
                                name="Function"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Function</label>
                                  <b-form-select
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="orgData.function"
                                    :options="functionResult"
                                    @change="getOrgCode( $event )"
                                    :disabled="orgRegion == null"
                                  ></b-form-select>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="def-func-input-group-col width-3">
                              <ValidationProvider
                                name="Org Code"
                                rules="required"
                                v-slot="{ valid, errors }"
                              >
                                <div class="required-field">
                                  <label>Org Code</label>
                                  <b-form-input
                                    placeholder="Org Code"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="orgData.code"
                                    disabled
                                  />
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div v-if="stationId == ''" class="def-func-input-group-col btn-add-outer width-2">
                              <button class="btn add-btn th-add-btn" variant="light">+</button>
                            </div>
                            <div v-else class="def-func-input-group-col width-2">
                              <div class="def-func-input-group-col btn-rename-delete float-right btn-rename-mob">
                                <button class="btn th-add-btn">Update</button>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </div>
                <stationTable :stationResult="stationResult"></stationTable>
                <b-pagination
                  v-model="paginate_params.page"
                  :total-rows="rows"
                  :per-page="paginate_params.limit"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                ></b-pagination>
                <div class="def-func-input-form-group">
                  <div class="clearfix"></div>
                  <div class="def-func-input-group">
                    <div class="def-func-input-group-col"></div>
                    <div class="def-func-input-group-col btn-rename-delete">
                      <button :disabled="stationId == ''" v-b-modal.confirm-delete @click="delete_popup" variant="light" class="btn">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <confirmModal></confirmModal>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import station_table from "../../components/admin_layouts/dashboard_layouts/station_table";
import confirm_modal from "../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { EventBus } from "../../main";
export default {
  data() {
    return {
      addStationData: {
        name: "",
        code: "",
        zip_code: "",
        address_line1: "",
        address_line2: "",
        country: "",
        city: "",
        organisation: ""
      },
      functionResult: [
        {
          value: null,
          text: "Select",
          disabled: true
        }
      ],
      orgResult: [
        {
          value: null,
          text: "Select",
          disabled: true
        }
      ],
      orgData: {
        function: null,
        code: ""
      },
      orgId: "",
      stationId: "",
      stationCode: [],
      codeSearch: "",
      selectedCode: null,
      searchQuery: {
        code: ""
      },
      stationOrgRelation: {
        stations: ""
      },
      rows: 10,
      stationResult: [],
      paginate_params: {
        page: 1,
        limit: "10"
      },
      response: {
        msg: "",
        popup: false
      },
      orgRegion: null,
      messsage_loader: false
    };
  },
  components: {
    stationTable: station_table,
    confirmModal: confirm_modal
  },
  watch: {
    codeSearch(newQuery) {
      if (newQuery) {
        this.searchQuery.code = newQuery;
        HTTP_formdata.post("stations/get_codes", this.searchQuery)
          .then(response => {
            this.stationCode = response.data;
          })
          .catch(err => {});
      } else {
        this.addStationData.code = "";
        this.addStationData.name = "";
        this.addStationData.country = "";
        this.addStationData.city = "";
      }
    },
    selectedCode(newQuery) {
      this.searchQuery.code = this.codeSearch;
      HTTP_formdata.post("stations/get_code_details", this.searchQuery)
        .then(response => {
          this.addStationData.code = response.data[0].code;
          this.addStationData.name = response.data[0].station;
          this.addStationData.country = response.data[0].country;
          this.addStationData.city = response.data[0].city;
        })
        .catch(err => {});
    },
    "paginate_params.page": {
      handler() {
        this.stationResult = [];
        this.getStationDetails();
        deep: true;
      }
    }
  },
  methods: {
    addStation() {
      this.messsage_loader = true;
      if (this.stationId == "") {
        // Add Station
        this.addStationData.organisation = this.orgId
        HTTP_formdata.post("stations/add_stations", this.addStationData)
          .then(response => {
            this.stationOrgRelation.stations = response.data;
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              this.response.msg = response.data
              this.response.popup = true
              EventBus.$emit("success_resp", this.response);
              this.addStationData.name = "";
              this.codeSearch = "";
              this.addStationData.code = "";
              this.addStationData.zip_code = "";
              this.addStationData.address_line1 = "";
              this.addStationData.address_line2 = "";
              this.addStationData.country = "";
              this.addStationData.city = "";
              this.addStationData.organisation = "";
              this.orgId = null;
              this.orgData.function = null;
              this.orgData.code = "";
              this.orgRegion = null
              if (this.$refs.typeahead) {
                this.$refs.typeahead.inputValue = "";
              }
              this.stationOrgRelation.stations = "";
              this.$nextTick(() => {
                this.$refs.observer.reset();
              });
              this.getStationDetails();
            }
          })
          .catch(err => {
            if ( err.response.status == 400 ) {
              this.messsage_loader = false;
              this.response.msg = err.response.data.message
              this.response.popup = true
              EventBus.$emit("error_resp", this.response);
            }
          });
      } else {
        // Edit Station
        let editStationData = {
          name: this.addStationData.name,
          code: this.addStationData.code,
          zip_code: this.addStationData.zip_code,
          address_line1: this.addStationData.address_line1,
          address_line2: this.addStationData.address_line2,
          country: this.addStationData.country,
          city: this.addStationData.city,
          organisation_id: this.orgId
        };
        HTTP_formdata.put( "stations/edit_stations/" + this.stationId, editStationData )
          .then(response => {
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              this.response.msg = response.data.message;
              this.response.popup = true
              EventBus.$emit("success_resp", this.response);
              this.stationId = "";
              this.addStationData.name = "";
              this.codeSearch = "";
              this.addStationData.code = "";
              this.addStationData.zip_code = "";
              this.addStationData.address_line1 = "";
              this.addStationData.address_line2 = "";
              this.addStationData.country = "";
              this.addStationData.city = "";
              this.addStationData.organisation = "";
              this.orgId = null;
              this.orgData.function = null;
              this.orgData.code = "";
              this.orgRegion = null
              this.stationOrgRelation.stations = "";
              this.$nextTick(() => {
                this.$refs.observer.reset();
              }); 
              this.getStationDetails();
              EventBus.$emit("UnCheckAll", this.stationId);
            }
          })
          .catch(err => {
            if ( err.response.status == 400 ) {
              this.messsage_loader = false;
              this.response.msg = err.response.data.message
              this.response.popup = true
              EventBus.$emit("error_resp", this.response);
            }
          });
      }
    },
    getOrgList() {
      HTTP.get("organisations/get_regions")
        .then(response => {
          for (var i = 0; i < response.data.length; i++) {
            this.orgResult.push({
              value: response.data[i].region,
              text: response.data[i].region
            });
          }
        })
        .catch(error => {});
    },
    getOrgDetails() {
      HTTP.get("organisations/get_functions?region=" + this.orgRegion)
        .then(response => {
          this.functionResult = [{
            value: null,
            text: "Select",
            disabled: true
          }];
          this.orgData.function = null
          this.orgData.code = ""
          for (var i = 0; i < response.data.length; i++) {
            this.functionResult.push({
              value: response.data[i].function,
              text: response.data[i].function
            });
          }
        })
        .catch(error => {});
    },
    getOrgCode() {
      let jsonData = {
        region: this.orgRegion,
        function: this.orgData.function
      }
      HTTP_formdata.post("organisations/get_org_code", jsonData)
        .then(response => {
          this.orgData.code = response.data[0].code;
          this.orgId = response.data[0].public_id;
        })
        .catch(error => {});
    },
    getStationDetails() {
      this.stationId = "";
      this.addStationData.name = "";
      this.codeSearch = "";
      this.addStationData.code = "";
      this.addStationData.zip_code = "";
      this.addStationData.address_line1 = "";
      this.addStationData.address_line2 = "";
      this.addStationData.country = "";
      this.addStationData.city = "";
      this.addStationData.organisation = "";
      this.orgId = null;
      this.orgData.function = null;
      this.orgData.code = "";
      this.orgRegion = null
      if (this.$refs.typeahead) {
        this.$refs.typeahead.inputValue = "";
      }
      this.stationOrgRelation.stations = "";
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      HTTP_formdata.post("stations/display_stations", this.paginate_params)
        .then(response => {
          this.stationResult = response.data.data;
          this.rows = response.data.total;
        })
        .catch(err => {});
    },
    deleteStation() {
      HTTP.delete("stations/delete_stations/" + this.stationId)
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
          }
          this.stationId = "";
          this.addStationData.name = "";
          this.codeSearch = "";
          this.addStationData.code = "";
          this.addStationData.zip_code = "";
          this.addStationData.address_line1 = "";
          this.addStationData.address_line2 = "";
          this.addStationData.country = "";
          this.addStationData.city = "";
          this.addStationData.organisation = "";
          this.orgId = null;
          this.orgData.function = null;
          this.orgData.code = "";
          this.orgRegion = null
          if (this.$refs.typeahead) {
            this.$refs.typeahead.inputValue = "";
          }
          this.stationOrgRelation.stations = "";
          this.$nextTick(() => {
            this.$refs.observer.reset();
          });
          this.getStationDetails();
          EventBus.$emit("UnCheckAll", this.stationId);
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            this.response.msg = err.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
            this.stationId = "";
            this.addStationData.name = "";
            this.codeSearch = "";
            this.addStationData.code = "";
            this.addStationData.zip_code = "";
            this.addStationData.address_line1 = "";
            this.addStationData.address_line2 = "";
            this.addStationData.country = "";
            this.addStationData.city = "";
            this.addStationData.organisation = "";
            this.orgId = null;
            this.orgData.function = null;
            this.orgData.code = "";
            this.orgRegion = null
            if (this.$refs.typeahead) {
              this.$refs.typeahead.inputValue = "";
            }
            this.stationOrgRelation.stations = "";
            this.$nextTick(() => {
              if (this.$refs.observer) {
                this.$refs.observer.reset();
              }
            });
            this.getStationDetails();
            EventBus.$emit("UnCheckAll", this.stationId);
          }
        });
    },
    delete_popup() {
      EventBus.$emit( "deleteSelected", this.addStationData.code );
    }
  },
  beforeMount() {
    this.getOrgList();
    this.getStationDetails();
  },
  created() {
    EventBus.$on("selectStation", stationSelectedData => {
      this.stationId = stationSelectedData.public_id;
      this.addStationData.name = stationSelectedData.stationName;
      this.codeSearch = stationSelectedData.code;
      this.addStationData.code = stationSelectedData.code;
      this.addStationData.zip_code = stationSelectedData.zipcode;
      this.addStationData.address_line1 = stationSelectedData.address[0].line1;
      this.addStationData.address_line2 = stationSelectedData.address[0].line2;
      this.addStationData.country = stationSelectedData.country;
      this.addStationData.city = stationSelectedData.city;
      this.orgRegion = stationSelectedData.org_region;
      this.getOrgDetails();
      setTimeout(() => {
        this.orgData.function = stationSelectedData.org_function;
        this.orgData.code = stationSelectedData.org_code;
        this.orgId = stationSelectedData.org_public_id;
        this.addStationData.organisation = stationSelectedData.org_public_id;
      }, 2000);
    });
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteStation();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
    EventBus.$off("selectStation")
  }
};
</script>
<style>
@import "./def_function.css";
</style>