<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class>
                    <div class="def-func-input-group">
                      <ValidationObserver ref="observerSetUpCheck" v-slot="{ passes }">
                        <b-form @submit.prevent="passes( setUpChecklist )">
                          <div class="set-up-checklist-row">
                            <b-row class="mb-2">
                              <b-col md="3">
                                <div class="required-field">
                                  <label>My Stations</label>
                                </div>
                                <b-form-input v-if="pageName == 'edit'" type="text" v-model="checklistData.station" disabled></b-form-input>
                                <span v-else>
                                  <!-- v-else<typeahead v-if="userRole == 'ROLE_SUPERADMIN'" :apiFunction="'stations/get_station_codes'"></typeahead> -->
                                  <typeahead area="set_up_checklists" :apiFunction="'users/get_stations'"></typeahead>
                                </span>
                                <ValidationProvider
                                  name="Station Code"
                                  rules="required"
                                  v-slot="{ valid, errors }"
                                >
                                  <b-form-input
                                    v-show="false"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    v-model="checklistData.station"
                                  ></b-form-input>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </b-col>
                              <b-col md="4">
                                <ValidationProvider
                                  name="Function"
                                  rules="required"
                                  v-slot="{ valid, errors }"
                                >
                                  <div class="required-field">
                                    <label>Select Function</label>
                                  </div>
                                  <b-form-select disabled
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    @input="onChangeFunction( $event )"
                                    v-model="checklistData.function"
                                    :options="functionResult"
                                  ></b-form-select>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </b-col>
                              <b-col md="5">
                                <div class="required-field">
                                  <label>Select Checklist</label>
                                </div>
                                <b-form-input v-if="pageName == 'edit'" type="text" v-model="checklistData.audit" disabled></b-form-input>
                                <dropdownField v-else
                                  :name="'rec_time'"
                                  :rules="'required'"
                                  :options="checklistOptions"
                                  :error_message="'Checklist'"
                                  :disabled_toggle="(checklistData.function.length >= 0 && checklistData.function != '') ? false : true"
                                ></dropdownField>
                              </b-col>
                            </b-row>
                          </div>
                          <div class="clearfix"></div>
                          <b-row>
                            <b-col>
                              <div class="def-func-input-group global-checklist-freq">
                                <div class="required-field">
                                  <label class="global-freq freq-resp-title">Frequency & Responsibility</label>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                          <div class="clearfix"></div>
                          <b-row>
                            <b-col>
                              <div class="def-func-form freq-resp-table">
                                <div class="def-func-table manage-users-table">
                                  <table class="global-checklist-table">
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          <span>Frequency</span>
                                        </th>
                                        <th class="text-left">
                                          <span>Due on</span>
                                        </th>
                                        <th class="text-left">
                                          <span>To be performed by</span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr  v-for="( data, freqIndex ) in frequencyData" :key="freqIndex">
                                        <td class="text-left">{{ data.type }}
                                          <b-form-input v-show="false" v-model="data.type"></b-form-input>
                                        </td>
                                        <td class="text-left">
                                          <div class="freq-resp-text">
                                            <span v-if="(data.type == 'Annual') && data.displayDay != ''"
                                              class="freq-resp-dueOn">{{ data.dueOn | moment }}</span>
                                            <span v-else-if="(data.type == 'Monthly') && data.displayDay != ''"
                                              class="freq-resp-dueOn">{{ data.dueOn | momentMonth }}</span>
                                            <span v-else class="freq-resp-dueOn">{{ data.dueOn }}</span>
                                            <div class="manage-user-action">
                                              <button type="button" v-b-modal.modal-due-on
                                                @click="presentmodel( $event, 'frequency', data, freqIndex )">
                                                <img src="@/assets/images/btn_dayofweek.png" title="Calendar" />
                                              </button>
                                            </div>
                                          </div>
                                          <ValidationProvider :rules="`${data.displayEmpName ? 'required' : ''}`" v-slot="{ errors }">
                                            <input v-show="false" v-model="data.displayDay" />
                                            <b-form-invalid-feedback style="display:block">{{ errors[0] | customMsgDueOn }}</b-form-invalid-feedback>
                                          </ValidationProvider>
                                        </td>
                                        <td class="text-left">
                                          <div class="freq-resp-text">
                                            <span class="freq-resp-perfmBy">{{ data.displayEmpName }}</span>
                                            <div class="manage-user-action">
                                              <button type="button" v-b-modal.modal-performed-by :disabled="!checklistData.function"
                                                @click="presentmodel( $event, 'frequency', data, freqIndex )">
                                                <img src="@/assets/images/btn_showassignoptions.png" />
                                              </button>
                                            </div>
                                          </div>
                                          <ValidationProvider :rules="`${data.displayDay ? 'required' : ''}`" v-slot="{ errors }">
                                            <input v-show="false" v-model="data.displayEmpName" />
                                            <b-form-invalid-feedback style="display:block">{{ errors[0] | customMsgPerformBy }}</b-form-invalid-feedback>
                                          </ValidationProvider>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <ValidationProvider name="frequency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-input v-show="false" :state="errors[0] ? false : ( valid ? true : null )" v-model="frequencyVal" />
                                    <b-form-invalid-feedback>{{ errors[0] | freqErrorMsg }}</b-form-invalid-feedback>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                          <div class="clearfix"></div>
                          <b-row>
                            <b-col>
                              <div class="def-func-input-group global-checklist-freq required-field">
                                <label class="global-freq freq-resp-title">Check Items</label>
                                <p>Note: Certain checklist items are defined on a global level as mandatory with a set frequency. These are already in the list below and cannot be changed. You may however, add to the frequency if you wish.</p>
                              </div>
                            </b-col>
                          </b-row>
                          <div class="clearfix"></div>
                          <b-row class="mt-3">
                            <b-col lg="6" xl="2">
                              <div class="btn-rename-delete">
                                <button
                                  :disabled="checklistData.function == '' || checklistData.audit == null || checklistData.station == ''"
                                  type="button"
                                  variant="light"
                                  class="btn th-add-btn"
                                  v-b-modal.modal-global-database
                                >Global Database</button>
                              </div>
                            </b-col>
                            <b-col lg="12" xl="2">
                              <div class="btn-rename-delete">
                                <button
                                  :disabled="checklistData.function == '' || checklistData.audit == null || checklistData.station == ''"
                                  type="button"
                                  variant="light"
                                  class="btn th-add-btn"
                                  v-b-modal.modal-create-new-check
                                >Create new check</button>
                              </div>
                            </b-col>
                          </b-row>
                          <ValidationProvider
                            name="Statement"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <b-form-input
                              v-show="false"
                              type="text"
                              :state="errors[0] ? false : ( valid ? true : null )"
                              v-model="statements"
                            ></b-form-input>
                            <b-form-invalid-feedback class="mb-1">Please add a Statement</b-form-invalid-feedback>
                          </ValidationProvider>
                          <b-row>
                            <b-col>
                              <checklistStatementTable
                                :refresh="refreshList"
                                :checklist="checklistData.audit"
                                :station="stationId"
                              ></checklistStatementTable>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <div class="create-new-check-btn def-func-input-group-col btn-rename-delete float-right">
                                <button type="submit" class="btn" variant="light">Save</button>
                              </div>
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationObserver>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <selectFrequency :frequency="typeFlag" :data="dueOn" :index="frequencyIndex"></selectFrequency>
    <assignChecklist :station="stationId" :function="checklistData.function" :frequency="typeFlag" :data="performedBy" :index="frequencyIndex"></assignChecklist>
    <localChecklist
      :function="checklistData.function"
      :checklist="checklistData.audit"
      :statement="statementDetail"
      :station="stationId"
    ></localChecklist>
    <globalChecklist :station="stationId" :function="checklistData.function" :checklist="checklistData.audit" :functionList="functionResult"></globalChecklist>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import localChecklist from "../../components/admin_layouts/dashboard_layouts/local_checklist";
import assignChecklist from "../../components/admin_layouts/dashboard_layouts/assign_checklist";
import selectFrequency from "../../components/admin_layouts/dashboard_layouts/select_frequency";
import checklistStatementTable from "../../components/admin_layouts/dashboard_layouts/checklist_statement_table";
import globalChecklist from "../../components/admin_layouts/dashboard_layouts/global_checklist_select";
import typeahead from "../../components/admin_layouts/dashboard_layouts/typeahead_search_stationcode";
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { isloggedin } from "../../constants/app-constants";
import { EventBus } from "../../main";
import dropdownField from "../../components/admin_layouts/dashboard_layouts/fields/static_required_dropdown_field";
import moment from "moment";
function initialState() {
  return {
    checklistData: {
      station: "",
      function: "",
      audit: null,
      audit_name: "",
      frequency: []
    },
    checklistOptions: [
      {
        value: null,
        text: "Select",
        disabled: true
      }
    ],
    functionResult: [
      {
        value: "",
        text: "Select",
        disabled: true
      }
    ],
    refreshList: "",
    statements: "",
    statementDetail: "",
    typeFlag: "",
    response: {
      msg: "",
      popup: false
    },
    frequencyData: [
      { type: "Daily", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" },
      { type: "Weekly", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" },
      { type: "Monthly", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" },
      { type: "Annual", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" }
    ],
    dueOn: "",
    performedBy: "",
    frequencyIndex: "",
    frequencyVal: "",
    messsage_loader: false,
    userRole: "",
    pageName: "",
    stationId: ""
  };
}
export default {
  data() {
    return initialState();
  },
  components: {
    assignChecklist,
    localChecklist,
    selectFrequency,
    checklistStatementTable,
    globalChecklist,
    typeahead,
    dropdownField
  },
  filters: {
    moment: function(value) {
      return moment(value).format("Do MMMM");
    },
    momentMonth: function(value) {
      if (value)
        return "Every " + moment(value).format("Do") + " of the Month";
    },
    customMsgPerformBy: function(value) {
      if(value){
        if(value.includes("The {field} field is required")){
          return "Select To be performed by"
        }
      }
    },
    customMsgDueOn: function(value) {
      if(value){
        if(value.includes("The {field} field is required")){
          return "Select Due on"
        }
      }
    },
    freqErrorMsg: function(value) {
      if(value){
        if(value.includes("The frequency field is required")){
          return "Select Atleast One Frequency"
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    Object.assign(this.$data, initialState());
    let clear = "clear";
    EventBus.$emit("clearData", clear);
    this.$nextTick(() => {
      if (this.$refs.observerSetUpCheck)
        this.$refs.observerSetUpCheck.reset();
    });
    next();
  },
  created() {
    EventBus.$on("typeaheadSearchStationcode", searchStationcode => {
      this.checklistData.station = (searchStationcode.code) ? searchStationcode.code : "";
      this.stationId = (searchStationcode.public_id) ? searchStationcode.public_id : "";
      this.checklistData.function = (searchStationcode.function_id) ? searchStationcode.function_id : "";
    });
    EventBus.$on("send_static_dropdown_value", data => {
      this.checklistData.audit = data.data_val;
      this.checklistData.audit_name = data.text;
    });
    EventBus.$on("selectFrequency", datepickerFreq => {
      this.frequencyData[datepickerFreq.index].displayDay = datepickerFreq.data;
      if ( this.frequencyData[datepickerFreq.index].type == "Daily" || this.frequencyData[datepickerFreq.index].type == "Weekly" ) {
        this.frequencyData[datepickerFreq.index].dueOn = (this.frequencyData[datepickerFreq.index].displayDay) ? this.frequencyData[datepickerFreq.index].displayDay.join(" ") : this.frequencyData[datepickerFreq.index].displayDay;
      } else {
        this.frequencyData[datepickerFreq.index].dueOn = this.frequencyData[datepickerFreq.index].displayDay;
      }
      this.frequencyCheck();
    });
    EventBus.$on("selectEmp", performed_by => {
      this.frequencyData[performed_by.index].performed_by.emp_group_id = performed_by.emp_group_id;
      this.frequencyData[performed_by.index].performed_by.emp_id = performed_by.emp_id;
      this.frequencyData[performed_by.index].displayEmpName = performed_by.display_name;
      this.frequencyCheck();
    });
    EventBus.$on("refreshStatementList", refresh => {
      this.refreshList = refresh;
      if ( refresh.status && refresh.status == 200 ) {
        this.response.msg = refresh.data
        this.response.popup = true
        EventBus.$emit("success_resp", this.response);
      }
    });
    EventBus.$on("refreshStatementListLocal", refresh => {
      this.refreshList = refresh;
      this.statementDetail = "";
      if ( refresh.status && refresh.status == 200 ) {
        this.response.msg = refresh.data
        this.response.popup = true
        EventBus.$emit("success_resp", this.response);
      }
    });
    EventBus.$on("statements", statementResult => {
      if (statementResult.length > 0) {
        this.statements = "sucess";
      } else {
        this.statements = "";
      }
    });
    EventBus.$on("statementEdit", result => {
      this.statementDetail = result;
      this.$bvModal.show("modal-create-new-check");
    });
  },
  beforeMount() {
    this.getFuncList();
    this.userRole = this.$cookies.get("user").data.roles[0];
    if ( this.$route.path == "/edit_checklist" ) {
      this.messsage_loader = true
      this.fetchChecklistInfo();
      this.pageName = "edit"
    }
  },
  watch: {
    stationId(newVal) {
      if ( this.pageName != 'edit' ) {
        for (var i = 0; i < this.frequencyData.length; i++) {
          if ( this.frequencyData[i].performed_by.emp_id != "" ) {
            this.frequencyData[i].performed_by.emp_id = "";
            this.frequencyData[i].displayEmpName = "";
          }
        }
      }
    }
  },
  methods: {
    setUpChecklist() {
      this.messsage_loader = true;
      this.checklistData.frequency = []
      for (let i = 0; i < this.frequencyData.length; i++) {
        if ( this.frequencyData[i].dueOn != "" || this.frequencyData[i].displayEmpName != "" ) {
          this.checklistData.frequency.push({
            type: this.frequencyData[i].type,
            on_days: this.frequencyData[i].displayDay,
            performed_by: this.frequencyData[i].performed_by
          });
        }
      }
      let jsonChecklist = {
        station: this.stationId,
        function: this.checklistData.function,
        audit_name: this.checklistData.audit,
        frequency: this.checklistData.frequency
      };
      let url = ( this.pageName == 'edit' ) ? "audits/edit_checklist_info" : "audits/setup_checklist"
      HTTP_formdata.post( url , jsonChecklist )
        .then(response => {
          if ( response.status == 200 ) {
            this.messsage_loader = false;
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.checklistData.frequency = []
            this.checklistData.station = "";
            this.stationId = "";
            this.checklistData.function = "";
            this.checklistData.audit = null;
            this.checklistData.audit_name = "";
            this.frequencyData = [
              { type: "Daily", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" },
              { type: "Weekly", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" },
              { type: "Monthly", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" },
              { type: "Annual", dueOn: "", performed_by: { emp_group_id: "", emp_id: "" }, displayDay: "", displayEmpName: "" }
            ]
            this.dueOn = ""
            this.performedBy = ""
            this.frequencyIndex = ""
            this.frequencyVal = ""
            this.typeFlag = ""
            this.refreshList = "";
            this.statements = "";
            this.statementDetail = "";
            this.checklistOptions = [{
              value: null,
              text: "Select",
              disabled: true
            }];
            let clear = "clear";
            EventBus.$emit("clearData", clear);
            this.$nextTick(() => {
              this.$refs.observerSetUpCheck.reset();
            });
            if ( isloggedin.access_rights.includes('List Checklists') ) {
              setTimeout(() => {
                this.$router.push({ path: "/list_of_checklists" });
              }, 2000)
            }
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.messsage_loader = false;
            this.response.msg = error.response.data.message
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    getFuncList() {
      HTTP.get("functions/display_functions")
        .then(response => {
          for (var i = 0; i < response.data.length; i++) {
            this.functionResult.push({
              value: response.data[i].public_id,
              text: response.data[i].title
            });
          }
        })
        .catch(err => {});
    },
    onChangeFunction(event) {
      if ( this.pageName != 'edit' ) {
        let jsonData = {
          function: this.checklistData.function
        };
        for (var i = 0; i < this.frequencyData.length; i++) {
          if ( this.frequencyData[i].performed_by.emp_group_id != "" ) {
            this.frequencyData[i].performed_by.emp_group_id = "";
            this.frequencyData[i].displayEmpName = "";
          }
        }
        HTTP_formdata.post("audits/get_audits", jsonData)
          .then(response => {
            this.checklistOptions = [
              {
                value: null,
                text: "Select",
                disabled: true
              }
            ];
            this.checklistData.audit = null;
            this.checklistData.audit_name = "";
            for (var i = 0; i < response.data.length; i++) {
              this.checklistOptions.push({
                value: response.data[i].title,
                text: response.data[i].title
              });
            }
          })
          .catch(error => {});
      }
    },
    presentmodel(event, action, data, index) {
      if ( action == "frequency" && data.type ) {
        this.typeFlag = data.type;
        this.dueOn = data.displayDay;
        this.performedBy = data;
        this.frequencyIndex = index
      }
    },
    frequencyCheck() {
      for (let i = 0; i < this.frequencyData.length; i++) {
        if ( this.frequencyData[i].dueOn != "" || this.frequencyData[i].displayEmpName != "" ) {
          this.frequencyVal = "frequency"
          return;
        } else {
          this.frequencyVal = ""
        }
      }
    },
    fetchChecklistInfo() {
      if ( $cookies.get("__sucheck") ) {
        let jsonData = {
          title: $cookies.get("__sucheck").title,
          station_code: $cookies.get("__sucheck").station_public_id
        };
        HTTP_formdata.post("audits/get_checklist_info", jsonData)
          .then(response => {
            let data = response.data[0]
            this.checklistData.station = data.station_code;
            this.stationId = $cookies.get("__sucheck").station_public_id;
            this.checklistData.function = data.function_id;
            this.checklistData.audit = data.title;
            this.checklistData.audit_name = data.title;
            for (var i = 0; i < data.frequency.length; i++) {
              for (var j = 0; j < this.frequencyData.length; j++) {
                if ( data.frequency[i].type == this.frequencyData[j].type ) {
                  this.frequencyData[j].performed_by = { emp_group_id: data.frequency[i].performed_by.emp_group_id, emp_id: data.frequency[i].performed_by.emp_id };
                  this.frequencyData[j].displayDay = data.frequency[i].on_days;
                  this.frequencyData[j].dueOn = ( this.frequencyData[j].type == "Daily" || this.frequencyData[j].type == "Weekly" ) ? (this.frequencyData[j].displayDay) ? this.frequencyData[j].displayDay.join(" ") : this.frequencyData[j].displayDay : this.frequencyData[j].displayDay;
                  this.frequencyData[j].displayEmpName = ( data.frequency[i].performed_by.emp_group_id != "") ? data.frequency[i].performed_by.group_name : data.frequency[i].performed_by.emp_name
                }
              }
            }
            this.frequencyCheck();
            this.messsage_loader = false
          })
          .catch(error => {});
      } else {
        this.$router.push({path:"/list_of_checklists"})
      }
    }
  }
};
</script>
<style scoped>
@import "./def_function.css";
</style>