var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-outer"},[_c('div',{staticClass:"login-outer first-login-outer"},[_c('ValidationObserver',{ref:"firstLogin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"first-login-form text-left mt-4",on:{"submit":function($event){$event.preventDefault();return passes( _vm.onSubmit )}}},[_c('div',{staticClass:"def-func-input-form-group"},[_c('div',{staticClass:"def-func-input-group"},[_c('b-row',[_c('b-col',{staticClass:"def-func-input-group-col"},[_c('ValidationProvider',{attrs:{"name":"Old Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('label',[_vm._v("Old Password")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"type":"password","placeholder":"Old Password"},model:{value:(_vm.changePassword.oldPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "oldPassword", $$v)},expression:"changePassword.oldPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"def-func-input-group-col"},[_c('ValidationProvider',{attrs:{"name":"New Password","vid":"confirmation","rules":"required|min:8|verify_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('label',[_vm._v("New Password")]),_c('b-form-input',{attrs:{"type":"password","state":errors[0] ? false : (valid ? true : null),"placeholder":"New Password"},model:{value:(_vm.changePassword.newPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "newPassword", $$v)},expression:"changePassword.newPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"def-func-input-group-col mb-0"},[_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required|min:8|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('label',[_vm._v("Confirm Password")]),_c('b-form-input',{attrs:{"type":"password","state":errors[0] ? false : (valid ? true : null),"placeholder":"Confirm Password"},model:{value:(_vm.changePassword.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "confirmNewPassword", $$v)},expression:"changePassword.confirmNewPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]),_c('div',{staticClass:"clearfix"}),_c('b-row',[_c('div',{staticClass:"text-right w-100"},[_c('b-button',{staticClass:"ml-3",attrs:{"type":"submit","variant":"light"}},[_vm._v("Change Password")])],1)]),_c('div',{staticClass:"clearfix"})],1)]}}])})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messsage_loader),expression:"messsage_loader"}],staticClass:"text-center spinner-div overlay"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }