<template>
  <vue-bootstrap-typeahead
    :data="user_holder"
    ref="UserHolder"
    v-model="users_Search"
    :serializer="(display == 'email') ? items => items.email : items => items.name"
    :placeholder="(display == 'email') ? 'Username OR Email Address' : 'Last Name, First Name, Username OR Email Address'"
    :minMatchingChars="1"
    @hit="users_Code = $event"
  ></vue-bootstrap-typeahead>
</template>
<script>
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  props: [ "display", "userFlag", "apidata" ],
  data() {
    return {
      user_holder: [],
      users_Search: "",
      users_Code: null
    };
  },
  watch: {
    users_Search(newQuery) {
      if (newQuery) {
        let searchQuery = { area: this.apidata.area, distributeTo: this.apidata.distributeTo, groupMembers: newQuery };
        HTTP_formdata.post( "briefing_distribution_list/search_filter", searchQuery )
          .then(response => {
            if (response != "Search Not Found") {
              this.user_holder = [];
              for (let i = 0; i < response.data.length; i++) {
                this.user_holder.push(response.data[i]);
              }
            }
          })
          .catch(err => {});
      }
    },
    users_Code(newQuery) {
      let users_Code = this.users_Code;
      EventBus.$emit("typeahead_search_users", users_Code);
    }
  },
  mounted() {
    if (this.userFlag) {
      if (this.$refs.UserHolder)
        this.$refs.UserHolder.inputValue = this.userFlag
    }
  }, 
  Destroyed(){
    EventBus.$off('userEmail')
  },
  created() {
    EventBus.$on("onAdd", clear => {
      if (this.$refs.UserHolder)
        this.$refs.UserHolder.inputValue = ''
    });
  }
};
</script>