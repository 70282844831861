import axios from 'axios'
import { isloggedin, BASE_URL, BASE_URL_VER } from './app-constants';
import store from "../store/store";
import { router } from "../main";

export const HTTP = axios.create({
  baseURL: BASE_URL_VER,
  headers: {
    Authorization: 'Bearer ' + isloggedin.token
  }
})

export const HTTP_imageupload = axios.create({
  baseURL: BASE_URL_VER,
  headers: {
    Authorization: 'Bearer ' + isloggedin.token,
    'Content-Type': 'multipart/form-data'
  }
})

export const HTTP_formdata = axios.create({
  baseURL: BASE_URL_VER,
  headers: {
    Authorization: 'Bearer ' + isloggedin.token,
    'Content-type': 'application/json'
  }
})

export const HTTP_BASE = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: 'Bearer ' + isloggedin.token
  }
})

export const HTTP_imageupload_BASE = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: 'Bearer ' + isloggedin.token,
    'Content-Type': 'multipart/form-data'
  }
})

export const HTTP_formdata_BASE = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: 'Bearer ' + isloggedin.token,
    'Content-type': 'application/json'
  }
})

const respInterceptorGet = HTTP.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response;
}, function (err) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // return Promise.reject(error);
  return new Promise(function (resolve, reject) {
    if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
      // if you ever get an unauthorized, logout the user
      store.dispatch('logout');
    } else if ( err.response.status === 403 ) {
      // redirect to dashboard
      router.push({ name: 'notFound' }).catch(e => {});
    } else {
      // console.log('else Response Interceptor')
    }
    throw err;
  });
});
// HTTP.interceptors.response.eject(resInterceptor)

const respInterceptorImg = HTTP_imageupload.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
      store.dispatch('logout');
    } else if ( err.response.status === 403 ) {
      router.push({ name: 'notFound' }).catch(e => {});
    } else {
      // console.log('else Response Interceptor')
    }
    throw err;
  });
});

const respInterceptorPost = HTTP_formdata.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
      store.dispatch('logout');
    } else if ( err.response.status === 403 ) {
      router.push({ name: 'notFound' }).catch(e => {});
    } else {
      // console.log('else Response Interceptor')
    }
    throw err;
  });
});

const respInterceptorGetBase = HTTP_BASE.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
      store.dispatch('logout');
    } else if ( err.response.status === 403 ) {
      router.push({ name: 'notFound' }).catch(e => {});
    } else {
      // console.log('else Response Interceptor')
    }
    throw err;
  });
});

const respInterceptorImgBase = HTTP_imageupload_BASE.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
      store.dispatch('logout');
    } else if ( err.response.status === 403 ) {
      router.push({ name: 'notFound' }).catch(e => {});
    } else {
      // console.log('else Response Interceptor')
    }
    throw err;
  });
});

const respInterceptorPostBase = HTTP_formdata_BASE.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
      store.dispatch('logout');
    } else if ( err.response.status === 403 ) {
      router.push({ name: 'notFound' }).catch(e => {});
    } else {
      // console.log('else Response Interceptor')
    }
    throw err;
  });
});