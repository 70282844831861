<template>
  <div class="login-outer forgot-outer">
    <img class="login-logo" :src="shopTalkImg" />
    <!-- <img class="login-logo" src="@/assets/images/shopTalk.png" /> -->
    <h2 class="login-title">Forgot Password?</h2>
    <div class="forgot-tabs">
      <p class="tab-note">If your username is an email address then enter the Email address below. If your username is not an email address then select the Non-Email tab and enter your Username.</p>
      <b-tabs v-model="tabIndex" active-tab-class="" content-class="mt-3" align="center">
        <b-tab title="Email" active>
          <ValidationObserver ref="observerEmail" v-slot="{ passes }">
            <b-form class="login-form text-left" @submit.prevent="passes( onSubmit )">
              <ValidationProvider name="Email" rules="required|email" v-slot="{ valid, errors }">
                <b-input-group class="mb-4">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input :state="errors[0] ? false : (valid ? true : null)" v-model="forgot_password.username" placeholder="Email"></b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </ValidationProvider>
              <div class="clearfix"></div>
              <div class="text-center">
                <b-spinner v-show="messsage_loader" variant="primary" label="Spinning"></b-spinner>
              </div>
              <b-alert v-model="success" variant="success">{{message}}</b-alert>
              <b-alert v-model="fail" variant="danger">{{message}}</b-alert>
              <b-button type="submit" class="login-submit" variant="light">Submit</b-button>
              <div class="clearfix"></div>
              <p class="text-center"><router-link class="mb-5 login-forgot" to="/login">Login</router-link></p>
            </b-form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="Non-Email">
          <ValidationObserver ref="observerNonEmail" v-slot="{ passes }">
            <b-form class="login-form text-left" @submit.prevent="passes( onSubmit )">
              <ValidationProvider rules="required" v-slot="{ valid, errors }">
                <b-form-group class="non-email-radio">
                  <b-form-radio-group @input="nonEmailRadioChange" :state="errors[0] ? false : (valid ? true : null)" id="radio-group-nonemail" :options="nonEmailOptions" v-model="userdetails" name="know-username">
                  </b-form-radio-group>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider v-if="userdetails == 'know-userdetails'" name="Username" v-slot="{ valid, errors }" :rules="`${(userdetails == 'know-userdetails') ? 'required|alpha_num' : ''}`">
                <b-input-group class="mb-4">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input :state="errors[0] ? false : ( valid ? true : null )" v-model="forgot_password.username" placeholder="Username"></b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </ValidationProvider>
              <div class="clearfix"></div>
              <div class="text-center mt-3">
                <b-spinner v-show="messsage_loader" variant="primary" label="Spinning"></b-spinner>
              </div>
              <p v-show="nonUsernameMsg" class="tab-note text-center non-username-note">Unfortunately, we cannot reset your password.<br>Please contact your station manager to reset your password.</p>
              <b-alert v-model="success" variant="success">{{ message }}</b-alert>
              <b-alert v-model="fail" variant="danger">{{ message }}</b-alert>
              <b-button v-if="userdetails != 'no-userdetails'" type="submit" class="login-submit" variant="light">Submit</b-button>
              <router-link class="mb-5 login-forgot tab2-login-link" to="/login">Login</router-link>
            </b-form>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
    </div>
    <p class="powered-by">POWERED BY</p>
    <img class="poweredby-logo" :src="poweredByImg">
    <!-- <img class="poweredby-logo" src="@/assets/images/frontlineDynamics.png"> -->
  </div>
</template>
<script>
  import axios from "axios";
  // import VueAxios from "vue-axios";
  import { BASE_URL_VER } from "../../../../constants/app-constants";
  export default {
    data() {
      return {
        success: false,
        fail: false,
        messsage_loader: false,
        message: "",
        forgot_password: {
          option: 1,
          username: "",
          first_name: "",
          last_name: "",
          station: "",
          remark: ""
        },
        userdetails: null,
        tabIndex: 0,
        nonEmailOptions: [
          { text: 'I know my username and have registered my cell phone at the initial login.', value: 'know-userdetails' },
          { text: 'I do not know my username or did not register my cell phone at the initial login.', value: 'no-userdetails' }
        ],
        nonUsernameMsg: false,
        shopTalkImg: process.env.VUE_APP_SHOPTALK,
        poweredByImg: process.env.VUE_APP_POWERED_BY
      };
    },
    watch: {
      userdetails(val) {
        if (val) {
          this.forgot_password.option = (val == 'know-userdetails') ? 2 : 3;
        }
      },
      tabIndex(val) {
        this.forgot_password = {
          option: (val == 0) ? 1 : "",
          username: "",
          first_name: "",
          last_name: "",
          station: "",
          remark: ""
        };
        this.userdetails = null;
        this.$nextTick(() => {
          if (this.$refs.observerEmail)
            this.$refs.observerEmail.reset();
          if (this.$refs.observerNonEmail)
            this.$refs.observerNonEmail.reset();
        });
      }
    },
    beforeCreate() {
      if ( this.$cookies.isKey("__uvd") == true )
        this.$cookies.remove( "__uvd" );
    },
    methods: {
      nonEmailRadioChange() {
        if ( this.userdetails == "no-userdetails" ) {
          this.nonUsernameMsg = true;
          this.fail = false;
          this.success = false;
        } else {
          this.nonUsernameMsg = false;
        }
      },
      onSubmit() {
        if ( this.$cookies.isKey("__uvd") == true )
          this.$cookies.remove( "__uvd" );
        this.messsage_loader = true;
        this.forgot_password.username.trim();
        axios
          .put(BASE_URL_VER + "users/forgot_password", this.forgot_password)
          .then(result => {
            this.messsage_loader = false;
            if (result.data.code == 200) {
              this.fail = false;
              this.success = true;
              this.message = result.data.response;
              setTimeout(() => {
                this.success = false;
                this.message = "";
                if ( result.data.data == null )
                  this.$router.push({ path: "/login" });
                else {
                  this.$cookies.set( "__uvd", result.data.data );
                  this.$router.push({ path: "/login/verify-OTP" });
                }
              }, 3000);
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            this.success = false;
            this.fail = true;
            this.message = err.response.data.response;
            setTimeout(() => {
              this.fail = false;
              this.message = "";
            }, 3000);
          });
      }
    }
  };
</script>
<style>
.forgot-outer .forgot-tabs .nav-tabs {border: none;margin: 30px 0 20px;}
.forgot-outer .forgot-tabs a.nav-link {background: #f1f1f1;color: #003261;border-radius: 0;padding: 8px;}
.forgot-outer .forgot-tabs a.nav-link.active {background: #003261;color: white;    border-color: #003261;}
.forgot-outer .forgot-tabs .nav-item {width: 50%;}
.forgot-outer .no-username.def-func-input-group input, .no-username.def-func-input-group select, .no-username input.th-input {border: 1px solid #a9a9a9;height: 40px;border-radius: 5px !important;width: 100%;padding: 7px 15px;}
.forgot-outer .def-func-input-form-group label {font-size: 14px;color: #999999;display: block;margin-bottom: 7px;text-align: left;}
.forgot-outer .def-func-input-group {display: table;width: 100%;table-layout: fixed;}
.forgot-outer .def-func-input-group-col {margin-bottom: 25px;}
.forgot-outer .def-func-input-group input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}
.forgot-outer .def-func-input-group input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}
.forgot-outer .def-func-input-group input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}
.forgot-outer .def-func-input-group input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}
.forgot-outer .def-func-input-group textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}
.forgot-outer .def-func-input-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}
.forgot-outer .def-func-input-group textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}
.forgot-outer .def-func-input-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}
.forgot-outer .custom-control-input:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {border-color: #003261;background-color: #003261;}
.forgot-outer .forgot-tabs {padding: 5% 10%;border: 1px solid lightgrey;margin: 30px 0;border-radius: 5px;box-shadow: 0 0 5px lightgrey;}
.login-outer.forgot-outer {max-width: 525px;}
.forgot-outer .forgot-tabs .tab2-login-link {width: 100%;text-align: center;margin: 0 0 35px 0;}
.forgot-outer .non-email-radio .custom-control.custom-radio {margin-bottom: 15px;}
.forgot-outer .non-email-radio .custom-control-input.is-valid ~ .custom-control-label span {color: #003261;}
.forgot-outer .def-func-input-group.no-username .def-func-input-group-col.col {padding: 0 15px;}
.forgot-outer .login-form .no-username .input-group::after {display: none;}
.forgot-outer .forgot-tabs a.nav-link {outline: none;}
.forgot-outer .tab-note {margin: 0 -24px;}
.forgot-outer .non-username-note {background: #d1ecf1;border: 1px solid #bee5eb;border-radius: .25rem;padding: .75rem 1.25rem;color: #0c5460;margin-bottom: 25px;}
</style>
