<template>
  <div>
    <div class="login-outer first-login-outer">
      <img class="login-logo" :src="shopTalkImg" />
      <!-- <img class="login-logo" src="@/assets/images/shopTalk.png" /> -->
      <h2 class="login-title">Hi {{ loggedin_user_details.name.first | firstLetterCapital }} {{ loggedin_user_details.name.last | firstLetterCapital }}, this is your first login.<br>For security reasons, we request you to please change your password.</h2>
      <ValidationObserver ref="firstLogin" v-slot="{ passes }">
        <b-form class="first-login-form text-left mt-4" @submit.prevent="passes( onSubmit )">
          <div class="def-func-input-form-group">
            <div class="def-func-input-group no-username">
              <b-row>
                <b-col class="def-func-input-group-col">
                  <ValidationProvider name="New Password" vid="confirmation" rules="required|min:8|verify_password" v-slot="{ valid, errors }">
                    <label>New Password</label>
                    <b-form-input type="password" v-model="changePassword.newPassword" :state="errors[0] ? false : (valid ? true : null)" placeholder="New Password"></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="def-func-input-group-col">
                  <ValidationProvider name="Confirm Password" rules="required|min:8|confirmed:confirmation" v-slot="{ valid, errors }">
                    <label>Confirm Password</label>
                    <b-form-input type="password" v-model="changePassword.confirmNewPassword" :state="errors[0] ? false : (valid ? true : null)" placeholder="Confirm Password"></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="def-func-input-group-col mb-0">
                  <label>Cell Number (Optional)</label>
                  <div class="cell-phone-outer">
                    <VuePhoneNumberInput v-model="phoneVal" class='country-code'
                    :clearable="true"
                    :show-code-on-list="true"
                    :error="(isValid == false && cellPhone != '') ? true : false"
                    :valid-color="'#28a745'"
                    :error-color="'#dc3545'"
                    :default-country-code="changePassword.country_code"
                    @update="phoneData($event)" />
                  </div>
                  <ValidationProvider name="Cell Phone" :rules="`${ (cellPhone != '') ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-input v-show="false" :state="errors[0] ? false : ( valid ? true : null )" v-model="changePassword.phone_no" />
                    <b-form-invalid-feedback>{{ errors[0] | custom_message }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="text-center">
            <b-spinner v-show="messsage_loader" variant="primary" label="Spinning"></b-spinner>
          </div>
          <b-alert class="mt-4" v-model="success" variant="success">{{ message }}</b-alert>
          <b-alert class="mt-4" v-model="fail" variant="danger">{{ message }}</b-alert>
          <b-button type="submit" class="login-submit" variant="light">Change Password</b-button>
          <div class="clearfix"></div>
        </b-form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
  import { HTTP_formdata } from "../../../../constants/http-common";
  import { isloggedin } from "../../../../constants/app-constants";
  export default {
    data() {
      return {
        loggedin_user_details: isloggedin,
        changePassword: {
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
          phone_no: "",
          country_calling_code: "",
          country_code: ""
        },
        messsage_loader: false,
        cellPhone: "",
        success: false,
        fail: false,
        message: "",
        phoneVal: "",
        isValid: false,
        shopTalkImg: process.env.VUE_APP_SHOPTALK
      };
    },
    methods: {
      phoneData(event) {
        this.isValid = event.isValid;
        this.cellPhone = (event.nationalNumber) ? event.nationalNumber : "";
        if ( event.isValid == true ) {
          this.changePassword.phone_no = (event.nationalNumber) ? event.nationalNumber : "";
          this.changePassword.country_calling_code = (event.countryCallingCode) ? event.countryCallingCode : "";
          this.changePassword.country_code = (event.countryCode) ? event.countryCode : "";
        } else {
          this.changePassword.phone_no = "";
          this.changePassword.country_calling_code = "";
          this.changePassword.country_code = "";
        }
      },
      onSubmit() {
        this.messsage_loader = true;
        HTTP_formdata.post("users/change_password", this.changePassword)
          .then(response => {
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              this.success = true;
              this.fail = false;
              this.message = response.data;
              this.$cookies.remove("user");
              setTimeout(() => {
                this.success = false;
                this.message = "";
                this.$store.dispatch('logout')
              }, 3000);
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            if ( err.response.status == 400 ) {
              this.success = false;
              this.fail = true;
              this.message = err.response.data.message;
              setTimeout(() => {
                this.fail = false;
                this.message = "";
              }, 3000);
            } else if ( err.response.status == 401 ) {
              this.$cookies.remove("user");
              this.$store.dispatch('logout')
            }
          });
      }
    },
    mounted() {
      if ( this.loggedin_user_details.passwordCheck != 2 ) {
        this.$router.push({path:"/"})
        window.location.reload();
      }
      if ( this.$cookies.isKey("user") == false )
        this.$router.push({path:"/login"})
      if ( this.loggedin_user_details.phoneNum ) {
        this.changePassword.phone_no = ( this.loggedin_user_details.phoneNum.phone_no ) ? this.loggedin_user_details.phoneNum.phone_no : "";
        this.cellPhone = ( this.loggedin_user_details.phoneNum.phone_no ) ? this.loggedin_user_details.phoneNum.phone_no : "";
        this.phoneVal = ( this.loggedin_user_details.phoneNum.phone_no ) ? this.loggedin_user_details.phoneNum.phone_no : "";
        this.changePassword.country_calling_code = ( this.loggedin_user_details.phoneNum.country_calling_code ) ? this.loggedin_user_details.phoneNum.country_calling_code : "";
        this.changePassword.country_code = ( this.loggedin_user_details.phoneNum.country_code ) ? this.loggedin_user_details.phoneNum.country_code : "";
        this.isValid = true;
      }
    },
    filters: {
      firstLetterCapital: function(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      custom_message: function(value) {
        if (value == "The Cell Phone field is required") {
          return "The Cell Phone field must have a valid phone number"
        } else {
          return value
        }
      }
    }
  };
</script>
<style scoped>
  @import "../../login.css";
  .login-outer.first-login-outer {max-width: 590px;}
  .login-outer.first-login-outer .first-login-form {max-width: 340px;margin: 30px auto 0;}
  h2.login-title {font-size: 17px;font-weight: normal;border-bottom: none;line-height: normal;}
  .phone-icon svg {font-size: 20px;}
  .def-func-input-form-group label {font-size: 14px;color: #999999;display: block;margin-bottom: 7px;text-align: left;}
  .def-func-input-group {display: table;width: 100%;table-layout: fixed;}
  .def-func-input-group-col {margin-bottom: 25px;}
  .def-func-input-group input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cccccc;
  }
  .def-func-input-group input::-moz-placeholder {
    /* Firefox 19+ */
    color: #cccccc;
  }
  .def-func-input-group input:-ms-input-placeholder {
    /* IE 10+ */
    color: #cccccc;
  }
  .def-func-input-group input:-moz-placeholder {
    /* Firefox 18- */
    color: #cccccc;
  }
  .def-func-input-group textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cccccc;
  }
  .def-func-input-group textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #cccccc;
  }
  .def-func-input-group textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #cccccc;
  }
  .def-func-input-group textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #cccccc;
  }
  .cell-phone-outer {
  display: flex;
}
.cell-phone-outer > input.cell-prefix {
  max-width: 65px;
  margin-right: 10px;
  text-align: center;
  padding: 7px 15px 7px 12px;
}
.cell-phone-outer > span {
  width: 100%;
}
.country-code .country-selector input.country-selector__input:focus,
.country-code .input-tel.input-phone-number input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-color: #80bdff;
}
.country-code .select-country-container {
  margin-right: 10px;
}
.country-code .input-tel.input-phone-number input {
  border-radius: 5px !important;
}
.country-code {
  z-index: 10;
}
.cell-phone-outer input::placeholder {
  text-transform: capitalize !important;
}
.country-code label.country-selector__label {
  text-transform: capitalize !important;
}
.cell-phone-outer .vue-phone-number-input {
    width: 100%;
}
.country-code .country-selector__list {
    bottom: 100%;
    top: auto;
}
</style>