<template>
  <vue-bootstrap-typeahead
    :data="typeData"
    ref="typeaheaduser"
    v-model="typesearch"
    :serializer="item => item.name"
    placeholder="Last Name, First Name OR Email Address"
    :minMatchingChars="1"
    @hit="selectedTypeAhead = $event"
  ></vue-bootstrap-typeahead>
</template>
<script>
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  props: [ "userVal", "clear_typeAhead", "api", "station", "apiData" ],
  data() {
    return {
      typeData: [],
      typesearch: "",
      selectedTypeAhead: null
    };
  },
  watch: {
    clear_typeAhead(newQuery) {
      this.$refs.typeaheaduser.inputValue = "";
    },
    typesearch(newQuery) {
      if (newQuery) {
        let searchQuery = ( this.station ) ? { station: this.station, user_filter: newQuery } : { area: this.apiData.area, area_id: this.apiData.area_id, distributeTo: this.apiData.distributeTo, groupMembers: newQuery };
        HTTP_formdata.post( this.api, searchQuery )
          .then(response => {
            if ( response.status == 200 ) {
              if (response.data != "Search Not Found") {
                this.typeData = response.data;
              } else {
                if (this.$refs.typeaheaduser)
                  this.$refs.typeaheaduser.inputValue = ''
                this.typesearch = ""
                let data = ( this.station ) ? { display_name: "", name: "", public_id: "" } : { display_name: "", name: "", public_id: "", type: "", user_type: "" };
                EventBus.$emit( "typeahead_search_user_and_group", data );
              }
            }
          })
          .catch(err => {});
      } else {
        if (this.$refs.typeaheaduser)
          this.$refs.typeaheaduser.inputValue = ''
        let data = ( this.station ) ? { display_name: "", name: "", public_id: "" } : { display_name: "", name: "", public_id: "", type: "", user_type: "" };
        EventBus.$emit( "typeahead_search_user_and_group", data );
      }
    },
    selectedTypeAhead(newQuery) {
      EventBus.$emit("typeahead_search_user_and_group", newQuery);
    }
  },
  mounted() {
    if (this.userVal) {
      this.$refs.typeaheaduser.inputValue = this.userVal;
      this.typesearch = this.userVal;
    }
  },
  created() {
    EventBus.$on("clearEmp", val => {
      if ( this.$refs.typeaheaduser )
        this.$refs.typeaheaduser.inputValue = "";
      this.typesearch = "";
    });
  }
};
</script>