<template>
  <div v-if="target" class="training-update-container">
    <div>
      <p class="update-title">{{ title }} Training Update</p>
      <div class="gauge-chart-inner">
        <div>
          <div class="week-status">
            <div class="update-label">Week</div>
            <div class="update-data-status">{{ label }}</div>
          </div>
          <div class="update-container">
            <div class="update-wrapper">
              <div class="update-label">Target</div>
              <div class="update-data">{{ target }}</div>
            </div>
            <div class="update-wrapper">
              <div class="update-label">Status</div>
              <div class="update-data-status">Completed</div>
            </div>
          </div>
        </div>
        <VueGauge v-show="optionCC" v-if="refId == 'Canity-current-week' && showCC == true" :refid="refId" :options="optionCC" />
        <img v-show="refId == 'Canity-current-week' && !optionCC" :title="refId" class="gauge-img" src="@/assets/images/zero-percent-gauge.png" />
        <VueGauge v-show="optionCL" v-if="refId == 'Canity-last-week' && showCL == true" :refid="refId" :options="optionCL" />
        <img v-show="refId == 'Canity-last-week' && !optionCL" :title="refId" class="gauge-img" src="@/assets/images/zero-percent-gauge.png" />
        <VueGauge v-show="optionSC" v-if="refId == 'ShopTalk-current-week' && showSC == true" :refid="refId" :options="optionSC" />
        <img v-show="refId == 'ShopTalk-current-week' && !optionSC" :title="refId" class="gauge-img" src="@/assets/images/zero-percent-gauge.png" />
        <VueGauge v-show="optionSL" v-if="refId == 'ShopTalk-last-week' && showSL == true" :refid="refId" :options="optionSL" />
        <img v-show="refId == 'ShopTalk-last-week' && !optionSL" :title="refId" class="gauge-img" src="@/assets/images/zero-percent-gauge.png" />
        <!-- <p class="update-percentage">{{ percent }}%</p> -->
        <!-- <div class="update-status">Completion Status</div> -->
      </div>
    </div>
  </div>
</template>
<script>
  import { EventBus } from "../../../main";
  import VueGauge from 'vue-gauge';
  export default {
    props: [ "title", "target", "refId", "label" ],
    data() {
      return {
        showCC: false,
        showCL: false,
        showSC: false,
        showSL: false,
        optionCC: {},
        optionCL: {},
        optionSC: {},
        optionSL: {}
      };
    },
    components: {
      VueGauge
    },
    methods: {
      optionConfiguration( percent ) {
        return {
          needleValue: 100,
          arcDelimiters: [ percent ],
          arcColors: ['#f44f3b'],
          hasNeedle: false,
          rangeLabel: ['0%','100%'],
          centralLabel: percent + '%',
          // arcLabels : [ percent + '%' ],
          components:"center",
        }
      }
    },
    created() {
      EventBus.$on( "canityGaugeChartCurrent", response => {
        this.optionCC = ( response.percentage > 0 ) ? this.optionConfiguration( parseFloat(response.percentage) ) : "";
        this.showCC = true;
      });
      EventBus.$on( "canityGaugeChartLast", response => {
        this.optionCL = ( response.percentage > 0 ) ? this.optionConfiguration( parseFloat(response.percentage) ) : "";
        this.showCL = true;
      });
      EventBus.$on( "shoptalkGaugeChartCurrent", response => {
        this.optionSC = ( response.percentage > 0 ) ? this.optionConfiguration( parseFloat(response.percentage) ) : "";
        this.showSC = true;
      });
      EventBus.$on( "shoptalkGaugeChartLast", response => {
        this.optionSL = ( response.percentage > 0 ) ? this.optionConfiguration( parseFloat(response.percentage) ) : "";
        this.showSL = true;
      });
    }
  };
</script>