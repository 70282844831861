<template>
  <div class="perform-content">
    <b-row>
      <b-col>
        <span v-for="( result, index ) in checkResults" :key="index">
          <div class="training-questns-section">
            <div class="training-questn-col questn-number">
              <b-badge class="questn-badge">{{ index + 1 }}</b-badge>
            </div>
            <div class="training-questn-col questn">
              <label>{{ result.question }}</label>
            </div>
            <div class="training-questn-col questn-action perform-image">
              <div class="dc-flex-center-perform-check">
                <div>
                  <b-icon v-b-tooltip.hover.top="'Only jpg, jpeg or png allowed'" icon="info" class="info-icon-size"></b-icon>
                </div>
                <div>
                  <img @click="choosePerformImage( index, checkResults[index].answers )" src="@/assets/images/camera-check.png" title="Add Image" />
                  <b-form-file
                    accept="image/jpeg, image/png, .jpg, .png"
                    v-model="checkResults[index].image"
                    @change="performImage_uploaded($event, index)"
                    :ref="'imgInput' + (index)"
                    class="hide"
                    :state="checkResults[index].errors ? false : true"
                    plain
                  ></b-form-file>
                  <p class="feedback-error" v-if="checkResults[index].errors">{{ checkResults[index].errors }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="training-options-section mb-5">
            <div class="perform-content-col">
              <div class="training-options-header perform-options-header">
                <b-form-group class="perform-scoreOptions add-media-inner">
                  <b-form-radio-group 
                    v-model="checkResults[index].answers"
                    @input="onChangeAnswer( $event, index )" :id="'radio-group-2' + (index)" :name="'score' + (index)" class="score-options">
                    <div class="required-field">
                      <label class="scoreOptions-title">Score:</label>
                    </div>
                    <b-form-radio value="Critical Flaw">Critical Flaw</b-form-radio>
                    <b-form-radio value="Failed">Failed</b-form-radio>
                    <b-form-radio value="Passed">Passed</b-form-radio>
                    <b-form-radio value="Above Average">Above Average</b-form-radio>
                    <b-form-radio value="BDP">BDP</b-form-radio>
                    <b-form-radio value="Not Applicable" class="perform-notAppl">Not Applicable</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="training-options-body">
                <label class="checkers_comments" :for="'checkers_comments' + (index)">Checkers comments</label>
                <b-form-textarea @input="formDataChange( $event )" v-model="checkResults[index].remarks" :id="'checkers_comments' + (index)" rows="5" no-resize></b-form-textarea>
              </div>
            </div>
            <div class="perform-image-col">
              <b-carousel v-if="checkResults[index].images && checkResults[index].images.length > 0"
                :id="'carousel-1' + (index)"
                :ref="'carousel' + (index)"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide v-for="( imgsrc, j ) in checkResults[index].images" :key="j" :img-src="imgsrc.name"><img @click="deletePerformImage( index, imgsrc, result, j )" src="@/assets/images/delete.png" title="Delete"></b-carousel-slide>
              </b-carousel>
            </div>          
            <div class="clearfix"></div>
          </div>
        </span>
      </b-col>
    </b-row>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import { EventBus } from "../../../main";
import { HTTP_formdata, HTTP_imageupload } from "../../../constants/http-common";
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      checkResults: [],
      results: [],
      checkId: "",
      checkReportId: "",
      messsage_loader: false
    };
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    choosePerformImage( index, answer ) {
      if ( answer ) {
        if ( (! this.checkReportId) || this.checkResults[index].images.length == 0 ) {
          let action = 'Save';
          EventBus.$emit( "saveCheck", action );
        }
        let checkImg = 'imgInput' + index
        this.$refs[checkImg][0].$el.click();
      } else {
        this.checkResults[index].errors = 'Select a score';
        setTimeout(() => {
          this.checkResults[index].errors = '';
        }, 3000);
      }
    },
    performImage_uploaded(event, index) {
      if ( event.target.files.length > 0 && ( event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "png" || event.target.files[0].type == "jpeg" ) ) {
        this.messsage_loader = true;
        this.checkResults[index].errors = '';
        if ( this.checkReportId == "" ) {
          let resp = {
            msg: "Something went wrong. Please upload the image again.",
            popup: true
          }
          EventBus.$emit("error_resp", resp);
          this.messsage_loader = false;
        } else {
          let jsonData = new FormData();
          jsonData.set("auditReportFiles", event.target.files[0]);
          jsonData.set("audit_reports", this.checkReportId);
          jsonData.set("audit", this.checkId);
          jsonData.set("statement", this.checkResults[index].public_id);
          HTTP_imageupload.post( "auditReports/audit_report_files", jsonData )
            .then(response => {
              if ( response.status == 200 )  {
                if ( ! this.checkResults[index].images ) {
                  this.checkResults[index].images = []
                }
                this.checkResults[index].images.push({name:response.data.images, file_name:response.data.file_name});
                this.messsage_loader = false;
              }
            })
            .catch(error => {});
        }
      } else {
        this.checkResults[index].errors = 'Select a valid image';
      }
    },
    onChangeAnswer( event, index ) {
      let pcount = 0;
      let scount = 0;
      let tmp = ["Passed", "Above Average", "BDP"];
      for ( var i = 0; i < this.checkResults.length; i++ ) {
        if ( this.checkResults[i].answers ) {
            pcount++;
          if ( tmp.includes(this.checkResults[i].answers) ) {
            scount++;
          }
        }
      }
      let cal = {
        percentage_cal: (pcount == 0) ? 0 : ((pcount/this.checkResults.length)*100),
        score_cal: (scount == 0) ? 0 : ((scount/this.checkResults.length)*100)
      }
      EventBus.$emit("calculate", cal);
      this.formDataChange();
    },
    formDataChange( event ) {      
      this.results = [];
      for (let i = 0; i < this.checkResults.length; i++) {
        let form_data = {
          question: ( ! this.checkResults[i].question) ? '' : this.checkResults[i].question,
          answers: ( ! this.checkResults[i].answers) ? '' : this.checkResults[i].answers,
          remarks: ( ! this.checkResults[i].remarks) ? '' : this.checkResults[i].remarks
        }
        this.results.push(form_data);
      }
      EventBus.$emit( "checkDetails", this.results );
    },
    deletePerformImage( index, imgsrc, result, imgIndex ) {
      let jsonData = {
        audit_reports: this.checkReportId,
        audit: this.checkId,
        statement: result.public_id,
        file_name: imgsrc.file_name
      }
      HTTP_formdata.post( "auditReports/delete_audit_report_files", jsonData )
        .then(response => {
          if ( response.status == 200 ) {
            this.checkResults[index].images.splice(imgIndex, 1);
          }
        })
        .catch(err => {});
    }
  },
  created() {
    EventBus.$on( "checkStatementData", checklistDetails => {
      this.checkResults = checklistDetails.question;
      this.checkId = checklistDetails.public_id;
      this.checkReportId = checklistDetails.report_id;
      this.onChangeAnswer();
    });
    EventBus.$on( "reportId", resp => {
      this.checkReportId = resp;
    });
    EventBus.$on( "clearMsg", resp => {
      for (let i = 0; i < this.checkResults.length; i++) {
        if ( this.checkResults[i].errors ) {
          this.checkResults[i].errors = ''
        }
      }
    });
  },
  beforeCreate() {
    EventBus.$off("checkStatementData")
    EventBus.$off("reportId")
    EventBus.$off("clearMsg")
  }
};
</script>