var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"hide-footer":"","id":"modal-create-new-check","size":"lg","modal-class":"flipModal manage-user-modal","title":(_vm.statement != '') ? 'Edit check' : 'Create new check',"centered":""},on:{"hidden":_vm.onClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return undefined},proxy:true}])},[_c('div',{staticClass:"def-func-input-group mb-0"},[_c('div',{staticClass:"def-func-input-form-group"},[_c('ValidationObserver',{ref:"observerLocal",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes( _vm.addLocalStatement )}}},[_c('b-row',{staticClass:"justify-content-space-between"},[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Describe check","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"required-field"},[_c('label',[_vm._v("Describe Check to be performed")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.globalData.check),expression:"globalData.check"}],attrs:{"disabled":_vm.statement != '' && _vm.statement.scope == 'Global Mandate',"state":errors[0] ? false : ( valid ? true : null ),"type":"text","placeholder":"Describe Check"},domProps:{"value":(_vm.globalData.check)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.globalData, "check", $event.target.value)}}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"text-center add-media-inner mt-1",attrs:{"md":"12"}},[_c('b-form-checkbox',{attrs:{"disabled":_vm.statement != '',"id":"checkbox-1","name":"checkbox-1","value":"true","unchecked-value":"false"},model:{value:(_vm.globalData.add_to_global),callback:function ($$v) {_vm.$set(_vm.globalData, "add_to_global", $$v)},expression:"globalData.add_to_global"}},[_vm._v("Add Check to Global Database for other Stations to adopt if needed.")])],1),_c('b-col',{staticClass:"def-func-input-group-col mt-5",attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Define Frequency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"required-field"},[_c('b-form-group',{staticClass:"global-freq",attrs:{"label":"Define Frequency (for current checklist only)"}},[_c('b-form-checkbox-group',{staticClass:"add-media-inner",attrs:{"state":errors[0] ? false : ( valid ? true : null ),"id":"checkbox-group-local","name":"flavour-2","options":_vm.frequenciesOptions},model:{value:(_vm.globalData.type),callback:function ($$v) {_vm.$set(_vm.globalData, "type", $$v)},expression:"globalData.type"}})],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"clearfix"}),_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{staticClass:"btn-rename-delete mt-3 float-right",attrs:{"type":"submit","variant":"light"}},[_vm._v("Update")])],1)],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }