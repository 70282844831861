<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class="def-func-input-group briefing-list-filter">
                    <div class="def-func-input-group-col btn-search">
                      <b-input-group>
                        <b-form-input placeholder="Search" v-model="search_parameter"></b-form-input>
                        <b-input-group-append is-text>
                          <font-awesome-icon :icon="['fas', 'search']" />
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix">
              <div class="def-func-table manage-users-table">
                <appVideogrammeTables :rows="rows" :data="trainings"></appVideogrammeTables>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
      ></b-pagination>
      </b-container>
    </div>
  </div>
</template>
<script>
import videogrammeTables from "./videogramme_table";
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  components: {
    appVideogrammeTables: videogrammeTables
  },
  data() {
    return {
      trainings: [],
      search_parameter: "",
      currentPage: 1,
      rows: 0,
      perPage: 10,
    };
  },
  watch:{
    currentPage(newVal) {
      this.get_videogramme(this.currentPage, this.perPage,this.search_parameter);
    },
    search_parameter(newquery) {
      this.currentPage = 1;
      this.trainings = [];
      let URL = "" 
      if ( newquery != "" || newquery != null ) {
        URL = "broadcast/list_broadcast?title=" + newquery + "&limit=" + this.perPage + "&page=" + this.currentPage
      } else {
        URL = "broadcast/list_broadcast?limit=" + this.perPage + "&page=" + this.currentPage
      }
      HTTP_formdata.get(URL)
        .then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.trainings = response.data.data;
          }
          this.rows = response.data.total_length;
        })
        .catch(response => {});
    }
  },
  methods: {
    get_videogramme(pagenumber, limit,newquery) {
      this.trainings = [];
      HTTP_formdata.get("broadcast/list_broadcast?title="+newquery+"&limit="+limit+"&page="+pagenumber)
        .then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.trainings = response.data.data;
          }
          this.rows = response.data.total_length;
        })
        .catch(response => {});
    }
  },
  mounted() {
    this.get_videogramme(this.currentPage, this.perPage, this.search_parameter);
  },
  created() {
    EventBus.$on( "refreshVideogrammeList", response => {
    this.get_videogramme(this.currentPage, this.perPage, this.search_parameter);
    });
  }
};
</script>
<style>
@import "../def_function.css";
</style>