<template>
  <div class="pre-test-question">
    <div class="def-func-input-group-col float-right btn-rename-delete mb-0">
      <b-button type="button" variant="light" class="mt-4" @click.prevent="savemsg">Save</b-button>
    </div>
    <div class="clearfix"></div>
    <div class="brief-rounded-sectn">
      <b-row>
        <b-col>
          <label
            class="distribute-group-label"
          >Setup pre test questions to test users knowledge after each module</label>
        </b-col>
      </b-row>
      <div class="quesn-outer">
        <div class="def-func-input-group">
          <div class="def-func-input-group-col briefing-station-code">
            <b-row>
              <b-col md="3" lg="3" xl="3">
                <div class="required-field">
                  <label>Select Module</label>
                  <b-form-select v-model="selectedModule" :options="moduleOptions"></b-form-select>
                </div>
              </b-col>
              <b-col md="7" lg="7" xl="8">
                <div class="justify-content-space-between">
                  <div class="width-100">
                    <div class="required-field">
                      <label>Question</label>
                      <b-form-input v-model="question" :disabled="!selectedModule"></b-form-input>
                      <div class="attachment-btn">
                        <b-input-group-append is-text>
                          <font-awesome-icon :icon="['fas', 'paperclip']" />
                        </b-input-group-append>
                        <b-form-file accept="image/jpeg, image/png, .jpg, .png, .jpeg" ref="fileInput" id="fileInput"
                        class="faq-fileInput" :disabled="!selectedModule" v-model="url" @change="onFileChange($event)"></b-form-file>
                      </div>
                      <div style="color:red" v-if="noQuestionError"><p v-if="!question.trim()">This field is required</p></div>
                      <p class="feedback-error" v-show="errorIncompleteData">Complete set of previous question</p>
                      <p class="feedback-error" v-show="imgError.valMsg">{{ imgError.errorMsg }}</p>
                    </div>
                  </div>
                  <div class="ml-3 pt-4 mt-3">
                    <b-icon v-b-tooltip.hover.top="'Only jpg, jpeg or png allowed'" icon="info" class="info-icon-size"></b-icon>
                  </div>
                </div>
              </b-col>
              <b-col md="2" lg="2" xl="1">
                <div class="def-func-input-group-col briefing-station-code-btn">
                  <button @click.prevent="addQuestions" :disabled="incompleteData" variant="light" class="btn th-add-btn add-btn">+</button>
                </div>
              </b-col>
              <b-col v-if="imgUpload.length > 0" class="text-center">
                <div class="preview" v-for="( data, imgIndex ) in imgUpload" :key="imgIndex">
                  <img :src="data.url" />
                  <div class="clearfix"></div>
                  <div class="training-questn-col questn-action">
                    <span><img src="@/assets/images/delete.png" @click="onImgDelete(imgIndex)" title="Delete" role="button" tabindex="0"></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <b-row>
        <b-col sm="12">
          <appTrainingQuestion :questions_get="questions" :questCheck="question"></appTrainingQuestion>
        </b-col>
      </b-row>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import training_questions from "./training_questions/training_questions";
import { EventBus } from "../../../../main";
import { HTTP_formdata } from "../../../../constants/http-common";
export default {
  props: ["selected_training", "moduleOptions"],
  components: {
    appTrainingQuestion: training_questions
  },
  data() {
    return {
      noQuestionError: false,
      noPointsError: false,
      exceedScoreError: false,
      totalPoints: 100,
      question: "",
      questions: "",
      points: "",
      remaining_points: "",
      // moduleOptions: [],
      selectedModule: null,
      questiondetails: [],
      messsage_loader: false,
      incompleteData: false,
      errorIncompleteData: false,
      imgUpload: [],
      url: null,
      imgError: {
        valMsg: false,
        errorMsg: ""
      }
    };
  },
  created() {
    EventBus.$on("send-deleted-points", remainingQuestion => {
      this.totalPoints = this.totalPoints + remainingQuestion;
    });
    EventBus.$on("quizIncomplete", incompleteData => {
      this.incompleteData = incompleteData.disable
      this.errorIncompleteData = incompleteData.error
    });
  },
  watch: {
    selectedModule(newVal) {
      this.get_innitial_data();
    }
  },
  methods: {
    onFileChange(e) {
      if (this.imgUpload.length < 3 ) {
        if ( e.target.files.length > 0 && ( e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "png" || e.target.files[0].type == "jpeg" ) ) {
          this.imgError.valMsg = false;
          let file = e.target.files[0];
          this.imgUpload.push({ url: URL.createObjectURL(file), data: file })
        } else {
          this.imgError.valMsg = true;
          this.imgError.errorMsg = "Select a valid image"
          setTimeout(() => {
            this.imgError.valMsg = false;
            this.imgError.errorMsg = ""
          }, 2000);
        }
      } else {
        this.imgError.valMsg = true;
        this.imgError.errorMsg = "Maximum 3 Images allowed"
        setTimeout(() => {
          this.imgError.valMsg = false;
          this.imgError.errorMsg = ""
        }, 2000);
      }
    },
    onImgDelete(imgIndex) {
      this.imgUpload.splice(imgIndex, 1);
      this.url = null;
    },
    savemsg(){
      let response ={
        popup:true,
        popupActive:true,
        msg:"Question added Successfully"
      }
      EventBus.$emit("success_resp", response)
    },
    addQuestions() {
      if (this.question.trim() == "") {
        this.noQuestionError = true;
      } else {
        this.noQuestionError = false;
      }
      if (this.noQuestionError == false) {
        this.messsage_loader = true;
        let jsonData = null;
        if ( this.imgUpload.length > 0) {
          jsonData = new FormData();
          this.imgUpload.forEach(function( data, index ) {
            jsonData.set(`question_file${index + 1}`, data.data);
          });
        }
        HTTP_formdata.post("training/setup_practice_question?question=" + this.question + "&quiz_type=practice&training=" + this.selected_training + "&module=" + this.selectedModule, jsonData)
          .then(response => {
            this.messsage_loader = false;
            this.imgUpload = [];
            this.question=""
            response.data.question.options = [];
            response.data.question.newoptions = "";
            response.data.question.label=true;
            response.data.question.edit=false;
            response.data.question.optionError = false;
            response.data.question.maxOptionError = false;
            response.data.question.noQuestionError = false;
            response.data.question.imgError = false;
            response.data.question.errorMsg = "";
            let question = response.data.question;
            this.totalPoints = this.totalPoints - this.points;
            EventBus.$emit("send-questionair", question);
          })
          .catch(error => { this.messsage_loader = false; });
      }
    },
    get_innitial_data() {
      this.messsage_loader = true;
      this.questions = [];
      let form_data = {
        training: this.selected_training,
        quiz_type: "practice",
        modules: this.selectedModule,
        update: true
      };
      let total = 0;
      HTTP_formdata.post("training/get_all_quiz_questions", form_data)
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].options == null) {
              response.data[i].options = [];
            }
            response.data[i].label=true
            response.data[i].edit=false
            response.data[i].optionError = false;
            response.data[i].maxOptionError = false;
            response.data[i].noQuestionError = false;
            response.data[i].imgError = false;
            response.data[i].errorMsg = "";
          }
          this.questions = response.data;
          this.messsage_loader = false;
        })
        .catch(error => {
          this.messsage_loader = false;
        });
    }
  },
  mounted() {
    this.noQuestionError= false
  }
};
</script>