<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class="def-func-input-group briefing-list-filter">
                    <div class="def-func-input-group-col btn-search">
                      <b-input-group>
                        <b-form-input
                          placeholder="Search"
                          v-model="search_parameter"
                        ></b-form-input>
                        <b-input-group-append is-text>
                          <font-awesome-icon :icon="['fas', 'search']" />
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix">
              <div class="def-func-table manage-users-table">
                <appdatatables :table_info="table_information"></appdatatables>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-container>
    </div>
    <b-modal
      hide-footer
      id="modal-ack-brief"
      size="lg"
      modal-class="flipModal manage-user-modal"
      :title="modelTitle"
      centered
      v-bind:content-class="{ show: modalShow1, modalActive: modalActive1 }"
      @hidden="acknowledgment = false"
    >
      <div>
        <div v-if="show_loader" class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div v-else>
          <div v-if="acknowledge_users_data.permission === 'block' ">
            <div v-if="ackReview.length > 0" class="faq-outer" id="faq-modalView">
              <div class="faq-tablist" role="tablist">
                <b-card no-body v-for="( result, ackindex ) in ackReview" :key="ackindex">
                  <b-card-header header-tag="header" role="tab" @click="ackToggleReset">
                    <b-button href="#" v-b-toggle="'accordion_' + ackindex" variant="info">
                      <div class="justify-content-space-between">
                        <div class="faq-header-col width-1">
                          <div class="faq-active-icon"></div>
                        </div>
                        <div class="faq-header-col width-4">{{ result.station.code }}</div>
                        <div class="faq-header-col width-2">{{ result.station.percentage }}</div>
                      </div>
                    </b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion_'+ackindex" accordion="my-accordion-new" role="tabpanel">
                    <b-card-body class="">
                      <b-card-text>
                        <div class="def-func-table manage-users-table m-0">
                          <b-form-checkbox switch size="lg" v-model="ack_label" class="ack-switch-btn">
                            <span v-if="ack_label">Acknowledged</span><span v-else>Non Acknowledged</span>
                          </b-form-checkbox>
                          <span v-if="ack_label">
                            <table>
                              <thead>
                                <tr><th>Name</th><th>Date</th></tr>
                              </thead>
                              <tbody>
                                <tr v-for="( ackData, ackKey ) in result.station.ack_users" :key="ackKey">
                                  <td class="text-left">{{ ackData.name }}</td>
                                  <td>{{ ackData.acknowledged_on.date | moment }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </span>
                          <span v-else>
                            <table>
                              <thead>
                                <tr><th>Name</th></tr>
                              </thead>
                              <tbody>
                                <tr v-for="( nonAckData, nonAckKey ) in result.station.not_ack_users" :key="nonAckKey">
                                  <td class="text-left">{{ nonAckData.name }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </span>
                        </div>
                        <div class="clearfix"></div>
                      </b-card-text>
                      <div class="clearfix"></div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
            <div v-else>
              <p>{{constants.NO_ACKNOWLEDGE_MESSAGE}}</p>
            </div>
          </div>
          <div v-else>
            <div class="text-center">
              <div v-if="acknowledgment">
                <p>{{constants.BRIEFINGS_ACKNOWLEDGE_LABEL}}</p>
              </div>
              <div v-else-if="acknowledge_users.length > 0">
                <div v-for="(ack_users,index) in acknowledge_users" :key="index">
                  <p>{{constants.USER_FIRST_NAME_LABEL}} {{ack_users.user_name.first}} {{ack_users.user_name.middle}} {{ack_users.user_name.last}}</p>
                  <p>{{constants.USER_ACKNOWLEDGE_ON_LABEL}} {{ack_users.updatedOn.date | moment}}</p>
                </div>
              </div>
              <div v-else>
                <p class="color-text">{{ briefTitle }}</p>
                <b-row align-h="center" class="mt-4">
                  <b-col md="4" class="mt-3">
                    <button @click="acknowledge_briefing" class="btn btn-rename-delete">{{constants.ACKNOWLEDGE_BUTTON}}</button>
                  </b-col>
                  <b-col md="4" class="mt-3">
                    <button class="btn btn-rename-delete" @click="$bvModal.hide('modal-ack-brief')">{{constants.CALCEL_BUTTON}}</button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import datatables from "./tables";
import { HTTP, HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
import { LIST_OF_BRIEFINGS } from "../../../constants/app-constants";
import moment from "moment";

export default {
  data() {
    return {
      constants: LIST_OF_BRIEFINGS,
      acknowledgment: false,
      show_loader: false,
      modelTitle: "",
      rows: 0,
      perPage: 10,
      currentPage: 1,
      table_information: [],
      search_parameter: "",
      modalShow1: false,
      modalActive1: false,
      acknowledge_users: [],
      acknowledge_users_data: {},
      briefTitle: '',
      response: {
        msg: "",
        popup: false
      },
      ackReview: [],
      ack_label: true
    };
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    }
  },
  components: {
    appdatatables: datatables
  },
  created() {
    EventBus.$on("check_acknowledge_users", row_data => {
      this.show_loader = true;
      this.modelTitle = this.constants.ACKNOWLEDGE_USER_MODEL_TITLE;
      this.briefTitle = row_data.title;
      this.acknowledge_users_data = row_data;
      if ( row_data.distribution != 2 ) {
        HTTP.get("briefings/get_briefing/" + row_data.public_id)
          .then(response => {
            this.show_loader = false;
            this.ackReview = response.data[1].acknowledgement_review;
          })
          .catch(error => {
            this.show_loader = false;
            this.ackReview = []
          });
      } else if ( row_data.acknowledgment ) {
        let form_data = {
          briefing: row_data.public_id,
          ack_id: row_data.ack_id
        };
        HTTP_formdata.post("user_briefing_acks/add_acknowledgement", form_data)
          .then(response => {
            this.show_loader = false;
            if ( response.data.code == 200 ) {
              this.show_loader = false;
              this.acknowledge_users = response.data.data;
            }
          })
          .catch(error => {
            this.show_loader = false;
          });
      } else {
        this.show_loader = false;
        this.acknowledge_users = [];
      }
    });
    EventBus.$on("refreshBriefing", refresh => {
      this.get_briefing_data();
    });
  },
  watch: {
    currentPage: function() {
      this.get_briefing_data()
    },
    search_parameter: function() {
      this.currentPage = 1;
      this.get_briefing_data();
    }
  },
  methods: {
    acknowledge_briefing() {
      let index = this.acknowledge_users_data.index;
      EventBus.$emit("briefings-acknowlwdged", index);
      let form_data = {
        briefing: this.acknowledge_users_data.public_id,
        ack_id: this.acknowledge_users_data.ack_id
      };
      HTTP_formdata.post("user_briefing_acks/add_acknowledgement", form_data)
        .then(response => {
          if ( response.status == 200 ) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-ack-brief");
            });
            this.response.msg = response.data.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
          }
        })
        .catch(error => {});
    },
    get_briefing_data() {
      this.table_information = [];
      let paginate_params = {
        title: this.search_parameter ? this.search_parameter : "",
        page: this.currentPage,
        limit: "10"
      };
      HTTP_formdata.post("briefings/search_briefings", paginate_params)
        .then(Response => {
          this.table_information = Response.data.data;
          this.rows = Response.data.total_records;
          return this.table_information;
        })
        .catch(error => {});
    },
    ackToggleReset() {
      this.ack_label = true;
    }
  },
  beforeMount() {
    this.get_briefing_data();
  }
};
</script>
<style>
@import "../def_function.css";
.ack-switch-btn.custom-control.custom-switch {
  padding-left: 3rem;
}
</style>