<template>
  <div>
    <div class="content-outer" :class="( $route.name && $route.name == 'incidentExternalAction' ) ? ' m-0 p-0' : ''">
      <b-container>
        <b-row v-if="( $route.name && $route.name == 'incidentExternalAction' )">
          <b-col cols="12">          
            <img class="login-logo incident-logo" :src="shopTalkImg"/>
          </b-col>
          <b-col cols="12"> 
            <div class="incident-title"><h2 class="login-title mb-0 text-center">Incident Action</h2></div>         
          </b-col>
        </b-row>
        <b-row v-if="validToken" class="incidentActionContainerOuter">
          <b-col md="8" class="incidentActionContainer">
            <span class="dTitle title">Title :</span>
            <span class="dData">{{ incidentData.title }}</span>
          </b-col>
          <b-col md="4" class="incidentActionContainer">
            <div><label class="dTitle sev">Severity :</label></div>
            <b-form-select v-if="!hideFeedbackForm && pageType == 'incident-action'" v-model="feedbackData.severity" :options="severityOption" :disabled="( $route.name && $route.name == 'incidentExternalAction' )"></b-form-select>
            <span v-else class="dData">{{ feedbackData.severity }}</span>
          </b-col>
          <b-col md="4" class="incidentActionContainer">
            <span class="dTitle">Category :</span>
            <span class="dData">{{ incidentData.category }}</span>
          </b-col>
          <b-col md="4" class="incidentActionContainer">
            <span class="dTitle station">Station :</span>
            <span class="dData">{{ incidentData.station }}</span>
          </b-col>
          <b-col md="4" class="incidentActionContainer">
            <span class="dTitle">Reported By :</span>
            <span class="dData">{{ incidentData.reportedBy }}</span>
          </b-col>
          <b-col md="12" class="incidentActionContainer def-func-input-form-group def-func-input-group">
            <span class="dTitle desc">Description :</span>
            <span class="dData">{{ incidentData.description }}</span>
          </b-col>
          <b-col md="12" class="incidentActionContainer attCol" v-if="incidentData.attachments.length > 0">
            <div class="att"><span class="dTitle">Attachement :</span></div>
            <div>
              <b-row class="incidentActionImages">
                <b-col xl="1" lg="2" md="2" sm="3" cols="4" class="imgcard" v-for="( imgData, imgIndex ) in incidentData.attachments" :key="imgIndex">
                  <b-card :img-src="imgData.path" img-alt="Card image" img-top class="mb-3">
                    <div class="attachmentImghover">
                      <b-icon v-if="imgData.format != 'application/pdf'" v-b-modal="'attachmentModal-' + imgIndex" icon="eye-fill" variant="#0e3d69"></b-icon>
                      <a :href="imgData.fileURL" download rel="noopener noreferrer" target="_blank">
                        <img src="@/assets/images/download-blue.png" title="Download" />
                      </a>
                      <b-modal :id="'attachmentModal-' + imgIndex" title="Attachments" hide-footer modal-class="flipModal manage-user-modal attModal">
                        <img :src="imgData.path" title="Attachment" />
                      </b-modal>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="validToken">
          <b-col>
            <div class="def-func-input-form-group">
              <div class="def-func-input-group mt-4 mb-0">
                <div class="def-func-input-group-col width-12">
                  <label class="global-freq freq-resp-title">Incident Progress & Updates</label>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="def-func-table manage-users-table">
              <table>
                <thead>
                  <tr>
                    <th class="text-left"><span> Date </span></th>
                    <th class="text-left"><span> Feedback Provided By Manager/User </span></th>
                    <th class="text-center"><span> Status </span></th>
                    <th class="text-left"><span> Manager/User Name </span></th>
                    <th class="text-left"><span> Assigned To </span></th>
                    <th class="text-center"><span> Attachment </span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="( row, index ) in feedbackDetails" :key="index">
                    <td class="text-left">{{ row.feedback_date | moment }}</td>
                    <td class="text-left">{{ row.comments }}</td>
                    <td class="text-center">{{ row.status }}</td>
                    <td class="text-left">{{ row.assigned_by }}</td>
                    <td class="text-left">{{ row.assigned_to }}</td>
                    <td class="text-center">
                      <b-icon v-if="row.attachments.length > 0" icon="eye" v-b-modal="'feedbackAttachment-' + index" />
                      <b-modal :id="'feedbackAttachment-' + index" title="Attachments" modal-class="flipModal manage-user-modal" hide-footer centered>
                        <template>
                          <div>
                            <b-carousel :id="'carousel-' + index" v-model="slide" :interval="4000" controls indicators
                                background="#ababab" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;">
                              <b-carousel-slide v-for="( data, key ) in row.attachments" :key="key" :img-src="data.path" :img-blank="( data.format == 'application/pdf' ) ? true : false" :class="( data.format == 'application/pdf' ) ? 'pdf-button-container' : ''">
                                <template v-if="data.format == 'application/pdf'">
                                  <a :href="data.fileURL" target="_blank" download><b-button class="pdf-button"><img src="@/assets/images/add-pdf.png" title="Download" class="pdf-button-img"/> Download</b-button></a>
                                </template>
                              </b-carousel-slide>
                            </b-carousel>
                          </div>
                        </template>
                      </b-modal>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <div class="def-func-form" v-if="!hideFeedbackForm && pageType == 'incident-action' && validToken">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes( submitFeedback )">
              <div class="def-func-input-form-group mb-0">
                <b-row>
                  <b-col>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12" class="issue-editor">
                          <ValidationProvider name="Managers Comments" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Manager's/User's Comments</label>
                            </div>
                            <b-form-textarea class="textarea-no-resize"
                              placeholder="Manager's/User's Comments" rows="7" max-rows="10" v-model="feedbackData.comments" no-resize
                              :state="errors[0] ? false : ( valid ? true : null )"></b-form-textarea>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="6">
                          <ValidationProvider name="Status" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Status</label>
                            </div>
                            <b-form-select :state="errors[0] ? false : ( valid ? true : null )" v-model="feedbackData.status"
                              :options="statusOption" :disabled="( $route.name && $route.name == 'incidentExternalAction' )"></b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                          <div class="required-field label-right">
                            <label>Assign to</label>
                            <div>
                              <b-form-checkbox v-model="feedbackData.assignToType" :disabled="( $route.name && $route.name == 'incidentExternalAction' ) || feedbackData.status == 'closed'" class="check-user" name="user-type" switch size="md"></b-form-checkbox>
                              <label v-if="feedbackData.assignToType">Manager</label>
                              <label v-else>External User</label>
                            </div>
                          </div>
                          <ValidationProvider name="Assign to" :rules="`${ ( feedbackData.status == 'closed' ) ? '' : ( feedbackData.assignToType ) ? 'required' : 'required|email' }`" v-slot="{ valid, errors }">
                            <b-form-select v-if="feedbackData.assignToType" :options="assignToOption" :disabled="feedbackData.status == 'closed' || ( $route.name && $route.name == 'incidentExternalAction' )" :state="errors[0] ? false : ( valid ? true : null )" v-model="feedbackData.assign_to"></b-form-select>
                            <span v-else>
                              <typeaheadInternalUser :api="( incidentData.reportedById ) ? 'incident_report_feedbacks/internal_users?station=' + incidentData.stationId + '&reported_by=' + incidentData.reportedById + '&' : 'incident_report_feedbacks/internal_users?station=' + incidentData.stationId + '&' "></typeaheadInternalUser>
                              <b-form-input v-show="false" disabled :state="errors[0] ? false : ( valid ? true : null )" v-model="feedbackData.externalEmail"></b-form-input>
                            </span>
                            <b-form-invalid-feedback class="d-block">{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12" class="attachment-issue">
                          <div class="justify-content-space-between">
                            <div class="width-100">
                              <ValidationProvider name="Attachment" rules="ext:jpg,png,jpeg,pdf" v-slot="{ errors }">
                                <label>Attachment</label>
                                <b-form-file :file-name-formatter="displayFileName" v-model="imgPath" placeholder="Choose a file or drop it here..." ref="attachments" id="attachments" :state="errorMsg ? false : (imgError ? true : null)"
                                  accept="image/jpeg, image/png, .jpg, .png, .jpeg, .pdf, application/pdf" @input="onFileChange( $event )" drop-placeholder="Drop file here..."></b-form-file>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                <p class="feedback-error" v-show="imgError && !errors[0]">{{ errorMsg }}</p>
                              </ValidationProvider>
                            </div>
                            <div class="ml-3 pt-4 mt-3">
                              <b-icon v-b-tooltip.hover.top="'Only pdf, jpg, jpeg, png allowed'" icon="info" class="info-icon-size"></b-icon>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="attachments.length > 0">
                      <b-row class="reportIncidentImages">
                        <b-col md="2" sm="3" cols="4" v-for="( imgData, imgIndex ) in attachments" :key="imgIndex">
                          <b-card :img-src="imgData.path" :img-alt="( imgData.format == 'application/pdf' ) ? 'PDF' : 'Image'" img-top class="mb-3">
                            <b-card-footer class="float-right" @click="onImgDelete( imgIndex )">
                              <img thumbnail src="@/assets/images/delete.png" title="Delete" role="button">
                            </b-card-footer>
                          </b-card>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-form-group">
                      <div class="clearfix"></div>
                      <div class="w-100">
                        <b-button type="submit" variant="light" class="float-right btn-rename-delete">Update</b-button>
                        <b-button v-if="( $route.name && $route.name == 'incidentExternalAction' )" type="button" variant="light" @click="submitFeedback( 'save' )" class="float-right btn-rename-delete mr-4">Save</b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
        <div v-if="!validToken" class="outer_reset outer_404">
          <div class="inner_404">
            <div class="title_404">Invalid Token</div>
            <div class="footer_404">
              <div class="body_404">Redirecting to login page in <strong>{{secondsTimer + 1 | secondText }}</strong></div>
              <b-progress :max="max" height="1.5rem" animated striped>
                <b-progress-bar :value="timerValue" class="progress-label">
                </b-progress-bar>
              </b-progress>
            </div>
          </div>
        </div>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <successmsg></successmsg>
    <errormsg></errormsg>
  </div>
</template>
<script>
  import moment from "moment";
  import { HTTP, HTTP_formdata } from "../../../constants/http-common";
  import { BASE_URL_VER } from "../../../constants/app-constants";
  import { EventBus } from "../../../main";
  import typeaheadInternalUser from "../../../components/admin_layouts/dashboard_layouts/typeahead_internal_user";
  export default {
    data() {
      return {
        incidentData: {
          title: "",
          category: "",
          station: "",
          description: "",
          reportedBy: "",
          attachments: [],
          stationId: "",
          reportedById: null
        },
        messsage_loader: false,
        feedbackData: {
          incident_public_id: this.$route.params.incidentId,
          severity: null,
          comments: "",
          status: null,
          assign_to: null,
          attachment: false,
          externalEmail: '',
          assignToType: true
        },
        imgPath: null,
        imgError: "",
        errorMsg: "",
        attachments: [],
        feedbackDetails: [],
        severityOption: [{ value: null, text: "Select", disabled: true }, { value: "High", text: "High" }, { value: "Medium", text: "Medium" }, { value: "Low", text: "Low" }],
        statusOption: [{ value: null, text: "Select", disabled: true }, { value: "reassigned", text: "Re-assigned" }],
        assignToOption: [],
        feedbackId: "",
        slide: 0,
        pageType: this.$route.meta.incident_page,
        hideFeedbackForm: false,
        validToken: true,
        secondsTimer: 4,
        timerValue: 0,
        max: 5,
        timer: null,
        shopTalkImg: process.env.VUE_APP_SHOPTALK,
        savedData: ""
      };
    },
    components: {
      typeaheadInternalUser
    },
    mounted() {
      if ( this.$route.name && this.$route.name == 'incidentExternalAction' ) {
        this.messsage_loader = true
        let jsonData = {
          token: this.$route.params.incidentToken
        };
        axios
          .post( BASE_URL_VER + "users/check_token_validity", jsonData )
          .then(result => {
            this.messsage_loader = false;
            if ( result.data.code == 200 ) {
              this.validToken = true;
              this.incidentDetails();
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            if ( err.response.status == 400 ) {
              this.validToken = false;
                this.timer = setInterval(() => {
                  if ( this.secondsTimer <= -1 ) {
                    clearInterval(this.timer);
                    this.$router.push({path:"/login"})
                  }
                  this.timerValue = this.max - this.secondsTimer;
                  this.secondsTimer -= 1;
                }, 1000)
            }
          });
      } else
        this.incidentDetails();
    },
    watch: {
      "feedbackData.status": {
        handler( val ) {
          this.feedbackData.assign_to = ( val == 'closed' ) ? null : this.feedbackData.assign_to;
          this.feedbackData.assignToType = ( val == 'closed' ) ? true : this.feedbackData.assignToType;
          deep: true;
        }
      },
      "feedbackData.assignToType": {
        handler( val ) {
          this.feedbackData.externalEmail = "";
          this.feedbackData.assign_to = null;
          this.$nextTick(() => {
            if (this.$refs.observer) {
              this.$refs.observer.reset();
            }
          });
          deep: true;
        }
      }
    },
    filters: {
      moment: function( value ) {
        return moment( value ).format( "DD MMM YYYY" );
      },
      secondText(val) {
        return ( val == 1 || val == 0 ) ? val + " second" : val + " seconds"
      }
    },
    methods: {
      incidentDetails() {
        this.messsage_loader = true;
        // HTTP.get( "incident_report/display/" + this.feedbackData.incident_public_id + "?page_name=" + this.pageType )
        let jsonData = {
          flag: ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? 'external' : 'internal',
          page_name: this.pageType,
          token: ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? this.$route.params.incidentToken : ""
        }
        let api = ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? axios.post( BASE_URL_VER + "incident_report/display/" + this.feedbackData.incident_public_id, jsonData ) : HTTP_formdata.post( "incident_report/display/" + this.feedbackData.incident_public_id, jsonData )
          api.then( response => {
            if ( response.status == 200 )  {
              let resp = response.data[0]
              this.messsage_loader = false;
              this.feedbackData.severity = resp.severity;
              if ( resp.flag == 1 )
                this.statusOption.push({ value: "closed", text: "Closed" })
              else if ( resp.flag == 0 )
                this.statusOption.push({ value: "resolved", text: "Resolved" })
              this.assignToOption = [{ value: null, text: "Select", disabled: true }]
              for ( let i = 0; i < resp.assign_to.length; i++ ) {
                this.assignToOption.push({
                  value: resp.assign_to[i].value,
                  text: resp.assign_to[i].text
                });
              }
              if ( this.$route.name && this.$route.name == 'incidentExternalAction' ) {
                this.feedbackData.status = this.statusOption[1].value;
                this.feedbackData.assign_to = (this.assignToOption.length > 1) ? this.assignToOption[1].value : null;
              }
              this.feedbackDetails = resp.feedback;
              this.incidentData = {
                title: resp.title,
                category: resp.category,
                station: resp.station,
                description: resp.description,
                reportedBy: ( resp.reported_by_name ) ? resp.reported_by_name : "Anonymous",
                attachments: resp.attachments,
                stationId: resp.station_public_id,
                reportedById: resp.reported_by_id
              }
              this.incidentData.attachments.forEach((val) => {
                val.fileURL = val.path;
                val.path = ( val.format == 'application/pdf' ) ? require(`@/assets/images/add-pdf.png`) : val.path;
              });
              this.savedData = resp.saved;
              if ( this.savedData != null ) {
                this.feedbackData.comments = this.savedData.comments;
                this.attachments = this.savedData.attachments;
              }
              this.hideFeedbackForm = ( resp.assign_to.length == 0 && this.$route.name == 'incidentExternalAction' ) ? true : false;
            }
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 403 ) {
              if ( this.$route.name && this.$route.name == 'incidentExternalAction' )
                this.$router.push({ path: "/login" })
              else
                this.$router.push({ path: "/my-incidents" })
            }
          });
      },
      onImgDelete( imgIndex ) {
        if ( !( 'data' in this.attachments[imgIndex] ) ) {
          HTTP_formdata.delete( "incident_report_feedbacks/delete_attachments/" + this.savedData.saved_feedback_id + "?file=" + this.attachments[imgIndex].file )
            .then(response => {
              this.attachments.splice(imgIndex, 1);
            })
            .catch(err => {});
        } else
          this.attachments.splice(imgIndex, 1);
      },
      onFileChange( e ) {
        if (this.attachments.length < 10 ) {
          if ( e && ( e.type == "image/png" || e.type == "image/jpeg" || e.type == "png" || e.type == "jpeg" || e.type == "pdf" || e.type == "application/pdf" ) ) {
            this.imgError = false;
            let file = e;
            let path = ( e.type == "application/pdf" ) ? require(`@/assets/images/add-pdf.png`) : URL.createObjectURL(file);
            this.attachments.push({ path: path, data: file, name: "", format: e.type })
          } else {
            this.imgError = true;
            this.errorMsg = "Select a valid file"
            setTimeout(() => {
              this.imgError = false;
              this.errorMsg = ""
            }, 2000);
          }
        } else {
          this.imgError = true;
          this.errorMsg = "Maximum 10 attachments allowed"
          setTimeout(() => {
            this.imgError = false;
            this.errorMsg = ""
          }, 2000);
        }
        this.imgPath = null;
        document.getElementById('attachments').value = ''
      },
      displayFileName( files ) {
        return "Choose a file or drop it here..." ;//( this.attachments.length < 10 ) ? ( !this.imgError ) ? files[0].name : "Choose a file or drop it here..." : "Choose a file or drop it here..."
      },
      submitFeedback( action ) {
        this.messsage_loader = true;
        this.feedbackData.attachment = ( this.attachments.length == 0 ) ? false : true;
        let feedbackJson = {
          incident_public_id: this.feedbackData.incident_public_id,
          severity: this.feedbackData.severity,
          comments: this.feedbackData.comments,
          status: this.feedbackData.status,
          assign_to: ( this.feedbackData.assignToType ) ? this.feedbackData.assign_to : '',
          external_user: ( this.feedbackData.assignToType ) ? '' : this.feedbackData.externalEmail,
          attachment: this.feedbackData.attachment,
          flag: ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? 'external' : 'internal',
          token: ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? this.$route.params.incidentToken : "",
          incident_feedback_saved: ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? ( this.savedData === null ) ? this.savedData : this.savedData.saved_feedback_id : null,
          update: ( action ) ? false : true
        }
        // if ( this.feedbackData.status == 'closed' ) { feedbackJson.assign_to = ( this.feedbackDetails.length > 0 ) ? this.assignToOption[1].value : null }
        // HTTP_formdata.post( "incident_report_feedbacks/add", feedbackJson )
        let api = ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? axios.post( BASE_URL_VER + "incident_report_feedbacks/add", feedbackJson ) : HTTP_formdata.post( "incident_report_feedbacks/add", feedbackJson )
          api.then( resp => {
            if ( resp.status == 200 ) {
              if ( this.feedbackData.assignToType && resp.data.assigned_to_group != null ) {
                this.feedbackId = resp.data.public_id;
                this.updatePermission( resp.data.assigned_to_group, feedbackJson.update, feedbackJson.flag );
              } else if ( this.attachments.length > 0 ) {
                this.feedbackId = resp.data.public_id;
                this.attachmentUpload( feedbackJson.update, feedbackJson.flag );
              } else {
                this.messsage_loader = false;
                let respPopUp = {
                  msg: resp.data.message,
                  popup: true
                }
                EventBus.$emit( "success_resp", respPopUp );
                setTimeout(() => {
                  if ( this.$route.name && this.$route.name == 'incidentExternalAction'  ) {
                    this.hideFeedbackForm = ( feedbackJson.update ) ? true : false;
                    this.incidentDetails();
                  } else
                    this.$router.push({ path: "/my-incidents" });
                }, 2000);
              }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 ) {
              this.messsage_loader = false;
              let errorResp = {
                msg: error.response.data.message,
                popup: true
              };
              EventBus.$emit( "error_resp", errorResp );
            }
          });
      },
      async attachmentUpload( update, flag ) {
        let formData = new FormData();
        for ( let index = 0; index < this.attachments.length; index++ ) {
          if ( 'data' in this.attachments[index] ) {
            formData.set( "attachment", this.attachments[index].data );
            let last_file = ( index+1 == this.attachments.length ) ? true : false;
            // HTTP_formdata.post( "incident_report_feedbacks/upload_attachments/" + this.feedbackId + "?last_file=" + last_file + "&update=" + update + "&flag=" + flag, formData )
            let api = ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? axios.post( BASE_URL_VER + "incident_report_feedbacks/upload_attachments/" + this.feedbackId + "?last_file=" + last_file + "&update=" + update + "&flag=" + flag, formData ) : HTTP_formdata.post( "incident_report_feedbacks/upload_attachments/" + this.feedbackId + "?last_file=" + last_file + "&update=" + update + "&flag=" + flag, formData )
            await api.then(response => {
                if ( index + 1 == this.attachments.length ) {
                  this.messsage_loader = false;
                  let respPopUp = {
                    msg: response.data.message,
                    popup: true
                  }
                  EventBus.$emit( "success_resp", respPopUp );
                  setTimeout(() => {
                    if ( this.$route.name && this.$route.name == 'incidentExternalAction' ) {
                      this.hideFeedbackForm = ( update ) ? true : false;
                      this.incidentDetails();
                    } else
                      this.$router.push({ path: "/my-incidents" })
                  }, 2000);
                }
              })
              .catch(error => {
                if ( error.response.status == 400 ) {
                  this.messsage_loader = false;
                  let errorResp = {
                    msg: error.response.data.message,
                    popup: true
                  };
                  EventBus.$emit( "error_resp", errorResp );
                }
              });
          } else {
            if ( index + 1 == this.attachments.length ) {
              this.messsage_loader = false;
              let respPopUp = {
                msg: "Feedback saved successfully",
                popup: true
              }
              EventBus.$emit( "success_resp", respPopUp );
              setTimeout(() => {
                if ( this.$route.name && this.$route.name == 'incidentExternalAction' ) {
                  this.hideFeedbackForm = ( update ) ? true : false;
                  this.incidentDetails();
                } else
                  this.$router.push({ path: "/my-incidents" })
              }, 2000);
            }
          }
        }
      },
      updatePermission( assignToGroup, update, flag ) {
        // HTTP_formdata.post( "incident_reports/access_rights", { group: assignToGroup } )
        let api = ( this.$route.name && this.$route.name == 'incidentExternalAction' ) ? axios.post( BASE_URL_VER + "incident_reports/access_rights", { group: assignToGroup } ) : HTTP_formdata.post( "incident_reports/access_rights", { group: assignToGroup } )
          api.then(response => {
            if ( response.status == 200 ) {
              if ( this.attachments.length > 0 )
                this.attachmentUpload( update, flag );
              else {
                this.messsage_loader = false;
                let respPopUp = {
                  msg: response.data.message,
                  popup: true
                }
                EventBus.$emit( "success_resp", respPopUp );
                setTimeout(() => {
                  if ( this.$route.name && this.$route.name == 'incidentExternalAction' ) {
                    this.hideFeedbackForm = ( update ) ? true : false;
                    this.incidentDetails();
                  } else
                    this.$router.push({ path: "/my-incidents" })
                }, 2000);
              }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 ) {
              this.messsage_loader = false;
              let errorResp = {
                msg: error.response.data.message,
                popup: true
              };
              EventBus.$emit( "error_resp", errorResp );
            }
          });
      }
    },
    created() {
      EventBus.$on("typeahead_search_internal_user", users => {
        this.feedbackData.externalEmail = users.username;
      });
    }
  };
</script>
<style>
  @import "../def_function.css";
  @import "../dashboard.css";

  .incident-logo{max-width: 315px;margin: 30px auto;}
  .incident-title{margin: auto;width: max-content;margin-bottom: 50px;}
  .label-right {display: flex; justify-content: space-between;}
  .dashboard-text {color: #333;font-size: 22px; margin: 5% 0;}
  .dashboard-text img {max-width: 35px;margin-top: -5px;}
  .info-span {color: #999;padding: 5px;}
  .issue-editor .ck.ck-content.ck-editor__editable {min-height: 200px;}
  .def-func-input-form-group .attachment-issue .custom-file label {min-height: 40px;border: 1px solid #a9a9a9;border-radius: 5px;padding: 10px;}
  .def-func-input-form-group .attachment-issue .custom-file label::after {height: 38px;padding: 8px 20px;font-size: 16px;background: #003261;color: white;font-weight: bold;letter-spacing: 0.03rem;}
  .textarea-no-resize {overflow-y: auto !important;}
  .incidentActionContainer {display: flex;margin-bottom: 20px;}
  .incidentActionContainer .dData {font-size: 16px; color:#003261;}
  .dTitle.desc{min-width: 102px;}
  .dTitle.sev{min-width: 75px;}
  .dTitle.title{min-width: 45px;}
  .dTitle.station{min-width: 65px;}
  .incidentActionContainer .dTitle {color: grey;font-size: 16px;margin-right: 10px;}
  .attachmentImghover svg {font-size: 20px;color: #0e3d69; cursor:pointer; outline: none;}
  .attachmentImghover img {width: 20px; cursor:pointer; outline: none;}
  .attachmentImghover{position: absolute;top: 50%;left: 50%;white-space: nowrap;transform: translate(-50%,-50%);}
  .attachmentData {display: flex;justify-content: space-between;}
  .attCol{flex-direction: column;}
  .att{display: flex;margin-bottom: 10px;}
  .incidentActionImages .card{border: 1px solid #e8ecf1; border-radius: 5px; padding: 5px;}
  .incidentActionImages .card-body{padding: 0;background-color: white;}
  .incidentActionImages [class*=col-]:first-child{margin-left: 8.333333%;}
  .incidentActionImages .card-footer img{width: 12px; outline: none;}
  .carousel-caption h3{font-size: 12px; }
  .attachmentImghover{display: none;}
  .incidentActionImages .card:hover .attachmentImghover{display: block;    min-width: 60px;display: flex;justify-content: space-around;}
  .incidentActionImages .card:hover > img{opacity: 0.4;}
  .incidentActionContainer2 {display: flex;flex-direction: column;margin-bottom: 20px;}
  .attModal .modal-body{text-align: center;}
  .attModal img{ width: 100%;}
  .reportIncidentImages [class*=col-]:first-child,.reportIncidentImages [class*=col-]:nth-child(6n) {margin-left: 8.333333%;}
  .incidentActionImages [class*=col-]:first-child{margin-left: 8.333333%;}
  .outer_reset .inner_404 {position: relative; top: initial; transform: translateY(0); padding: 25px 30px 0;}
  .outer_reset .btn {background: white; border: 1px solid #003261; border-radius: 5px; color: #031d38; padding: 0 15px; height: 40px; position: relative;}
  .progress-bar.progress-label {flex-direction: initial; padding: 3px; font-size: 15px; background-color: #003261;}
  .progress-bar.progress-label strong {line-height: 24px; padding: 0 1px 0 5px;}
  .outer_reset .body_404 {margin: 30px 0 20px;}
  .outer_reset .body_404 strong { font-size: 20px; }
  .title_404 {font-size: 30px; letter-spacing: 0px;}
  .pdf-button-container{background-color: #ebebeb !important;}
  .pdf-button{font-size: 16px;background-color: #003261;color: white;position: relative;padding-left: 50px;min-width: 160px;border: none;box-shadow: 2px 2px 4px #8b8b8b;}
  .pdf-button-img{width: 46px;background-color: white;padding: 6px;border-radius: 4px;box-shadow: 2px 2px 4px #8b8b8b;position: absolute;left: -4px;top: -8px;}
  .pdf-button-container .carousel-caption{bottom: 32%;}
  @media only screen and (max-width: 1200px){
    .incidentActionImages [class*=col-]:nth-child(6n) {margin-left: 8.333333%;}
  }
  @media only screen and (max-width: 768px){
    .reportIncidentImages [class*=col-]:first-child,.reportIncidentImages [class*=col-]:nth-child(6n) {margin-left: 0;}
    .incidentActionImages [class*=col-]:first-child,.incidentActionImages [class*=col-]:nth-child(6n) {margin-left: 0;}
  }
</style>