<template>
  <div class="outer_404">
    <appheader></appheader>
    <div class="inner_404">
      <div class="title_404">404</div>
      <div class="subTitle_404">Oops! Page not found</div>
      <div
        class="body_404"
      >Sorry, but the page you are looking is not found. Please, make sure you have typed the correct URL.</div>
      <div class="footer_404">
        <button class="btn th-add-btn" @click="backToHome">Back to Dashboard</button>
      </div>
    </div>
  </div>
</template>
<script>
import header from "../../../src/components/admin_layouts/header";
export default {
  components: {
    appheader: header
  },
  methods: {
    backToHome() {
      this.$router.push({path:"/"})
    }
  }
};
</script>
<style scoped>
@import "./def_function.css";
@import "./404.css";
</style>