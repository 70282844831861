<template>
  <div class="def-func-input-form-group">
    <b-container>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <b-form @submit.prevent="passes( addGlobalChecklist )">
          <b-row>
            <b-col>
              <div class="def-func-input-group">
                <b-row>
                  <b-col class="def-func-input-group-col" sm="5">
                    <ValidationProvider
                      name="Describe check"
                      rules="required"
                      v-slot="{ valid, errors }"
                    >
                      <div class="required-field">
                        <label>Describe Check to be performed</label>
                      </div>
                      <b-form-input
                        :state="errors[0] ? false : ( valid ? true : null )"
                        v-model="globalChecklistData.statement" placeholder="Describe Check"
                      />
                      <!-- <input type="text" :state="errors[0] ? false : ( valid ? true : null )" v-model="globalChecklistData.statement" /> -->
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                  <b-col class="def-func-input-group-col" sm="3">
                    <ValidationProvider name="Function" rules="required" v-slot="{ valid, errors }">
                      <div class="required-field">
                        <label>Functional Relevance</label>
                      </div>
                      <b-form-select
                        class="mb-3"
                        :state="errors[0] ? false : ( valid ? true : null )"
                        @change="onChangeFunction( $event )"
                        v-model="globalChecklistData.function"
                        :options="functionResult"
                      ></b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                  <b-col class="def-func-input-group-col" sm="4">
                    <b-form-checkbox
                      :disabled="globalChecklistData.function == null"
                      id="checkbox-1"
                      name="checkbox-1"
                      value="true"
                      unchecked-value="false"
                      v-model="globalChecklistData.mandatory"
                    >Include in Checklist</b-form-checkbox>
                    <dropdownField
                      :name="'rec_time'"
                      :rules="'required'"
                      :options="checklistOptions"
                      :error_message="'Checklist'"
                      :disabled_toggle="globalChecklistData.mandatory == false"
                    ></dropdownField>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="def-func-input-group global-checklist-freq">
                <b-row>
                  <b-col class="def-func-input-group-col" sm="11">
                    <ValidationProvider
                      name="Define frequency"
                      rules="required"
                      v-slot="{ valid, errors }"
                    >
                      <div>
                        <div class="required-field" style="display: flex;"><label><span>Define frequency</span></label>:</div><hr class="mt-0" />
                        <b-form-group class="global-freq">
                          <b-form-checkbox-group
                            id="checkbox-group-2"
                            name="flavour-2"
                            class="add-media-inner"
                            :state="errors[0] ? false : ( valid ? true : null )"
                            v-model="globalChecklistData.type"
                          >
                            <b-form-checkbox value="Daily">Daily</b-form-checkbox>
                            <b-form-checkbox value="Weekly">Weekly</b-form-checkbox>
                            <b-form-checkbox value="Monthly">Monthly</b-form-checkbox>
                            <b-form-checkbox value="Annual">Annual</b-form-checkbox>
                          </b-form-checkbox-group>
                        </b-form-group>
                      </div>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                  <b-col class="def-func-input-group-col global-checklist-add" sm="1">
                    <div class="def-func-input-group-col btn-add-outer">
                      <button type="submit" variant="light" class="btn add-btn">+</button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-container>
  </div>
</template>
<script>
import dropdownField from "./fields/static_required_dropdown_field";
import { HTTP_formdata, HTTP } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  data() {
    return {
      checklistOptions: [
        {
          value: null,
          text: "Select",
          disabled: true
        }
      ],
      functionResult: [
        {
          value: null,
          text: "Select",
          disabled: true
        }
      ],
      globalChecklistData: {
        statement: "",
        function: null,
        mandatory: false,
        audit: null,
        type: []
      }
    };
  },
  watch: {
    "globalChecklistData.mandatory"(newQuery) {
      this.globalChecklistData.mandatory = eval(newQuery);
      if ( this.globalChecklistData.mandatory == false ) {
        EventBus.$emit( "setData", null );
        this.globalChecklistData.audit = null;
      }
    }
  },
  components: {
    dropdownField
  },
  beforeMount() {
    this.getFuncList();
  },
  methods: {
    getFuncList() {
      HTTP.get("functions/display_functions")
        .then(response => {
          for (var i = 0; i < response.data.length; i++) {
            this.functionResult.push({
              value: response.data[i].public_id,
              text: response.data[i].title
            });
          }
        })
        .catch(err => {});
    },
    addGlobalChecklist() {
      let messsage_loader = true;
      EventBus.$emit( "loader", messsage_loader );
      HTTP_formdata.post( "audits/create_audit_statements", this.globalChecklistData )
        .then(response => {
          this.globalChecklistData.statement = "";
          this.globalChecklistData.audit = null;
          this.checklistOptions = [{
            value: null,
            text: "Select",
            disabled: true
          }];
          this.globalChecklistData.function = null;
          this.globalChecklistData.mandatory = false;
          this.globalChecklistData.type = [];
          this.$nextTick(() => {
            this.$refs.observer.reset();
          });
          response.messsage_loader = false;
          EventBus.$emit( "addGlobal", response );
        })
        .catch(error => {});
    },
    onChangeFunction(event) {
      let jsonData = {
        function: this.globalChecklistData.function
      };
      HTTP_formdata.post("audits/get_audits", jsonData)
        .then(response => {
          this.checklistOptions = [
            {
              value: null,
              text: "Select",
              disabled: true
            }
          ];
          this.globalChecklistData.audit = null;
          for (var i = 0; i < response.data.length; i++) {
            this.checklistOptions.push({
              value: response.data[i].public_id,
              text: response.data[i].title
            });
          }
        })
        .catch(error => {});
    }
  },
  created() {
    EventBus.$on("send_static_dropdown_value", data => {
      this.globalChecklistData.audit = data.data_val;
    });
  },
  beforeCreate() {
    EventBus.$off("send_static_dropdown_value")
  }
};
</script>