<template>
  <div>
    <table>
      <thead>
        <tr>
          <th class="text-left">
            <span>Video Title</span>
          </th>
          <th class="text-left">
            <span>Description</span>
          </th>
          <th class="text-left">
            <span>Created By</span>
          </th>
          <th class="text-left">
            <span>Date</span>
          </th>
          <th class="text-center">
            <span>Actions</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="rows > 0">
        <tr v-for="(row,index) in data" :key="'video_'+index"> 
          <td class="text-left">{{ row.title}}</td>
          <td class="text-left" style="width: 50%;">{{ row.description}}</td>
          <td class="text-left">{{ row.created_by_name.first | firstLetterCapital }} {{ row.created_by_name.last | firstLetterCapital }}</td>
          <td class="text-left">{{ row.created_on.date|moment }}</td>
          <td>
            <div class="manage-user-action training-action manage-user-page">
              <button v-b-modal.modal-video-play @click="sendvideo(row.file, row.title, row, index)" :class="row.status == 1 ? 'brief-notify' : ''">
                <img src="@/assets/images/play.png" title="Play video" />
              </button>
              <button v-if="row.distribution && row.status == 0">
                <router-link :to="'/distribute/videogramme/' + row.broadcast_public_id">
                  <img src="@/assets/images/distribute.svg"  title="Distribute"/>
                </router-link>
              </button>
              <button v-if="row.distributed && (row.status == 0)" class="distribute_history" @click.prevent="distributionData(row)" v-b-modal.modal-briefing-history>
                <img src="@/assets/images/history.png" title="Distribution History">
              </button>
              <button v-else disabled class="distribute_history">
                <img src="@/assets/images/history.png" title="Distribution History">
              </button>
              <!-- <button :disabled="!row.distributed && row.status == 1" class="distribute_history" @click.prevent="distributionData(row)" v-b-modal.modal-briefing-history>
                <img src="@/assets/images/history.png" title="Distribution History">
              </button> -->
              <button v-if="row.continuous_distribution" :disabled="row.distribution ? false : true" v-b-modal.confirm-delete @click.prevent="confirm_popup(row, 'deactivate')">
                <img src="@/assets/images/continousDistributionA.png" title="Continuous Distribution - Active" />
              </button>
              <button v-else :disabled="row.distribution ? false : true" v-b-modal.confirm-delete @click.prevent="confirm_popup(row, 'activate')">
                <img src="@/assets/images/continousDistributionInA.png" title="Continuous Distribution - Inactive" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal
      hide-footer
      id="modal-video-play"
      size="lg"
      modal-class="flipModal manage-user-modal"
      :title="videoTitle"
      centered
    >
      <div>
        <video-player  class="video-player-box brief-video"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
          customEventName="customstatechangedeventname">
        </video-player>
      </div>
    </b-modal>
    <historyModal historyArea="videogramme" :distributeId="distributeId" :historyTitle="historyTitle"></historyModal>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
import moment from "moment";
import historyModal from "../../../components/admin_layouts/dashboard_layouts/history_modal";
import { videoPlayer } from 'vue-video-player';
import { HTTP_formdata } from "../../../constants/http-common";
import 'video.js/dist/video-js.css';
import { EventBus } from "../../../main";
import confirmModal from "../../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
export default {
  props:["data", "rows"],
    data() {
    return {
      video_src:"",
      videoTitle: "",
      distributeId: "",
      historyTitle: "",
      playerOptions: {
        // videojs options
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
      },
      continuousDistributionData: ""
    };
  },
  components: {
    historyModal,
    videoPlayer,
    confirmModal
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    },
    firstLetterCapital: function(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    sendvideo(file, title, data, index) {
      this.video_src = file
      this.videoTitle = title
      this.playerOptions.sources[0].src = file;
      if ( data.status ) {
        HTTP_formdata.put( "broadcast/view_broadcast/" + data.broadcast_public_id )
          .then(response => {
            this.data[index].status = 0;
          })
          .catch(err => {});
      }
    },
    distributionData(data) {
      this.distributeId = data.broadcast_public_id;
      this.historyTitle = data.title;
    },
    confirm_popup( data, action ) {
      if ( action ) {
        this.continuousDistributionData = data;
        this.continuousDistributionData.confirmToState = action;
        EventBus.$emit( "deleteSelected", this.continuousDistributionData );
      }
    },
    continuousDistribution() {
      let jsonData = {
        module: this.$route.meta.route_ref,
        value: ( this.continuousDistributionData.confirmToState == 'activate' ) ? true : false
      }
      HTTP_formdata.put( "distribution/continuous_distribution/" + this.continuousDistributionData.broadcast_public_id, jsonData )
        .then(response => {
          if ( response.status == 200 ) {
            let resp = { msg: response.data.message, popup: true };
            EventBus.$emit( "success_resp", resp );
            this.continuousDistributionData = "";
            EventBus.$emit("refreshVideogrammeList", true);
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            let resp = { msg: err.response.data.message, popup: true };
            EventBus.$emit( "error_resp", resp );
            this.continuousDistributionData = "";
          }
        });
    }
  },
  created() {
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "Continuous Distribution" ) {
        this.continuousDistribution();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
  }
}
</script>
<style scoped>
.distribute_history img {
  max-width: 23px;
}
</style>