import Vue from "vue";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export const isloggedin = {
  token: $cookies.get("user") ? $cookies.get("user").data.token : "",
  name: $cookies.get("user") ? $cookies.get("user").data.name : {},
  station_code: $cookies.get("user") ? $cookies.get("user").data.station_code : "",
  roles: $cookies.get("user") ? $cookies.get("user").data.roles : "",
  public_id: $cookies.get("user") ? $cookies.get("user").data.public_id : "",
  access_rights: $cookies.get("uar") ? $cookies.get("uar").access_rights : "",
  group_name: $cookies.get("uar") ? $cookies.get("uar").group_name : "",
  training_results: $cookies.get("uar") ? $cookies.get("uar").training_results : "",
  briefing_reports: $cookies.get("uar") ? $cookies.get("uar").briefing_reports : "",
  create_briefing: $cookies.get("uar") ? $cookies.get("uar").create_briefing : "",
  set_up_training: $cookies.get("uar") ? $cookies.get("uar").set_up_training : "",
  user_stations: $cookies.get("uar") ? $cookies.get("uar").stations : "",
  contract: $cookies.get("uar") ? $cookies.get("uar").contract : "",
  image: $cookies.get("user") ? $cookies.get("user").data.image : "",
  passwordCheck: $cookies.get("user") ? $cookies.get("user").data.change_password : "",
  phoneNum: $cookies.get("user") ? $cookies.get("user").data.contact_num : "",
  shuttle_tracker: $cookies.get("__shuttle") ? $cookies.get("__shuttle").visibility : "",
  shuttle_tracker_link: $cookies.get("__shuttle") ? $cookies.get("__shuttle").link : ""
};

// Base API URL Constants
export const BASE_URL = process.env.VUE_APP_API_URL;
export const BASE_URL_VER = process.env.VUE_APP_API_URL_VER;

export const AMAZON_S3_IMAGE_URL="https://s3.us-east-2.amazonaws.com/polar.orgadynamics.com/images/"

export const CREATE_NEW_BRIEFINGS = {
  BRIEFING_TITLE_LABEL:"Briefing Title",
  BRIEFING_TITLE_VALIDATION:"Briefing Title",
  BRIEFING_TITLE_PLACEHOLDER:"Briefing Title",

  FUNCTIONAL_RELEVENCE_LABEL:"Functional Relevance",
  FUNCTIONAL_RELEVENCE_VALIDATION:"Functional Relevance",

  TYPE_OF_BRIEFINGS_LABEL:"Type of Briefing",
  TYPE_OF_BRIEFINGS_VALIDATIONL:"Type of Briefing",
  TYPES_OF_BRIEFINGS_DATA:[
    { text: "Select", value: null, disabled:true },
    { value: "Mandatory Process Compliance",text: "Mandatory Process Compliance" },
    { value: "Information", text: "Information" },
    { value: "Best Demonstrated Practice",text: "Best Demonstrated Practice" },
    { value: "Announcement", text: "Announcement" },
    { value: "Other", text: "Other" }
  ],

  EFFECTIVE_DATE_LABEL:"Effective Date",
  EFFECTIVE_DATE_VALIDATION:"Effective Date",

  END_DATE_LABEL:"End Date",
  END_DATE_VALIDATION:"End Date",

  BRIEFINGS_DETAIL_LABEL:"Briefing Details",
  BRIEFINGS_DETAIL_VALIDATION:"Briefing Details",
  BRIEFINGS_DETAIL_PLACEHOLDER:"Briefing Details",

  BULLET_POINTS_LABEL:"Add key bullet points or step by step instructions",
  BULLET_POINTS_VALIDATION:"bullet points",

  IMAGE_OR_VIDEO_VALIDATION:"Upload Type",
  PICTURE_RADIO_LABEL:"Add Pictures",
  PICTURE_RADIO_VALUE:"Picture",
  ADD_NO_OF_PICTURE_LABEL_SELECT:"Select number of pictures to add",

  VIDEO_RADIO_LABEL:"Add Video",
  VIDEO_RADIO_VALUE:"Video",

  UPLOAD_PDF_VALIDATION:"upload PDF",

  BACK_TO_BRIEFINGS_BUTTON:"Back to List of Briefings",
  HOW_IT_LOOKS_BUTTON:"How does it look",
  SAVE_BUTTON:"Save",
  SAVE_AND_DISTRIBUTE_BUTTON:"Save & Distribute",

  POPUP_EFFECTIVE:"Effective:",
  POPUP_ULTIL:"Until:",
  POPUP_KEYNOTE:"Keynote",
  POPUP_ADDITIONAL_REFERENCE:"Additional Reference",
  POPUP_ATTACHMENTS:"Attachments"
}

export const LIST_OF_BRIEFINGS={
  CREATE_NEW_BRIEFINGS_BUTTON:"Create New Briefings",
  BRIEFING_TABLE_HEADER_1:"Briefing Title",
  BRIEFING_TABLE_HEADER_2:"Function",
  BRIEFING_TABLE_HEADER_3:"Type",
  BRIEFING_TABLE_HEADER_4:"Start Date",
  BRIEFING_TABLE_HEADER_5:"End Date",
  BRIEFING_TABLE_HEADER_6:"Ack Rate",
  BRIEFING_TABLE_HEADER_7:"Actions",

  ACKNOWLEDGE_USER_MODEL_TITLE:"Acknowledge Briefing",
  USER_FIRST_NAME_LABEL:"Name :",
  USER_ACKNOWLEDGE_ON_LABEL:"Acknowledged On :",
  NO_ACKNOWLEDGE_MESSAGE:"No User has Acknowledge this Briefings",
  BRIEFINGS_ACKNOWLEDGE_LABEL:"Briefings Acknowledged",
  USER_ACKNOWLEDGE_OPTION_LABEL:"Do you want to Acknowledge the Briefing",
  ACKNOWLEDGE_BUTTON:"Acknowledge",
  CALCEL_BUTTON:"Cancel"
}

export const DISTRIBUTE={
  INTERNAL_RADIO_LABEL:"Internal",
  EXTERNAL_RADIO_LABEL:"External",
  ALLUSERS_RADIO_LABEL:"All",
  DISTRIBUTE_TO_GROUPS_REGION_LABEL:"Distribute by Station and Employee Group selection",
  REGION_OR_STATION_LABEL:"Stations",
  EMPLOYEE_GROUPS_LABEL:"Employee Groups",
  DISTRIBUTE_ORG_STATION_LABEL:"Distribute by org or station code",
  DISTRIBUTE_SPECIFICUSER_LABEL:"Distribute to Specific Users",
  EXCLUDE_SPECIFICUSER_LABEL:"Exclude Specific Users",
  CREATE_AND_MANAGE_DISTRIBUTION_BUTTON:"Create & Manage Distribution List",
  CREATE_AND_MANAGE_EXCLUSION_BUTTON:"Create & Manage Exclusion List",
  CREATE_AND_MANAGE_POPUP_TITLE:"Create & Manage Distribution Lists",
  GROUP_NAME_HEADER:"Distribution Group Name",
  MEMBERS_HEADER:"Member",

  NONINTERNAL_USERS_ALERT_POPUP:"Following are the list of non internal users",
  SEND_TO_ALL_USERS_BUTTON:"Send to all users",
  SEND_TO_INTERNAL_USERS_BUTTON:"Send to internal users",
  CANCEL_BUTTON:"Cancel",

  BACK_TO_BRIEFINGS_BUTTON:"Back to list of briefings",
  SEND_BRIEFINGS_BUTTON:"Send Briefings",

  ACK_LABEL:"Tracking",
  REQUEST_ACKNOWLWDGEMENT_LABEL:"Request Acknowledgement",
  NOTIFY_ALL_USER_ACK:"Notify when 100% target group has acknowledged"
}
