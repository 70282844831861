<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <b-form class="input-padding">
                <div class="def-func-form">
                  <div class="def-func-input-form-group">
                    <div class="def-func-input-group mb-0">
                      <div class="test-questn-sectn perform-check">
                        <div class="briefing-admin-panel justify-content-space-between">
                          <div>
                            <label>
                              <span>{{ trainingData.training_name }}</span>
                            </label>
                          </div>
                          <div>
                            <span v-b-modal.log-modal @click.prevent="historyLogs()">
                              <img src="@/assets/images/log.png" title="Log" />
                            </span>
                          </div>
                        </div>
                        <div class="briefing-admin-panel justify-content-space-between">
                          <div>
                            <label class="start-training-score">
                              <span class="pr-1">Your Score: </span>
                              <span>{{ trainingData.score }}</span>
                            </label>
                          </div>
                          <div>
                            <label style="text-transform: capitalize"><span>{{loggedin_user_details.name.first}} {{loggedin_user_details.name.last}}</span></label>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <h5
                        class="start-message"
                      >Please keep this page open during the entire session and follow the steps outlined below</h5>
                      <div class="clearfix"></div>
                      <div class="training-steps-outer">
                        <ul>
                          <li class="training-steps">
                            <img src="@/assets/images/play-content-grey.png"/>
                            <!-- <img src="@/assets/images/download-grey.png"/> -->
                            <h6 class="step-title">Play Content</h6>
                            <!-- <h6 class="step-title">Download & Review</h6> -->
                          </li>
                          <li class="training-steps training-steps-line"></li>
                          <li class="training-steps">
                            <img src="@/assets/images/book-grey.png" />
                            <h6 class="step-title">Test your knowledge</h6>
                          </li>
                          <li class="training-steps training-steps-line"></li>
                          <li class="training-steps">
                            <img src="@/assets/images/certificate-grey.png" />
                            <h6 class="step-title">Final Test</h6>
                          </li>
                        </ul>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="def-func-input-group mb-0">
                      <appStartDownload v-if="!start_quiz"></appStartDownload>
                      <appQuizQuestions v-else :moduledata="moduleData" :trainingid="training_id"></appQuizQuestions>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <trainingLogs></trainingLogs>
  </div>
</template>
<script>
import start_download from "./start-training-downloads";
import quiz_questions from "./quiz_questions";
import { EventBus } from "../../../main";
import { isloggedin } from "../../../constants/app-constants";
import trainingLogs from "../../../components/admin_layouts/dashboard_layouts/training_log";
export default {
  data() {
    return {
      moduleData: "",
      training_id: this.$route.params.trainingid,
      start_quiz: false,
      loggedin_user_details: isloggedin,
      trainingData: ""
    };
  },
  components: {
    appStartDownload: start_download,
    appQuizQuestions: quiz_questions,
    trainingLogs
  },
  methods: {
    historyLogs() {
      let data = {
        training_public_id: this.$cookies.get("__train").public_id,
        user_public_id: this.$cookies.get("user").data.public_id,
        recurrence: this.$cookies.get("__train").recurrence,
        title: this.$cookies.get("__train").title
      }
      EventBus.$emit( "historyLog", data );
    }
  },
  created() {
    EventBus.$on("innitialize-quiz", emitdata => {
      this.start_quiz = emitdata.start_quiz;
      this.moduleData = emitdata.moduleData;
    });
    EventBus.$on("innitialize-final-quiz", emitdata => {
      this.start_quiz = emitdata.start_quiz;
      this.moduleData = "";
    });
    EventBus.$on("quiz-completed", complete => {
      this.start_quiz = !complete
    });
    EventBus.$on("training_header_data", data => {
      this.trainingData = data
    });
  }
};
</script>
<style>
@import "../def_function.css";
</style>
