<template>
  <div>
    <div class="def-func-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              <span>Employee Group</span>
            </th>
            <th>
              <span>Function</span>
            </th>
            <th class="text-center">
              <span>Date Created</span>
            </th>
            <th class="text-center">
              <span>Dependencies</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="( result, index ) in grpResult" :key="index">
            <td>
              <b-form-radio
                name="grp"
                v-model="grpIdPassed"
                :value="result.group_public_id"
                @change="onSelectGrp"
              />
            </td>
            <td>{{ result.group_name }}</td>
            <td class="text-left">{{ result.function_name }}</td>
            <td class="text-center">{{ result.created_on.date | moment }}</td>
            <td>{{ result.dependencies }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { EventBus } from "../../../main";
export default {
  props: ["grpResult"],
  data() {
    return {
      grpIdPassed: ""
    };
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    }
  },
  methods: {
    onSelectGrp(event) {
      this.grpIdPassed = event;
      EventBus.$emit("selectGrp", this.grpIdPassed);
    }
  },
  created() {
    EventBus.$on("UnCheckAll", grpId => {
      this.grpIdPassed = grpId;
    });
  },
  beforeCreate() {
    EventBus.$off("UnCheckAll")
  }
};
</script>