<template>
  <div>
    <b-modal ref="modal-my-training" size="md" v-model="modalShow" modal-class="flipModal manage-user-modal" 
      :title="trainingData.header" id="modal-my-training" centered hide-footer>
      <div class="d-block training-modal">
        <p class="font-16">Dear User,<p>
        <p class="font-16" v-if="trainingData.training_format == 'external'">{{ trainingData.user_instructions }}</p>
        <p class="font-16" v-else>Please upload the Certificate of the training or the evidence that you have attended the training.</p>
        <div v-if="trainingData.training_format == 'classroom'" class="def-func-form">
          <ValidationObserver ref="observerClassroom" v-slot="{ passes }">
            <b-form @submit.prevent="passes( hideModal )">
              <div class="def-func-input-form-group mb-0">
                <b-row>
                  <b-col md="12" class="attachment-issue mt-3">
                    <div class="justify-content-space-between">
                      <div class="width-100">
                        <label>File to Upload</label>
                        <ValidationProvider name="File to Upload" rules="required|ext:pdf,jpg,png,jpeg,doc,docx" v-slot="{ valid, errors }">
                          <b-form-file accept="image/jpeg, image/png, .jpg, .png, application/pdf" :state="errors[0] ? false : (valid ? true : null)"
                            v-model="attachment" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
                          <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                      <div class="ml-3 pt-4 mt-3">
                        <b-icon v-b-tooltip.hover.top="'Only jpg, jpeg, png, doc, docx, pdf allowed'" icon="info" class="info-icon-size"></b-icon>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="def-func-input-form-group">
                <div class="clearfix"></div>
                <div class="w-100 text-center">
                  <b-button type="submit" variant="light" class="btn-rename-delete mt-4">Upload</b-button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </div>
      <div class="text-center">
        <a v-if="trainingData.training_format == 'external'" :href="trainingData.external_link" target="_blank"><b-button @click="hideModal" class="mt-3 btn-rename-delete" variant="light">Okay</b-button></a>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import { EventBus } from "../../../main";
  import { HTTP_imageupload } from "../../../constants/http-common"
  export default {
    data() {
      return {
        modalShow: null,
        trainingData: "",
        attachment: null
      }
    },
    watch: {
      // modalShow( val ) {
      //   if ( val == false ) {
      //     // EventBus.$emit( "noConnectionOk", 'Okay' );
      //   }
      // }
    },
    methods: {
      hideModal() {
        if ( this.trainingData.training_format == 'external' ) {
          this.$refs['modal-my-training'].hide();
        } else {
          console.log("sdsd");
          let jsonData = new FormData();
          jsonData.set( "file", this.attachment );
          HTTP_imageupload.post( "user_training_distributes/upload_certificate/" + this.trainingData.distribute_id, jsonData )
            .then(response => {
              if ( response.status == 200 ) {
                let res = {
                  popup: true,
                  msg: response.data.message
                }
                EventBus.$emit( "success_resp", res );
                EventBus.$emit( "refreshTraining", true );
                this.$refs['modal-my-training'].hide();
              }
            })
            .catch(error => {
              if ( error.response.status == 400 ) {
                let response = {
                  popup: true,
                  msg: error.response.data.message
                };
                EventBus.$emit("error_resp", response)
              }
            });
        }
      }
    },
    created() {
      EventBus.$on( "startTrainingData", data => {
        this.trainingData = data;
      });
    },
    beforeCreate() {
      EventBus.$off( "startTrainingData" )
    }
  }
</script>
<style >
  .d-block.training-modal p{
    font-size: 16px;
  }
</style>