import Vue from 'vue'
import Vuex from 'vuex'
import { router } from "../main";
import { HTTP } from "./../constants/http-common";

Vue.use(Vuex)

// export const store = new Vuex.Store({
export default new Vuex.Store({
  state: {
    idToken: $cookies.get("user") ? $cookies.get("user").data.token : null,
    userId: $cookies.get("user") ? $cookies.get("user").data.public_id : null,
    network: null,
    mouse: null
  },
  mutations: {
    authUser (state, userData) {
      state.idToken = userData.token
      state.userId = userData.userId
    },
    clearAuthData (state) {
      state.idToken = null
      state.userId = null
    },
    networkStatus (state, payload) {
      state.network = payload;
    },
    mouseLeaveEvent (state, payload) {
      state.mouse = payload;
    }
  },
  actions: {
    setLogoutTimer ({dispatch}, expirationTime) {
      setTimeout(() => {
        dispatch('logout').then(resp => {
          router.push({ path: "/login" });
        }).catch(e => {});
      }, expirationTime * 1000)
    },
    login ({commit, dispatch}, authData) {
      commit('authUser', {
        token: authData.token,
        userId: authData.userId
      })
      let now = new Date()
      let MSDate = now.getTime()
      let expirationDate = Math.round(authData.expirationDate - MSDate/1000);
      dispatch('setLogoutTimer', expirationDate)
    },
    tryAutoLogin ({commit, dispatch}) {
      let token = "";
      let userId = ""
      if ( $cookies.isKey("user") == true ) {
        token = $cookies.get("user") ? $cookies.get("user").data.token : "";
        userId = $cookies.get("user") ? $cookies.get("user").data.public_id : ""
      }
      if ( ! token ) {
        dispatch('logout')
      }
      const expirationDate = localStorage.getItem('__used')
      const now = new Date()
      if (now >= expirationDate) {
        dispatch('logout')
      }
      commit('authUser', {
        token: token,
        userId: userId
      })
    },
    logout ({commit}) {
      HTTP.get( "users/logout" )
        .then(response => {
          $cookies.remove("user");
          $cookies.remove("uar");
          $cookies.remove("__shuttle");
          if ( $cookies.isKey("__check") == true )
            $cookies.remove( "__check" );
          if ( $cookies.isKey("__train") == true )
            $cookies.remove( "__train" );
          if ( $cookies.isKey("__sucheck") == true )
            $cookies.remove( "__sucheck" );
          localStorage.removeItem('__used');
          // localStorage.removeItem('_check_local');
          commit('clearAuthData')
          router.replace('/login').catch(e => {});
          window.location.reload();
        })
        .catch(error => {
          if ( error.response.status == 400 || error.response.status == 404 || error.response.status == 401 || error.response.status == 500 ) {
            $cookies.remove("user");
            $cookies.remove("uar");
            $cookies.remove("__shuttle");
          if ( $cookies.isKey("__check") == true )
              $cookies.remove( "__check" );
            if ( $cookies.isKey("__train") == true )
              $cookies.remove( "__train" );
            if ( $cookies.isKey("__sucheck") == true )
              $cookies.remove( "__sucheck" );
            localStorage.removeItem('__used');
            // localStorage.removeItem('_check_local');
            commit('clearAuthData')
            router.replace('/login').catch(e => {});
            window.location.reload();
          }
        });
    },
    networkData ( { commit }, data ) {
      commit('networkStatus', data );
    },
    mouseLeave ( { commit }, data ) {
      commit('mouseLeaveEvent', data );
    }
  },
  getters: {
    isAuthenticated (state) {
      return state.idToken !== null
    },
    networkConnection (state) {
      return state.network;// !== null
    },
    mouseLeave (state) {
      return state.mouse;
    }
  }
  // below is used in the page where u check if the user is logged in
  // computed: {
  //   network () {
  //     return this.$store.getters.networkConnection;
  //   }
  // auth () {
  //   return this.$store.getters.isAuthenticated
  // }
  // }
});