<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class>
                    <div class="def-func-input-group pending-check-head">
                      <label>Due and upcoming checks</label>
                      <b-row>
                        <b-col md="4" lg="3">
                          <div class="def-func-input-group-col">
                            <div class="global-freq freq-resp-title inline">
                              <label>From</label>
                              <b-input-group class="date-field pending-chk-date">
                                <b-input-group-append is-text>
                                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                </b-input-group-append>
                                <Datepicker v-model="paginate_params.from_date" :disabled-dates="fromState.disabledDates"></Datepicker>
                              </b-input-group>
                              <input
                                v-show="false"
                                id="from_date"
                                name="from_date" v-model="paginate_params.from_date"
                                aria-describedby="from_Date_error"
                              />
                            </div>
                          </div>
                        </b-col>
                        <b-col md="4" lg="3">
                          <div class="def-func-input-group-col">
                            <div class="global-freq freq-resp-title inline">
                              <label>To</label>
                              <b-input-group class="date-field pending-chk-date">
                                <b-input-group-append is-text>
                                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                </b-input-group-append>
                                <Datepicker v-model="paginate_params.to_date" :disabled-dates="toState.disabledDates"></Datepicker>
                              </b-input-group>
                              <input
                                v-show="false"
                                id="to_date"
                                name="to_date" v-model="paginate_params.to_date"
                                aria-describedby="to_Date_error"
                              />
                            </div>
                          </div>
                        </b-col>
                        <b-col md="4" lg="3">
                          <div class="def-func-input-group-col">
                            <div class="global-freq freq-resp-title inline">
                               <b-form-checkbox
                                id="checkbox-1"
                                v-model="paginate_params.previous_checks"
                                name="checkbox-1"
                                value=true
                                unchecked-value=false
                              >
                                Show Overdue Checks
                              </b-form-checkbox>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="clearfix"></div>
                      <b-row>
                        <b-col>
                          <div class="def-func-form freq-resp-table">
                            <div class="def-func-table manage-users-table">
                              <table class="global-checklist-table">
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      <span>Check to be performed</span>
                                    </th>
                                    <th class="text-left">
                                      <span>Function</span>
                                    </th>
                                    <th class="text-left">
                                      <span>For Station</span>
                                    </th>
                                    <th class="text-left">
                                      <span>Responsible</span>
                                    </th>
                                    <th class="text-left">
                                      <span>Due Date</span>
                                    </th>
                                    <th class="text-left">
                                      <span>Status</span>
                                    </th>
                                    <th class="text-center">
                                      <span>Actions</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="( result, index ) in pendingCheckData" :key="index">
                                    <td class="text-left overflow-break-word">{{ result.title }}</td>
                                    <td class="text-left">{{ result.fun_title }}</td>
                                    <td class="text-left">{{ result.station_code }}</td>
                                    <td class="text-left">{{ result.responsible }}</td>
                                    <td class="text-left" :class="result.pending_flag == 1 ? 'text-danger' : ''">{{ result.due_on[0] | moment }}</td>
                                    <td class="text-left">{{ result.status }}</td>
                                    <td>
                                      <div class="manage-user-action justify-content-around manage-user-page">
                                        <router-link :to="'/perform_check/' + result.public_id">
                                          <img src="@/assets/images/play.png" @click="checkDetails( result )" title="Start Check" />
                                        </router-link>
                                        <button v-b-modal.modal-abandon-check @click.prevent="modalClicked( result )">
                                          <img src="@/assets/images/btn_abandoncheck.png" title="Abandon Check" />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <b-pagination
                              v-model="paginate_params.page"
                              :total-rows="rows"
                              :per-page="paginate_params.limit"
                              first-text="First"
                              prev-text="Prev"
                              next-text="Next"
                              last-text="Last"
                            ></b-pagination>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      hide-footer
      id="modal-abandon-check"
      size="md"
      modal-class="flipModal manage-user-modal"
      title="Abandon Check"
      centered
      @hidden="resetModal"
    >
      <div class="def-func-input-form-group">
        <ValidationObserver ref="observerAdandon" v-slot="{ passes }">
          <b-form @submit.prevent="passes( abandonCheck )">
            <b-row>
              <b-col>
                <div class="def-func-input-group">
                  <ValidationProvider
                    name="Reason"
                    rules="required"
                    v-slot="{ valid, errors }"
                  >
                    <label>Reason</label>
                    <b-form-textarea
                      id="textarea"
                      :state="errors[0] ? false : ( valid ? true : null )"
                      v-model="abandonCheckData.reason"
                      rows="6"
                      max-rows="6"
                    ></b-form-textarea>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="check-sign">
                  <label style="text-transform: capitalize">{{ LogInUserDetails.name.first }} {{ LogInUserDetails.name.last }}</label>
                  <label class="check-date">{{ new Date() | momentDateTime }}</label>
                </div>
              </b-col>
              <b-col>
                <b-button
                  type="submit"
                  class="btn-rename-delete float-right"
                  variant="light"
                >Confirm</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </div>
      <template v-slot:modal-footer></template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import { EventBus } from "../../main";
import { HTTP_formdata } from "../../constants/http-common";
import { isloggedin } from "../../constants/app-constants";
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      pendingCheckData: [],
      rows: 10,
      paginate_params: {
        from_date: new Date(),
        to_date: new Date(),
        previous_checks: true,
        page: 1,
        limit: "10"
      },
      abandonCheckData: {
        audit: "",
        due_on: "",
        reason: ""
      },
      LogInUserDetails: isloggedin,
      response: {
        msg: "",
        popup: false
      },
      fromState: {
        disabledDates: {
          from: new Date()
        }
      },
      toState: {
        disabledDates: {
          to: new Date()
        }
      }
    };
  },
  watch: {
    "paginate_params.page": {
      handler() {
        this.pendingCheckData = [];
        this.pendingChecklist();
        deep: true;
      }
    },
    "paginate_params.from_date": {
      handler() {
        this.pendingCheckData = [];
        this.pendingChecklist();
        deep: true;
      }
    },
    "paginate_params.to_date": {
      handler() {
        this.pendingCheckData = [];
        this.pendingChecklist();
        deep: true;
      }
    },
    "paginate_params.previous_checks": {
      handler() {
        this.pendingCheckData = [];
        this.pendingChecklist();
        deep: true;
      }
    }
  },
  methods: {
    pendingChecklist() {
      let from_date = moment(this.paginate_params.from_date).format('YYYY-MM-DD');
      let to_date = moment(this.paginate_params.to_date).format('YYYY-MM-DD');
      let paginate_params = {
        from_date: from_date,
        to_date: to_date,
        previous_checks: this.paginate_params.previous_checks,
        page: this.paginate_params.page,
        limit: this.paginate_params.limit
      };
      HTTP_formdata.post("audits/pending_checks", paginate_params)
        .then(response => {
          this.pendingCheckData = response.data.data;
          this.rows = response.data.totalLength;
        })
        .catch(err => {});
    },
    abandonCheck() {
      HTTP_formdata.post("audits/abandon_check", this.abandonCheckData)
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data.message
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.abandonCheckData.audit = "";
            this.abandonCheckData.reason = "";
            this.abandonCheckData.due_on = "";
            this.pendingCheckData = [];
            this.pendingChecklist();
            this.$nextTick(() => {
              this.$refs.observerAdandon.reset();
              this.$bvModal.hide("modal-abandon-check");
            });
          }
        })
        .catch(err => {});
    },
    modalClicked( result ) {
      this.abandonCheckData.audit = result.public_id
      this.abandonCheckData.due_on = result.due_on[0]
    },
    resetModal() {
      this.abandonCheckData.audit = "";
      this.abandonCheckData.reason = "";
      this.abandonCheckData.due_on = "";
    },
    checkDetails( result ) {
      this.$cookies.set("__check", result);
    }
  },
  beforeMount() {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    this.toState.disabledDates.to = new Date( date.toLocaleString() );
    date.setDate(date.getDate() + 3);
    this.paginate_params.to_date = new Date( date );
  },
  components: {
    Datepicker
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    },
    momentDateTime: function(value) {
      return moment(value).format("DD MMM YYYY | HH:mm");// - h:mm a - LT");
    }
  },
  beforeCreate() {
    if ( this.$cookies.isKey("__check") == true )
      this.$cookies.remove( "__check" );
  } 
};
</script>
<style>
@import "./def_function.css";
.pending-check-head .global-freq.freq-resp-title.inline {
    display: inline-flex;
    align-items: center;
    gap: 12px;
}
.pending-check-head .global-freq.freq-resp-title.inline .custom-control.custom-checkbox {
    margin-top: 8px;
}
.pending-check-head .global-freq.freq-resp-title.inline label {
    font-size: 16px;
    margin: 0;
}
.def-func-input-group.pending-check-head > label {
    font-size: 16px;
}
</style>
