<template>
  <b-col xl="9" lg="12" class="training-update-container">
    <b-row class="training-canity-graph" align-v="stretch">
      <b-col class="training-category">
        <div class="series-vertical-text-outer" v-for="( result, index ) in categories" :key="index">
          <span class="series-vertical-text" :style="{ height: result.height + 'px' }">{{ result.category }}</span>
        </div>
      </b-col>
      <b-col class="series-outer">
        <span class="series-text-container">
          <div class="series-text" :id="'training_' + index" v-for="( result, index ) in data.trainings" :key="index">{{ result }}</div>
        </span>
      </b-col>
      <b-col md="1" class="series-number-outer">
        <div class="series-number" v-for="( result, index ) in data.userCount" :key="index">{{ result }}</div>
      </b-col>
      <b-col md="8" class="graph-wrapper">
        <Highcharts v-if="data" ref="barGraphCanity" :options="options" />
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
  import { EventBus } from "../../../main";
  import Highcharts from 'highcharts';
  import { genComponent } from 'vue-highcharts';
  export default {
    props: [ "data" ],
    data() {
      return {
        // chart: {
        //   type: 'column',
        // },
        options: {
            chart: { type: 'bar', 
            height: '100%',
            // renderTo: 'container' 
          },
          title: { text: '' },
          xAxis: {
            categories: [],
            title: {
              text: null
            }
          },
          yAxis: {
            min: 0,
            //  maxPadding: 0.1,
            title: {
              text: '',
              align: 'high'
            },
            labels: { overflow: 'justify' }
          },
          tooltip: { valueSuffix: '%' },

          plotOptions: {
            // series: {
            //   pointPadding: 0.2,
            //   pointWidth: 25
            // },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                color: '#fff',
                shadow: false,
                style: {}
                // align: 'left'
              },
              pointWidth: '27'
            }
          },
          legend: { enabled: false
          // layout: 'vertical', align: 'right', verticalAlign: 'top', x: -40, y: 80, floating: true, borderWidth: 1, backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF', shadow: true
          },
          credits: {
            enabled: false
          },
          series: [{
            name: '',
            data: []
          }]
        },
        categories: []
      }
    },
    components: {
      Highcharts: genComponent('Highcharts', Highcharts),
    },
    created() {
      EventBus.$on( "canityStatsData", response => {
        this.options.series[0].data = response.value
        this.options.xAxis.categories = response.label
        this.categories = response.categories
      });
      EventBus.$on( "canityCategoryData", response => {
        this.categories = response
      });
    },
    beforeCreate() {
      EventBus.$off( "canityStatsData" )
      EventBus.$off( "canityCategoryData" )
    }
  }
</script>
<style>
  rect.highcharts-point.highcharts-color-0:nth-child(even) {
    fill: #26afec;
    stroke: #26afec;
  }
  rect.highcharts-point.highcharts-color-0:nth-child(odd) {
    fill: #699eff;
    stroke: #699eff;
  }
</style>
