<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <ValidationObserver ref="observerSetUpCheck" v-slot="{ passes }">
                <b-form class="input-padding breifing-content" @submit.prevent="passes( searchFilter('search') )">
                  <div class="def-func-form">
                    <div class="def-func-input-form-group">
                      <div class="def-func-input-group check-results-sectn">
                        <b-row class="align-bottom">
                          <b-col class="def-func-input-group-col" sm="7" lg="7" xl="3">
                            <b-form-group>
                              <ValidationProvider name="Filter results by" rules="required" v-slot="{ valid, errors }">
                                  <div class="required-field">
                                  <label for>Filter results by</label>
                                  <b-form-select v-model="filter"
                                  :state="errors[0] ? false : ( valid ? true : null )"
                                  :options="filterOptions" @change="filterChange">
                                  </b-form-select>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col">
                            <b-form-group>
                              <ValidationProvider name="Value" v-slot="{ errors }" :rules="`${filtervalid ? 'required' : ''}`">
                                <div class="required-field">
                                  <label for>Value</label>
                                  <vue-bootstrap-typeahead v-if="filtervalid"
                                    :data="valueOptions"
                                    ref="valtypeahead"
                                    v-model="valueFilter"
                                    :serializer="item => item.text"
                                    :minMatchingChars="1"
                                    @hit="selectedVal = $event"
                                  ></vue-bootstrap-typeahead>
                                  <b-form-input v-else type="text" v-model="valueFilter" disabled></b-form-input>
                                  <!-- <b-form-select v-model="valueFilter" :disabled="filter == 'all_trainings'" :options="valueOptions">
                                  v-bind:class="{ isInvalid : errors[0], isValid : valid, } "</b-form-select> -->
                                  <b-form-invalid-feedback style="display:block;">{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <!-- <b-col class="def-func-input-group-col" sm="4" lg="4" xl="2">
                            <b-form-group>
                              <ValidationProvider name="Function" v-slot="{ errors }" :rules="`${functionValid ? 'required' : ''}`">
                                <label for>Function</label>
                                <b-form-select v-model="functionFilter" :disabled="filter != 'all_trainings'" :options="functionResult">
                                </b-form-select>
                                <b-form-invalid-feedback style="display:block;">{{ errors[0] }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col> -->
                          <b-col class="def-func-input-group-col" sm="3" lg="3" xl="2">
                            <!-- <ValidationProvider name="From Date" rules="required" v-slot="{ valid, errors }"> -->
                              <b-form-group id="from-Date" label="From" label-for="From_Date">
                                <b-input-group class="date-field">
                                  <b-input-group-append is-text>
                                    <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                  </b-input-group-append>
                                  <Datepicker ref="fromDatepicker" v-model="from_date" clear-button :disabled-dates="stateFrom.disabledDates"></Datepicker>
                                </b-input-group> <!-- <img src="@/assets/images/btn_undo.png" @click.prevent="clearDate()" /> -->
                                <!-- <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback> -->
                                <!-- <input
                                  v-show="false"
                                  id="From_Date"
                                  name="From_Date"
                                  v-model="from_date"
                                  :state="errors[0] ? false : ( valid ? true : null )"
                                  aria-describedby="from_Date_error"
                                /> -->
                              </b-form-group>
                            <!-- </ValidationProvider> -->
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="3" lg="3" xl="2">
                            <!-- <ValidationProvider name="To Date" rules="required" v-slot="{ valid, errors }"> -->
                              <b-form-group id="to-Date" label="To" label-for="To_Date">
                                <b-input-group class="date-field">
                                  <b-input-group-append is-text>
                                    <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                  </b-input-group-append>
                                  <Datepicker ref="toDatepicker" v-model="to_date" clear-button :disabled-dates="stateTo.disabledDates"></Datepicker>
                                </b-input-group>
                                <!-- <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                                <input
                                  v-show="false"
                                  id="To_Date"
                                  name="To_Date"
                                  v-model="to_date"
                                  :state="errors[0] ? false : ( valid ? true : null )"
                                  aria-describedby="to_Date_error"
                                /> -->
                              </b-form-group>
                            <!-- </ValidationProvider> -->
                          </b-col>
                          <b-col class="def-func-input-group-col align-el" sm="2" lg="1" xl="1">
                            <div class="def-func-input-group-col btn-add-outer">
                              <button type="submit" variant="light" class="btn add-btn">
                                <font-awesome-icon :icon="['fas', 'search']" />
                              </button>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
              <div class="clearfix"></div>
              <span v-show="showResults">
                <b-row>
                  <!-- <b-col>
                    <div class="def-func-input-group-col mb-4">
                      <b-button :disabled="exportBtn" class="mb-1" variant="light" @click.prevent="searchFilter(1)">Export to Excel</b-button>
                    </div>
                  </b-col> -->
                  <b-col>
                    <div class="def-func-input-group-col mb-4 float-right">
                      <b-button :disabled="exportBtn" @click.prevent="searchFilter(1)" class="float-right mb-1" variant="light">Export to Excel</b-button>
                      <!-- <b-button :disabled="exportBtn" @click.prevent="showPdf" v-b-modal.modal-training-pdf class="float-right mb-1" variant="light">Export to PDF -->
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" lg="6">
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                      <b-form @submit.prevent="passes( searchFilter )">
                        <div class="brief-rounded-sectn mb-4 mt-0">
                          <b-row>
                            <b-col>
                              <div>
                                <label class="distribute-group-label">User Status</label>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="12">
                              <div class="distribute-group">
                                <ValidationProvider name="User Status" rules="required" v-slot="{ valid, errors }">
                                  <b-form-group>
                                    <b-form-checkbox-group id="user-status-opt" aria-describedby="ariaDescribedby" name="user-status-opt" class="add-media-inner"
                                      :state="errors[0] ? false : ( valid ? true : null )" @input="searchFilter('user')" v-model="userResultFilter" :options="userStatusOptions"></b-form-checkbox-group>
                                  </b-form-group>
                                  <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </b-col>
                  <b-col md="12" lg="6">
                    <div class="brief-rounded-sectn mb-4 mt-0">
                      <b-row>
                        <b-col>
                          <div>
                            <label class="distribute-group-label">Training Status</label>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <div class="distribute-group mb-1">
                            <b-form-group>
                              <b-form-radio-group class="add-media-inner" @input="resultList"
                              v-model="resultFilter" :options="resultOptions" name="training-filter"></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <resultTable :selected="resultFilter" :userStatus="userResultFilter" :selectedResult="resultData"></resultTable>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <trainingResultPDF :trainingData="PDFdata"></trainingResultPDF>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { EventBus } from "../../main";
import resultTable from "../../components/admin_layouts/dashboard_layouts/training_result_table";
import trainingResultPDF from "./training_result_pdf"
import axios from "axios";
import { BASE_URL_VER, isloggedin } from "../../constants/app-constants";
export default {
  data() {
    return {
      PDFdata:[],
      filter: null,
      valueFilter: null,
      valueData: null,
      // functionFilter: null,
      filterOptions: [
        { value: null, text: "Select", disabled: true },
        { value: "training", text: "Specific Training" },
        { value: "specific_user", text: "Specific User" },
        { value: "station", text: "Station" }//,
        // { value: "region", text: "ORG Code" },
        // { value: "function", text: "Function" },
        // { value: "all_trainings", text: "All Trainings" }
      ],
      valueOptions: [],
        //{ value: null, text: "Select", disabled: true }
      // ],
      // functionResult: [
      //   { value: null, text: "Select", disabled: true },
      //   { value: "all", text: "All" }
      // ],
      from_date: "",
      to_date: "",
      stateTo: {
        disabledDates: {
          to: "",
          from: ""
        }
      },
      stateFrom: {
        disabledDates: {
          to: "",
          from: ""
        }
      },
      messsage_loader: false,
      showResults: false,
      resultData: {
        all: [],
        completed: [],
        pending: []
      },
      userStatusOptions: [
          { text: 'Active', value: 'active' },
          { text: 'Inactive', value: 'inactive' },
          { text: 'Deactivated', value: 'deactivated' }
      ],
      resultOptions: [
        { value: "all", text: "All Trainings" },
        { value: "completed", text: "Completed Trainings" },
        { value: "pending", text: "Pending Trainings" }
      ],
      resultFilter: "all",
      userResultFilter: ['active', 'inactive', 'deactivated'],
      filtervalid: true,
      exportBtn: true,
      selectedVal: null
      // functionValid: false
    };
  },
  components: {
    Datepicker,
    resultTable,
    trainingResultPDF
  },
  watch: {
    from_date(val) {
      // if (this.from_date) {
        this.stateTo.disabledDates.to = this.from_date;
      // }
    },
    to_date(val) {
      // if (this.to_date) {
        this.stateFrom.disabledDates.from = this.to_date;
      // }
    },
    valueFilter( newQuery ) {
      if ( newQuery )
        this.valChange(newQuery);
      else {
        this.valueOptions = [];
        this.valueFilter = null;
        this.valueData = null
      }
    },
    selectedVal(data) {
      this.valueData = data;
    }
  },
  methods: {
    // getFuncList() {
    //   HTTP.get("functions/display_functions")
    //     .then(response => {
    //       for (var i = 0; i < response.data.length; i++) {
    //         this.functionResult.push({
    //           value: response.data[i].public_id,
    //           text: response.data[i].title
    //         });
    //       }
    //     })
    //     .catch(err => {});
    // },
    filterChange(){
      this.valueOptions = [];
      this.valueFilter = null;
      this.valueData = null
      if (this.$refs.valtypeahead)
        this.$refs.valtypeahead.inputValue = ""
      if ( this.filter == 'all_trainings') {
        this.filtervalid = false;
      } else if ( this.filter == 'region' || this.filter == 'function' ) {
        if ( isloggedin.roles[0] == 'ROLE_SUPERADMIN' || isloggedin.training_results == "global" ) {
          this.filtervalid = true;
        } else {
          this.filtervalid = false;
          this.valChange('');
        }
      } else {
        this.filtervalid = true;
        // this.functionValid = true;
      }
    },
    valChange(valQuery) {
      this.valueOptions = [];// { value: null, text: "Select", disabled: true }
      this.valueData = null;
      // this.functionFilter = null;
      // if ( this.filter != 'all_trainings' ) {
      //   this.filtervalid = true;
        // this.functionValid = false;
        let jsonData = {
          filter: this.filter,
          value: valQuery,
          geographic_right: isloggedin.training_results
        }
        HTTP_formdata.post("training/training_filters", jsonData)
          .then(response => {
            if ( response.status == 200 ) {
              this.valueOptions = [];// { value: null, text: "Select", disabled: true }
              this.valueOptions = response.data.values;
              // this.valueFilter = (!valQuery) ? null;
              if ( this.filtervalid == false && this.filter != 'all_trainings' ) {
                this.valueData = response.data.values[0];
                this.valueFilter = response.data.values[0].text;
              }
              // for (var i = 0; i < response.data.values.length; i++) {
              //   this.valueOptions.push({
              //     value: response.data.values[i].value,
              //     text: response.data.values[i].text
              //   });
              // }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 404 ) {
              this.valueOptions = [];
              if (this.$refs.valtypeahead)
                this.$refs.valtypeahead.inputValue = ''
              this.valueData = null;
              this.valueFilter = null;
            }
          });
    },
    searchFilter(excel) {
      this.userResultFilter = ( excel == 'search' && this.userResultFilter.length != 3 ) ? ['active', 'inactive', 'deactivated'] : this.userResultFilter;
      if ( this.userResultFilter.length > 0  && this.filter && ( this.filter != 'all_trainings' && this.valueData ) || ( this.filter == 'all_trainings' && !this.valueData ) ) { // || ( !this.filtervalid && !this.valueData )
        this.messsage_loader = true;
        if ( !excel || ( excel && excel != 1 ) ) this.resultData = { all: [], completed: [], pending: [] }
        let jsonData = {
          filter: this.filter,
          value: ( this.valueData ) ? this.valueData.value : this.valueData,
          // function: "",//this.functionFilter,
          from_date: this.from_date,
          to_date: this.to_date,
          excel: ( excel == 1 ) ? 1 : 0,
          status: ( excel == 1 ) ? this.resultFilter : "",
          user_status: this.userResultFilter
        };
        // apiHeader let apiHeader = ( excel == 1 ) ? axios({ url: BASE_URL_VER + "training/get_training_reports", method: 'POST', data: jsonData, responseType: 'blob', headers: { Authorization: "Bearer " + isloggedin.token, "Content-Type": "application/json" } }) : 
        HTTP_formdata.post("training/get_training_reports", jsonData, ( excel == 1 ) ? { responseType: 'blob' } : '' )
        // HTTP_formdata.post("training/get_training_reports", jsonData)
          .then(response => {
            if ( response.status == 200 ) {
              this.messsage_loader = false;
              if ( excel == 1 ) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'TrainingResults.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
              } else {
                this.resultData = { all: [], completed: [], pending: [] }
                this.resultData.all = response.data.all_training;
                this.resultData.completed = response.data.completed;
                this.resultData.pending = response.data.pending;
                this.resultFilter = ( excel == 'user' && this.resultFilter != "" ) ? this.resultFilter : "all";
                this.showResults = true;
                this.resultList();
              }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 404 || error.response.status == 500 ) {
              this.messsage_loader = false;
              this.showResults = ( excel == 'user' ) ? true : false;
              this.resultData = { all: [], completed: [], pending: [] }
              this.resultList();
              let resp = {
                msg: ( error.response.status == 500 ) ? "Status Code: 500" : error.response.data.message,
                popup: true
              }
              EventBus.$emit("error_resp", resp);
            }
          });
      }
    },
    showPdf() {
      let PDFdata = {}
      PDFdata.from_date = this.from_date;
      PDFdata.to_date = this.to_date;
      let filterVal = this.filterOptions.find(e=>e.value == this.filter)
      PDFdata.filter = filterVal.text

      // let functionvalue = this.functionResult.find(e=>e.value == this.functionFilter)
      // PDFdata.functionFilter = functionvalue.text

      let valuename = this.valueData;//this.valueOptions.find(e=>e.value == this.valueFilter)
      PDFdata.valueFilter = ( valuename ) ? valuename.text : "-";
      if(this.resultFilter == "completed"){
         PDFdata.results = this.resultData.completed
         PDFdata.type="completed"
      } else if(this.resultFilter == "pending"){
        PDFdata.results = this.resultData.pending
        PDFdata.type="pending"
      } else {
        PDFdata.results = this.resultData.all
        PDFdata.type="all"
      }
      this.PDFdata = []
      this.PDFdata.push(PDFdata)
      EventBus.$emit( "downloadPDFClicked", PDFdata );
    },
    resultList() {
      this.exportBtn = ( this.resultData[this.resultFilter].length > 0 ) ? false : true;
    },
    clearDate() {
      this.$refs.fromDatepicker.clearDate();
    }
  },
  beforeMount() {
    if (isloggedin.training_results == "global") {
      this.filterOptions.push(
        { value: "region", text: "ORG Code" },
        { value: "function", text: "Function" },
        { value: "all_trainings", text: "All Trainings" }
      );
    } else if ( isloggedin.training_results == "function" ) {
      this.filterOptions.push(
        { value: "region", text: "ORG Code" },
        { value: "function", text: "Function" }
      );
    } else if ( isloggedin.training_results == "org_code" ) {
      this.filterOptions.push(
        { value: "region", text: "ORG Code" }
      );
    }
  }
};
</script>
<style scoped>
@import "./def_function.css";
button.btn a {
    color: #003261;
    display: flex;
    align-items: center;
}
button.btn a img {
  padding-right: 10px;
}
</style>
