<template>
  <vue-bootstrap-typeahead
    :data="typeData"
    :ref="( refer ) ? refer : 'typeaheadStation'"
    v-model="typesearch"
    :serializer="item => item.code"
    placeholder="Station Code"
    :minMatchingChars="1"
    @hit="selectedTypeAhead = $event"
  ></vue-bootstrap-typeahead>
</template>
<script>
  import { HTTP_formdata, HTTP } from "../../../constants/http-common";
  import { EventBus } from "../../../main";
  export default {
    props: [ 'apiFunction', 'location', 'page', 'refer', 'area' ],
    data() {
      return {
        typeData: [],
        typesearch: "",
        selectedTypeAhead: null
      };
    },
    watch: {
      typesearch(newQuery) {
        if (newQuery) {
          if ( this.page ) {
            HTTP.get( this.apiFunction + "?area=station&station_code=" + newQuery )
              .then(response => {
                if ( response.status == 200 ) {
                  this.typeData = response.data;
                }
              })
              .catch(error => {
                // if (this.refer) {
                  if (this.$refs.typeaheadBasedStation)
                    this.$refs.typeaheadBasedStation.inputValue = ''
                    this.typeData = []
                    this.typesearch = ""
                // } else {
                //   if (this.$refs.typeaheadStation)
                //     this.$refs.typeaheadStation.inputValue = ''
                // }
              });
          } else {
            let searchQuery = { area: this.area, code: newQuery };
            HTTP_formdata.post( this.apiFunction, searchQuery )
              .then(response => {
                if (response.status == 200) {
                  this.typeData = response.data;
                }
              })
              .catch(err => {});
          }
        } else {
          if (this.refer) {
            if (this.$refs.typeaheadBasedStation)
              this.$refs.typeaheadBasedStation.inputValue = ''
          } else {
            if (this.$refs.typeaheadStation)
              this.$refs.typeaheadStation.inputValue = ''
          }
          let data = (this.location) ? { location: this.location, typesearch: this.typesearch } : this.typesearch
          EventBus.$emit( "typeaheadSearchStationcode", data );
        }
      },
      selectedTypeAhead(newQuery) {
        let data = (this.location) ? { location: this.location, typesearch: newQuery } : newQuery
        EventBus.$emit( "typeaheadSearchStationcode", data );
      }
    },
    created() {
      EventBus.$on("clearData", clear => {
        if ( ! this.location || this.page ) {
          if (this.$refs.typeaheadStation)
            this.$refs.typeaheadStation.inputValue = ''
        }
      });
      EventBus.$on("clearDataBasedStation", clear => {
        if ( ! this.location || this.page ) {
            if (this.$refs.typeaheadBasedStation)
              this.$refs.typeaheadBasedStation.inputValue = ''
        }
      });
      EventBus.$on("stationData", data => {
        if (this.location) {
          // if (this.refer) {
            if (this.$refs.typeaheadBasedStation)
              this.$refs.typeaheadBasedStation.inputValue = data
          // } else {
          //   if (this.$refs.typeaheadStation)
          //     this.$refs.typeaheadStation.inputValue = data
          // }
          this.typesearch = data;
        }
      });
    }
  };
</script>