<template>
  <b-modal
    hide-footer
    id="modal-performed-by"
    size="md"
    modal-class="flipModal manage-user-modal"
    title="Assign checklist to"
    @show="onShow"
    centered
  >
    <div class="def-func-input-group">
      <div class="def-func-input-form-group">
        <b-row class="justify-content-space-between">
          <b-col v-if="frequency != 'Annual'" md="12">
            <label>Select Employee Group</label>
            <b-form-select
              class="mb-3"
              v-model="selectedEmpGrp"
              :options="grpResult"
              @change="onSelectEmpGrp( $event )"
              :disabled="performed_by.emp_id != ''"
            ></b-form-select>
          </b-col>
          <b-col md="12" class="text-center" v-if="frequency != 'Annual'">
            <h5>OR</h5>
          </b-col>
          <b-col class="def-func-input-group-col" md="12">
            <label>Email of specific Employee</label>
            <b-form-input
              v-if="performed_by.emp_group_id != ''"
              v-show="(performed_by.emp_group_id != '') ? true :false"
              v-model="performed_by.emp_id"
              :disabled="performed_by.emp_group_id != ''"
            ></b-form-input>
            <typeaheadUserGroup v-else :station="station" api="audits/get_checklist_users" :userVal="performed_by.display_name"></typeaheadUserGroup>
          </b-col>
          <div class="clearfix"></div>
          <b-col md="12" class="mt-7 text-center">
            <div class="justify-content-space-between sel-freq-btns mt-4">
              <b-button
                class="btn-rename-delete mt-3"
                variant="light"
                @click="onSelectEmp( $event )"
              >Update</b-button>
              <b-button
                class="btn-rename-delete mt-3"
                variant="light"
                @click.prevent="onClear( $event )"
              >Clear</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <template v-slot:modal-footer></template>
  </b-modal>
</template>
<script>
import { EventBus } from "../../../main";
import { HTTP_formdata } from "../../../constants/http-common";
import typeaheadUserGroup from "./typeahead_search_user_and_group";
export default {
  props: [ "function", "frequency", "data", "index", "station" ],
  data() {
    return {
      grpResult: [{
        value: { val: null, name: null },
        text: "Select",
        disabled: true
      }],
      performed_by: {
        emp_group_id: "",
        emp_id: "",
        display_name: ""
      },
      selectedEmpGrp: { val: null, name: null }
    };
  },
  components: {
    typeaheadUserGroup
  },
  watch: {
    frequency(newVal) {
      if (newVal) {
        if (this.data.displayEmpName == "" ) {
          this.performed_by.emp_group_id = "";
          this.performed_by.emp_id = "";
          this.performed_by.display_name = "";
          this.selectedEmpGrp = { val: null, name: null };
        } else {
          this.performed_by.emp_group_id = this.data.performed_by.emp_group_id;
          this.performed_by.emp_id = this.data.performed_by.emp_id;
          this.performed_by.display_name = this.data.displayEmpName;
          this.getGrpList();
          this.selectedEmpGrp.val = (this.data.performed_by.emp_group_id == "") ? null : this.data.performed_by.emp_group_id;
          this.selectedEmpGrp.name = (this.data.performed_by.emp_group_id == "") ? null : this.data.displayEmpName;
        }
      }
    },
    function(val) {
      this.getGrpList();
    }
  },
  methods: {
    onShow(bvModalEvt) {
      if (this.data.displayEmpName == "" ) {
        this.performed_by.emp_group_id = "";
        this.performed_by.emp_id = "";
        this.performed_by.display_name = "";
        this.selectedEmpGrp = { val: null, name: null };
      } else {
        this.performed_by.emp_group_id = this.data.performed_by.emp_group_id;
        this.performed_by.emp_id = this.data.performed_by.emp_id;
        this.performed_by.display_name = this.data.displayEmpName;
        this.getGrpList();
        this.selectedEmpGrp.val = (this.data.performed_by.emp_group_id == "") ? null : this.data.performed_by.emp_group_id;
        this.selectedEmpGrp.name = (this.data.performed_by.emp_group_id == "") ? null : this.data.displayEmpName;
      }
    },
    getGrpList() {
      let jsonData = {
        function: this.function,
        station: this.station
      };
      this.grpResult = [{ value: { val: null, name: null }, text: "Select", disabled: true }]
      HTTP_formdata.post("audits/get_checklist_group", jsonData)
        .then(response => {
          this.grpResult = [{ value: { val: null, name: null }, text: "Select", disabled: true }]
          for (var i = 0; i < response.data.length; i++) {
            this.grpResult.push({
              value: {
                val: response.data[i].group_public_id,
                name: response.data[i].group_name
              },
              text: response.data[i].group_name
            });
          }
        })
        .catch(error => {});
    },
    onSelectEmp(event) {
      this.performed_by.index = this.index
      EventBus.$emit("selectEmp", this.performed_by);
      this.$nextTick(() => {
        this.$bvModal.hide("modal-performed-by");
      });
    },
    onSelectEmpGrp(event) {
      this.performed_by.emp_group_id = event.val;
      this.performed_by.display_name = event.name;
    },
    onClear( event ) {
      this.performed_by.emp_id = ""
      this.performed_by.display_name = ""
      this.performed_by.emp_group_id = ""
      this.selectedEmpGrp = { val: null, name: null }
      EventBus.$emit("clearEmp", this.performed_by);
    }
  },
  created() {
    EventBus.$on("typeahead_search_user_and_group", newQuery => {
      this.performed_by.emp_group_id = "";
      this.performed_by.emp_id = newQuery.public_id;
      this.performed_by.display_name = newQuery.display_name;
    });
    EventBus.$on("clearData", clear => {
      this.performed_by.emp_id = ""
      this.performed_by.display_name = ""
      this.performed_by.emp_group_id = ""
      this.selectedEmpGrp = { val: null, name: null }
    });
  },
  beforeCreate() {
    EventBus.$off("typeahead_search_user_and_group")
  }
};
</script>