<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col sm="12" md="6" lg="6">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-input-form-group">
                  <div class="clearfix"></div>
                  <div class="def-func-input-group">
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                      <b-form @submit.prevent="passes( addEmpGrp )">
                        <div class="def-func-input-group-col width-6">
                          <ValidationProvider
                            name="Employee Group"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Name of Employee Group</label>
                              <b-form-input
                                placeholder="Employee Group"
                                :state="errors[0] ? false : ( valid ? true : null )"
                                type="text"
                                v-model="addGroupData.name"
                              />
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col width-6">
                          <ValidationProvider
                            name="Function"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <div class="required-field">
                              <label>Select Function</label>
                              <b-form-select
                                :state="errors[0] ? false : ( valid ? true : null )"
                                v-model="addGroupData.function_id"
                                :options="functionResult"
                              ></b-form-select>
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="def-func-input-group-col btn-add-outer width-1">
                          <button type="submit" variant="light" class="btn add-btn th-add-btn">+</button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
                  <div class="def-func-input-form-group">
                    <div class="def-func-input-group mb-0">
                      <div class="def-func-input-group-col btn-search">
                        <b-input-group>
                          <b-form-input placeholder="Search" v-model="paginate_params.search_filter"></b-form-input>
                          <b-input-group-append is-text>
                            <font-awesome-icon :icon="['fas', 'search']" />
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                <grpTable :grpResult="grpResult" class="employee-table"></grpTable>
                <b-pagination
                  v-model="paginate_params.page"
                  :total-rows="rows"
                  :per-page="paginate_params.limit"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                ></b-pagination>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="6" class="pl-0">
            <appaccessrights v-if="grpId != '' || showAccessRights"></appaccessrights>
          </b-col>
        </b-row>
        <b-row>
            <b-col lg="8">
              <div class="def-func-input-form-group">
                <div class="clearfix"></div>
                <div class="def-func-input-group">
                  <ValidationObserver ref="observerEdit" v-slot="{ passes }">
                    <b-form @submit.prevent="passes( editGrp )">
                      <div class="def-func-input-group-col width-7">
                        <ValidationProvider
                          name="Employee Group Title"
                          rules="required"
                          v-slot="{ valid, errors }"
                        >
                          <div class="required-field">
                            <label>Rename Selected Employee Group</label>
                            <b-form-input :disabled="grpId == ''"
                              placeholder="Rename Employee Group"
                              :state="errors[0] ? false : ( valid ? true : null )"
                              v-model="editGroupData.name"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="def-func-input-group-col width-5">
                        <ValidationProvider
                          name="Function"
                          rules="required"
                          v-slot="{ valid, errors }"
                        >
                          <div class="required-field">
                            <label>Select Function</label>
                            <b-form-select :disabled="grpId == ''"
                              v-model="editGroupData.function"
                              :state="errors[0] ? false : ( valid ? true : null )"
                              :options="functionResult"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="def-func-input-group-col btn-rename-delete width-1">
                        <button :disabled="grpId == ''" type="submit" variant="light" class="btn th-add-btn">Rename</button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                  <div class="def-func-input-group-col btn-rename-delete width-1">
                    <button :disabled="grpId == ''" v-b-modal.confirm-delete @click="delete_popup" class="btn th-add-btn" variant="light">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        <div class="clearfix"></div>
      </b-container>
    </div>
    <confirmModal></confirmModal>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import group_table from "../../components/admin_layouts/dashboard_layouts/group_table";
import access_rights from "../../components/admin_layouts/dashboard_layouts/access_rights";
import confirm_modal from "../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { EventBus } from "../../main";
export default {
  data() {
    return {
      functionResult: [
        {
          value: null,
          text: "Select",
          disabled: true
        }
      ],
      addGroupData: {
        name: "",
        function_id: null
      },
      editGroupData: {
        name: "",
        function: null
      },
      grpResult: [],
      grpId: "",
      rows: 10,
      paginate_params: {
        search_filter: "",
        page: 1,
        limit: "10"
      },
      accessRightsData: {
        accessRights: [],
        function: "",
        grpTitle: "",
        grpId: "",
        geographicRights: ""
      },
      response: {
        msg: "",
        popup: false
      },
      showAccessRights: false,
      messsage_loader: false
    };
  },
  components: {
    grpTable: group_table,
    appaccessrights: access_rights,
    confirmModal: confirm_modal
  },
  watch: {
    "paginate_params.page": {
      handler() {
        this.grpResult = [];
        this.getGrpList();
        this.grpId = "";
        this.editGroupData.name = "";
        this.editGroupData.function = null;
        EventBus.$emit("UnCheckAll", this.grpId);
        deep: true;
      }
    },
    "paginate_params.search_filter": {
      handler() {
        this.grpResult = [];
        this.getGrpList();
        this.grpId = "";
        this.editGroupData.name = "";
        this.editGroupData.function = null;
        EventBus.$emit("UnCheckAll", this.grpId);
        deep: true;
      }
    }
  },
  methods: {
    getFuncList() {
      HTTP.get("functions/display_functions")
        .then(response => {
          for (var i = 0; i < response.data.length; i++) {
            this.functionResult.push({
              value: response.data[i].public_id,
              text: response.data[i].title
            });
          }
        })
        .catch(err => {});
    },
    addGrp() {
      HTTP_formdata.post("groups/add_groups", this.addGroupData)
        .then(response => {
          if ( response.status == 200 ) {
            EventBus.$emit("addAccessRight", response.data);
            // this.response.msg = response.data
            // this.response.popup = true
            // EventBus.$emit("success_resp", this.response);
            // this.addGroupData.name = "";
            // this.addGroupData.function_id = null;
            // this.$nextTick(() => {
            //   if (this.$refs.observer) {
            //     this.$refs.observer.reset();
            //   }
            // });
            // this.getGrpList();
            // this.grpId = "";
            // this.editGroupData.name = "";
            // this.editGroupData.function = null;
            // this.showAccessRights = false
            // EventBus.$emit("UnCheckAll", this.grpId);
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.messsage_loader = false;
            this.response.msg = error.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    addEmpGrp() {
      this.showAccessRights = true;
      this.grpId = "";
      this.editGroupData.name = "";
      this.editGroupData.function = null;
      this.$nextTick(() => {
        if (this.$refs.observerEdit) {
          this.$refs.observerEdit.reset();
        }
      });
      let accessRightsData = {
        accessRights: null,
        function: this.addGroupData.function_id,
        grpTitle: this.addGroupData.name,
        grpId: this.grpId,
        geographicRights: null,
        addGrp: this.showAccessRights
      }
      EventBus.$emit("UnCheckAll", this.grpId);
      setTimeout(() => {
        EventBus.$emit("selectGrpAccessRights", accessRightsData);
      }, 10);
    },
    getGrpList() {
      this.editGroupData.name = "";
      this.editGroupData.function = null;
      this.$nextTick(() => {
        if (this.$refs.observerEdit) {
          this.$refs.observerEdit.reset();
        }
      });
      HTTP_formdata.post("groups/display_groups", this.paginate_params)
        .then(response => {
          this.grpResult = response.data.data;
          this.rows = response.data.total;
        })
        .catch(error => {});
    },
    editGrp() {
      HTTP_formdata.put(
        "groups/rename_groups/" + this.grpId,
        this.editGroupData
      )
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editGroupData.name = "";
            this.editGroupData.function = null;
            this.$nextTick(() => {
              if (this.$refs.observerEdit) {
                this.$refs.observerEdit.reset();
              }
            });
            this.getGrpList();
            this.grpId = "";
            EventBus.$emit("UnCheckAll", this.grpId);
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.response.msg = error.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
          }
        });
    },
    deleteGrp() {
      HTTP.delete("groups/delete_groups/" + this.grpId)
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.editGroupData.name = "";
            this.editGroupData.function = null;
            this.$nextTick(() => {
              if (this.$refs.observerEdit) {
                this.$refs.observerEdit.reset();
              }
            });
            this.getGrpList();
            this.grpId = "";
            EventBus.$emit("UnCheckAll", this.grpId);
          }
        })
        .catch(error => {
          if ( error.response.status == 400 ) {
            this.response.msg = error.response.data.response
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
            this.editGroupData.name = "";
            this.editGroupData.function = null;
            this.$nextTick(() => {
              if (this.$refs.observerEdit) {
                this.$refs.observerEdit.reset();
              }
            });
            this.getGrpList();
            this.grpId = "";
            EventBus.$emit("UnCheckAll", this.grpId);
          }
        });
    },
    delete_popup() {
      EventBus.$emit( "deleteSelected", this.editGroupData.name );
    }
  },
  beforeMount() {
    this.getFuncList();
    this.getGrpList();
  },
  created() {
    EventBus.$on("selectGrp", grpIdPassed => {
      this.addGroupData.name = "";
      this.addGroupData.function_id = null;
      this.grpId = grpIdPassed;
      this.$nextTick(() => {
        if (this.$refs.observer) {
          this.$refs.observer.reset();
        }
      });
      HTTP.get("groups/get_group/" + this.grpId)
        .then(response => {
          this.editGroupData.name = response.data.group_name;
          this.editGroupData.function = response.data.function_public_id;
          this.accessRightsData.accessRights = response.data.access_rights;
          this.accessRightsData.function = this.editGroupData.function;
          this.accessRightsData.grpTitle = this.editGroupData.name;
          this.accessRightsData.grpId = this.grpId;
          this.accessRightsData.geographicRights = response.data.geographic_rights;
          EventBus.$emit("selectGrpAccessRights", this.accessRightsData);
        })
        .catch(error => {});
    });
    EventBus.$on("accessRights", response => {
      if ( response ) {
        this.messsage_loader = false;
        this.showAccessRights = false;
        let resp = {
          msg: response,
          popup: true
        }
        EventBus.$emit("success_resp", resp);
        this.editGroupData.name = "";
        this.editGroupData.function = null;
        this.addGroupData.name = "";
        this.addGroupData.function_id = null;
        this.$nextTick(() => {
          if (this.$refs.observer)
            this.$refs.observer.reset();
          if (this.$refs.observerEdit)
            this.$refs.observerEdit.reset();
        });
        this.getGrpList();
        this.grpId = "";
        EventBus.$emit("UnCheckAll", this.grpId);
      }
    });
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteGrp();
      }
    });
    EventBus.$on( "accessRightAdd", response => {
      if ( response == "add" ) {
        this.messsage_loader = true;
        this.addGrp();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
    EventBus.$off("selectGrp")
    EventBus.$off("accessRightAdd")
    EventBus.$off("accessRights")
  }
};
</script>
<style scoped>
  @import "./def_function.css";
  .employee-table {margin-top: -10px;}
  .content-sidebar h5.sidebar-title {margin: 28px 0 0;}
</style>