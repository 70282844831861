<template>
  <table striped responsive>
    <thead>
      <tr>
        <th :class="'text-' + row.align" v-for="( row, index ) in colHead" :key="index"><span>{{ row.label }}</span></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row,index) in colData" :key="index">
        <td class="text-left">{{ row.incident_no }}</td>
        <td class="text-left">{{ row.title }}</td>
        <td class="text-left">{{ row.category }}</td>
        <td class="text-left">{{ row.priority }}</td>
        <td class="text-left">{{ row.station }}</td>
        <td class="text-left">{{ row.reported_by_name | custom_name }}</td>
        <td class="text-center">{{ row.date | moment }}</td>
        <td class="text-center">{{ row.status }}</td>
        <td class="text-center" v-show="area == 'My Incident'">
          <div class="manage-user-action training-action">
            <button>
              <router-link :to="'/incident-action/' + row.public_id">
                <img src="@/assets/images/openbriefing.png" title="Incident Action" />
              </router-link>
            </button>
          </div>
        </td>
        <td class="text-left" v-show="area == 'Reassigned Incident'">{{ row.assigned_to_user }}</td>
        <td class="text-center" v-show="area == 'Reassigned Incident'">
          <div class="manage-user-action training-action">
            <button>
              <router-link :to="'/incident-view/' + row.public_id">
                <img src="@/assets/images/openbriefing.png" title="Incident View" />
              </router-link>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import moment from "moment";
  export default {
    data() {
      return {
        incident: ""
      };
    },
    props:[ "colHead", "colData", "area" ],
    filters: {
      moment: function( value ) {
        return moment( value ).format( "DD MMM YYYY" );
      },
      custom_name( val ) {
        return ( val ) ? val : "Anonymous"
      }
    },
    mounted() {
    }
  };
</script>