<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form class="input-padding breifing-content" @submit.prevent="passes( searchFilter )">
                  <div class="def-func-form">
                    <div class="def-func-input-form-group">
                      <div class="def-func-input-group check-results-sectn">
                        <b-row class="align-bottom">
                          <b-col class="def-func-input-group-col" sm="6" lg="6" xl="3">
                            <b-form-group>
                              <div class="required-field">
                                <label for>Station</label>
                                <!--  v-else<typeahead v-if="userRole == 'ROLE_SUPERADMIN'" :apiFunction="'stations/get_station_codes'"></typeahead> -->
                                <typeahead area="retrieve_reports" :apiFunction="'users/get_stations'"></typeahead>
                                <ValidationProvider name="Station" rules="required" v-slot="{ valid, errors }">
                                  <b-form-input v-show="false" v-model="stationFilter"
                                    :state="errors[0] ? false : ( valid ? true : null )"></b-form-input>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="6" lg="6" xl="3">
                            <b-form-group>
                              <div class="required-field">
                                <label>Function</label>
                                <b-form-select disabled v-model="functionFilter" :options="functionResult"></b-form-select>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="6" lg="6" xl="3">
                            <b-form-group>
                              <div class="required-field">
                                <label for>Checklist</label>
                                <dropdownField
                                  name="check"
                                  :rules="'required'"
                                  :options="checklistOptions"
                                  :error_message="'Checklist'"
                                  :disabled_toggle="( functionFilter != null && stationFilter != '' ) ? false : true"
                                ></dropdownField>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="4" lg="3" xl="2">
                            <ValidationProvider name="Finalized Date" rules="required" v-slot="{ valid, errors }">
                                <b-form-group id="finalized-Date" label="Finalized Date" label-for="Finalized_date" class="required-field">
                                  <b-input-group class="date-field">
                                    <b-input-group-append is-text>
                                      <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                    </b-input-group-append>
                                    <Datepicker v-model="finalizedDate" :highlighted="state.highlighted" :disabled-dates="state.disabledDates"></Datepicker>
                                  </b-input-group>
                                  <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                                  <input
                                    v-show="false"
                                    id="Finalized_date"
                                    name="Finalized_date"
                                    v-model="finalizedDate"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    aria-describedby="Finalized_date_error"
                                  />
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col class="def-func-input-group-col align-el" sm="2" lg="1" xl="1">
                            <div class="def-func-input-group-col btn-add-outer">
                              <button type="submit" variant="light" class="btn add-btn">
                                <font-awesome-icon :icon="['fas', 'search']" />
                              </button>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
              <div class="clearfix"></div>
              <span v-show="showResults">
                <b-row>
                  <b-col>
                    <div class="def-func-input-form-group breifing-content">
                      <div class="def-func-input-group pb-3 mb-0">
                        <div class="def-func-input-group-col briefing-admin-panel width-10">
                          <label
                            class="global-freq freq-resp-title check-title"
                          >Score: <span>{{ score_cal }}%</span></label>
                        </div>
                        <div class="def-func-input-group-col width-4">
                          <label class="float-right global-freq freq-resp-title check-title">Finalized By: <span>{{ finalizedBy }}</span></label>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <checkResultTable :reportData="resultData"></checkResultTable>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-modal
    hide-footer
    id="modal-choose-freq"
    size="lg"
    modal-class="flipModal manage-user-modal"
    title="Choose Frequency"
    centered>
      <div class="def-func-input-group mb-0">
        <b-form>
          <b-row class="justify-content-space-between">
            <b-col md="12" class="text-center">
              <b-button v-for="( freq, index ) in popupData" :key="index" @click.prevent="onSelectFrequency( index, freq )"
                type="text" class="mx-3" variant="light">Due On: {{ freq.due_on.date | moment }} | {{ freq.type | text }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <template v-slot:modal-footer></template>
    </b-modal>
  </div>
</template>
<script>
  import moment from "moment";
  import Datepicker from "vuejs-datepicker";
  import { HTTP_formdata, HTTP } from "../../constants/http-common";
  import { EventBus } from "../../main";
  import dropdownField from "../../components/admin_layouts/dashboard_layouts/fields/static_required_dropdown_field";
  import checkResultTable from "../../components/admin_layouts/dashboard_layouts/check_result_table";
  import typeahead from "../../components/admin_layouts/dashboard_layouts/typeahead_search_stationcode";
  export default {
    data() {
      return {
        stationFilter: "",
        functionFilter: null,
        checklistFilter: null,
        functionResult: [ {  value: null, text: "Select", disabled: true } ],
        checklistOptions: [ { value: null, text: "Select", disabled: true } ],
        finalizedDate: "",
        state: {
          disabledDates: {
            to: "",
            from: new Date()
          },
          highlighted: {
            dates: []
          }
        },
        messsage_loader: false,
        showResults: false,
        resultData: "",
        userRole: "",
        popupData: "",
        score_cal: 0,
        finalizedBy: ""
      };
    },
    components: {
      Datepicker,
      dropdownField,
      checkResultTable,
      typeahead
    },
    methods: {
      searchFilter() {
        this.messsage_loader = true;
        let jsonData = {
          station: this.stationFilter,
          function: this.functionFilter,
          audit_name: this.checklistFilter,
          on_day: this.finalizedDate
        };
        HTTP_formdata.post("audits/checklist_results", jsonData)
          .then(response => {
            if ( response.status == 200 ) {
              this.popupData = response.data.list;
              this.resultData = "";
              if ( this.popupData.length == 0 ) {
                this.showResults = false;
                let resp = {
                  msg: "Data not Found",
                  popup: true
                }
                EventBus.$emit("error_resp", resp);
              } else if ( this.popupData.length == 1 ) {
                this.showResults = true;
                this.resultData = response.data.data[0].result;
                let scount = 0;
                let tmp = ["Passed", "Above Average", "BDP"];
                for ( var i = 0; i < this.resultData.length; i++ ) {
                  if ( this.resultData[i].answers ) {
                    if ( tmp.includes(this.resultData[i].answers) ) {
                      scount++;
                    }
                  }
                }
                this.score_cal = (scount == 0) ? 0 : ((scount/this.resultData.length)*100);
                this.score_cal = (this.score_cal % 1 != 0) ? this.score_cal.toFixed(2) : this.score_cal;
                this.finalizedBy = response.data.data[0].finalised_by;
              } else {
                this.showResults = false;
                this.resultData = response.data.data;
                this.$bvModal.show("modal-choose-freq");
              }
              this.messsage_loader = false;
            }
          })
          .catch(error => {
            if ( error.response.status == 400 || error.response.status == 404 || error.response.status == 500 ) {
              this.messsage_loader = false;
              this.showResults = false;
              this.resultData = ""
              let resp = {
                msg: ( error.response.status == 500 ) ? "Status Code: 500" : error.response.data.message,
                popup: true
              }
              EventBus.$emit("error_resp", resp);
            }
          });
      },
      onChangeFunctionStation(event) {
        if ( this.stationFilter != '' && this.functionFilter != null ) {
          let jsonData = {
            station: this.stationFilter,
            function: this.functionFilter
          };
          HTTP_formdata.post("audits/get_checklists", jsonData)
            .then(response => {
              this.checklistOptions = [ { value: null, text: "Select", disabled: true } ];
              this.checklistFilter = null;
              for (var i = 0; i < response.data.length; i++) {
                this.checklistOptions.push({ value: response.data[i].title, text: response.data[i].title });
              }
            })
            .catch(error => {
              this.checklistOptions = [ { value: null, text: "Select", disabled: true } ];
              this.checklistFilter = null;
            });
        } else {
          this.checklistOptions = [ { value: null, text: "Select", disabled: true } ];
          this.checklistFilter = null;
        }
      },
      getFuncList() {
        HTTP.get("functions/display_functions")
          .then(response => {
            for (var i = 0; i < response.data.length; i++) {
              this.functionResult.push({
                value: response.data[i].public_id,
                text: response.data[i].title
              });
            }
          })
          .catch(err => {});
      },
      onSelectFrequency( index, freq ) {
        this.$bvModal.hide("modal-choose-freq");
        this.finalizedBy = this.resultData[index].finalised_by;
        this.resultData = this.resultData[index].result;
        this.showResults = true;
        let scount = 0;
        let tmp = ["Passed", "Above Average", "BDP"];
        for ( var i = 0; i < this.resultData.length; i++ ) {
          if ( this.resultData[i].answers ) {
            if ( tmp.includes(this.resultData[i].answers) ) {
              scount++;
            }
          }
        }
        this.score_cal = (scount == 0) ? 0 : ((scount/this.resultData.length)*100);
        this.score_cal = (this.score_cal % 1 != 0) ? this.score_cal.toFixed(2) : this.score_cal;
      }
    },
    beforeMount() {
      this.getFuncList();
      this.userRole = this.$cookies.get("user").data.roles[0];
    },
    created() {
      EventBus.$on("send_static_dropdown_value", data => {
        this.checklistFilter = data.data_val;
        HTTP.get( "audits/get_finalized_dates?audit_name=" + encodeURIComponent( this.checklistFilter ) )
          .then(response => {
            if ( response.status == 200 ) {
              for ( var i = 0; i < response.data.length; i++ ) {
                this.state.highlighted.dates.push( new Date( response.data[i] ) );
              }
            }
          })
          .catch(err => {});
      });
      EventBus.$on("typeaheadSearchStationcode", searchStationcode => {
        this.stationFilter = (searchStationcode.public_id) ? searchStationcode.public_id : "";
        this.functionFilter = (searchStationcode.function_id) ? searchStationcode.function_id : "";
        this.onChangeFunctionStation();
      });
    },
    filters: {
      moment: function(value) {
        return moment(value).format("DD MMM YYYY");
      },
      text: function(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    }
  };
</script>
<style scoped>
@import "./def_function.css";
#modal-choose-freq .btn-rename-delete {min-width: 125px;width: 125px;}
</style>
