<template>
  <div>
    <div class="content-outer">
      <b-container>
        <div class="def-func-form">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes( submitIncident )">
              <div class="def-func-input-form-group mb-0">
                <b-row>
                  <b-col>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12">
                          <ValidationProvider name="Title" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Title</label>
                            </div>
                            <b-form-input placeholder="Title" v-model="incidentData.title" :state="errors[0] ? false : ( valid ? true : null )" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="4">
                          <ValidationProvider name="Category" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Category</label>
                            </div>
                            <b-form-select :state="errors[0] ? false : ( valid ? true : null )" v-model="categorySelected"
                              :options="categoryOption"></b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                        <b-col md="4">
                          <ValidationProvider name="Severity" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Severity</label>
                            </div>
                            <b-form-select :state="errors[0] ? false : ( valid ? true : null )" v-model="incidentData.severity"
                              :options="severityOption"></b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                        <b-col md="4">
                          <ValidationProvider name="Station" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Station</label>
                            </div>
                            <b-form-select :state="errors[0] ? false : ( valid ? true : null )" v-model="incidentData.station"
                              :options="stationOption"></b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group" v-if="categorySelected && categorySelected.msg">
                      <b-row class="label-blue">
                        <b-col sm="12">
                          <label>
                            <span>{{ categorySelected.msg }}</span>
                          </label>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12" class="issue-editor">
                          <ValidationProvider name="Description" rules="required" v-slot="{ valid, errors }">
                            <div class="required-field">
                              <label>Description</label>
                            </div>
                            <b-form-textarea class="textarea-no-resize"
                              placeholder="Description" rows="7" max-rows="10" v-model="incidentData.description" no-resize
                              :state="errors[0] ? false : ( valid ? true : null )"
                            ></b-form-textarea>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12" class="attachment-issue">
                          <div class="justify-content-space-between">
                            <div class="width-100">
                              <ValidationProvider name="Attachment" rules="ext:jpg,png,jpeg,pdf" v-slot="{ errors }">
                                <label>Attachment</label>
                                <b-form-file :file-name-formatter="displayFileName" v-model="imgPath" placeholder="Choose a file or drop it here..." ref="attachments" id="attachments" :state="errorMsg ? false : (imgError ? true : null)"
                                  accept="image/jpeg, image/png, .jpg, .png, .jpeg, .pdf, application/pdf" @input="onFileChange( $event )" drop-placeholder="Drop file here..."></b-form-file>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                <p class="feedback-error" v-show="imgError && !errors[0]">{{ errorMsg }}</p>
                              </ValidationProvider>
                            </div>
                            <div class="ml-3 pt-4 mt-3">
                              <b-icon v-b-tooltip.hover.top="'Only pdf, jpg, jpeg, png allowed'" icon="info" class="info-icon-size"></b-icon>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="attachments.length > 0">
                      <b-row class="reportIncidentImages">
                        <b-col md="2" sm="3" cols="4" v-for="( imgData, imgIndex ) in attachments" :key="imgIndex">
                          <b-card :img-src="imgData.path" :img-alt="( imgData.format == 'application/pdf' ) ? 'PDF' : 'Image'" img-top :class="( imgData.format == 'application/pdf' ) ? 'report-pdf-img' : 'report-att-img'" class="mb-3">
                            <b-card-footer class="float-right">
                              <img thumbnail @click="onImgDelete( imgIndex )" src="@/assets/images/delete.png" title="Delete" role="button">
                            </b-card-footer>
                          </b-card>
                        </b-col>
                      </b-row>
                    </div>
                    <!-- <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12">
                          <span v-if="attachments.length > 0">
                            <div class="text-center col">
                              <div class="preview" v-for="( imgData, imgIndex ) in attachments" :key="imgIndex">
                                <img :src="imgData.path" />
                                <div class="clearfix"></div>
                                <div class="training-questn-col questn-action">
                                  <span><img @click="onImgDelete( imgIndex )" src="@/assets/images/delete.png" title="Delete" role="button" tabindex="0"></span>
                                </div>
                              </div>
                            </div>
                          </span>
                        </b-col>
                      </b-row>
                    </div> -->
                    <div class="def-func-input-group">
                      <b-row class="label-blue">
                        <b-col sm="12">
                          <label>
                            <span v-if="incidentData.anonymous">The incident will be reported as Anonymous, you will not receive any email updates.</span>
                            <span v-else>You will receive email updates on the progress of this incident.</span>
                          </label>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-group mt-4">
                      <b-row>
                        <b-col md="12">
                          <b-form-checkbox v-model="incidentData.anonymous" name="checkbox-1">Report as Anonymous</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="def-func-input-form-group">
                      <div class="clearfix"></div>
                      <div class="w-100">
                        <b-button type="submit" variant="light" class="float-right btn-rename-delete">Submit</b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
  import { HTTP_BASE, HTTP_formdata } from "../../../constants/http-common";
  import { isloggedin } from "../../../constants/app-constants";
  import { EventBus } from "../../../main";
  export default {
    data() {
      return {
        incidentData: {
          title: "",
          category: null,
          severity: null,
          station: null,
          description: "",
          anonymous: false,
          attachment: false
        },
        imgPath: null,
        imgError: "",
        errorMsg: "",
        messsage_loader: false,
        categoryOption: [],
        severityOption: [{ value: null, text: "Select", disabled: true }, { value: "High", text: "High" }, { value: "Medium", text: "Medium" }, { value: "Low", text: "Low" }],
        stationOption: [],
        attachments: [],
        incidentId: "",
        categorySelected: null
      }
    },
    watch: {
      categorySelected( val ) {
        if ( val )
          this.incidentData.category = val.value;
      }
    },
    methods: {
      onFileChange( e ) {
        if (this.attachments.length < 10 ) {
          if ( e && ( e.type == "image/png" || e.type == "image/jpeg" || e.type == "png" || e.type == "jpeg" || e.type == "pdf" || e.type == "application/pdf" ) ) {
            this.imgError = false;
            let file = e;
            let path = ( e.type == "application/pdf" ) ? require(`@/assets/images/add-pdf.png`) : URL.createObjectURL(file);
            this.attachments.push({ path: path, data: file, name: "", format: e.type })
          } else {
            this.imgError = true;
            this.errorMsg = "Select a valid file"
            setTimeout(() => {
              this.imgError = false;
              this.errorMsg = ""
            }, 2000);
          }
        } else {
          this.imgError = true;
          this.errorMsg = "Maximum 10 attachments allowed"
          setTimeout(() => {
            this.imgError = false;
            this.errorMsg = ""
          }, 2000);
        }
        this.imgPath = null;
        document.getElementById('attachments').value = ''
      },
      displayFileName( files ) {
        return "Choose a file or drop it here..." ; //( this.attachments.length < 10 ) ? ( !this.imgError ) ? files[0].name : "Choose a file or drop it here..." : "Choose a file or drop it here..."
      },
      submitIncident() {
        this.messsage_loader = true;
        this.incidentData.attachment = ( this.attachments.length == 0 ) ? false : true;
        HTTP_formdata.post( "incident_reports/report_incident", this.incidentData )
          .then( resp => {
            if ( resp.status == 200 ) {
              if ( this.attachments.length > 0 ) {
                this.incidentId = resp.data.public_id;
                this.attachmentUpload();
              } else {
                this.messsage_loader = false;
                let respPopUp = {
                  msg: resp.data.message,
                  popup: true
                }
                EventBus.$emit( "success_resp", respPopUp );
                this.incidentData = {
                  title: "",
                  category: null,
                  severity: null,
                  station: null,
                  description: "",
                  anonymous: false,
                  attachment: false
                }
                this.categorySelected = null;
                this.$nextTick(() => {
                  if ( this.$refs.observer )
                    this.$refs.observer.reset();
                });
              }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 ) {
              this.messsage_loader = false;
              let errorResp = {
                msg: error.response.data.message,
                popup: true
              };
              EventBus.$emit( "error_resp", errorResp );
            }
          });
      },
      onImgDelete(imgIndex) {
        this.attachments.splice(imgIndex, 1);
      },
      async attachmentUpload() {
        let formData = new FormData();
        for ( let index = 0; index < this.attachments.length; index++ ) {
          formData.set( "attachment", this.attachments[index].data );
          let last_file = ( index+1 == this.attachments.length ) ? true : false;
          await HTTP_formdata.post( "incident_reports/upload_attachments/" + this.incidentId + "?anonymous=" + this.incidentData.anonymous + "&last_file=" + last_file, formData )
            .then(response => {
              if ( index + 1 == this.attachments.length ) {
                this.attachments = [];
                this.messsage_loader = false;
                let respPopUp = {
                  msg: response.data.message,
                  popup: true
                }
                EventBus.$emit( "success_resp", respPopUp );
                this.incidentData = {
                  title: "",
                  category: null,
                  severity: null,
                  station: null,
                  description: "",
                  anonymous: false,
                  attachment: false
                }
                this.categorySelected = null;
                this.$nextTick(() => {
                  if (this.$refs.observer)
                    this.$refs.observer.reset();
                });
              }
            })
            .catch(error => {
              if ( error.response.status == 400 ) {
                this.messsage_loader = false;
                let errorResp = {
                  msg: error.response.data.message,
                  popup: true
                };
                EventBus.$emit( "error_resp", errorResp );
              }
            });
        }
      }
    },
    mounted() {
      this.stationOption = [{ value: null, text: "Select", disabled: true }];
      this.categoryOption = [{ value: null, text: "Select", disabled: true }];
      for (var i = 0; i < isloggedin.user_stations.length; i++) {
        this.stationOption.push({
          value: isloggedin.user_stations[i].value,
          text: isloggedin.user_stations[i].text
        });
      }
      HTTP_BASE.get( "incident_report_categories" )
        .then( response => {
          let category = response.data['hydra:member'];
          for (var i = 0; i < category.length; i++) {
            this.categoryOption.push({
              value: { value: category[i].public_id, msg: category[i].message },
              text: ( category[i].message ) ? category[i].category + ' *' : category[i].category
            });
          }
        })
        .catch(err => {});
    }
  }
</script>
<style>
  @import "../def_function.css";
  @import "../dashboard.css";
  .dashboard-text {
    color: #333;
    font-size: 22px;
    margin: 5% 0;
  }
  .dashboard-text img {
      max-width: 35px;
      margin-top: -5px;
  }
  .info-span {
      color: #999;
      padding: 5px;
  }
  .issue-editor .ck.ck-content.ck-editor__editable {
      min-height: 200px;
  }
  .def-func-input-form-group .attachment-issue .custom-file label {
      min-height: 40px;
      border: 1px solid #a9a9a9;
      border-radius: 5px;
      padding: 10px;
  }
  .def-func-input-form-group .attachment-issue .custom-file label::after {
      height: 38px;
      padding: 8px 20px;
      font-size: 16px;
      background: #003261;
      color: white;
      font-weight: bold;
      letter-spacing: 0.03rem;
  }
  .textarea-no-resize {overflow-y: auto !important;}
  .reportIncidentImages .card{border: 1px solid #e8ecf1; border-radius: 5px; padding: 15px;}
  .reportIncidentImages .card-body{padding: 0;background-color: white;}
  .reportIncidentImages .card-footer{background-color: transparent;padding: 6px 10px;border: 1px solid #e8ecf1;margin-top: 12px;}
  .reportIncidentImages [class*=col-]:first-child,.reportIncidentImages [class*=col-]:nth-child(6n) {margin-left: 8.333333%;}
  .reportIncidentImages .card-footer img{width: 12px;}
  .label-blue label span {color: #003261;}
  .report-pdf-img img{max-width: 100px; margin: auto;}
  /* .reportIncidentImages .card-img-top {width: 100%;height: 12vw;object-fit: cover;} */
  @media only screen and (max-width: 768px){
    .reportIncidentImages [class*=col-]:first-child,.reportIncidentImages [class*=col-]:nth-child(6n) {margin-left: 0;}
  }
</style>