<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mt-4 mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">My Incidents</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mb-4">
                  <div class="def-func-table manage-users-table mt-4">
                    <incidentTable area="My Incident" :colHead="incident.myIncidentLabel" :colData="incident.myIncidentData"></incidentTable>
                  </div>
                  <b-pagination
                    v-model="paginate_params.incidentPage"
                    :total-rows="paginate_params.incidentRow"
                    :per-page="paginate_params.incidentLimit"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                  ></b-pagination>
                </b-col>
              </b-row>
              <div class="clearfix"></div>
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">Reassigned/Closed Incidents</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="def-func-table manage-users-table mt-4">
                    <incidentTable area="Reassigned Incident" :colHead="incident.reassignedIncidentLabel" :colData="incident.reassignedIncidentData"></incidentTable>
                  </div>
                  <b-pagination
                    v-model="paginate_params.reassignedPage"
                    :total-rows="paginate_params.reassignedRow"
                    :per-page="paginate_params.reassignedLimit"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
  import { HTTP } from "../../../constants/http-common";
  import incidentTable from "../../../components/admin_layouts/dashboard_layouts/incident_table.vue"
  export default {
    components: {
      incidentTable
    },
    data() {
      return {
        incident: {
          myIncidentLabel: [],
          myIncidentData: [],
          reassignedIncidentLabel: [],
          reassignedIncidentData: []
        },
        paginate_params: {
          incidentPage: 1,
          incidentLimit: 10,
          incidentRow: 10,
          reassignedPage: 1,
          reassignedLimit: 10,
          reassignedRow: 10
        }
      };
    },
    watch: {
      "paginate_params.incidentPage": {
        handler() {
          this.incidentData();
          deep: true;
        }
      },
      "paginate_params.reassignedPage": {
        handler() {
          this.incidentData();
          deep: true;
        }
      }
    },
    mounted() {
      this.incident.myIncidentLabel = [ { align:"left", label: "Incident No." }, { align:"left", label: "Title" }, { align:"left", label: "Category" }, { align:"left", label: "Priority" }, { align:"left", label: "Station" }, { align:"left", label: "Reported By" }, { align:"center", label: "Date" }, { align:"center", label: "Status" }, { align:"center", label: "Actions" } ]
      this.incident.reassignedIncidentLabel = [ { align:"left", label: "Incident No." }, { align:"left", label: "Title" }, { align:"left", label: "Category" }, { align:"left", label: "Priority" }, { align:"left", label: "Station" }, { align:"left", label: "Reported By" }, { align:"center", label: "Date" }, { align:"center", label: "Status" }, { align:"left", label: "Assigned to" }, { align:"center", label: "Actions" } ]
      this.incidentData();
    },
    methods: {
      incidentData() {
        HTTP.get( "incident_reports/my_incidents?incident_page=" + this.paginate_params.incidentPage + "&incident_limit=" + this.paginate_params.incidentLimit + "&reassigned_page=" + this.paginate_params.reassignedPage + "&reassigned_limit=" + this.paginate_params.reassignedLimit )
        .then( response => {
          if ( response.status == 200 ) {
            this.incident.myIncidentData = response.data.my_incidents.data
            this.paginate_params.incidentRow = response.data.my_incidents.total
            this.incident.reassignedIncidentData = response.data.reassigned_incidents.data
            this.paginate_params.reassignedRow = response.data.reassigned_incidents.total
          }
        })
        .catch( error => {} )
      }
    }
  };
</script>
<style>
  @import "../def_function.css";
</style>