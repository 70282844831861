<template>
  <div>
    <div class="content-outer setup-test-question">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <b-form class="input-padding">
                <div class="def-func-form">
                  <div class="def-func-input-form-group">
                    <div class="def-func-input-group mb-0">
                      <div class="test-questn-sectn">
                        <div class="briefing-admin-panel justify-content-space-between panel-cols panel-align-top">
                          <div>
                            <label>
                              Training:
                              <b-form-select class="testQuestn-field" disabled v-model="selectedTraining" :options="trainingOptions"></b-form-select>
                            </label>
                          </div>
                          <div class="margint-6">
                            <label>
                              Modules:
                              <span>{{noOfModules}}</span>
                            </label>
                          </div>
                          <div  v-if="testQuestion === 'final-question'">
                            <div class="briefing-rqd-panel">
                              <label>
                                <span>Total Points</span>:
                                <b-input-group class="total-points-field">
                                  <b-form-input @change="totalPointsVal($event)" v-model="totalPoints_save"></b-form-input>
                                </b-input-group>
                              </label>
                            </div>
                            <div class="feedback-error total-points-error" v-show="noPointsError"><p v-if="!totalPoints_save || totalPoints_save == 0">Enter Total points</p></div>
                          </div>
                          <div class="margint-6">
                            <b-form-group class="add-media-inner">
                              <b-form-radio-group id name="set-up-question">
                                <b-form-radio
                                  v-model="testQuestion"
                                  value="pre-test"
                                >Set Up Pre Test Questions</b-form-radio>
                              </b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="margint-6">
                            <b-form-group class="add-media-inner">
                              <b-form-radio-group id name="set-up-question">
                                <b-form-radio
                                  v-model="testQuestion"
                                  value="final-question"
                                >Set Up Final Questions</b-form-radio>
                              </b-form-radio-group>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <appFinalQuestion
                        v-if="testQuestion === 'final-question'"
                        :selected_training="selectedTraining"
                        :totalPoints_save="totalPoints_save"
                      ></appFinalQuestion>
                      <appPreTestQuestions
                        v-else-if="testQuestion === 'pre-test'"
                        :selected_training="selectedTraining"
                        :moduleOptions="moduleOptions"
                      ></appPreTestQuestions>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import final_question from "./setup_types/final_question";
import pre_test_questions from "./setup_types/pre_test_questions";
import { EventBus } from "../../../main";
import { HTTP_formdata } from "../../../constants/http-common";
export default {
  components: {
    appFinalQuestion: final_question,
    appPreTestQuestions: pre_test_questions
  },
  data() {
    return {
      noPointsError:false,
      noOfModules:0,
      selectedTraining: this.$route.params.trainingid,
      trainingOptions: [],
      totalPoints_save:0,
      testQuestion: "",
      moduleOptions: []
    };
  },
  created(){
    EventBus.$on("send-root-total-points", totalPoints_save => {
      this.totalPoints_save = totalPoints_save
    })
    EventBus.$on("zero-total-points", noPointsError => {
      this.noPointsError = noPointsError
    })
  },
  watch: {
    selectedTraining(trainingId) {
      EventBus.$emit("training-changed", trainingId);
    },
    testQuestion(newVal) {
      if ( newVal == 'final-question' ) {
        setTimeout(() => {
          this.totalPointsVal();
        }, 2000);
      }
    }
  },
  methods: {
    get_all_trainings() {
      this.trainingOptions = [
        { value: null, text: "Select",disabled:true }
      ];
      let formData = {
        page: "",
        limit: ""
      };
      HTTP_formdata.post("training/get_all_trainings", formData)
        .then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.trainingOptions.push({
              value: response.data.data[i].public_id,
              text: response.data.data[i].title
            });
          }
        })
        .catch(response => {});
    },
    get_modules() {
      this.moduleOptions = [{ value: null, text: "Select", disabled:true }];
      let formData = {
        training: this.$route.params.trainingid
      };
      HTTP_formdata.post("training/get_modules", formData)
        .then(response => {
          this.noOfModules = response.data.length
          for (let i = 0; i < response.data.length; i++) {
            this.moduleOptions.push({
              value: response.data[i].public_id,
              text: response.data[i].title
            });
          }
        })
        .catch(response => {});
    },
    totalPointsVal(event) {
      if ( this.totalPoints_save < 0 ) {
        this.totalPoints_save = 0;
      } else if ( isNaN( Number(this.totalPoints_save) ) == true ) {
        this.totalPoints_save = 0;
      }
      EventBus.$emit("send-final-quest-total-points", this.totalPoints_save);
    }
  },
  mounted() {
    this.get_all_trainings();
    this.get_modules();
  }
};
</script>
<style>
@import "../def_function.css";
.total-points-error {margin-left: 110px;}
.panel-align-top {align-items: start;}
.attachment-btn {float: right;margin-top: -40px;position: relative;z-index: 3;}
.attachment-btn .input-group-text svg {font-size: 26px;}
.preview {margin: 20px 10px 0;;padding: 15px;border: 1px solid #e8ecf1;border-radius: 5px;display: inline-block;max-width: 40%;text-align: center;position: relative;}
.preview img {max-width: 100%;max-height: 400px;}
.preview .training-questn-col.questn-action {
    float: right;
    margin-top: 15px;
    right: 0;
    width: 50px;
    padding: 10px 10px 10px 0;
    border: 1px solid #f5f5f5;
}
.margint-6 {
    margin-top: 6px;
}
.briefing-station-code .preview {max-width: calc(33.33% - 20px);}
.setup-test-question .faq-fileInput label.custom-file-label {
    display: none;
}
.setup-test-question label.custom-control-label::before, .setup-test-question label.custom-control-label::after {
    margin-top: 3px;
}
.attachment-btn input#fileInput {
    font-size: 0;
}
</style>