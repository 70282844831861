<template>
  <b-col xl="9" lg="12" class="training-update-container linegraph-wrapper">
    <Highcharts v-if="data" ref="lineGraphTraining" :options="options" />
  </b-col>
</template>
<script>
  import Highcharts from 'highcharts';
  import { genComponent } from 'vue-highcharts';
  export default {
    props: [ "data" ],
    data() {
      return {
        options: {}
      }
    },
    watch: {
      data( val ) {
        if ( val ) {
          this.options = {
            title: {
              text: 'onboarding training user development',
              x: -20 //center
            },
            xAxis: {
              categories: this.data.week
            },
            credits: {
                enabled: false
            },
            yAxis: {
              title: { text: '' },
              plotLines: [{
                value: 0,
                width: 1,
                color: '#808080'
              }]
            },
            tooltip: { valueSuffix: '%' },
              // formatter() { return this.x + " week " + this.series.name + " ---" + this.y + "%"; },
            series:
            [{
              name: 'Active users',
              data: this.data.statistics[0].data
            }, {
              name: 'Training completed from total # of users',
              data: this.data.statistics[1].data
            }]
          }
        }
      }
    },
    components: {
      Highcharts: genComponent('Highcharts', Highcharts),
    }
  }
</script>
