<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <div class="def-func-table manage-users-table">
                  <appdatatables :checklist="checklist"></appdatatables>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-container>
    </div>
  </div>
</template>
<script>
  import datatables from "./checklists_tables";
  import { HTTP_formdata } from "../../../constants/http-common";
  export default {
    data() {
      return {
        currentPage: 1,
        rows: 10,
        perPage: 10,
        checklist: []
      };
    },
    components: {
      appdatatables: datatables
    },
    watch: {
      currentPage(newVal) {
        this.get_all_checklists();
      }
    },
    methods: {
      get_all_checklists() {
        this.checklist = [];
        let jsonData = {
          page: this.currentPage,
          limit: this.perPage
        };
        HTTP_formdata.post( "audits/list_checklists", jsonData )
          .then(response => {
            for (let i = 0; i < response.data.data.length; i++) {
              this.checklist = response.data.data;
              this.rows = response.data.total
            }
          })
          .catch(error => {});
      }
    },
    mounted() {
      this.get_all_checklists();
    }
  };
</script>
<style>
  @import "../def_function.css";
</style>