var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"hide-footer":"","id":"modal-global-database","size":"lg","modal-class":"flipModal manage-user-modal","title":"Global Database","centered":""},on:{"show":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return undefined},proxy:true}])},[_c('div',{staticClass:"def-func-input-group mb-0"},[_c('div',{staticClass:"def-func-input-form-group"},[_c('ValidationObserver',{ref:"observerGlobalAdd",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes( _vm.addGlobalStm )}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Function","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"required-field"},[_c('label',[_vm._v("Function")])]),_c('b-form-select',{staticClass:"mb-3",attrs:{"state":errors[0] ? false : ( valid ? true : null ),"options":_vm.functionList},on:{"change":function($event){return _vm.changeFunction( $event )}},model:{value:(_vm.functionId),callback:function ($$v) {_vm.functionId=$$v},expression:"functionId"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Global Statement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"required-field"},[_c('b-form-group',{staticClass:"mt-4 global-statement",attrs:{"label":"Select optional Checks from Global Database"}},[_c('b-form-checkbox-group',{attrs:{"stacked":"","state":errors[0] ? false : ( valid ? true : null ),"options":_vm.statementGlobal},model:{value:(_vm.statementsSelected),callback:function ($$v) {_vm.statementsSelected=$$v},expression:"statementsSelected"}})],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"create-new-check-btn btn-rename-delete"},[_c('button',{staticClass:"btn btn-rename-delete",attrs:{"type":"submit","variant":"light"}},[_vm._v("Add")])])])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }