<template>
  <div class="outer_404">
    <appheader></appheader>
    <div class="inner_404" v-if="!showMessage">
      <div class="title_404">Processing Data</div>
      <div class="subTitle_404">Please wait while we complete processing your training</div>
    </div>
    <div class="inner_404" v-if="showMessage">
        <div class="title_404"> Processing Complete  </div>
      <div class="subTitle_404"> You may now close this page</div>
    </div>
  </div>
</template>
<script>
import { HTTP_formdata } from "../../constants/http-common";
import { EventBus } from "../../main";
import header from "../../../src/components/admin_layouts/header";

export default {
  components: {
    appheader: header,
  },
   data() {
    return {
      showMessage: false, 
    };
  },
  methods: {
      showTraining() {
         let getURL= this.$route.query.dist_id;
          console.log('url', getURL)
      let formData = {
        dist_id : getURL
      };
      HTTP_formdata.post("training/show_quiz", formData)
        .then(response => {
              if ( response.status == 200 ) {
                  console.log('success')
                let res = {
                  popup:true,
                  popupActive:true,
                  msg: response.data.message
                }
                EventBus.$emit( "success_resp", res );
                setTimeout(() => (this.showMessage = true), 4500)
              }
              })
        .catch(response => {});
    }
  },
  mounted() {
    this.showTraining();
  },
};
</script>
<style scoped>
@import "./def_function.css";
@import "./404.css";
</style>