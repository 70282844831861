<template>
  <span>
    <table>
      <thead>
        <tr>
          <th class="text-left">
            <span>{{constants.BRIEFING_TABLE_HEADER_1}}</span>
          </th>
          <th class="text-left">
            <span>{{constants.BRIEFING_TABLE_HEADER_2}}</span>
          </th>
          <th class="text-left">
            <span>{{constants.BRIEFING_TABLE_HEADER_3}}</span>
          </th>
          <th class="text-left">
            <span>Created By</span>
          </th>
          <th class="text-center table-date">
            <span>{{constants.BRIEFING_TABLE_HEADER_4}}</span>
          </th>
          <th class="text-center table-date">
            <span>{{constants.BRIEFING_TABLE_HEADER_5}}</span>
          </th>
          <th class="table-date">
            <span>{{constants.BRIEFING_TABLE_HEADER_6}}</span>
          </th>
          <th class="text-center">
            <span>{{constants.BRIEFING_TABLE_HEADER_7}}</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="table_info.length > 0">
        <tr v-for="(data,index) in table_info" :key="index" :class="data.status == 'deactive' ? 'inactive' : ''">
          <td class="text-left">{{ data.title }}</td>
          <td class="text-left">{{ data.fun_title }}</td>
          <td class="text-left">{{ data.type }}</td>
          <td class="text-left">{{ data.createdBy.first | firstLetterCapital }} {{ data.createdBy.last | firstLetterCapital }}</td>
          <td>{{ data.from_date.date | moment }}</td>
          <td v-if="data.to_date != 'UFN'">{{  data.to_date | moment  }}</td>
          <td v-else>{{  data.to_date  }} </td>
          <td class="text-left">{{ (typeof data.ack_rate === 'string')? data.ack_rate:data.ack_rate.toFixed(2)+"%" }} </td>
          <td>
            <div class="manage-user-action icons-6">
              <button @click.prevent="show_briefings(data)" :class="( data.faqs_status == 1 && data.status != 'deactive' ) ? 'brief-notify' : ''">
                <img src="@/assets/images/openbriefing.png" title="Open Briefing" />
              </button>
              <!-- <button v-b-modal.modal-ack-brief @click.prevent="check_acknowledge_users(data,index)">
                <img v-if="data.acknowledgment == 0" src="@/assets/images/acknowledgebriefing.png" title="Acknowledge Briefing" />
                <img v-else src="@/assets/images/briefingacknowledged.png" title="Briefing Acknowledged" />
              </button> -->
              <button v-if="data.acknowledgment == false" disabled @click.prevent="set_distribution(data, 'edit')">
                <img src="@/assets/images/editbriefing.png" title="Edit" />
              </button>
              <button v-else :disabled="( data.distribution == 0 || data.status == 'deactive' ) ? false : true" @click.prevent="set_distribution(data, 'edit')">
                <img src="@/assets/images/editbriefing.png" title="Edit" />
              </button>
              <button v-if="data.acknowledgment == false" disabled @click.prevent="set_distribution(data, 'distribute')">
                <img src="@/assets/images/distribute.svg" title="Distribute Briefing" />
              </button>
              <button v-else :disabled="( data.distribution == 2 || data.status == 'deactive' ) ? true : false" @click.prevent="set_distribution(data, 'distribute')">
                <img src="@/assets/images/distribute.svg" title="Distribute Briefing" />
              </button>
              <button v-if="data.status == 'deactive' || data.acknowledgment == false" disabled class="distribute_history" >
                <img src="@/assets/images/history.png" title="Distribution History">
              </button>
              <button v-else :disabled="( data.distribution == 1 ) ? false : true" class="distribute_history" @click.prevent="set_distribution(data, 'history')" v-b-modal.modal-briefing-history>
                <img src="@/assets/images/history.png" title="Distribution History">
              </button>
              <button v-if="data.continuous_distribution || data.acknowledgment == false" :disabled="( data.distribution == 2 || data.status == 'deactive' ) ? true : false" v-b-modal.confirm-delete @click.prevent="confirm_popup(data, 'deactivate')">
                <img src="@/assets/images/continousDistributionA.png" title="Continuous Distribution - Active" />
              </button>
              <button v-else :disabled="( data.distribution == 2 || data.status == 'deactive' ) ? true : false" v-b-modal.confirm-delete @click.prevent="confirm_popup(data, 'activate')">
                <img src="@/assets/images/continousDistributionInA.png" title="Continuous Distribution - Inactive" />
              </button>
              <button v-if="data.status == 'deactive' || data.acknowledgment == false" disabled>
                <img src="@/assets/images/delete.png" title="Delete Briefing" />
              </button>
              <button v-else :disabled="( data.distribution == 0 || data.distribution == 1 ) ? false : true" v-b-modal.confirm-delete @click.prevent="confirm_popup(data)">
                <img src="@/assets/images/delete.png" title="Delete Briefing" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <historyModal historyArea="briefings" :distributeId="distributeId" :historyTitle="historyTitle"></historyModal>
    <confirmModal></confirmModal>
  </span>  
</template>
<script>
import { EventBus } from "../../../main";
import { LIST_OF_BRIEFINGS } from "../../../constants/app-constants";
import moment from "moment";
import confirm_modal from "../../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import historyModal from "../../../components/admin_layouts/dashboard_layouts/history_modal";
import { HTTP, HTTP_formdata } from "../../../constants/http-common";

export default {
  components: {
    confirmModal: confirm_modal,
    historyModal
  },
  props: {
    table_info: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      constants: LIST_OF_BRIEFINGS,
      deleteData: "",
      distributeId: "",
      historyTitle: "",
      continuousDistributionData: ""
    };
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    },
    firstLetterCapital: function(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  created() {
    EventBus.$on("briefings-acknowlwdged", index => {
      this.table_info[index].acknowledgment = 1;
    });
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteBriefing();
      } else {
        this.continuousDistribution();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation");
  },
  methods: {
    check_acknowledge_users(row_data, index) {
      row_data.index = index;
      EventBus.$emit("check_acknowledge_users", row_data);
    },
    set_distribution(row_data, action) {
      let uuid = row_data.public_id;
      if ( action == 'distribute' ) {
        this.$router.push({ path: "/distribute/briefings/" + uuid });
      } else if ( action == 'edit' ) {
        this.$router.push({ path: "/edit_briefing/" + uuid });
      } else if ( action == "history") {
        this.distributeId = uuid;
        this.historyTitle = row_data.title;
      }
    },
    show_briefings(row_data) {
      this.$router.push({ path: "/display_briefing/" + row_data.public_id });
    },
    deleteBriefing() {
      HTTP.delete( "briefings/delete_briefings/" + this.deleteData.public_id )
        .then(response => {
          if ( response.status == 200 ) {
            let resp = { msg: response.data, popup: true };
            EventBus.$emit( "success_resp", resp );
            this.deleteData = "";
            EventBus.$emit("refreshBriefing", true);
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            let resp = { msg: err.response.data.response, popup: true };
            EventBus.$emit( "error_resp", resp );
            this.deleteData = "";
          }
        });
    },
    confirm_popup(row_data, action) {
      if (action) {
        this.continuousDistributionData = row_data;
        this.continuousDistributionData.confirmToState = action;
        EventBus.$emit( "deleteSelected", this.continuousDistributionData );
      } else {
        this.deleteData = row_data;
        EventBus.$emit( "deleteSelected", this.deleteData.title );
      }
    },
    continuousDistribution() {
      let jsonData = {
        module: this.$route.meta.route_ref,
        value: ( this.continuousDistributionData.confirmToState == 'activate' ) ? true : false
      }
      HTTP_formdata.put( "distribution/continuous_distribution/" + this.continuousDistributionData.public_id, jsonData )
        .then(response => {
          if ( response.status == 200 ) {
            let resp = { msg: response.data.message, popup: true };
            EventBus.$emit( "success_resp", resp );
            this.continuousDistributionData = "";
            EventBus.$emit("refreshBriefing", true);
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            let resp = { msg: err.response.data.message, popup: true };
            EventBus.$emit( "error_resp", resp );
            this.continuousDistributionData = "";
          }
        });
    }
  }
};
</script>
<style scoped>
.faq-tablist .card {
    margin: 20px 0 0;
}
.distribute_history img {
  max-width: 23px;
}
tr.inactive td {
  color: #cccccc;
}
tr.inactive .btn-inactive {
  opacity: 0.15;
  cursor: default;
}
</style>