<template>
  <vue-bootstrap-typeahead
    :data="typeData"
    ref="typeaheadinternaluser"
    v-model="typesearch"
    :serializer="item => item.name"
    placeholder="Last Name, First Name OR Email Address"
    :minMatchingChars="1"
    @hit="selectedTypeAhead = $event"
  ></vue-bootstrap-typeahead>
</template>
<script>
import { HTTP } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  props: [ "api" ],
  data() {
    return {
      typeData: [],
      typesearch: "",
      selectedTypeAhead: null
    };
  },
  watch: {
    typesearch(newQuery) {
      if (newQuery) {
        HTTP.get( this.api + "filter=" + newQuery )
          .then(response => {
            if ( response.status == 200 ) {
              if ( response.data.length > 0 )
                this.typeData = response.data;
              else
                EventBus.$emit( "typeahead_search_internal_user", { username: newQuery } );
            }
          })
          .catch(err => {
            // if (this.$refs.typeaheadinternaluser)
            //   this.$refs.typeaheadinternaluser.inputValue = ''
            // this.typesearch = ""
            // this.typeData = [];
            EventBus.$emit( "typeahead_search_internal_user", { username: newQuery } );
          });
      } else {
        if (this.$refs.typeaheadinternaluser)
          this.$refs.typeaheadinternaluser.inputValue = ''
        this.typeData = [];
        EventBus.$emit( "typeahead_search_internal_user", { username: newQuery } );
      }
    },
    selectedTypeAhead(newQuery) {
      EventBus.$emit( "typeahead_search_internal_user", newQuery );
    }
  },
  created() {
    EventBus.$on("clearInternal", val => {
      if ( this.$refs.typeaheadinternaluser )
        this.$refs.typeaheadinternaluser.inputValue = "";
      this.typesearch = "";
      this.typeData = [];
    });
  }
};
</script>