<template>
  <div>
    <ValidationProvider name="Functional Relevance" rules="required" v-slot="{ valid, errors }">
      <div class="required-field">
        <label for="input-1">Functional Relevance</label>
        <b-input-group class="mb-4">
          <b-form-select
            id="functional_relevance"
            name="example-input-2"
            v-model="functional_relevance"
            :options="functions"
            :state="errors[0] ? false : (valid ? true : null)"
            aria-describedby="functional_relevance_error"
            @change="send_data"
          ></b-form-select>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-input-group>
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
import { HTTP, HTTP_formdata } from "../../../../constants/http-common";
import { isloggedin } from "../../../../constants/app-constants";
import { EventBus } from "../../../../main";
export default {
  data() {
    return {
      functional_relevance: null,
      functions: []
    };
  },
  methods: {
    send_data() {
      EventBus.$emit( "send-functional-relevance-data", this.functional_relevance );
    }
  },
  mounted: function() {
    this.functions = [];
    if ( isloggedin.roles[0] == 'ROLE_SUPERADMIN' ||  isloggedin.set_up_training == "global" ) {
      this.functions.push({ value: null, text: "Select", disabled: true },{ value: 'all', text: "All" });
      HTTP.get("functions/display_functions")
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            this.functions.push({
              text: response.data[i].title,
              value: response.data[i].public_id
            });
          }
        })
        .catch(error => {});
    } else {
      this.functions.push({ text: "Select", value: null, disabled:true });
      let jsonData = {
        filter: "function",
        value: "",
        geographic_right: isloggedin.set_up_training
      }
      HTTP_formdata.post("training/training_filters", jsonData)
        .then(response => {
          if ( response.status == 200 ) {
            for ( let i = 0; i < response.data.values.length; i++ ) {
              this.functions.push({
                text: response.data.values[i].text,
                value: response.data.values[i].value
              });
            }
          }
        })
        .catch(error => {});
    }
  }
};
</script>