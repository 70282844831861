<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <b-form class="input-padding">
                <div class="def-func-form">
                  <div class="def-func-input-form-group">
                    <div class="def-func-input-group mb-0">
                      <div class="test-questn-sectn perform-check">
                        <div class="briefing-admin-panel justify-content-space-between panel-cols">
                          <div>
                            <label>
                              Check:
                              <span>{{ checklistDetails.title }}</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              Station:
                              <span>{{ checklistDetails.station_name }}</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              Due Date:
                              <span class="upperCase" :class="checklistDetails.pending == 1 ? 'text-danger' : ''">{{ checklistDetails.due_on | moment }}</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              Progress:
                              <span>{{ (cal.percentage) + '%' }}</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              Score:
                              <span>{{ (cal.score) + '%' }}</span>
                            </label>
                          </div>
                          <div>
                            <div class="def-func-input-group-col btn-rename-delete">
                              <b-button
                                type="submit"
                                variant="light"
                                class="btn"
                                @click.prevent="submitPerformCheck( $event, 'Save' )"
                              >Save</b-button>
                            </div>
                            <div class="def-func-input-group-col btn-rename-delete">
                              <b-button
                                :disabled="cal.percentage != 100"
                                type="submit"
                                variant="light"
                                class="btn"
                                v-b-modal.modal-finalize-perform-check @click.prevent
                              >Finalize</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <appPerformCheckQuestion></appPerformCheckQuestion>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      hide-footer
      id="modal-finalize-perform-check"
      size="md"
      modal-class="flipModal manage-user-modal"
      title="Finalize"
      centered
    >
      <div class="def-func-input-form-group">
        <b-row>
          <b-col>
            <div class="def-func-input-group test-questn-sectn mb-4">
              <label>Check Finalized By</label>
            </div>
            <div class="perform-finalize-check">
              <label style="text-transform: capitalize">{{ LogInUserDetails.name.first }} {{ LogInUserDetails.name.last }}</label>
              <label>{{ userGrp }}</label>
              <label class="check-date upperCase">{{ new Date() | momentDateTime }}</label>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button @click.prevent="submitPerformCheck( $event, 'Finalize' )" class="btn-rename-delete float-right" variant="light">Confirm</b-button>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer></template>
    </b-modal>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import perform_check_question from "../../components/admin_layouts/dashboard_layouts/perform_check_question";
import { HTTP_formdata } from "../../constants/http-common";
import { EventBus } from "../../main";
import { isloggedin } from "../../constants/app-constants";
import moment from "moment";
export default {
  components: {
    appPerformCheckQuestion: perform_check_question
  },
  data() {
    return {
      checklistId: "",
      checklistDetails: "",
      cal: {
        percentage: 0,
        score: 0
      },
      checkData: "",
      response: {
        msg: "",
        popup: false
      },      
      LogInUserDetails: isloggedin,
      userGrp: "",
      messsage_loader: false,
      userId: ""
    };
  },
  methods: {
    performChecklistDetails() {
      this.checklistId = this.$route.params.checklistId
      if ( $cookies.get("__check") ) {
        let due_on = $cookies.get("__check").due_on[0]
        this.userId = $cookies.get("__check").user
        let checklist = { audit: this.checklistId, due_on: due_on, user: this.userId }
        HTTP_formdata.post( "audits/get_saved_checklists", checklist )
          .then(response => {
            if ( response.status == 200 )  {
              this.checklistDetails = response.data.data[0]
              this.userGrp = response.data.group_name
              this.userId = response.data.data[0].user
              EventBus.$emit( "checkStatementData", this.checklistDetails );
            } else {
              this.$router.push({path:"/pending_checks"})           
            }
          })
          .catch(error => {
            this.$router.push({path:"/pending_checks"})
          });
      } else {
        this.$router.push({path:"/pending_checks"})
      }
    },
    submitPerformCheck( event, action ) {
      if ( event != "saveCheck" ) {
        this.messsage_loader = true;
      }
      let jsonData = {
        audit: this.checklistId,
        status: "",
        results: this.checkData,
        due_on: this.checklistDetails.due_on,
        user: this.userId
      }
      if ( action == 'Save' ) {
        jsonData.status = "In-Progress";
      } else if ( action == 'Finalize' ) {
        jsonData.status = "Completed";
      }
      HTTP_formdata.post( "auditReports/audit_reports", jsonData )
        .then(response => {
          if ( response.status == 200 ) {
            if ( event == "saveCheck" ) {
              EventBus.$emit("reportId", response.data.report_id);
            } else {
              this.messsage_loader = false;
              this.response.msg = response.data.message
              this.response.popup = true
              EventBus.$emit("success_resp", this.response);
              EventBus.$emit("clearMsg", 'clear');
            }
            if ( response.data.status == 'Completed' ) {
              this.$router.push({path:"/pending_checks"})
            }
          }
        })
        .catch(error => {});
    }
  },
  filters: {
    moment: function(value) {
      return moment(value).format("DD MMM YYYY");
    },
    momentDateTime: function(value) {
      return moment(value).format("DD MMM YYYY | HH:mm");
    }
  },
  mounted() {
    this.performChecklistDetails()    
  },
  created() {
    EventBus.$on( "calculate", cal => {
      this.cal.percentage = (cal.percentage_cal % 1 != 0) ? cal.percentage_cal.toFixed(2) : cal.percentage_cal
      this.cal.score = (cal.score_cal % 1 != 0) ? cal.score_cal.toFixed(2) : cal.score_cal
    });
    EventBus.$on( "checkDetails", details => {
      this.checkData = details;
    });
    EventBus.$on( "saveCheck", action => {
      this.submitPerformCheck( "saveCheck", action )
    });
  },
  beforeCreate() {
    EventBus.$off("calculate")
    EventBus.$off("checkDetails")
    EventBus.$off("saveCheck")
  }
};
</script>
<style>
@import "./def_function.css";
</style>
