<template>
  <div>
    <div class="def-func-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              <span>Station Name</span>
            </th>
            <th>
              <span>Code</span>
            </th>
            <th>
              <span>Address</span>
            </th>
            <th>
              <span>Region</span>
            </th>
            <th>
              <span>Function</span>
            </th>
            <th>
              <span>Org Code</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="( result, index ) in stationResult" :key="index">
            <td>
              <b-form-radio
                name="station"
                v-model="stationIdPassed"
                :value="result.public_id"
                @change="onSelectStation( $event, result )"
              />
            </td>
            <td class="text-left">{{ result.stationName }}</td>
            <td class="text-left">{{ result.code }}</td>
            <td class="text-left">
              {{ result.address[0].line1 }}
              <br />
              {{ result.address[0].line2 }}
            </td>
            <td class="text-left">{{ result.org_region }}</td>
            <td class="text-left">{{ result.org_function }}</td>
            <td class="text-left">{{ result.org_code }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { EventBus } from "../../../main";
export default {
  props: ["stationResult"],
  data() {
    return {
      stationSelectedData: "",
      stationIdPassed: ""
    };
  },
  methods: {
    onSelectStation(event, result) {
      this.stationSelectedData = result;
      EventBus.$emit("selectStation", this.stationSelectedData);
    }
  },
  created() {
    EventBus.$on("UnCheckAll", stationId => {
      this.stationIdPassed = stationId;
    });
  },
  beforeCreate() {
    EventBus.$off("UnCheckAll")
  }
};
</script>