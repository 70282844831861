<template>
  <b-modal
    hide-footer
    id="create-distrib-list"
    size="lg"
    modal-class="flipModal manage-user-modal"
    title="Create & Manage Distribution Lists"
    centered
    @hidden="onClose"
    v-bind:content-class="{ show: modalShow, modalActive: modalActive }"
  >
    <b-row no-gutters>
      <b-col>
        <div class="def-func-input-group briefing-list-filter">
          <div class="def-func-input-group-col briefing-station-code">
            <b-input-group>
              <b-form-input placeholder="Enter name of new distribution group" v-model="new_group_name"></b-form-input>
            </b-input-group>
            <div style="color:red" v-show="toggle_group_name_error">The Distribution group field is required</div>
          </div>
          <div class="def-func-input-group-col briefing-station-code-btn">
            <button variant="primary" class="btn add-btn" @click.prevent="add_new_group">+</button>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="def-func-table manage-users-table">
          <table>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">
                  <span>Distribution Group Name</span>
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(group,index) in distribition_groups" :key="'group_'+index">
                <td><b-form-radio v-model="Radio_group_id" :value="group.public_id" name="title" /></td>
                <td class="text-left">{{group.group_name}}</td>
                <td class="text-left"><img class="dc-pointer" @click="delete_group(group.public_id,index)" src="@/assets/images/delete.png" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col>
        <div class="def-func-input-group briefing-list-filter">
          <div class="def-func-input-group-col briefing-station-code">
            <span v-if="Radio_group_id">
              <typeaheadSearchUsers :apidata="apidata"></typeaheadSearchUsers>
              <div style="color:red" v-show="toggle_group_to_user_error">{{ errorMsg }}</div>
            </span>
            <span v-else>
              <b-form-input disabled placeholder="Last Name, First Name, Username OR Email Address" v-model="users_Code"></b-form-input>
            </span>
          </div>
          <div class="def-func-input-group-col briefing-station-code-btn">
            <button :disabled="!Radio_group_id" @click="add_users_to_groups" variant="primary" class="btn add-btn">+</button>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="def-func-table manage-users-table">
          <table>
            <thead>
              <tr>
                <th class="text-left">
                  <span>Member</span>
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user_list,uindex) in user_list_display_holder" :key="'user_list_'+uindex">
                <td class="text-left">{{user_list.email}}</td>
                <td class="text-left"><img class="dc-pointer" src="@/assets/images/delete.png" @click="delete_users(user_list.public_id, uindex)" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { HTTP, HTTP_formdata, HTTP_formdata_BASE } from "../../../constants/http-common";
import { isloggedin } from "../../../constants/app-constants";
import typeaheadSearchUsers from "./typeahead_search_users";
import { EventBus } from "../../../main";
export default {
  components: {
    typeaheadSearchUsers
  },
  props: ["modalShow", "modalActive", "apidata"],
  data() {
    return {
      new_group_name: "",
      distribition_groups: [],
      toggle_group_to_user_error: false,
      Radio_group_id: "",
      user_list_display_holder: [],
      users_Code: null,
      toggle_group_name_error: false,
      errorMsg: ''
    };
  },
  created() {
    EventBus.$on("typeahead_search_users", users_Code => {
      this.users_Code = users_Code;
    });
  },
  watch: {
    Radio_group_id(newVal) {
      HTTP.get( "/briefing_distribution_list/get_members/" + newVal + '?area=' + this.apidata.area + '&area_id=' + this.apidata.area_id + '&distribute_to=' + this.apidata.distributeTo )
        .then(response => {
          if ( response.status == 200 ) {
            for (let i = 0; i < response.data.length; i++) {
              this.user_list_display_holder = response.data;
            }
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            this.user_list_display_holder = [];
          }
      });
    }
  },
  methods: {
    onClose() {
      this.new_group_name = "";
      // this.distribition_groups = [];
      this.toggle_group_to_user_error = false;
      this.Radio_group_id = "";
      this.user_list_display_holder = [];
      this.users_Code = null;
      this.toggle_group_name_error = false;
      this.errorMsg = '';
    },
    add_new_group() {
      if ( this.new_group_name ) {
        let add_groups = {
          groupName: this.new_group_name,
          createdBy: "users/" + isloggedin.public_id,
          createdOn: new Date()
        };
        HTTP_formdata_BASE.post( "briefing_distribution_list/add_group", add_groups )
          .then(response => {
            this.new_group_name = ""
            this.get_group_list();
          })
          .catch(err => {
            if ( err.response.status == 400 ) {
              let respPoup = {
                msg: err.response.data.violations[0].message,
                popup: true
              }
              EventBus.$emit("error_resp", respPoup);
            }
          });
      } else {
        this.toggle_group_name_error = true;
        setTimeout(() => {
          this.toggle_group_name_error = false;
        }, 4000);
      }
    },
    delete_group(group_id, index) {
      HTTP_formdata.delete(
        "briefing_distribution_list/delete_groups/" + group_id
      )
        .then(response => {
          this.distribition_groups.splice(index, 1);
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            let respPoup = {
              msg: err.response.data.response,
              popup: true
            }
            EventBus.$emit("error_resp", respPoup);
          }
        });
    },
    delete_users(user_public_id, index) {
      let form_data = {
        groupMembers: user_public_id
      };
      HTTP_formdata.put(
        "briefing_distribution_list/delete_members/" + this.Radio_group_id, form_data
      )
        .then(response => {
          this.user_list_display_holder.splice(index, 1);
        })
        .catch(err => {});
    },
    get_group_list() {
      // this.distribition_groups = [];
      HTTP.get("briefing_distribution_list/display_lists")
        .then(Response => {
          this.onClose();
          this.distribition_groups = Response.data;
        })
        .catch(error => {
          return error.status;
        });
    },
    add_users_to_groups() {
      if (this.Radio_group_id ) {
        if ( this.users_Code != null ) {
          let userExist = false
          for ( let i = 0; i < this.user_list_display_holder.length; i++ ) {
            if ( this.users_Code.public_id && this.user_list_display_holder[i].public_id === this.users_Code.public_id ) {
              userExist = true;
            }
          }
          if ( !userExist ) {
            let form_data = {
              groupMembers: this.users_Code.public_id
            };
            HTTP_formdata.put( "briefing_distribution_list/add_members/" + this.Radio_group_id, form_data )
              .then(response => {
                this.user_list_display_holder.push(this.users_Code);
                this.users_Code = null
                EventBus.$emit("onAdd", 'clear');
              })
              .catch(err => {});
          } else {
            EventBus.$emit("onAdd", 'clear');
          }
        } else {
          this.errorMsg = "The Member field is required"
          this.toggle_group_to_user_error = true;
          setTimeout(() => {
          this.errorMsg = ""
            this.toggle_group_to_user_error = false;
          }, 4000);
        }
      } else {
        this.errorMsg = "Select the Group"
        this.toggle_group_to_user_error = true;
        setTimeout(() => {
        this.errorMsg = ""
          this.toggle_group_to_user_error = false;
        }, 4000);
      }
    }
  },
  mounted: function() {
    this.get_group_list();
  }
};
</script>