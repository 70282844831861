<template>
  <b-modal
  hide-footer
  id="modal-global-database"
  size="lg"
  modal-class="flipModal manage-user-modal"
  title="Global Database"
  centered
  @show="resetModal"
  >
    <div class="def-func-input-group mb-0">
      <div class="def-func-input-form-group">
        <ValidationObserver ref="observerGlobalAdd" v-slot="{ passes }">
          <b-form @submit.prevent="passes( addGlobalStm )">
            <b-row>
              <b-col sm="12">
                <ValidationProvider name="Function" rules="required" v-slot="{ valid, errors }">
                  <div class="required-field">
                    <label>Function</label>
                  </div>
                  <b-form-select class="mb-3"
                    :state="errors[0] ? false : ( valid ? true : null )"
                    @change="changeFunction( $event )"
                    v-model="functionId"
                    :options="functionList"
                  ></b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
              <b-col sm="12">
                <ValidationProvider
                  name="Global Statement"
                  rules="required"
                  v-slot="{ valid, errors }"
                >
                  <div class="required-field">
                    <b-form-group label="Select optional Checks from Global Database" class="mt-4 global-statement">
                      <b-form-checkbox-group stacked class=""
                        :state="errors[0] ? false : ( valid ? true : null )"
                        v-model="statementsSelected"
                        :options="statementGlobal"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-col>
              <b-col sm="12">
                <div class="create-new-check-btn btn-rename-delete">
                  <button
                    type="submit"
                    variant="light"
                    class="btn btn-rename-delete"
                  >Add</button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
    <template v-slot:modal-footer></template>
  </b-modal>
</template>
<script>
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  props: [ "checklist", "function", "functionList", "station" ],
  data() {
    return {
      functionId: "",
      statementGlobal: [],
      statementsSelected: []
    };
  },
  methods: {
    addGlobalStm() {
      let globalAdd = {
        title: this.checklist,
        station_code: this.station,
        statements: this.statementsSelected
      };
      HTTP_formdata.post("audits/save_global_checks", globalAdd)
        .then(response => {
          this.statementsSelected = [];
          this.functionId = "";
          this.statementGlobal = []
          let refresh = response;
          EventBus.$emit("refreshStatementList", refresh);
          this.$nextTick(() => {
            this.$refs.observerGlobalAdd.reset();
            this.$bvModal.hide("modal-global-database");
          });
        })
        .catch(err => {});
    },
    resetModal() {
      this.functionId = this.function;
      this.changeFunction();
    },
    changeFunction(event) {
      let jsonData = {
        function: this.functionId,
        title: this.checklist,
        station_code: this.station
      }
      HTTP_formdata.post("audits/get_global_checks", jsonData)
        .then(response => {
          this.statementsSelected = [];
          this.statementGlobal = []
          for (var i = 0; i < response.data.length; i++) {
            this.statementGlobal.push({
              value: response.data[i].public_id,
              text: response.data[i].statement
            });
          }          
          this.$nextTick(() => {
            this.$refs.observerGlobalAdd.reset();
          });
        })
        .catch(error => {});
    }
  },
  created() {
    EventBus.$on("clearData", clear => {
      this.statementGlobal = [];
      this.statementsSelected = [];
      this.functionId = "";
    });
  }
};
</script>