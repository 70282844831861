<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mt-4 mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">Pending Trainings</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mb-4">
                  <div class="def-func-table manage-users-table mt-4">
                    <pendingtable :data="trainingData.pending"></pendingtable>
                  </div>
                </b-col>
              </b-row>
              <div class="clearfix"></div>
              <b-row>
                <b-col>
                  <div class="def-func-input-form-group breifing-content">
                    <div class="def-func-input-group mb-0">
                      <div class="def-func-input-group-col width-12">
                        <label class="global-freq freq-resp-title check-title">Completed Trainings</label>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="def-func-table manage-users-table mt-4">
                    <completedtable :data="trainingData.completed"></completedtable>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <startTrainingModal />
  </div>
</template>
<script>
import { HTTP } from "../../../constants/http-common";
import { EventBus } from "../../../main";
import completedtable from "./completed_table";
import pendingtable from "./pending_table";
import startTrainingModal from "../../../components/admin_layouts/dashboard_layouts/external_training"
export default {
  components:{
    completedtable,
    pendingtable,
    startTrainingModal
  },
  data() {
    return {
      trainingData: {}
    };
  },
  mounted() {
    this.listTraining();
  },
  methods: {
    listTraining() {
      HTTP.post("training/get_user_training")
      .then(response=> {
        this.trainingData = response.data
      })
      .catch(response=>{})
    }
  },
  created() {
    EventBus.$on( "refreshTraining", response => {
      this.listTraining();
    });
  },
  beforeCreate() {
    EventBus.$off("refreshTraining")
  }
};
</script>
<style>
@import "../def_function.css";
</style>