<template>
  <table>
    <thead>
      <tr>
        <th class="text-left">
          <span>Training Title</span>
        </th>
        <th class="text-left">
          <span>Format</span>
        </th>
        <th class="text-left">
          <span>Function</span>
        </th>
        <th class="text-left table-min-width">
          <span>Recurrence</span>
        </th>
        <th class="text-left table-min-width">
          <span>Type</span>
        </th>
        <th class="text-left table-min-width">
          <span>Created By</span>
        </th>
        <th class="text-left table-min-width">
          <span>Status</span>
        </th>
        <th class="text-center">
          <span>Actions</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data,index) in trainings" :key="'trainings_'+index">
        <td class="text-left table-half-width">{{data.title}}</td>
        <td class="text-left">{{ data.training_format }}</td>
        <td class="text-left">{{data.function}}</td>
        <td class="text-left table-min-width">{{ data.recurrence | recurrence }}</td>
        <td class="text-left table-min-width">{{data.type}}</td>
        <td class="text-left table-min-width">{{data.createdby_name}}</td>
        <td class="text-left table-min-width">{{data.status}}</td>
        <td class="text-center">
          <div class="manage-user-action icons-5">
            <router-link v-if="loggedin_user_details.access_rights.includes('Set Up New Training') && data.set_up_questions" :to="'/set-up-questions/'+data.public_id" class="pr-2">
              <img src="@/assets/images/test-questions.png" title="Set Up Test Questions" />
            </router-link>
            <a v-else class="btn-inactive pr-2" @click.prevent="">
              <img src="@/assets/images/test-questions.png" title="Set Up Test Questions" />
            </a>
            <button disabled v-if="data.status == 'Test Questions Pending' || data.status == 'Points Allocation Incomplete'">
              <img src="@/assets/images/distribute.svg" title="Distribute" />
            </button>
            <button disabled v-else-if="data.completion_status != 'completed'">
              <img src="@/assets/images/distribute.svg" title="Distribute" />
            </button>
            <!-- <router-link v-else :to="'/distribute/trainings/'+data.public_id">
              <img src="@/assets/images/distribute.svg" title="Distribute" />
            </router-link> -->
            <button  v-else class="distribute_history" @click="openDistribution(data)" >
              <img src="@/assets/images/distribute.svg" title="Distribute">
            </button>
            <button :disabled="!data.distribute" class="distribute_history" @click.prevent="distributionData(data)" v-b-modal.modal-briefing-history>
              <img src="@/assets/images/history.png" title="Distribution History">
            </button>
            <button v-if="data.continuous_distribution" :disabled="data.permission ? false : true" v-b-modal.confirm-delete @click.prevent="confirm_popup(data, 'deactivate')">
              <img src="@/assets/images/continousDistributionA.png" title="Continuous Distribution - Active" />
            </button>
            <button v-else :disabled="data.permission ? false : true" v-b-modal.confirm-delete @click.prevent="confirm_popup(data, 'activate')">
              <img src="@/assets/images/continousDistributionInA.png" title="Continuous Distribution - Inactive" />
            </button>
            <button v-b-modal.confirm-delete :disabled="data.permission ? false : true" @click.prevent="confirm_popup( data )">
              <img src="@/assets/images/delete.png" title="Delete" />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
    <historyModal historyArea="trainings" :distributeId="distributeId" :historyTitle="historyTitle"></historyModal>
    <confirmModal></confirmModal>
  </table>
</template>
<script>
import confirm_modal from "../../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
import { EventBus } from "../../../main";
import { HTTP_formdata } from "../../../constants/http-common";
import { isloggedin } from "../../../constants/app-constants";
import historyModal from "../../../components/admin_layouts/dashboard_layouts/history_modal";

export default {
  props: ["trainings"],
  data() {
    return {
      loggedin_user_details: isloggedin,
      trainingId: "",
      response: {
        msg: "",
        popup: false
      },
      distributeId: "",
      historyTitle: "",
      continuousDistributionData: ""
    };
  },
  components: {
    confirmModal: confirm_modal,
    historyModal
  },
  filters: {
    recurrence: function(value) {
      return ( value.time == "year" ) ? ( ( value.number == 1 ) ? value.number + ' year' : value.number + ' years' ) : ( value.time == 'month' ) ? ( ( value.number == 1 ) ? value.number + ' month' : value.number + ' months' ) : ''
    }
  },
  methods: {
    openDistribution(data){
      let fileName = null 
      console.log(data.files)
      if (data.files[0] && data.files[0].name && data.files[0].name.endsWith('.mp4' ||'.html')) {
        fileName = data.files[0].name 
        this.$cookies.set("dist_file", fileName);
      }
      else {
        this.$cookies.remove("dist_file");
      }
      this.$router.push({ path: `/distribute/trainings/${data.public_id}` })
    },
    confirm_popup( data, action ) {
      this.trainingId = data.public_id;
      if ( action ) {
        this.continuousDistributionData = data;
        this.continuousDistributionData.confirmToState = action;
        EventBus.$emit( "deleteSelected", this.continuousDistributionData );
      } else {
        EventBus.$emit( "deleteSelected", data.title );
      }
    },
    deleteTraining() {
      let jsonData = {
        training: this.trainingId
      };
      HTTP_formdata.post("training/delete_training", jsonData)
        .then(response => {
          if ( response.status == 200 ) {
            this.response.msg = response.data.response
            this.response.popup = true
            EventBus.$emit("success_resp", this.response);
            this.trainingId = "";
            EventBus.$emit("refreshTrainingList", response);
          }
        })
        .catch(err => {
          if ( err.response.status == 400 || err.response.status == 403 ) {
            this.response.msg = err.response.data.message
            this.response.popup = true
            EventBus.$emit("error_resp", this.response);
            this.trainingId = "";
          }
        });
    },
    distributionData(data) {
      this.distributeId = data.public_id;
      this.historyTitle = data.title;
    },
    continuousDistribution() {
      let jsonData = {
        module: this.$route.meta.route_ref,
        value: ( this.continuousDistributionData.confirmToState == 'activate' ) ? true : false
      }
      HTTP_formdata.put( "distribution/continuous_distribution/" + this.continuousDistributionData.public_id, jsonData )
        .then(response => {
          if ( response.status == 200 ) {
            let resp = { msg: response.data.message, popup: true };
            EventBus.$emit( "success_resp", resp );
            this.continuousDistributionData = "";
            EventBus.$emit("refreshTrainingList", true);
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            let resp = { msg: err.response.data.message, popup: true };
            EventBus.$emit( "error_resp", resp );
            this.continuousDistributionData = "";
          }
        });
    }
  },
  created() {
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteTraining();
      } else {
        this.continuousDistribution();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
  }
};
</script>
<style scoped>
tr td div .btn-inactive {
  opacity: 0.15;
  cursor: default;
}
.distribute_history img {
  max-width: 23px;
}
</style>