<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <ValidationObserver ref="observer" v-slot="{ passes }">
                  <b-form @submit.prevent="passes( sendDistribution )">
                    <div class="def-func-input-form-group">
                        <b-row>
                          <b-col md="10" lg="7">
                            <div class="brief-rounded-sectn mb-4">
                              <b-row>
                                <b-col>
                                  <div class="required-field">
                                    <label class="distribute-group-label">Distribute To</label>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col md="12">
                                  <div class="distribute-group">
                                    <ValidationProvider name="Distribute To" rules="required" v-slot="{ valid, errors }">
                                      <b-form-group class="distribute-user-select">
                                        <b-form-radio-group :state="errors[0] ? false : (valid ? true : null)" :options="distributeToOptions"
                                          id="select-user" name="select-user" class="add-media-inner" v-model="distributeData.distribute_to" @change="onChangeDistributeBy($event)">
                                        </b-form-radio-group>
                                        <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                          <b-col md="8" lg="5" v-show="distributeData.distribute_to">
                          <div class="brief-rounded-sectn mb-4">
                            <b-row>
                              <b-col>
                                <div class="required-field">
                                  <label class="distribute-group-label">Distribute By</label>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col md="12">
                                <div class="distribute-group">
                                  <ValidationProvider name="Distribute By" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group class="distribute-user-select">
                                      <b-form-radio-group :state="errors[0] ? false : (valid ? true : null)" :options="distributeByOptions"
                                        id="distribute-by" name="distribute-by" class="add-media-inner" v-model="distributeData.distribute_by" @change="onChangeDistributeBy($event)">
                                      </b-form-radio-group>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                  </ValidationProvider>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="brief-rounded-sectn" v-show="distributeData.distribute_by === 'station'">
                        <b-row>
                          <b-col>
                            <label class="distribute-group-label">{{constants.DISTRIBUTE_TO_GROUPS_REGION_LABEL}}</label>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="5" xl="4">
                            <div class="distribute-group dc-custom-indeterminate">
                              <b-form-group>
                                <template v-slot:label>
                                  <div class="select-all-outer">
                                    <div class="required-field">
                                      <label>{{constants.REGION_OR_STATION_LABEL}}</label>
                                    </div>
                                    <b-form-checkbox
                                      v-model="allStationSelected"
                                      :indeterminate="indeterminateStation"
                                      aria-describedby="regionsStations"
                                      aria-controls="regionsStations"
                                      @change="toggleStationAll($event)"
                                    >All</b-form-checkbox>
                                  </div>
                                </template>
                                <b-form-checkbox-group
                                  id="regionsStations"
                                  v-model="stationEmpGroupData.stations"
                                  :options="stationOptions"
                                  name="regionsStations"
                                  class="distribute-checkboxes"
                                  aria-label="Regions or Stations"
                                  stacked
                                ></b-form-checkbox-group>
                                  <!-- @input="stationSelected($event)" -->
                              </b-form-group>
                            </div>
                          </b-col>
                          <b-col sm="5" xl="4">
                            <div class="distribute-group dc-custom-indeterminate">
                              <b-form-group>
                                <template v-slot:label>
                                  <div class="select-all-outer">
                                    <div class="required-field">
                                      <label>{{constants.EMPLOYEE_GROUPS_LABEL}}</label>
                                    </div>
                                    <br />
                                    <b-form-checkbox
                                    :disabled="stationEmpGroupData.stations.length == 0 || empGroupOptions.length == 0"
                                      v-model="allEmployeeGroupsSelected"
                                      :indeterminate="indeterminateEmployeeGroups"
                                      aria-describedby="employeeGroups"
                                      aria-controls="employeeGroups"
                                      @change="toggleEmployeeGroupsAll"
                                    >All</b-form-checkbox>
                                  </div>
                                </template>
                                <b-form-checkbox-group
                                  id="employeeGroups"
                                  v-model="stationEmpGroupData.groups"
                                  :options="empGroupOptions"
                                  name="employeeGroups"
                                  class="distribute-checkboxes"
                                  aria-label="Employee Groups"
                                  stacked
                                  @input="grpSelected($event)"
                                ></b-form-checkbox-group>
                              </b-form-group>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="clearfix"></div>
                      <div class="clearfix"></div>
                      <div class="brief-rounded-sectn distribute-section" v-show="distributeData.distribute_by === 'specific_users'">
                        <b-row>
                          <b-col>
                            <div class="required-field">
                              <label class="distribute-group-label">{{constants.DISTRIBUTE_SPECIFICUSER_LABEL}}</label>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="7" xl="7">
                            <div class="distribute-group">
                              <div class="def-func-input-group briefing-list-filter">
                                <div class="def-func-input-group-col briefing-station-code">
                                  <typeaheadUserGroup :apiData="{ area: distributeData.area, area_id: distributeData.area_id, distributeTo: distributeData.distribute_to }" api="briefing_distribution_list/search_distribution_filter" :clear_typeAhead="clearUserGroup"></typeaheadUserGroup>
                                </div>
                                <div class="def-func-input-group-col briefing-station-code-btn">
                                  <button variant="primary" class="btn add-btn" @click.prevent="add_usersTo_group(false)">+</button>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col sm="5" xl="5">
                            <div class="distribute-group pl-0">
                              <b-button v-b-modal.create-distrib-list>{{ constants.CREATE_AND_MANAGE_DISTRIBUTION_BUTTON }}</b-button>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                        <b-row>
                          <b-col sm="12">
                            <div class="distribute-group">
                              <ul class="distrib-orga-code">
                                <li
                                  v-for="( users, index ) in specificUserData"
                                  :key="'usersBriefings_'+index"
                                  class="distrib-orga-code-col distrib-orga-code-name"
                                >
                                  <div class="distrib-orga-inner">
                                    <div class="text-left">{{users.display_name}}</div>
                                    <div class="manage-user-action">
                                      <button class="px-0" @click.prevent="delete_users_from_briefings(index, 'specific')">
                                        <img src="@/assets/images/delete.png" title="Delete"/>
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="clearfix"></div>
                      <div class="brief-rounded-sectn distribute-section" v-show="distributeData.distribute_by === 'specific_users' && previousDistributed == true">
                        <b-row>
                          <b-col>
                            <label class="distribute-group-label">{{constants.EXCLUDE_SPECIFICUSER_LABEL}}</label>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="7" xl="7">
                            <div class="distribute-group">
                              <div class="def-func-input-group briefing-list-filter">
                                <div class="def-func-input-group-col briefing-station-code">
                                  <typeaheadExclusion :api="'distribution/distributed_users?area=' + distributeData.area + '&area_id=' + distributeData.area_id" />
                                </div>
                                <div class="def-func-input-group-col briefing-station-code-btn">
                                  <button variant="primary" class="btn add-btn" @click.prevent="add_usersTo_group(true)">+</button>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                        <b-row>
                          <b-col sm="12">
                            <div class="distribute-group">
                              <ul class="distrib-orga-code">
                                <li v-for="( users, index ) in exclusionList" :key="index" class="distrib-orga-code-col distrib-orga-code-name">
                                  <div class="distrib-orga-inner">
                                    <div class="text-left">{{users.display_name}}</div>
                                    <div class="manage-user-action">
                                      <button class="px-0" @click.prevent="delete_users_from_briefings(index, 'exclusion')">
                                        <img src="@/assets/images/delete.png" title="Delete"/>
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="clearfix"></div>
                      <div class="brief-rounded-sectn distribute-section" v-if="showDistribution" v-show="distributeData.distribute_by">
                        <b-row>
                          <b-col>
                            <label class="distribute-group-label">{{constants.ACK_LABEL}}</label>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                          <div class="distribute-group">
                            <div class="def-func-input-group briefing-list-filter mb-1">
                              <div class="def-func-input-group-col briefing-station-code">
                                <b-row>
                                <b-col sm="7" lg="7" xl="5" class="mt-2">
                                  <b-form-checkbox
                                    v-model="distributeData.notification_req"
                                    name="notify_completion"
                                    value="true"
                                    unchecked-value="false"
                                    @change="notifyChange($event)"
                                  >{{constants.NOTIFY_ALL_USER_ACK}}</b-form-checkbox>
                                </b-col>
                                <b-col sm="5" lg="5" xl="3" class="pl-0 float-right">
                                  <ValidationProvider name="Email Address" :rules="distributeData.notification_req=='true' ? 'required|email' : ''"
                                    v-slot="{ touched, valid, errors }">
                                    <b-input-group class="mb-4">
                                      <b-form-input
                                        :disabled="distributeData.notification_req=='true' ? false : true"
                                        v-model="distributeData.notification_email" placeholder="Enter Your email address for notification"
                                        :state="errors[0] ? false : ( valid ? ( touched ? ( distributeData.notification_req == 'true' ? true : null ) : null ) : null)"
                                      ></b-form-input>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row> 
                              </div>
                            </div>
                          </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="clearfix"></div>
                      <div class="briefing-btns">
                        <button class="btn" variant="light" @click.prevent="previous_page">Back to List of {{distribution_list_page_name}}</button>
                        <button :disabled="enableSend == false" type="submit" class="btn" variant="light">Send {{distribution_page_name}}</button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <createManageDistributionList :apidata="{ area: distributeData.area, area_id: distributeData.area_id, distributeTo: distributeData.distribute_to }" :modalShow="modalShow" :modalActive="modalActive"></createManageDistributionList>
    <div class="text-center spinner-div overlay load-outer" v-show="messsage_loader"> 
      <label class="wait-text">Please wait...</label> 
      <br>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
      <br>
      <label v-show="count_loader" class="loading-text">{{ loaderStatus }} Completed {{ distributionCompletedCount }}/{{ distributionTotalCount }}</label>
    </div>
    <noInternetModal />
    <detailResp></detailResp>
  </div>
</template>
<script>
import { HTTP, HTTP_formdata } from "../../constants/http-common";
import { DISTRIBUTE, isloggedin } from "../../constants/app-constants";
import typeaheadUserGroup from "../../components/admin_layouts/dashboard_layouts/typeahead_search_user_and_group";
import typeaheadExclusion from "../../components/admin_layouts/dashboard_layouts/typeahead_exclusion";
import createManageDistributionList from "../../components/admin_layouts/dashboard_layouts/model_setup_briefings_distribution";
import { EventBus } from "../../main";
import axios from "axios";
import noInternetModal from "../../components/admin_layouts/dashboard_layouts/status_message/no-internet";
import detailResp from "../../components/admin_layouts/dashboard_layouts/status_message/distribution_failed";
export default {
  data() {
    return {
      constants: DISTRIBUTE,
      distributeToOptions: [
        { text: DISTRIBUTE.INTERNAL_RADIO_LABEL, value: 'internal', disabled: ( isloggedin.contract == 'Contracted' ) ? true : false },
        { text: DISTRIBUTE.EXTERNAL_RADIO_LABEL, value: 'external' },
        { text: DISTRIBUTE.ALLUSERS_RADIO_LABEL, value: 'all' , disabled: ( isloggedin.contract == 'Contracted' ) ? true : false }
      ],
      distributeByOptions: [
        { text: 'Stations', value: "station" },
        { text: 'Specific Users', value: "specific_users" }
      ],
      distributeData: {
        area: this.$route.params.type,
        area_id: this.$route.params.distribute_id,
        distribute_to: "",
        distribute_by: "",
        user_public_id: "",
        distribution_history_id: "",
        notification_req: false,
        notification_email: "", 
        hide_quiz: 0
      },
      stationOptions: [],
      empGroupOptions: [],
      stationEmpGroupData: {
        stations: [],
        groups: []
      },
      specificUserData: [],
      initialCountCheck: false,
      addHistoryCheck: false,
      distribution_page_name: this.$route.params.type,
      showDistribution: false,
      clearOrgStation: true,
      clearUserGroup: true,
      nonInternalUsers: [],
      toggle_orga_code_error: "",
      toggle_orga_Distribue_to_specific_users_error: "",
      form_data: {
        selectedRegions: [],
        selectedEmployeeGroups: [],
        target_users: "",
        distribute_by: "",
        all_station_codes: [],
        selected_users_for_briefings: [],
        notify_completion: false,
        Request_ack: false,
        email: ""
      },
      selected_users_for_briefings_temp: {},
      allStationSelected: false,
      indeterminateStation: false,
      regionsStations: [{ text: "", value: "" }],
      employeeGroups: [],
      allEmployeeGroupsSelected: false,
      indeterminateEmployeeGroups: false,
      modalShow: false,
      modalActive: false,
      briefings_uuid: "",
      all_station_codes_temp: {},
      messsage_loader: false,
      distribution_list_page_name: "",
      confirmpopup: false,
      enableSend: false,
      distributionCompletedCount: 0,
      distributionTotalCount: 0,
      hundredCounter: 0,
      timer: false,
      timerCheck: false,
      counterAPICheck: false,
      chunkLength: 1,
      distributeLength: 0,
      distributeStatus: [],
      user_public_id: [],
      userCount: 0,
      count_loader: false,
      request: null,
      counterCheck: false,
      respStatus: [],
      APICont : false,
      distTo: "",
      distApiCheck: false,
      submitCheck: false,
      previousDistributed: false,
      exclusionList: [],
      tempSelectedExclusionUser: {},
      loaderStatus: "",
      exclusionCheck: false
    };
  },
  components: {
    typeaheadUserGroup,
    createManageDistributionList,
    noInternetModal,
    detailResp,
    typeaheadExclusion
  },
  computed: {
    onLine() {
      return this.$store.getters.networkConnection;
    }
  },
  created() {
    EventBus.$on("typeahead-orga-and-station", newQuery => {
      this.all_station_codes_temp = newQuery;
    });
    EventBus.$on("typeahead_search_user_and_group", newQuery => {
      this.selected_users_for_briefings_temp = {};
      if ( newQuery.public_id )
        this.selected_users_for_briefings_temp = newQuery;
    });
    EventBus.$on("noConnectionOk", newQuery => {
      if ( newQuery == 'Okay' ) {
        this.networkConnect();
      }
    });
    EventBus.$on("typeahead_search_exclusion_user", newQuery => {
      this.tempSelectedExclusionUser = {};
      if ( newQuery.public_id )
        this.tempSelectedExclusionUser = newQuery;
    });
  },
  methods: {
    previous_page(){
      if (this.$route.params.type == "briefings") {
        this.$router.push({ path: "/list_of_briefings" });
      } else if (this.$route.params.type == "videogramme") { 
        this.$router.push({ path: "/list_of_videogramme" });
      } else {
        this.$router.push({ path: "/list_of_trainings" });
      }
    },
    onChangeDistributeBy(event) {
      // station
      this.allStationSelected = false;
      this.stationEmpGroupData.stations = [];
      this.empGroupOptions = [];
      this.stationEmpGroupData.groups = [];
      this.allEmployeeGroupsSelected = false;
      //user
      this.specificUserData = [];
      this.exclusionList = []
      this.enableSend = false;
    },
    check_Dublicate_Selected_Code( public_id, type ) {
      let checkDublicate = "";
      if ( type == "organization_code" ) {
        checkDublicate = this.form_data.all_station_codes;
      } else if (type == "user-group") {
        checkDublicate = this.specificUserData;
      } else if ( type == "emp_grp" ) {
        checkDublicate = this.stationEmpGroupData.groups;
      } else if ( type == "exclusion" ) {
        checkDublicate = this.exclusionList;
      }
      for ( let i = 0; i < checkDublicate.length; i++ ) {
        if ( public_id === null )
          return false;
        else if ( checkDublicate[i].public_id === public_id || checkDublicate[i] === public_id )
          return false;
      }
      if ( public_id == null )
        return false;
      else
        return true;
    },
    add_usersTo_group(data) {
      if ( data ) {
        if ( this.check_Dublicate_Selected_Code( this.tempSelectedExclusionUser.public_id, "exclusion" ) ) {
          this.exclusionList.push( this.tempSelectedExclusionUser );
          this.enableSend = true;
          this.tempSelectedExclusionUser = {}
        }
        EventBus.$emit( "clearExclusion", '' );
      } else {
        if ( this.selected_users_for_briefings_temp.type == "group" ) {
          HTTP.get( "/briefing_distribution_list/get_members/" + this.selected_users_for_briefings_temp.public_id + '?area=' + this.distributeData.area + '&area_id=' + this.distributeData.area_id + '&distribute_to=' + this.distributeData.distribute_to )
            .then(response => {
              if ( response.status == 200 ) {
                for ( let i = 0; i < response.data.length; i++ ) {
                  if ( this.check_Dublicate_Selected_Code( response.data[i].public_id, "user-group" ) ) {
                    this.specificUserData.push( response.data[i] );
                  }
                }
                this.enableSend = true;
              }
            })
            .catch(err => {});
        } else {
          if ( this.check_Dublicate_Selected_Code( this.selected_users_for_briefings_temp.public_id, "user-group" ) ) {
            this.specificUserData.push( this.selected_users_for_briefings_temp );
            this.enableSend = true;
          }
        }
        this.selected_users_for_briefings_temp = {};
        this.clearUserGroup = !this.clearUserGroup;
      }
    },
    delete_users_from_briefings(index, action) {
      if ( action == 'exclusion' )
        this.exclusionList.splice(index, 1);
      else
        this.specificUserData.splice(index, 1);
      if ( this.specificUserData.length == 0 && this.exclusionList.length == 0 )
        this.enableSend = false;
    },
    toggleStationAll(checked) {
      if (checked) {
        this.stationEmpGroupData.stations = [];
        for (let i = 0; i < this.stationOptions.length; i++) {
          this.stationEmpGroupData.stations.push( this.stationOptions[i].value );
        }
      } else
        this.stationEmpGroupData.stations = [];
      // this.stationSelected(this.stationEmpGroupData.stations)
    },
    toggleEmployeeGroupsAll(checked) {
      if (checked) {
        for (let i = 0; i < this.empGroupOptions.length; i++) {
          if ( this.check_Dublicate_Selected_Code( this.empGroupOptions[i].value, "emp_grp" ) ) {
            this.stationEmpGroupData.groups.push( this.empGroupOptions[i].value );
          }
        }
        if ( this.stationEmpGroupData.groups.length == 0 )
          this.enableSend = false;
        else
          this.enableSend = true;
      } else {
        this.stationEmpGroupData.groups = [];
        this.enableSend = false;
      }
    },
    stationSelected(event) {
      // Handle changes in individual grp checkboxes
      if (event.length === 0) {
        this.indeterminateStation = false
        this.allStationSelected = false
      } else if ( event.length === this.stationOptions.length ) {
        this.indeterminateStation = false
        this.allStationSelected = true
      } else {
        this.indeterminateStation = true
        this.allStationSelected = false
      }

      if ( this.stationEmpGroupData.stations.length > 0 ) {
        let jsonData = {
          public_id: event,
          users: this.distributeData.distribute_to
        }
        HTTP_formdata.post("stations/get_employee_groups", jsonData)
          .then(Response => {
            this.empGroupOptions = [];
            for (let i = 0; i < Response.data.length; i++) {
              this.empGroupOptions.push({
                text: Response.data[i].name,
                value: Response.data[i].public_id
              });
            }
            this.stationEmpGroupData.groups = [];
            this.enableSend = false;
            this.allEmployeeGroupsSelected = false
          })
          .catch(error => {
            if ( error.response.status == 500 || error.response.status == 400) {
              this.empGroupOptions = [];
              this.stationEmpGroupData.groups = [];
              this.enableSend = false;
              this.allEmployeeGroupsSelected = false
            }
          });
      } else {
        this.empGroupOptions = [];
        this.stationEmpGroupData.groups = [];
        this.enableSend = false;
        this.allEmployeeGroupsSelected = false
      }
    },
    grpSelected(event) {
      // Handle changes in individual grp checkboxes
      if (event.length === 0) {
        this.indeterminateEmployeeGroups = false
        this.allEmployeeGroupsSelected = false
      } else if ( event.length === this.empGroupOptions.length ) { //stationEmpGroupData.groups
        this.indeterminateEmployeeGroups = false
        this.allEmployeeGroupsSelected = true
      } else {
        this.indeterminateEmployeeGroups = true
        this.allEmployeeGroupsSelected = false
      }
      if ( event.length > 0 ) {
        if ( this.stationEmpGroupData.groups.length == 0 )
          this.enableSend = false;
        else
          this.enableSend = true;
      } else
        this.enableSend = false;
    },
    notifyChange(event) {
      this.distributeData.notification_email = ( event == "true" ) ? this.distributeData.notification_email : ''
    },
    cancel() {
      this.request.cancel();
      this.clearOldRequest();
    },
    clearOldRequest() {
      this.request = null;
    },
    sendDistribution() {
      this.networkConnect();
      if ( this.onLine == true || this.onLine == null ) {
        this.messsage_loader = true;
        if ( this.exclusionList.length > 0 ) {
          this.distributionTotalCount = this.exclusionList.length;
          this.distributionCompletedCount = 0;
          this.sendExclusion();
        } else
          this.userDistributionCount();
      }
    },
    async sendExclusion() {
      this.loaderStatus = "Exclusion";
      this.exclusionCheck = true;
      this.count_loader = true;
      let errorCheck = 0;
      if ( this.onLine == true || this.onLine == null ) {
        for ( let i = this.distributionCompletedCount; i < this.distributionTotalCount; i++ ) {
          if ( this.onLine == true || this.onLine == null ) {
            this.counterCheck = true;
            this.axiosSource = axios.CancelToken.source();
            this.request = { cancel: this.axiosSource.cancel };
            let jsonData = { area: this.distributeData.area, area_id: this.distributeData.area_id };
            await HTTP_formdata.put( "distribution/undistribute/" + this.exclusionList[i].public_id, jsonData, { cancelToken: this.axiosSource.token }  )
              .then(response => {
                if ( response.status == 200 ) {
                  this.clearOldRequest();
                  this.respStatus.push({ status: "success", msgId: response.data.flag, msg: response.data.message });
                  this.distributionCompletedCount++;
                }
              })
              .catch(err => {
                if (axios.isCancel(err)) {
                } else {
                  this.clearOldRequest();
                  if ( !err.response ) {
                    // console.log('***************if no response from reminder api*********** Error: Network Error');
                    errorCheck = 1;
                  } else if ( err.response.status == 400 ) {
                    // console.log('failed sent');
                    this.respStatus.push({ status: "fail", msgId: err.response.data.flag, msg: err.response.data.message });
                    this.distributionCompletedCount++;
                  } else {
                    // console.log('else if error is not 400');
                    errorCheck = 1;
                  }
                }
              });
            if (errorCheck == 1) {
              errorCheck = 0;
              // console.log("errorCheck is 1");
              break;
            }
          } else {
            // console.log("no net in the middle of loop")
            break;
          }
        }
        setTimeout(() => {
          if ( this.distributionCompletedCount == this.distributionTotalCount ) {
            this.counterCheck = false;
            if ( this.specificUserData.length == 0 ) {
              let respDetail = [ { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" } ];

              for ( const obj of this.respStatus ) {
                if ( obj.msgId == '3' ) { respDetail[0].count++; respDetail[0].msg = obj.msg; respDetail[0].type = obj.status; }
                else if ( obj.msgId == '4' ) { respDetail[1].count++; respDetail[1].msg = obj.msg; respDetail[1].type = obj.status; }
                else if ( obj.msgId == '5' ) { respDetail[2].count++; respDetail[2].msg = obj.msg; respDetail[2].type = obj.status; }
              }

              let result = respDetail.filter( function( item ) {
                if ( item.count > 0 ) return true;
                else return false;
              });

              this.messsage_loader = false;
              this.count_loader = false;

              let responseData = {
                popup: true,
                data: result,
                title: 'Exclusion Details',
                loader: false
              };
              EventBus.$emit("confirm_count_resp", responseData);
              setTimeout(() => {
                EventBus.$emit( "confirmPopUPClose", true );
                if (this.$route.params.type == "briefings") {
                  setTimeout(() => {
                    this.$router.push({path:"/list_of_briefings"})
                  }, 2000);
                } else if (this.$route.params.type == "videogramme") {
                  setTimeout(() => {
                    this.$router.push({path:"/list_of_videogramme"})
                  }, 2000);
                } else {
                  setTimeout(() => {
                    this.$router.push({path:"/list_of_trainings"})
                  }, 2000);
                }
              }, 2000);
            } else {
              this.count_loader = false;
              this.userDistributionCount();
            }
          }
        }, 2000);
      }
    },
    userDistributionCount() {
      this.initialCountCheck = true;
      if ( this.distributeData.distribute_by == 'station' ) {
        let stationList = this.stationEmpGroupData.stations.toString();
        let grpList = this.stationEmpGroupData.groups.toString();
        HTTP.get( "users/get_user_count?area=" + this.distributeData.area + "&area_id=" + this.distributeData.area_id + "&distribute_to=" + this.distributeData.distribute_to + "&stations=" + stationList + "&groups=" + grpList )
          .then(response => {
            if ( response.status == 200 && response.data.users.length > 0 ) {
              this.distributionTotalCount = response.data.count;
              this.user_public_id = response.data.users;
              this.distributionCompletedCount = 0;
              this.addHistory();
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            if ( err.response.status == 400 ) {
              this.initialCountCheck = false;
              let resperr = {
                popup: true,
                msg: 'No Users Found'
              };
              EventBus.$emit( "error_resp", resperr );
              this.distributionTotalCount = 0;
              this.user_public_id = [];
              this.distributionCompletedCount = 0;
              this.hundredCounter = 0;
            }
          });
      } else {
        this.specificUserData.forEach((element) => { this.user_public_id.push( element.public_id ); });
        this.distributionTotalCount = this.specificUserData.length;
        this.distributionCompletedCount = 0;
        this.addHistory();
      }
    },
    addHistory() {
      // console.log("hello history");
      // this.networkConnect();
      if ( this.onLine == true || this.onLine == null ) {
        let specificUser = ( this.distributeData.distribute_by == 'station' ) ? [] : this.user_public_id;
        this.addHistoryCheck = true;
        let jsonData = {
          area: this.distributeData.area,
          area_id: this.distributeData.area_id,
          distribute_to: this.distributeData.distribute_to,
          stations: this.stationEmpGroupData.stations,
          specific_users: specificUser,
          groups: this.stationEmpGroupData.groups,
          notification_req: this.distributeData.notification_req,
          notification_email: this.distributeData.notification_email
        }
        HTTP_formdata.post( "distribution/add_history", jsonData )
          .then(response => {
            if ( response.status == 200 ) {
              this.distributeData.distribution_history_id = response.data.dist_id;
              this.setAPITimer();
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            if ( err.response.status == 400 ) {
              this.addHistoryCheck = false;
              let resperr = {
                popup: true,
                msg: err.response.data.message
              };
              EventBus.$emit( "error_resp", resperr );
              this.distributionTotalCount = 0;
              this.user_public_id = [];
              this.distributionCompletedCount = 0;
              this.hundredCounter = 0;
            }
          });
      }
    },
    setAPITimer() {
      this.timerCheck = true;
      // console.log("start timer");
      this.timer = setInterval(() => {
        clearInterval( this.timer );
        this.timer = false;
        if ( this.request ) this.cancel();
        let waitTime = ( this.hundredCounter < 100 ) ? 15000 : 30000;
        this.hundredCounter = 0;
        // console.log("waitTime",  waitTime);
        setTimeout(() => {
          this.setAPITimer();
        }, waitTime );
      }, 60000);
      // console.log('this.timer',this.timer);
      this.sendDistributeInitial();
    },
    async sendDistributeInitial( recall ) {
      // console.log("initial reminder");
      this.loaderStatus = "Distribution";
      this.count_loader = true;
      let errorCheck = 0;
      if ( this.onLine == true || this.onLine == null ) {
        for ( let i = this.distributionCompletedCount; i < this.distributionTotalCount; i++ ) {
          if ( this.onLine == true || this.onLine == null ) {
            this.counterCheck = true;
            // console.log("iteration", i);
            if ( this.timer == false ) {
              // console.log('this.timer false');
              break;
            } else {
              if ( this.hundredCounter >= 100 )  {
                // console.log('this.hundredCounter 100', this.hundredCounter);
                break;
              } else {
                // console.log('all good', this.hundredCounter);
                // console.log('email');
                // console.log('start reminder api');
                this.axiosSource = axios.CancelToken.source();
                this.request = { cancel: this.axiosSource.cancel };
                this.distributeData.user_public_id = this.user_public_id[i];
                // await HTTP.get( "distribution_histories/send_reminders?id=" + this.distributeId + "&user_public_id=" + this.reminderUserList[i] + "&module=" + this.$route.meta.route_ref, { cancelToken: this.axiosSource.token } )
                if (this.$cookies.get("dist_file")) {
                  this.distributeData.hide_quiz = 1
                }
                await HTTP_formdata.post( "distribution/distribute", this.distributeData, { cancelToken: this.axiosSource.token }  )
                  .then(response => {
                    if ( response.status == 200 ) {
                      // console.log(response.data);
                      // console.log('success sent');
                      this.clearOldRequest();
                      this.respStatus.push({ status: "success", msgId: response.data.flag, msg: response.data.response });
                      this.distributionCompletedCount++;
                      this.hundredCounter++;
                    }
                  })
                  .catch(err => {
                    if (axios.isCancel(err)) {
                    } else {
                      // console.log('onLine-- ',this.onLine);
                      // console.log('completedCount-- ',this.distributionCompletedCount);
                      // console.log('hundredCounter-- ',this.hundredCounter);
                      this.clearOldRequest();
                      if ( !err.response ) {
                        // console.log('***************if no response from reminder api*********** Error: Network Error');
                        errorCheck = 1;
                      } else if ( err.response.status == 400 ) {
                        // console.log('failed sent');
                        this.respStatus.push({ status: "fail", msgId: err.response.data.flag, msg: err.response.data.response });
                        this.distributionCompletedCount++;
                        this.hundredCounter++;
                      } else {
                        // console.log('else if error is not 400');
                        errorCheck = 1;
                        // console.log('failed sent 500');
                        // this.respStatus.push({ status: "fail", msgId: 4, msg: "Internal Server Error - 500" });
                        // this.completedCount++;
                        // this.hundredCounter++;
                      }
                    }
                  });
                  if (errorCheck == 1) {
                    errorCheck = 0;
                    // console.log("errorCheck is 1");
                    break;
                  }
              }
            }
          } else {
            // console.log("no net in the middle of loop")
            break;
          }
        }
        setTimeout(() => {
          if ( this.distributionCompletedCount == this.distributionTotalCount ) {
            clearInterval(this.timer);
            this.timer = false;
            let respDetail = [ { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" }, { count: 0, msg: "", type: "" } ];

            for ( const obj of this.respStatus ) {
              if ( obj.msgId == '1' ) { respDetail[0].count++; respDetail[0].msg = obj.msg; respDetail[0].type = obj.status; }
              else if ( obj.msgId == '2' ) { respDetail[1].count++; respDetail[1].msg = obj.msg; respDetail[1].type = obj.status; }
              else if ( obj.msgId == '3' ) { respDetail[2].count++; respDetail[2].msg = obj.msg; respDetail[2].type = obj.status; }
              else if ( obj.msgId == '4' ) { respDetail[3].count++; respDetail[3].msg = obj.msg; respDetail[3].type = obj.status; }
              else if ( obj.msgId == '5' ) { respDetail[4].count++; respDetail[4].msg = obj.msg; respDetail[4].type = obj.status; }
            }

            let result = respDetail.filter( function( item ) {
              if ( item.count > 0 ) return true;
              else return false;
            });

            this.messsage_loader = false;
            this.count_loader = false;

            let resultCheck = 0;
            resultCheck = result.filter( item => item.type == 'success' ).length;
            // console.log(resultCheck);

            let responseData = {
              popup: true,
              data: result,
              title: 'Distribution in progress',
              loader: ( resultCheck > 0 ) ? true : false
            };
            EventBus.$emit("confirm_count_resp", responseData);

            // console.log('----------Report------------------');
            // console.log('totalCount', this.distributionTotalCount);
            // console.log('completedCount', this.distributionCompletedCount);
            // console.log('hundredCounter', this.hundredCounter);

            // setTimeout(() => {
            //   EventBus.$emit( "confirmPopUPClose", true );
            //   this.reminderCheck = false;
            //   this.timerCheck = false;
            //   this.counterCheck = false;
            //   this.distributionTotalCount = 0;
            //   this.reminderUserList = [];
            //   this.distributionCompletedCount = 0;
            //   this.hundredCounter = 0;
            // }, 2000);

            if ( resultCheck > 0 ) {
              let jsonData = {
                module: ( this.$route.params.type == 'videogramme' ) ? this.$route.params.type : this.$route.params.type.slice(0, -1),
                criteria: ( this.distributeData.distribute_by == 'specific_users' ) ? this.distributeData.distribute_by : 'other_criteria'
              }
              HTTP_formdata.put( "distribution/check_previous_distributions/" + this.$route.params.distribute_id, jsonData )
                .then(response => {
                  if ( response.status == 200 ) {
                    setTimeout(() => {
                      // let resp = { msg: response.data.message, popup: true };
                      // EventBus.$emit( "success_resp", resp );
                      EventBus.$emit( "confirmPopUPClose", true );
                      if (this.$route.params.type == "briefings") {
                        setTimeout(() => {
                          this.$router.push({path:"/list_of_briefings"})
                        }, 2000);
                      } else if (this.$route.params.type == "videogramme") {
                        setTimeout(() => {
                          this.$router.push({path:"/list_of_videogramme"})
                        }, 2000);
                      } else {
                        setTimeout(() => {
                          this.$router.push({path:"/list_of_trainings"})
                        }, 2000);
                      }
                    }, 2000);
                  }
                })
                .catch(err => {
                  if ( err.response.status == 400 ) {
                    setTimeout(() => {
                      // let resp = { msg: err.response.data.message, popup: true };
                      // EventBus.$emit( "error_resp", resp );
                      EventBus.$emit( "confirmPopUPClose", true );
                      if (this.$route.params.type == "briefings") {
                        setTimeout(() => {
                          this.$router.push({path:"/list_of_briefings"})
                        }, 2000);
                      } else if (this.$route.params.type == "videogramme") {
                        setTimeout(() => {
                          this.$router.push({path:"/list_of_videogramme"})
                        }, 2000);
                      } else {
                        setTimeout(() => {
                          this.$router.push({path:"/list_of_trainings"})
                        }, 2000);
                      }
                    }, 2000);
                  }
                });
            } else {
              setTimeout(() => {
                EventBus.$emit( "confirmPopUPClose", true );
                if (this.$route.params.type == "briefings") {
                  setTimeout(() => {
                    this.$router.push({path:"/list_of_briefings"})
                  }, 2000);
                } else if (this.$route.params.type == "videogramme") {
                  setTimeout(() => {
                    this.$router.push({path:"/list_of_videogramme"})
                  }, 2000);
                } else {
                  setTimeout(() => {
                    this.$router.push({path:"/list_of_trainings"})
                  }, 2000);
                }
              }, 2000);
            }
          }
        }, 2000);
      }
    },
    networkConnect( val ) {
      let network = ( val ) ? val : this.onLine;
      if ( network != null ) {
        // console.log("not null")
        // console.log(network)
        if ( network == false ) {
          this.$bvModal.show("modal-no-internet");
          this.counterAPICheck = ( this.initialCountCheck == true || this.exclusionCheck == true ) ? true : false;
          if ( this.request ) this.cancel();
        } else if ( network == true ) {
          this.$bvModal.hide("modal-no-internet");
          if ( this.counterAPICheck == true ) {
            if ( this.initialCountCheck == true ) {
              if ( this.addHistoryCheck == true ) {
                if ( this.timerCheck == true ) {
                  if ( this.counterCheck == true ) this.sendDistributeInitial();
                  else {
                    if ( this.timer == false ) this.setAPITimer(); else this.sendDistributeInitial();
                  }
                } else
                  this.addHistory();
              } else
                this.sendDistribution();
            } else if ( this.exclusionCheck == true ) {
              this.sendExclusion();
            }
            this.counterAPICheck = false;
          }
        }
      } else {
        // console.log("null")
      }
    }
  },
  watch: {
    onLine( val ) {
      if ( val != null ) {
        // console.log("distribute", val);
        this.networkConnect( val );
      }
    },
    "stationEmpGroupData.stations": {
      handler(newVal) {
        // console.log("-----------------------");
        // console.log(newVal);
        this.stationSelected(newVal)
        // this.userResult = [];
        // this.manageUserList();
        deep: true;
      }
    }
    // if ( val == false ) {
    //   if ( this.distributeLength > 0 ) {
    //     this.APICont = true;
    //     console.log("net false");
    //     this.$bvModal.show("modal-no-internet");
    //     // this.save_distribution_data();
    //     // this.$refs['modal-no-internet'].show();
    //   }
    // } else if ( val == true ) {
    //   if ( this.distributeLength > 0 && this.APICont == true ) {
    //     this.APICont = false;
    //     console.log("net true");
    //     this.$bvModal.hide("modal-no-internet");
    //     this.save_distribution_data();
    //     // this.$refs['modal-no-internet'].hide();
    //   }
    // }
  },
  beforeMount() {
    this.networkConnect();
    this.stationOptions = [];
    let jsonData = { area: this.distributeData.area, code: "" }
    HTTP_formdata.post( "users/get_stations", jsonData )
      .then(response => {
        if ( response.status == 200 ) {
          for ( let i = 0; i < response.data.length; i++ ) {
            this.stationOptions.push({ text: response.data[i].code, value: response.data[i].public_id });
          }
        }
      })
      .catch(error => {});
    this.distribution_list_page_name = this.distribution_page_name.charAt(0).toUpperCase() + this.distribution_page_name.slice(1);
    this.distribution_page_name = this.distribution_list_page_name.replace(new RegExp("s$","ig"), "")
  },
  mounted() {
    this.showDistribution = this.$route.params.type == "briefings" ? true : false;
    HTTP.get( "distribution/distributed_users?area=" + this.distributeData.area + "&area_id=" + this.distributeData.area_id )
      .then(response => {
        if ( response.status == 200 ) {
          this.previousDistributed = response.data.distributed
        }
      })
      .catch(err => {});
  }
};
</script>
<style scoped>
@import "./def_function.css";
.distribute-group {padding: 0 44px 0;}
.distribute-user-select {border: none;}
.history-icon {
    color: white;
    padding: 0;
    display: block;
    font-weight: bold;
    float: right;
    width: 50px;
    background: transparent;
    border: none;
}
.history-icon span {
    font-size: 28px;
    font-weight: 500;
}
.history-icon img {
    max-width: 100%;
}
.faq-active-icon.faq-active-icon-circle {
    border-radius: 50%;
    border: 6px solid #003261;
}
.faq-noTab a.btn {
    cursor: default;
}
.group-names-outer {
    border: 1px solid #dfdfdf;
    padding: 20px 15px 15px;
}
.group-names-outer .distribute-group-label {margin: -30px 0 0 0px;}
.group-names-outer .badge {
    padding: 7px 10px;
    color: #333;
    font-size: 14px;
    font-weight: normal;
    margin: 0 2px;
}
#faq-modalView > ul.pagination {
    margin: 20px 0 0;
}
#confirm-adding-internal button.btn {margin-right: 20px;}
.wait-text, .loading-text {
    color: #003261;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05em;
    z-index: 1;
  }
  .wait-text {
    margin-bottom: -10px;
  }
  .loading-text {
    margin-top: -10px;
}
  .spinner-div.load-outer {
    flex-direction: column;
  }
@media screen and (min-width: 992px) {
  .dist-by-outer {padding-left: 0;}
}
</style>