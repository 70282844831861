<template>
    <b-modal id="modal-training-pdf" 
    hide-footer
    size="lg"
    modal-class="flipModal manage-user-modal"
    title="Training Results Preview"
    centered
    class="training-pdf-div">
        <div class="pdf-btns-inner">
            <b-button variant="light" @click="downloadPDF()" class="mt-4">Download</b-button>
        </div>
        <div id="training-pdf-outer">
            <b-container>
                <div ref="content">
                    <b-row>
                        <b-col>
                            <h3 class="mb-5">Training Results</h3>
                        </b-col>
                    </b-row>
                    <b-row class="label-row">
                        <b-col><label>Filter Results by: <span>{{pdfDetails.filter}}</span></label></b-col>
                        <b-col><label>Value: <span>{{pdfDetails.valueFilter}}</span></label></b-col>
                        <!-- <b-col><label>Function: <span>{{pdfDetails.functionFilter}}</span></label></b-col> -->
                    </b-row>
                    <b-row class="label-row">
                        <b-col><label><span>{{pdfDetails.type }} Training</span></label></b-col>
                        <b-col><label>From: <span>{{pdfDetails.from_date|moment}}</span></label></b-col>
                        <b-col><label>To: <span>{{pdfDetails.to_date|moment}}</span></label></b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col>
                        <div class="def-func-table manage-users-table mb-1">
                            <table striped responsive id="training-result-table" ref="table">
                                <thead>
                                    <tr>
                                        <th class="text-left" style="width: 42%"><span>Training</span></th>
                                        <th class="text-left" style="width: 20%"><span>Employee</span></th>
                                        <th class="text-left"><span>Station</span></th>
                                        <th class="text-left" style="width: 15%"><span>Due Date</span></th>
                                        <th class="text-left" style="width: 18%"><span>Date Completed</span></th>
                                        <th class="text-center" style="width: 15%"><span>Score</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="( data, index ) in resultData" :key="index">
                                        <td class="text-left">{{ data.title }}</td>
                                        <td class="text-left">{{ data.employee_name }}</td>
                                        <td class="text-left">{{ data.station }}</td>
                                        <td class="text-left">{{ (data.due_date) ? data.due_date : "" | moment }}</td>
                                        <td class="text-left">{{ (data.date_completed) ? data.date_completed : ""  | moment}}</td>
                                        <td>{{ data.score | score }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-col>
                </b-row>
            </b-container>            
        </div>
        <div class="clearfix"></div>
    </b-modal>
</template>
<script>
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { EventBus } from "../../main";
import resultTable from "../../components/admin_layouts/dashboard_layouts/training_result_table";
import jsPDF from "jspdf";
import html2canvas  from 'html2canvas';
import moment from "moment";
import 'jspdf-autotable';

export default {
    props:["trainingData"],
    data() {
        return{
            resultData: [],
            resultFilter: "",
            filterName: "", 
            pdfDetails: {
                filter: "",
                value: "",
                function: "",
                from_date: "",
                to_date: "",
                type:""
            }
        }
    },
    filters: {
        moment: function(value) {
            if (value) {
                return moment(value).format("DD MMM YYYY");
            } else {
                return "-"
            }
        },
        score: function(value) {
            if ( value == null || value == "" )
                return value = "";
            else
                return value = value + " %";
        }
    },
    watch: {
        // trainingData(newval){
        //     console.log(newval)
        //     this.pdfDetails= {
        //         filter: newval[0].filter,
        //         value: newval[0].valueFilter,
        //         function: newval[0].functionFilter,
        //         from_date: newval[0].from_date,
        //         to_date: newval[0].to_date,
        //         type: newval[0].type
        //     }
        //     this.resultData = []
        //     this.resultData = newval[0].results
        //     console.log(this.resultData)
        // }
    },
    components: {
        resultTable
    },
    created: function() {
        EventBus.$on("downloadPDFClicked", pdfDetails => {
            this.pdfDetails = {
                from_date: pdfDetails.from_date,
                to_date: pdfDetails.to_date,
                filter: pdfDetails.filter,
                // functionFilter: ((pdfDetails.functionFilter == "Select") ? "-" : pdfDetails.functionFilter),
                valueFilter: ((pdfDetails.valueFilter == "Select") ? "-" : pdfDetails.valueFilter),
                type: pdfDetails.type
            };
            this.resultData = pdfDetails.results;
        });
    },
    methods: {
        downloadPDF(){
         var canvasElement = document.createElement('canvas');
         html2canvas(this.$refs.content, {scale: 1,scrollX: 0, scrollY: 0}).then(function (canvas) {
            var imgData = canvas.toDataURL('image/jpeg');
            var imgWidth = 380; 
            var pageHeight = 537;  
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'px', 'a4');
            var position = 0;
            doc.addImage(imgData, 'JPEG', 30, 30 , imgWidth, imgHeight);
            //const tableTop = canvas.height / 4.65;
            const tableTop = imgHeight * 1.61;
            const html = '';
            var res = doc.autoTableHtmlToJson(document.getElementById('training-result-table'));
            doc.autoTable({ 
                html: '#training-result-table', 
                startY: doc.pageCount > 1? doc.autoTableEndPosY() + 20 : tableTop,
                //theme: 'grid',
                headStyles: {
                    fillColor: [0, 50, 97],
                    fontSize: 10,
                    2: {minCellWidth: 50}, 
                    3: { minCellWidth: 70 },
                    4: {minCellWidth: 35}
                },
                columnStyles: { 2: { minCellWidth: 50 }, 3: { minCellWidth: 70 }, 4: {minCellWidth: 35} }
            })
         
            doc.fromHTML(html, 15, 15, {
                width: 794,
                height: 1123,
                
            })
         
            doc.save( 'Training Result.pdf');
         });
        }
    }
}
</script>
<style scoped>
@import "./def_function.css";
#modal-training-pdf .training-pdf-div {margin: 100px auto 20px;width: 754px;}
#modal-training-pdf #training-pdf-outer {width: 722px;min-height: 1060px;color: #485882;padding: 28px 0px 0;text-align: center;margin: 0 auto;max-width: 100%;}
#modal-training-pdf label {color: #333;font-size: 14px;}
#modal-training-pdf .pdf-btns-inner {margin: auto;text-align: right;max-width: 100%;padding: 0 1.5rem;}
#modal-training-pdf .label-row {text-align: left;margin-bottom: 10px;}
#modal-training-pdf label span {text-transform: capitalize;font-size: 14px;}
#modal-training-pdf .def-func-table {margin-top: 0;}
#modal-training-pdf .def-func-table tbody th, #modal-training-pdf .def-func-table tbody td {padding: 6px 10px;}
#modal-training-pdf.manage-user-modal .modal-body {padding: 0 0 2em;}
#modal-training-pdf.manage-user-modal .modal-dialog {max-width: 772px;}
#modal-training-pdf th {font-size: 12px;padding: 5px 10px;height: 38px;}
#modal-training-pdf .def-func-table thead th span, #modal-training-pdf table td {border: none;}
#modal-training-pdf table {border-collapse: collapse;}
#modal-training-pdf tbody tr:nth-child(2n+1) {background: #f5f5f5;}
#modal-training-pdf tbody tr td {border: none;}
#modal-training-pdf th:last-child {min-width: 65px;}
</style>