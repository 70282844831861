<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form class="input-padding breifing-content" @submit.prevent="passes( searchFilter )">
                  <div class="def-func-form">
                    <div class="def-func-input-form-group">
                      <div class="def-func-input-group check-results-sectn">
                        <b-row class="align-bottom">
                          <b-col class="def-func-input-group-col" sm="7" lg="7" xl="3">
                            <b-form-group>
                              <ValidationProvider name="Filter results by" rules="required" v-slot="{ valid, errors }">
                                <div class="required-field">
                                  <label for>Filter results by</label>
                                  <b-form-select v-model="filter"
                                  :state="errors[0] ? false : ( valid ? true : null )"
                                  :options="filterOptions" @change="filterChange">
                                  </b-form-select>
                                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col class="def-func-input-group-col">
                            <b-form-group>
                              <ValidationProvider name="Value" v-slot="{ errors }" :rules="`${filtervalid ? 'required' : ''}`">
                                <div class="required-field">
                                  <label for>Value</label>
                                  <b-form-select v-model="valueFilter" :disabled="filter == 'global'" :options="valueOptions">
                                  </b-form-select>
                                  <b-form-invalid-feedback style="display:block;">{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <!-- <b-col class="def-func-input-group-col" sm="4" lg="4" xl="2">
                            <b-form-group>
                              <ValidationProvider name="Function" v-slot="{ errors }" :rules="`${filtervalid ? '' : 'required'}`">
                                <div class="required-field">
                                  <label for>Function</label>
                                  <b-form-select v-model="functionFilter" :disabled="filter != 'global'" :options="functionResult">
                                  </b-form-select>
                                  <b-form-invalid-feedback style="display:block;">{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col> -->
                          <b-col class="def-func-input-group-col" sm="3" lg="3" xl="2">
                            <ValidationProvider name="From Date" rules="required" v-slot="{ valid, errors }">
                                <b-form-group id="from-Date" label="From" label-for="From_Date" class="required-field">
                                  <b-input-group class="date-field">
                                    <b-input-group-append is-text>
                                      <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                    </b-input-group-append>
                                    <Datepicker
                                      v-model="from_date"
                                      :disabled-dates="stateFrom.disabledDates"
                                    ></Datepicker>
                                  </b-input-group>
                                  <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                                  <input
                                    v-show="false"
                                    id="From_Date"
                                    name="From_Date"
                                    v-model="from_date"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    aria-describedby="from_Date_error"
                                  />
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="3" lg="3" xl="2">
                            <ValidationProvider name="To Date" rules="required" v-slot="{ valid, errors }">
                                <b-form-group id="to-Date" label="To" label-for="To_Date" class="required-field">
                                  <b-input-group class="date-field">
                                    <b-input-group-append is-text>
                                      <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                    </b-input-group-append>
                                    <Datepicker
                                      v-model="to_date"
                                      :disabled-dates="stateTo.disabledDates"
                                    ></Datepicker>
                                  </b-input-group>
                                  <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                                  <input
                                    v-show="false"
                                    id="To_Date"
                                    name="To_Date"
                                    v-model="to_date"
                                    :state="errors[0] ? false : ( valid ? true : null )"
                                    aria-describedby="to_Date_error"
                                  />
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col class="def-func-input-group-col align-el" sm="2" lg="1" xl="1">
                            <div class="def-func-input-group-col btn-add-outer">
                              <button type="submit" variant="light" class="btn add-btn">
                                <font-awesome-icon :icon="['fas', 'search']" />
                              </button>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
              <div class="clearfix"></div>
              <span v-show="showResults">
                <b-row v-show="reportDetail">
                  <b-col>
                    <div class="def-func-input-group-col mb-4 float-right">
                      <b-button :disabled="reportDetail.selected == ''" @click.prevent="showPdf" v-b-modal.modal-summary-pdf class="float-right mb-1" variant="light">Create PDF
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="def-func-input-form-group breifing-content">
                      <div class="def-func-input-group pb-3">
                        <div class="def-func-input-group-col width-10">
                          <label
                            class="global-freq freq-resp-title check-title"
                          >Click on individual tiles below to see details</label>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="access-tabs">
                      <b-form-radio-group
                        class="access-rights check-results-tiles"
                        name="admin"
                        v-model="reportDetail.selected"
                        @input="reportChange"
                      >
                        <b-form-radio value="responseRate" :disabled="reportData.response_rate == 0">
                          <p>Completion Rate</p>
                          <h2>{{ reportData.response_rate }}%</h2>
                          <img class="check-img" src="@/assets/images/check.png" />
                        </b-form-radio>
                        <b-form-radio value="complianceRatio" :disabled="reportData.compliance_ratio == 0">
                          <p>Compliance Ratio</p>
                          <h2>{{ reportData.compliance_ratio }}%</h2>
                          <img class="check-img" src="@/assets/images/check.png" />
                        </b-form-radio>
                        <b-form-radio value="critical_flaw" :disabled="reportData.critical_flaw == 0">
                          <p>Critical Flaws</p>
                          <h2>{{ reportData.critical_flaw }}</h2>
                          <img class="check-img" src="@/assets/images/check.png" />
                        </b-form-radio>
                        <b-form-radio value="failed" :disabled="reportData.failed == 0">
                          <p>Failed</p>
                          <h2>{{ reportData.failed }}</h2>
                          <img class="check-img" src="@/assets/images/check.png" />
                        </b-form-radio>
                        <b-form-radio value="passed" :disabled="reportData.passed == 0">
                          <p>Passed</p>
                          <h2>{{ reportData.passed }}</h2>
                          <img class="check-img" src="@/assets/images/check.png" />
                        </b-form-radio>
                        <b-form-radio value="above_avg" :disabled="reportData.above_avg == 0">
                          <p>Above Average</p>
                          <h2>{{ reportData.above_avg }}</h2>
                          <img class="check-img" src="@/assets/images/check.png" />
                        </b-form-radio>
                        <b-form-radio value="bdp" :disabled="reportData.bdp == 0">
                          <p>BDPs</p>
                          <h2>{{ reportData.bdp }}</h2>
                          <img class="check-img" src="@/assets/images/check.png" />
                        </b-form-radio>
                      </b-form-radio-group>
                    </div>
                  </b-col>
                </b-row>
              </span>
            </div>
          </b-col>
        </b-row>
        <span v-show="showTables">
          <reportTables :selectedReport="reportDetail" :reportData="reportData"></reportTables>
          <reportScoreTables :selectedReport="reportDetail" :reportData="reportData.report_details"></reportScoreTables>
        </span>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <reportPDF></reportPDF>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import reportTables from "../../components/admin_layouts/dashboard_layouts/reports_table";
import reportScoreTables from "../../components/admin_layouts/dashboard_layouts/report_score_table";
import reportPDF from "./reports_pdf";
import { HTTP_formdata, HTTP } from "../../constants/http-common";
import { EventBus } from "../../main";
export default {
  data() {
    return {
      filter: null,
      valueFilter: null,
      functionFilter: null,
      filterOptions: [
        { value: null, text: "Select", disabled: true },
        { value: "code", text: "Station" },
        { value: "specific_checklist", text: "Specific Checklist" }
        // { value: "global", text: "Global" }
      ],
      valueOptions: [
        { value: null, text: "Select", disabled: true }
      ],
      functionResult: [
        { value: null, text: "Select", disabled: true }
      ],
      from_date: "",
      to_date: "",
      stateTo: {
        disabledDates: {
          to: "",
          from: ""
        }
      },
      stateFrom: {
        disabledDates: {
          to: "",
          from: ""
        }
      },
      reportData: "",
      messsage_loader: false,
      showResults: false,
      showTables: false,
      reportDetail: {
        displayScope: false,
        displayRate: false,
        selected: ""
      },
      filtervalid: true
    };
  },
  components: {
    Datepicker,
    reportTables,
    reportScoreTables,
    reportPDF
  },
  watch: {
    from_date(val) {
      if (this.from_date) {
        this.stateTo.disabledDates.to = this.from_date;
      }
    },
    to_date(val) {
      if (this.to_date) {
        this.stateFrom.disabledDates.from = this.to_date;
      }
    }
  },
  methods: {
    filterChange() {
      this.valueOptions = [ { value: null, text: "Select", disabled: true } ];
      this.valueFilter = null;
      this.functionFilter = null;
      if ( this.filter != 'global') {
        this.filtervalid = true;
        let jsonData = {
          filter: this.filter
        }
        HTTP_formdata.post("audits/checklist_filters", jsonData)
          .then(response => {
            if ( response.status == 200 ) {
              this.valueOptions = [ { value: null, text: "Select", disabled: true } ];
              this.valueFilter = null;
              for (var i = 0; i < response.data.values.length; i++) {
                this.valueOptions.push({
                  value: response.data.values[i].value,
                  text: response.data.values[i].text
                });
              }
            }
          })
          .catch(error => {
            if ( error.response.status == 400 ) {
              this.valueOptions = [ { value: null, text: "Select", disabled: true } ];
            }
          });
      } else {
        this.filtervalid = false;
      }
    },
    // getFuncList() {
    //   HTTP.get("functions/display_functions")
    //     .then(response => {
    //       for (var i = 0; i < response.data.length; i++) {
    //         this.functionResult.push({
    //           value: response.data[i].public_id,
    //           text: response.data[i].title
    //         });
    //       }
    //     })
    //     .catch(err => {});
    // },
    searchFilter() {
      this.messsage_loader = true;
      let jsonData = {
        audit: (this.filter == "specific_checklist") ? this.valueFilter : "",
        station_code: (this.filter == "code") ? this.valueFilter : "",
        // region: (this.filter == "region") ? this.valueFilter : "",
        // global: (this.filter == "global") ? this.filter : "",
        // function: this.functionFilter,
        from_date: this.from_date,
        to_date: this.to_date
      };
      HTTP_formdata.post("audits/get_checklist_reports", jsonData)
        .then(response => {
          if ( response.status == 200 ) {
            this.messsage_loader = false;
            this.reportData = response.data[0].results;
            this.showResults = true;
          }
        })
        .catch(error => {
          if ( error.response.status == 400 || error.response.status == 404) {
            this.messsage_loader = false;
            this.showResults = false;
            this.reportData = "";
            this.reportDetail = {
              displayScope: false,
              displayRate: false,
              selected: ""
            };
            let resp = {
              msg: error.response.data.message,
              popup: true
            }
            EventBus.$emit("error_resp", resp);
          }
        });
    },
    reportChange() {
      if ( this.reportDetail.selected ) {
        this.showTables = true;
        this.reportDetail.displayRate = ( this.reportDetail.selected == "responseRate" || this.reportDetail.selected == "complianceRatio" ) ? true : false;
        this.reportDetail.displayScope = ( this.reportDetail.selected == "responseRate" || this.reportDetail.selected == "complianceRatio" ) ? false : true;
      } else {
        this.showTables = false;
      }
    },
    showPdf() {
      // let functionvalue = this.functionResult.find(e=>e.value == this.functionFilter)
      let valuename = this.valueOptions.find(e=>e.value == this.valueFilter)
      let filter = this.filterOptions.find(e=>e.value == this.filter)
      let PDFdata = {
        from_date: this.from_date,
        to_date: this.to_date,
        reportDetail: this.reportDetail,
        reportData: this.reportData,
        filter: filter.text,
        // functionFilter: functionvalue.text,
        valueFilter: valuename.text
      }
      EventBus.$emit( "downloadPDFClicked", PDFdata );
    }
  },
  beforeMount() {
    // this.getFuncList();
  }
};
</script>
<style>
@import "./def_function.css";
</style>
