<template>
<div>
    <table striped responsive>
    <thead>
    <tr>
        <th class="text-left">
        <span>Training</span>
        </th>
        <th class="text-left">
          <span>Format</span>
        </th>
        <th class="text-left table-date">
        <span>Due Date</span>
        </th>
        <th class="text-left table-date">
        <span>Date Completed</span>
        </th>
        <th class="text-left table-date">
        <span>Status</span>
        </th>
        <th class="text-center training-action">
        <span>Actions</span>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row,index) in data" :key="'completed_'+index">
        <td class="text-left">{{ row.title }}</td>
        <td class="text-left">{{ row.training_format }}</td>
        <td class="text-left" v-if="row.due_date == null">{{ row.due_date }}</td>
        <td class="text-left" v-else>{{ row.due_date.date | moment }}</td>
        <td class="text-left">{{ row.date_completed.date | moment }}</td>
        <td class="text-left">{{ row.status }}</td>
        <td class="text-center">
        <div class="manage-user-action training-action my-training-action">
            <button v-if="row.training_format == 'internal'">
                <router-link :to="'/revise_training/'+row.public_id">
                    <img src="@/assets/images/btn_startcheck.png" @click="trainingDetails( row )" title="Start Training" />
                </router-link>
            </button>
            <b-button v-if="row.training_format == 'external'" v-b-modal.modal-my-training @click="startTraining( row )">
              <img src="@/assets/images/btn_startcheck.png" title="Start Training" />
            </b-button>
            <button v-if="row.title !== 'Final greening Training' && row.training_format !== 'classroom' " v-b-modal.downloadPDF @click="getCertDetails(row)">
                <img src="@/assets/images/print.png" title="Print" />
            </button>   
            <!-- <a href="https://s3.us-east-2.amazonaws.com/dev.orgadynamics.com/user_training_distributes/upload_certificate/ + data.distribute_id + this.fileName" v-if="row.training_format == 'classroom'" target="blank" > -->
                <button  v-if="row.training_format == 'classroom'" v-b-modal.showAttachment @click="showAttachment(row)">
                    <img src="@/assets/images/print.png" title="View Uploaded Certificate" />
                </button> 
            <!-- </a> -->
            <!-- <button v-else @click.prevent class="btn-inactive">
                <img src="@/assets/images/print.png" title="Print" />
            </button> -->
        </div>
        </td>
    </tr>
    </tbody>
</table>
  <b-modal
      hide-footer
      id="downloadPDF"
      size="lg"
      modal-class="flipModal manage-user-modal"
      title="Certificate"
      centered
    >
    <certificate ></certificate>
  </b-modal>
  <b-modal
      hide-footer
      id="showAttachment"
      size="xl"
      modal-class="flipModal manage-user-modal"
      title="Uploaded attachment"
      centered
    >
    <div v-if="this.fileExtension.endsWith('jpg') || this.fileExtension.endsWith('jpeg') || this.fileExtension.endsWith('png')">
        <img :src= "attachmentSource" width="100%" height="100%" class="attachment-img" />
    </div>
    <div v-else>
        <iframe :src= "attachmentSource" width="100%" height="600px" frameborder="0"  />
    </div>
  </b-modal>
</div>
</template>
<script>
import { EventBus } from "../../../main";
import { HTTP } from "../../../constants/http-common";
import certificate from "./certificate";
import moment from "moment";
export default {
    props:["data"],
    components: {
        certificate
    },
    data() {
        return {
            downloadPDF: false,
            fileName: null,
            showImage: false,
            attachmentSource: " ",
            fileExtension : " "
        };
    },
    methods: {
        showAttachment(row) {
            this.showImage = !this.showImage
            this.attachmentSource = row.cert_file
            const fileExtension = row.certificate
            const extention = fileExtension.split('.').pop()
            this.fileExtension = extention
            console.log(this.fileExtension, 'attachmentSource')
        },
        // clickMe() {

        // } ,
        // getCert(){
        //     console.log(this.fileName)
        //     // HTTP.get( "user_training_distributes/upload_certificate/" + data.distribute_id + this.fileName )
        //     // .then(response => {
        //     //   if ( response.status == 200 ) {
        //     //     let res = {
        //     //       popup: true,
        //     //       msg: response.data.message
        //     //     }
        //     //   }
        //     // })
        // },
        getCertDetails(data) {
            console.log('data_console', data.title)
            HTTP.get("training/get_training_certificate/" + data.distribute_id)
                .then(response => {
                let pdfDetails = {
                    user_name: response.data[0].user_name,
                    training_title: response.data[0].title,
                    date_completed: response.data[0].date_completed,
                    modules_completed: response.data[0].modules_completed
                }
                    EventBus.$emit( "downloadPDFClicked", pdfDetails );
                })
                .catch(err => {});
        },
        trainingDetails( result ) {
            this.$cookies.set("__train", result);
        },
        startTraining( data ) {
          data.header = "User Information - External";
          EventBus.$emit( "startTrainingData", data );
        }
    },
    beforeCreate() {
        if ( this.$cookies.isKey("__train") == true )
            this.$cookies.remove( "__train" );
    },
//     created() {
//       console.log('test123')
//       EventBus.$on( "uploadedFile", (data) => {
//         console.log('data', data)
//         this.fileName = data;
//       }); console.log(this.fileName)
// },
    filters: {
        moment: function(value) {
            return moment(value).format("DD MMM YYYY");
        }
    },
}
</script>
<style>
    tr td div .btn-inactive {
        opacity: 0.15;
        cursor: default;
    }

    .attachment-img {
        vertical-align: middle;
        /* height: fit-content; */
        width: auto;
        max-width: 100%;
        margin: auto;
        display: block;
    }
</style>