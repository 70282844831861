<template>
  <vue-bootstrap-typeahead
    :data="typeData"
    ref="typeaheadexclusionuser"
    v-model="typesearch"
    :serializer="item => item.name"
    placeholder="Last Name, First Name OR Email Address"
    :minMatchingChars="1"
    @hit="selectedTypeAhead = $event"
  ></vue-bootstrap-typeahead>
</template>
<script>
import { HTTP } from "../../../constants/http-common";
import { EventBus } from "../../../main";
export default {
  props: [ "api" ],
  data() {
    return {
      typeData: [],
      typesearch: "",
      selectedTypeAhead: null
    };
  },
  watch: {
    typesearch(newQuery) {
      if (newQuery) {
        HTTP.get( this.api + "&filter=" + newQuery )
          .then(response => {
            if ( response.status == 200 ) {
              this.typeData = response.data.data;
            }
          })
          .catch(err => {
            if (this.$refs.typeaheadexclusionuser)
              this.$refs.typeaheadexclusionuser.inputValue = ''
            this.typesearch = ""
            this.typeData = [];
            EventBus.$emit( "typeahead_search_exclusion_user", this.typesearch );
          });
      } else {
        if (this.$refs.typeaheadexclusionuser)
          this.$refs.typeaheadexclusionuser.inputValue = ''
        this.typeData = [];
        EventBus.$emit( "typeahead_search_exclusion_user", this.typesearch );
      }
    },
    selectedTypeAhead(newQuery) {
      EventBus.$emit( "typeahead_search_exclusion_user", newQuery );
    }
  },
  created() {
    EventBus.$on("clearExclusion", val => {
      if ( this.$refs.typeaheadexclusionuser )
        this.$refs.typeaheadexclusionuser.inputValue = "";
      this.typesearch = "";
      this.typeData = [];
    });
  }
};
</script>