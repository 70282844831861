<template>
  <b-row>
    <b-col>
      <div class="def-func-input-form-group">
        <div class="perform-content">
          <span v-for="( report, index ) in reportData" :key="index">
            <div class="training-questns-section">
              <div class="training-questn-col questn-number">
                <b-badge class="questn-badge">{{ index + 1 }}</b-badge>
              </div>
              <div class="training-questn-col questn">
                <label>{{ report.question }}</label>
              </div>
            </div>
            <div class="training-options-section mb-5">
              <div class="perform-content-col">
                <div class="training-options-header perform-options-header">
                  <b-form-group class="perform-scoreOptions add-media-inner">
                    <b-form-radio-group disabled :id="'radio-group-' + (index)" :name="'score' + (index)" v-model="report.answers" class="score-options">
                      <label class="scoreOptions-title">Score:</label>
                      <b-form-radio value="Critical Flaw">Critical Flaw</b-form-radio>
                      <b-form-radio value="Failed">Failed</b-form-radio>
                      <b-form-radio value="Passed">Passed</b-form-radio>
                      <b-form-radio value="Above Average">Above Average</b-form-radio>
                      <b-form-radio value="BDP">BDP</b-form-radio>
                      <b-form-radio value="Not Applicable" class="perform-notAppl">Not Applicable</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
                <div class="training-options-body">
                  <label class="checkers_comments" :for="'checkers_comments' + (index)">Checkers comments</label>
                  <b-form-textarea disabled v-model="report.remarks" :id="'checkers_comments' + (index)" rows="5" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="perform-image-col">
                <b-carousel v-if="report.images && report.images.length > 0"
                :id="'carousel-1' + (index)" v-model="slide" :interval="4000" controls indicators
                  background="#ababab" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                  <b-carousel-slide v-for="( imgsrc, imgindex ) in report.images" :key="imgindex" :img-src="imgsrc.name" crossOrigin=”anonymous”>
                  </b-carousel-slide>
                </b-carousel>
                <img v-if="report.images && report.images.length > 0" @click.prevent="zipImageDownload( report.images, report.question )" src="@/assets/images/download.png" title="Download" />
              </div>
              <div class="clearfix"></div>
            </div>
          </span>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  import JSZip from 'jszip';
  import JSZipUtils from 'jszip-utils';
  import { saveAs } from 'save-as';
  export default {
    props: [ "reportData" ],
    data() {
      return {
        slide: 0,
        sliding: null,
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
      zipImageDownload( data, check ) {
        let zip = new JSZip();
        let zipFilename = check.replace(/\s+/g, '_').toLowerCase() + ".zip";
        let images = [];
        let counter = 0;
        for ( let i = 0; i < data.length; i++ ) {
          images.push({ url: data[i].name + '?callback=?', name: data[i].file_name })
        }
        images.forEach( function( image, index ) {
          var filename = image.name;
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent( image.url, function ( err, data ) {
            if ( err ) {
              throw err; // or handle the error
            }
            counter++;
            zip.file( filename, data, { binary: true } );
            if ( index + 1 == images.length ) {
              zip.generateAsync( { type: "blob" } ).then( function( content ) {
                saveAs( content, zipFilename );
              });
            }
          });
        });
      }
    }
  }
</script>